import React, { useState } from "react";
import PagesIndex from "../../../../PageIndex";
import Index from "../../../..";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const rows = [
  { name: "Privacy Policy", value: "privacyPolicy" },
  { name: "Refund Policy", value: "refundPolicy" },
  { name: "Term & Condition", value: "termCondition" },
];

const OfficerContentManagement = () => {
  const navigate = PagesIndex.useNavigate();
  //Getting data from redux
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  const SubAdminPermission = adminLoginData?.roleId?.SubAdminPermission;
  // const roleName = adminLoginData?.name;
  const roleName = adminLoginData?.roleId?.Permission_name;
  // Delete new
  const [openDeleteNew, setOpenDeleteNew] = useState(false);
  const handleOpenDeleteNew = () => setOpenDeleteNew(true);
  const handleCloseDeleteNew = () => setOpenDeleteNew(false);

  const handleCmsRoute = (value) => {
    if (value === "privacyPolicy") {
      navigate("/admin/admincontentmanagement/officer-privacy-policy", {
        state: "officer",
      });
    } else if (value === "termCondition") {
      navigate("/admin/admincontentmanagement/officer-terms-conditions", {
        state: "officer",
      });
    } else if (value === "refundPolicy") {
      navigate("/admin/admincontentmanagement/officer-refund-policy", {
        state: "officer",
      });
    }
  };



  // const handleOfficerDelete = () => {
  //   setIsLoading(true);
  //   const id = deleteOfficerId?._id;
  //   DataService.get(`${Api.Admin.DELETE_OFFICER}/${id}`)
  //     .then((response) => {
  //       if (response?.data?.status === 200) {
  //         PagesIndex.toast.success(response?.data?.message);
  //         handleCloseDeleteNew();
  //         getOfficerListData();
  //         setTimeout(() => {
  //           setIsLoading(false);
  //         }, 2000);
  //       }
  //     })
  //     .catch((err) => {
  //       PagesIndex.toast.error(err.response.data.message);
  //       setIsLoading(false);
  //     });
  // };


  const handleOfficerDeleteConfirm = () => {
    // handleOfficerDelete();
  };

  return (
    <>
      <Index.Box className="officer-content-sec">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Content Management
          </Index.Typography>
        </Index.Box>


        <Index.Box className="common-card">
        <Index.Box className="admin-card-title-main">
            <Index.Typography
              className="admin-card-title"
              component="h2"
              variant="h2"
            >
              CMS (Officer)
            </Index.Typography>
          </Index.Box>
          <Index.Box className="page-table-main officer-content-table-main">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table"
              >
                <Index.TableHead className="table-head">
                  <Index.TableRow className="table-row">
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="90%"
                    >
                      Content
                    </Index.TableCell>

                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="10%"
                      align="right"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody className="table-body">
                  {rows.map((row, index) => (
                    <Index.TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                      >
                        {row.name}
                      </Index.TableCell>

                      <Index.TableCell
                        component="td"
                        variant="td"
                        className="table-td"
                      >
                        <Index.Box className="page-table-btn-main btn-main-secondary btn-main-red">
                          {roleName === "Admin" ||
                            SubAdminPermission?.CMS?.permission?.edit ==
                            true ? (
                            <Index.Button className='btn-secondary'
                              onClick={() => handleCmsRoute(row.value)}
                            >
                              <img
                                src={PagesIndex.Svg.editNew}
                                className="admin-icons"
                                alt="plus icon"
                              />
                            </Index.Button>
                          ) : null}

                          {/* {roleName === "Admin" ||
                          SubAdminPermission?.CMS?.permission?.delete ==
                          true ? (
                        <Index.Button className='btn-red'
                            onClick={handleOpenDeleteNew}
                          >
                            <img
                              src={PagesIndex.Svg.deletewhite}
                              className="admin-icons"
                              alt="plus icon"
                            />
                          </Index.Button>
                        ) : null} */}
                        </Index.Box>
                      </Index.TableCell>
                    </Index.TableRow>
                  ))}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>

        <Index.Modal
          open={openDeleteNew}
          onClose={handleCloseDeleteNew}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-delete modal"
        >
          <Index.Box
            sx={style}
            className="add-user-modal-inner-main modal-inner extra-delete"
          >
            <Index.Box className="modal-header">
              <Index.Typography
                id="modal-modal-title"
                className="modal-title"
                variant="h6"
                component="h2"
              >
              </Index.Typography>
              <img
                src={PagesIndex.Svg.closeblack}
                className="modal-close-icon"
                onClick={handleCloseDeleteNew}
                alt=""
              />
            </Index.Box>
            <Index.Box className="modal-body">

              <Index.Box className="modal-body">
                <Index.Box className="admin-modal-hgt-scroll">
                  <Index.Box className="delete-modal-body">
                    <img
                      src={PagesIndex.Svg.deletecross}
                      alt="Delete"
                      className="delete-icon"
                    />
                    <Index.Typography component="h6" className="delete-title">Are you sure?</Index.Typography>
                    <Index.Typography component="p" className="
                delete-message">
                      Do you really want to Delete this record?
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="modal-footer">
                <Index.Box className="modal-footer-btn-flex">
                  <Index.PrimaryButton
                    btnLabel="Delete"
                    className="btn-primary bg-approve"
                    onClick={() => handleOfficerDeleteConfirm()}
                  />

                  <Index.PrimaryButton
                    btnLabel="Cancel"
                    className="btn-primary bg-suspend"
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Modal>
      </Index.Box>
    </>
  );
};

export default OfficerContentManagement;
