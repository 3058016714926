import React, { useRef, useState } from "react";
import Index from '../../../index';
import PagesIndex from '../../../PageIndex';
import { SwipeableDrawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// tabbing start 
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Index.Box sx={{ p: 3 }}>
                    <Index.Typography>{children}</Index.Typography>
                </Index.Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
// tabbing end

// grid start
const Item = styled(Index.Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
//  end
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

export default function CategoryManagementPlatformCategories() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [age, setAge] = React.useState('');

    const handleChangedropdown = (event) => {
        setAge(event.target.value);
    };

    function createData(id, categoryName, createdBy, createdOn, description, action) {
        return { id, categoryName, createdBy, createdOn, description, action };
    }
    const rows = [
        createData(
            "Id",
            "Category Name",
            "User Name",
            "00/00/0000",
            "Description"
        ),
        createData(
            "Id",
            "Category Name 1",
            "Admin",
            "00/00/0000",
            "Description"
        ),
        createData(
            "Id",
            "Category Name 2",
            "Admin",
            "00/00/0000",
            "Description"
        ),
        createData(
            "Id",
            "Category Name ",
            "User Name",
            "00/00/0000",
            "Description"
        ),
    ];

    // add user modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // edit modal
    const [openEdit, setopenEdit] = React.useState(false);
    const handleopenEdit = () => setopenEdit(true);
    const handleCloseEdit = () => setopenEdit(false);

    // Delete new
    const [openDeleteNew, setOpenDeleteNew] = useState(false);
    const handleOpenDeleteNew = () => setOpenDeleteNew(true);
    const handleCloseDeleteNew = () => setOpenDeleteNew(false);

    // reject modal
    const [openReject, setOpenReject] = useState(false);
    const handleOpenReject = () => setOpenReject(true);
    const handleCloseReject = () => setOpenReject(false);

    // filter
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Index.Box className="filter-main"
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
        </Index.Box>
    );
    return (
        <div>
            {/* head filter start */}
            <Index.Box className="user-list-flex">
                <Index.Typography className='admin-page-title user-list-page-title' component='h2' variant='h2'>
                    <Index.Link to="/admin/dashboard" className="filter-back-link">
                        <img
                            src={PagesIndex.Svg.leftArrow}
                            className="left-arrow-ic"
                            alt="search grey img"
                        ></img>
                    </Index.Link>{" "}
                    Category
                </Index.Typography>
                <Index.Box className="userlist-btn-flex">
                    <Index.Box className="user-search-main">
                        <Index.Box className="user-search-box">
                            <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder="Search"
                                />
                                <img
                                    src={PagesIndex.Svg.search}
                                    className="search-grey-img" alt='search grey img'
                                ></img>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-main">
                        {['right'].map((anchor) => (
                            <React.Fragment key={anchor}>
                                <Index.Box className="export-btn-main border-btn-main">
                                    <Index.Button className='export-btn border-btn' onClick={toggleDrawer(anchor, true)}><img src={PagesIndex.Svg.filter} className="down-icon" alt='download icon' />Filter</Index.Button>
                                </Index.Box>
                                <SwipeableDrawer
                                    className='filter-main'
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                    onOpen={toggleDrawer(anchor, true)}
                                >
                                    <Index.Box className="filter-header">
                                        <Index.Typography className='filter-title'>User Filter</Index.Typography>
                                        <img src={PagesIndex.Svg.closeicon} className="filter-close-icon" onClick={toggleDrawer(anchor, false)} />
                                    </Index.Box>
                                    <Index.Box className="filter-inner-main">
                                        <Index.Box className="input-box filter-input-box">
                                            <Index.FormHelperText className='form-lable'>Name</Index.FormHelperText>
                                            <Index.Box className="form-group">
                                                <Index.TextField
                                                    fullWidth
                                                    id="fullWidth"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                            </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="input-box filter-input-box">
                                            <Index.FormHelperText className='form-lable'>City</Index.FormHelperText>
                                            <Index.Box className="form-group">
                                                <Index.TextField
                                                    fullWidth
                                                    id="fullWidth"
                                                    className="form-control"
                                                    placeholder=""
                                                />
                                            </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="input-box filter-input-box">
                                            <Index.FormHelperText className='form-lable'>Status</Index.FormHelperText>
                                            <Index.Box className='checkbox-main filter-checkbox-main input-box'>
                                                <Index.FormControlLabel control={<Index.Checkbox defaultChecked />} label="Active" className='checkbox-lable' />
                                                <Index.FormControlLabel control={<Index.Checkbox />} label="Deactivate" className='checkbox-lable' />
                                                <Index.FormControlLabel control={<Index.Checkbox />} label="Pending" className='checkbox-lable' />
                                            </Index.Box>
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="filter-footer">
                                        <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                                            <Index.Button className="border-btn filter-cancel-btn">Cancel</Index.Button>
                                            <Index.Button className="btn-primary filter-btn">Filter</Index.Button>
                                        </Index.Box>
                                    </Index.Box>
                                </SwipeableDrawer>
                            </React.Fragment>
                        ))}
                    </Index.Box>
                    <Index.Box className="userlist-inner-btn-flex">

                        <Index.Box className="adduser-btn-main btn-main-primary">
                            <Index.Button className='adduser-btn btn-primary' onClick={handleOpen}><img src={PagesIndex.Svg.plus} className="plus-icon" alt='plus icon' />Add Category</Index.Button>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
            {/* Edit User Details start */}

            {/* work table start */}
            <Index.Box className="admin-dashboard-list-row">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                    <Index.Box
                        display="grid"
                        className="display-row"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                    >
                        <Index.Box
                            gridColumn={{
                                xs: "span 12",
                                sm: "span 12",
                                md: "span 12",
                                lg: "span 12",
                            }}
                            className="grid-column"
                        >
                            <Index.Box className="admin-dash-box box-shadow category-list-wrape">

                                <Index.Box className="page-table-main">
                                    <Index.Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        {/* tabbing start  */}
                                        <Index.Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons
                                            allowScrollButtonsMobile aria-label="basic tabs example" className='cus-tabs-main'>
                                            <Index.Tab label="Platform Categories" {...a11yProps(0)} className="cus-tab" />
                                            <Index.Tab label="Category Requests from Users" {...a11yProps(1)} className="cus-tab" />
                                        </Index.Tabs>
                                    </Index.Box>
                                    <TabPanel value={value} index={0} className="cus-tabpanel">
                                        {/* table start */}
                                        <Index.TableContainer
                                            component={Index.Paper}
                                            className="table-container res-over-hide"
                                        >
                                            <Index.Table
                                                sx={{ minWidth: 700 }}
                                                aria-label="simple table"
                                                className="table res-plateform-tbl category-page-table"
                                            >
                                                <Index.TableHead className="table-head">
                                                    <Index.TableRow className="table-row">
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >
                                                            Id
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >
                                                            Category Name
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >
                                                            Created By
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >
                                                            Created On
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >
                                                            Description
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th action-head"
                                                        >
                                                            Action
                                                        </Index.TableCell>
                                                    </Index.TableRow>
                                                </Index.TableHead>
                                                <Index.TableBody className="table-body">
                                                    {rows.map((row) => (
                                                        <Index.TableRow
                                                            key={row.id}
                                                            sx={{
                                                                "&:last-child td, &:last-child th": {
                                                                    border: 0,
                                                                },
                                                            }}
                                                        >
                                                            <Index.TableCell
                                                                component="td"
                                                                variant="td"
                                                                scope="row"
                                                                className="table-td"
                                                            >
                                                                {row.id}
                                                            </Index.TableCell>
                                                            <Index.TableCell
                                                                component="td"
                                                                variant="td"
                                                                scope="row"
                                                                className="table-td"
                                                            >
                                                                {row.categoryName}
                                                            </Index.TableCell>
                                                            <Index.TableCell
                                                                component="td"
                                                                variant="td"
                                                                scope="row"
                                                                className="table-td"
                                                            >
                                                                {row.createdBy}
                                                            </Index.TableCell>
                                                            <Index.TableCell
                                                                component="td"
                                                                variant="td"
                                                                scope="row"
                                                                className="table-td"
                                                            >
                                                                {row.createdOn}
                                                            </Index.TableCell>
                                                            <Index.TableCell
                                                                component="td"
                                                                variant="td"
                                                                scope="row"
                                                                className="table-td"
                                                            >
                                                                {row.description}
                                                            </Index.TableCell>
                                                            <Index.TableCell
                                                                component="td"
                                                                variant="td"
                                                                className="table-td action-cell"
                                                            >
                                                                <Index.Button className='adduser-btn btn-primary icon' onClick={handleopenEdit}>
                                                                    <img src={PagesIndex.Svg.editNew} className="plus-icon" alt='edit icon' /></Index.Button>
                                                                <Index.Button className='adduser-btn btn-primary bg-suspend icon' onClick={handleOpenDeleteNew}>
                                                                    <img src={PagesIndex.Svg.deletewhite} className="plus-icon" alt='delete icon' /></Index.Button>
                                                            </Index.TableCell>
                                                        </Index.TableRow>
                                                    ))}
                                                </Index.TableBody>
                                            </Index.Table>
                                        </Index.TableContainer>
                                        {/* table end */}
                                    </TabPanel>
                                    <TabPanel value={value} index={1} className="cus-tabpanel">
                                        <Index.Box className="card-main">
                                            <Index.Box className="card-left">
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Category Name :
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Requested by:&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        Username
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Requested on :&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        00 /00/0000
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Description :&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        "Description will be shown here"
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Pay Rate :&nbsp;
                                                    </Index.Typography>
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="card-right">
                                                <Index.PrimaryButton
                                                    btnLabel="Approve"
                                                    className="btn-primary bg-approve" />
                                                <Index.PrimaryButton
                                                    onClick={handleOpenReject}
                                                    btnLabel="Reject"
                                                    className="btn-primary bg-suspend" />
                                            </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="card-main">
                                            <Index.Box className="card-left">
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Category Name :
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Requested by:&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        Username
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Requested on :&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        00 /00/0000
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Description :&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        "Description will be shown here"
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Pay Rate :&nbsp;
                                                    </Index.Typography>
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="card-right">
                                                <Index.PrimaryButton
                                                    btnLabel="Approve"
                                                    className="btn-primary bg-approve" />
                                                <Index.PrimaryButton
                                                    btnLabel="Reject"
                                                    className="btn-primary bg-suspend" />
                                            </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="card-main">
                                            <Index.Box className="card-left">
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Category Name :
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Requested by:&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        Username
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Requested on :&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        00 /00/0000
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Description :&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        "Description will be shown here"
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Pay Rate :&nbsp;
                                                    </Index.Typography>
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="card-right">
                                                <Index.PrimaryButton
                                                    btnLabel="Approve"
                                                    className="btn-primary bg-approve" />
                                                <Index.PrimaryButton
                                                    btnLabel="Reject"
                                                    className="btn-primary bg-suspend" />
                                            </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="card-main">
                                            <Index.Box className="card-left">
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Category Name :
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Requested by:&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        Username
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Requested on :&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        00 /00/0000
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Description :&nbsp;
                                                    </Index.Typography>
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-text-label"
                                                    >
                                                        "Description will be shown here"
                                                    </Index.Typography>
                                                </Index.Box>
                                                <Index.Box className="job-title-text">
                                                    <Index.Typography
                                                        component="p"
                                                        variant="p"
                                                        className="job-title-label"
                                                    >
                                                        Pay Rate :&nbsp;
                                                    </Index.Typography>
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="card-right">
                                                <Index.PrimaryButton
                                                    btnLabel="Approve"
                                                    className="btn-primary bg-approve" />
                                                <Index.PrimaryButton
                                                    btnLabel="Reject"
                                                    className="btn-primary bg-suspend" />
                                            </Index.Box>
                                        </Index.Box>
                                    </TabPanel>
                                    {/* tabbing end */}

                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
            {/* work table end */}

            {/* Add New User modal */}
            <Index.Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modal'
            >
                <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
                    <Index.Box className="modal-header" >
                        <Index.Typography id="modal-modal-title" className='modal-title' variant="h6" component="h2">
                            Add New Category
                        </Index.Typography>
                        <img src={PagesIndex.Svg.closeblack} className="modal-close-icon" onClick={handleClose} />
                    </Index.Box>
                    <Index.Box className="modal-body">
                        <Index.Box sx={{ flexGrow: 1 }}>
                            <Index.Grid container spacing={2}>
                                <Index.Grid item xs={12}>
                                    <Index.Box className="input-box modal-input-box">
                                        <Index.FormHelperText className='form-lable'>Add Category Name</Index.FormHelperText>
                                        <Index.Box className="form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                placeholder="Please enter category name"
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="input-box modal-input-box">
                                        <Index.FormHelperText className='form-lable'>Add Description for the category</Index.FormHelperText>
                                        <Index.Box className="form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                placeholder="Please enter category description"
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Grid>
                            </Index.Grid>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="modal-footer">
                        <Index.Box className='document-btn-grp'>
                            <Index.PrimaryButton
                                btnLabel="Add"
                                className="btn-primary bg-approve appr-rej-btn" />
                            <Index.PrimaryButton
                                btnLabel="Cancel"
                                className="btn-primary bg-suspend appr-rej-btn" />
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>
            {/* End Add New User */}

            {/* Add New User modal */}
            <Index.Modal
                open={openEdit}
                onClose={handleCloseEdit}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='modal'
            >
                <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
                    <Index.Box className="modal-header" >
                        <Index.Typography id="modal-modal-title" className='modal-title' variant="h6" component="h2">
                            Edit Category
                        </Index.Typography>
                        <img src={PagesIndex.Svg.closeblack} className="modal-close-icon" onClick={handleCloseEdit} />
                    </Index.Box>
                    <Index.Box className="modal-body">
                        <Index.Box sx={{ flexGrow: 1 }}>
                            <Index.Grid container spacing={2}>
                                <Index.Grid item xs={12}>
                                    <Index.Box className="input-box modal-input-box">
                                        <Index.FormHelperText className='form-lable'>Category Id</Index.FormHelperText>
                                        <Index.Box className="form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                placeholder="Please end categroy ID"
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="input-box modal-input-box">
                                        <Index.FormHelperText className='form-lable'>Category Name</Index.FormHelperText>
                                        <Index.Box className="form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                placeholder="Please enter category name"
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="input-box modal-input-box">
                                        <Index.FormHelperText className='form-lable'>Date</Index.FormHelperText>
                                        <Index.Box className="cus-datepicker full">
                                            <Index.LocalizationProvider
                                                dateAdapter={Index.AdapterDayjs}
                                            >
                                                {/* <DemoItem label="Mobile variant"> */}
                                                <Index.MobileDatePicker />
                                                {/* </DemoItem> */}
                                            </Index.LocalizationProvider>
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="input-box modal-input-box">
                                        <Index.FormHelperText className='form-lable'>Description Category</Index.FormHelperText>
                                        <Index.Box className="form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                placeholder="Please enter category description"
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Grid>
                            </Index.Grid>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="modal-footer">
                        <Index.Box className='document-btn-grp'>
                            <Index.PrimaryButton
                                btnLabel="Update"
                                className="btn-primary bg-approve appr-rej-btn" />
                            <Index.PrimaryButton
                                btnLabel="Cancel"
                                className="btn-primary bg-suspend appr-rej-btn" />
                        </Index.Box>
                        {/* <Index.Box className="modal-user-btn-flex">
                                <Index.Box className="discard-btn-main border-btn-main btn-modal-both">
                                    <Index.Button className='discard-user-btn border-btn add-btn-modal user-modal-btn'>Update</Index.Button>
                                </Index.Box>
                                <Index.Box className="save-btn-main border-btn-main">
                                    <Index.Button className='save-user-btn border-btn cancel-btn bg-suspend user-modal-btn-color'>Cancel</Index.Button>
                                </Index.Box>
                            </Index.Box> */}
                    </Index.Box>
                </Index.Box>
            </Index.Modal>
            {/* End Add New User */}

            {/* delete modal start */}
            <Index.Modal
                open={openDeleteNew}
                onClose={handleCloseDeleteNew}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal-delete modal"
            >
                <Index.Box
                    sx={style}
                    className="add-user-modal-inner-main modal-inner extra-delete"
                >
                    {/* <Index.Box className="modal-header">
              <Index.Typography
                id="modal-modal-title"
                className="modal-title"
                variant="h6"
                component="h2"
              >
                Delete Officer
              </Index.Typography>
              
            </Index.Box> */}
                    <Index.Box className="modal-body">
                        <img
                            src={PagesIndex.Svg.closeblack}
                            className="modal-close-icon"
                            onClick={handleCloseDeleteNew}
                        />
                        <Index.Box className="only-message-wrape">
                            <img
                                src={PagesIndex.Svg.deletecross}
                                alt="Delete"
                                className="delete-icon"
                            />
                            <Index.Typography component="h6">Are you sure?</Index.Typography>
                            <Index.Typography component="p">
                                Do you really want to Delete this record? This process cannot be
                                undone.
                            </Index.Typography>
                        </Index.Box>

                        <Index.Box className="document-btn-grp">
                            <Index.PrimaryButton
                                btnLabel="Confirm"
                                className="btn-primary bg-approve appr-rej-btn"
                            />

                            <Index.PrimaryButton
                                btnLabel="Cancel"
                                className="btn-primary bg-suspend appr-rej-btn"
                            />
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>
            {/* delete modal End */}

            {/* raject modal start */}
            <Index.Modal
                open={openReject}
                onClose={handleCloseReject}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal-delete modal"
            >
                <Index.Box
                    sx={style}
                    className="add-user-modal-inner-main modal-inner extra-delete"
                >
                    {/* <Index.Box className="modal-header">
              <Index.Typography
                id="modal-modal-title"
                className="modal-title"
                variant="h6"
                component="h2"
              >
                Delete Officer
              </Index.Typography>
              
            </Index.Box> */}
                    <Index.Box className="modal-body">
                        <img
                            src={PagesIndex.Svg.closeblack}
                            className="modal-close-icon"
                            onClick={handleCloseReject}
                        />
                        <Index.Box className="only-message-wrape">
                            <img
                                src={PagesIndex.Svg.deletecross}
                                alt="Delete"
                                className="delete-icon"
                            />
                            <Index.Typography component="h6">Are you sure?</Index.Typography>
                            <Index.Typography component="p">
                                Do you really want to reject this record? This process cannot be
                                undone.
                            </Index.Typography>
                        </Index.Box>

                        <Index.Box className="document-btn-grp">
                            <Index.PrimaryButton
                                btnLabel="Confirm"
                                className="btn-primary bg-approve appr-rej-btn"
                            />

                            <Index.PrimaryButton
                                btnLabel="Cancel"
                                className="btn-primary bg-suspend appr-rej-btn"
                            />
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Modal>
            {/* raject modal End */}
        </div>
    )
}
