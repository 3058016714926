import moment from "moment";

export function TotalCalculateByTime(jobData, rate) {
  console.log("jobData238",jobData)
  let totalHours = moment.duration(0);
  jobData?.jobSchedule?.map((items, index) => {
    
    let startTime = items.jobStartTime;
    let endTime = items.jobEndTime;
    let momentTime1 = moment(startTime, "HH:mm");
    let momentTime2 = moment(endTime, "HH:mm");
    let differenceInMinutes = momentTime2.diff(momentTime1, "minutes");
    const duration = moment.duration(differenceInMinutes, "minutes");
    // Add the duration to totalHours
    totalHours = totalHours.add(duration);
  });
  const formattedTime = moment
    .utc(totalHours.asMilliseconds())
    .format("HH:mm");

    const [hours, minutes] = formattedTime.split(":").map(Number);

  const totalHourss = hours + minutes / 60;
  const totalCost = totalHourss * rate;

  console.log("formattedTime",formattedTime,hours)

  return totalCost.toFixed(0);

  
}