import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";

export default function AdminAgentDetailsCompleted() {
  const [ratingvalue, setRatingValue] = React.useState(2);
  const navigate = PagesIndex.useNavigate();
  const { state } = PagesIndex.useLocation();
  const adminCompletejobsDetail = state.item;
  const agentId = state.agentId
  console.log(adminCompletejobsDetail, "fatmaaaaa");

  // us Formate
  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters from the input
    const cleaned = ("" + input).replace(/\D/g, "");

    // Format the cleaned phone number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return cleaned;
  };

  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Box className="title-header-flex">
          <img
            src={PagesIndex.Svg.leftArrow}
            className="left-arrow-ic"
            alt="search grey img"
            onClick={() => {
              navigate(`/admin/agentmanagement/adminagentdetails/${agentId}`);
            }}
          />
          <Index.Box>
            <Index.Typography
              component="p"
              variant="p"
              className="admin-page-title user-list-page-title"
            >
              Job Title <span>for</span> ${adminCompletejobsDetail?.rate}<span> Per hour</span>
            </Index.Typography>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="admin-client-history-sec">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Assigned Officer & Associated Agency/Department
          </Index.Typography>
        </Index.Box>
        <>
          {adminCompletejobsDetail?.officerId?.map((item, index) => {
            const officershortDetail = item?.officerId;
            return (
              <Index.Box className="card-main">
                <Index.Box className="card-left">
                  <Index.Box className="officer-profile-flex">
                    <img
                      src={PagesIndex.Png.usericon}
                      className="prof-img"
                      alt=""
                    />
                    <Index.Box className="officer-detail">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-title"
                      >
                        {officershortDetail?.firstName ? (
                          <>
                            {officershortDetail?.firstName +
                              " " +
                              officershortDetail?.lastName}
                          </>
                        ) : null} : &nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-email"
                      >
                        {officershortDetail?.officerType}
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-email "
                      >
                        Officer Assigned on :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-email"
                      >
                        {PagesIndex.dayjs(item?.jobAcceptedDate).format(
                          "MM-DD-YYYY"
                        )}
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-email"
                      >
                        Officer Check-In :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-email"
                      >
                        {/* {PagesIndex.dayjs(
            item.jobCheckInTime[0]
            ).format("MM-DD-YYYY : HH:mm")} */}
                        {item?.jobCheckInTime && PagesIndex.dayjs(item.jobCheckInTime).isValid() ? PagesIndex.dayjs(item.jobCheckInTime).format("MM-DD-YYYY - HH:mm") : " Not checkin"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            );
          })}
        </>
        <>
          {[1].map((item, index) => (
            <Index.Box className="card-main">
              <Index.Box className="card-left">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Agency Name
                  </Index.Typography>
                </Index.Box>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.approveBy?.agencyName}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          ))}
        </>
      </Index.Box>
      <Index.Box className="admin-client-history-sec">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            General Information
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Title :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.title}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted By :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.userId?.name}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex ">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.dayjs(adminCompletejobsDetail?.createdAt).format(
                    "MM-DD-YYYY"
                  )}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Payment :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Transaction Id :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Phone Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {formatPhoneNumber(adminCompletejobsDetail?.mobileNumber)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.userId?.email}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Point Of Contact Name :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.pointOfContactName}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Point Of Contact Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {formatPhoneNumber(
                    adminCompletejobsDetail?.pointOfContactNumber || "-"
                  )}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Location :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.location}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Service :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.service}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Department :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.department}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Type :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.jobType?.jobType}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Zip Code :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.zipCode}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Date/Time :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.showDateTime(adminCompletejobsDetail)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Hours :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.timeDifferenceCalculation(adminCompletejobsDetail)} hour
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Pay Rate :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.rate ? "$" : null}
                  {adminCompletejobsDetail?.rate}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  No of Officer :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.totalOfficer}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Description :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.description}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="admin-client-history-sec">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Organizer (Client)
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Name :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.userId?.name}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Mobile Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.userId?.mobileNumber}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminCompletejobsDetail?.userId?.email}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="admin-client-history-sec">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Job Log
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.dayjs(adminCompletejobsDetail?.createdAt).format(
                    "MM-DD-YYYY"
                  )}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Transferred To :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Transferred On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
