import React, { useRef, useState } from "react";
import PagesIndex from '../../../PageIndex';
import Index from '../../../index';
import { SwipeableDrawer } from '@mui/material';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};
export default function AdminExtraWorkPermit() {
    // reject modal
    const [openReject, setOpenReject] = useState(false);
    const handleOpenReject = () => setOpenReject(true);
    const handleCloseReject = () => setOpenReject(false);

     // filter
     const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Index.Box className="filter-main"
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
        </Index.Box>
    );
  return (
    <div>
      <Index.Box className="dashboard-content extra-work-main bg-none p-0">
               
                <Index.Box className="user-list-flex">
                    <Index.Typography className='admin-page-title user-list-page-title' component='h2' variant='h2'>
                        <Index.Link to="/admin/dashboard" className="filter-back-link">
              <img
                src={PagesIndex.Svg.leftArrow}
                className="left-arrow-ic"
                alt="search grey img"
              ></img>
            </Index.Link>{" "} Extra Work Premit Requests
                    </Index.Typography>
                    <Index.Box className="userlist-btn-flex">
                        <Index.Box className="user-search-main">
                            <Index.Box className="user-search-box">
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder="Search"
                                    />
                                    <img
                                        src={PagesIndex.Svg.search}
                                        className="search-grey-img" alt='search grey img'
                                    ></img>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="filter-main">
                            {['right'].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <Index.Box className="export-btn-main border-btn-main">
                                        <Index.Button className='export-btn border-btn' onClick={toggleDrawer(anchor, true)}><img src={PagesIndex.Svg.filter} className="down-icon" alt='download icon' />Filter</Index.Button>
                                    </Index.Box>
                                    <SwipeableDrawer
                                        className='filter-main'
                                        anchor={anchor}
                                        open={state[anchor]}
                                        onClose={toggleDrawer(anchor, false)}
                                        onOpen={toggleDrawer(anchor, true)}
                                    >
                                        <Index.Box className="filter-header">
                                            <Index.Typography className='filter-title'>User Filter</Index.Typography>
                                            <img src={PagesIndex.Svg.closeicon} className="filter-close-icon" onClick={toggleDrawer(anchor, false)} />
                                        </Index.Box>
                                        <Index.Box className="filter-inner-main">
                                            <Index.Box className="input-box filter-input-box">
                                                <Index.FormHelperText className='form-lable'>Name</Index.FormHelperText>
                                                <Index.Box className="form-group">
                                                    <Index.TextField
                                                        fullWidth
                                                        id="fullWidth"
                                                        className="form-control"
                                                        placeholder=""
                                                    />
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="input-box filter-input-box">
                                                <Index.FormHelperText className='form-lable'>City</Index.FormHelperText>
                                                <Index.Box className="form-group">
                                                    <Index.TextField
                                                        fullWidth
                                                        id="fullWidth"
                                                        className="form-control"
                                                        placeholder=""
                                                    />
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="input-box filter-input-box">
                                                <Index.FormHelperText className='form-lable'>Status</Index.FormHelperText>
                                                <Index.Box className='checkbox-main filter-checkbox-main input-box'>
                                                    <Index.FormControlLabel control={<Index.Checkbox defaultChecked />} label="Active" className='checkbox-lable' />
                                                    <Index.FormControlLabel control={<Index.Checkbox />} label="Deactivate" className='checkbox-lable' />
                                                    <Index.FormControlLabel control={<Index.Checkbox />} label="Pending" className='checkbox-lable' />
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="filter-footer">
                                            <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                                                <Index.Button className="border-btn filter-cancel-btn">Cancel</Index.Button>
                                                <Index.Button className="btn-primary filter-btn">Filter</Index.Button>
                                            </Index.Box>
                                        </Index.Box>
                                    </SwipeableDrawer>
                                </React.Fragment>
                            ))}
                        </Index.Box>
                        {/* <Index.Box className="userlist-inner-btn-flex">

                            <Index.Box className="adduser-btn-main btn-main-primary">
                                <Index.Button className='adduser-btn btn-primary'>Export</Index.Button>
                            </Index.Box>
                        </Index.Box> */}
                    </Index.Box>
                </Index.Box>
                <Index.Box className='officer-detail-wrapper res-officer-detail-wrapper mob-inner-scroll-area'>
                    <Index.Box className="card-main">
                        <Index.Box className="card-left">
                            
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Requested By:&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    Officer Name
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Agency Name :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    Agency Name
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Requested on :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    00/00/0000
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Requested for :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    job Id
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Job Title :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                   Job Title
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Client Name :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                  Username
                                </Index.Typography>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="card-right">
                            <Index.Box className="right-btn-group">
                                <Index.PrimaryButton
                                    btnLabel="Approve"
                                    className="btn-primary bg-approve" />
                                <Index.PrimaryButton
                                    onClick={handleOpenReject}
                                    btnLabel="Reject"
                                    className="btn-primary bg-suspend" />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-main">
                        <Index.Box className="card-left">
                            
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Requested By:&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    Officer Name
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Agency Name :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    Agency Name
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Requested on :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    00/00/0000
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Requested for :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    job Id
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Job Title :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                   Job Title
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Client Name :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                  Username
                                </Index.Typography>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="card-right">
                            <Index.Box className="right-btn-group">
                                <Index.PrimaryButton
                                    btnLabel="Approve"
                                    className="btn-primary bg-approve" />
                                <Index.PrimaryButton
                                    onClick={handleOpenReject}
                                    btnLabel="Reject"
                                    className="btn-primary bg-suspend" />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-main">
                        <Index.Box className="card-left">
                            
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Requested By:&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    Officer Name
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Agency Name :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    Agency Name
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Requested on :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    00/00/0000
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Requested for :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    job Id
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Job Title :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                   Job Title
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Client Name :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                  Username
                                </Index.Typography>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="card-right">
                            <Index.Box className="right-btn-group">
                                <Index.PrimaryButton
                                    btnLabel="Approve"
                                    className="btn-primary bg-approve" />
                                <Index.PrimaryButton
                                    onClick={handleOpenReject}
                                    btnLabel="Reject"
                                    className="btn-primary bg-suspend" />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-main">
                        <Index.Box className="card-left">
                            
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Requested By:&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    Officer Name
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Agency Name :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    Agency Name
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Requested on :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    00/00/0000
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Requested for :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    job Id
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Job Title :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                   Job Title
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                   Client Name :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                  Username
                                </Index.Typography>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="card-right">
                            <Index.Box className="right-btn-group">
                                <Index.PrimaryButton
                                    btnLabel="Approve"
                                    className="btn-primary bg-approve" />
                                <Index.PrimaryButton
                                    onClick={handleOpenReject}
                                    btnLabel="Reject"
                                    className="btn-primary bg-suspend" />
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>

                {/* raject modal start */}
                <Index.Modal
                    open={openReject}
                    onClose={handleCloseReject}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="modal-delete modal"
                >
                    <Index.Box
                    sx={style}
                    className="add-user-modal-inner-main modal-inner extra-delete"
                    >
                    {/* <Index.Box className="modal-header">
                        <Index.Typography
                            id="modal-modal-title"
                            className="modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Delete Officer
                        </Index.Typography>
                        
                        </Index.Box> */}
                    <Index.Box className="modal-body">
                        <img
                        src={PagesIndex.Svg.closeblack}
                        className="modal-close-icon"
                        onClick={handleCloseReject}
                        />
                        <Index.Box className="only-message-wrape">
                        <img
                            src={PagesIndex.Svg.deletecross}
                            alt="Delete"
                            className="delete-icon"
                        />
                        <Index.Typography component="h6">Are you sure?</Index.Typography>
                        <Index.Typography component="p">
                            Do you really want to Reject this record? This process cannot be
                            undone.
                        </Index.Typography>
                        </Index.Box>

                        <Index.Box className="document-btn-grp">
                        <Index.PrimaryButton
                            btnLabel="Confirm"
                            className="btn-primary bg-approve appr-rej-btn"
                        />

                        <Index.PrimaryButton
                            btnLabel="Cancel"
                            className="btn-primary bg-suspend appr-rej-btn"
                        />
                        </Index.Box>
                    </Index.Box>
                    </Index.Box>
                </Index.Modal>
                {/* raject modal End */}
            </Index.Box>
    </div>
  )
}
