import React, { useEffect, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { AdminEditUserSchema } from "../../../../../validation/FormikSchema";
import Loader from "../../../../../common/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const EditNewUser = (props) => {
  const { openEditModal, handleCloseEdit, editUserData, getUserListData } = props;
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  // console.log("editUserData", editUserData);
  const [isLoading, setIsLoading] = useState(false);
  // us phone number functionality
  const [mobileNumber, setmobileNumber] = useState("");

  console.log(mobileNumber, "mobileNumber")
  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;
    const cleaned = input.replace(/\D/g, "");

    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] == '0') {
      return; // Do nothing if the first digit is 0
    }

    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);

    // Format only if we have 10 digits
    if (cleaned.length === 10) {
      const formattedPhoneNumber = formatPhoneNumber(cleaned);
      setmobileNumber(formattedPhoneNumber);
    } else {
      // If less than 10 digits, just set the cleaned (unformatted) version
      setmobileNumber(cleaned);
    }
  };

  const formatPhoneNumber = (input) => {
    const strInput = String(input); // Ensure input is a string
    const match = strInput?.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return strInput;
  };

  useEffect(() => {
    setmobileNumber(formatPhoneNumber(editUserData?.mobileNumber));
  }, [editUserData]);


  const initialValues = {
    name: editUserData ? editUserData?.name : "",
    email: editUserData ? editUserData?.email : "",
    // rollName: editUserData ? editUserData?.roleId?._id : "",
    // editUserData? formatPhoneNumber(editUserData?.mobileNumber): ""
    mobileNumber: "",
  };

  const handleAddNewUserForm = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", editUserData?._id);
    urlEncoded.append("name", values?.name);
    // urlEncoded.append("roleId", values?.rollName);
    urlEncoded.append("mobileNumber", values?.mobileNumber);
    try {
      const response = await DataService.post(
        Api.Agency.AGENCY_ADD_EDIT_USER,
        urlEncoded
      );
      if (response?.data?.status === 201) {
        PagesIndex.toast.success(response?.data?.message);
        handleCloseEdit();
        getUserListData();
        setIsLoading(false);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Index.Modal
        open={openEditModal}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          validationSchema={AdminEditUserSchema}
          initialValues={initialValues}
          onSubmit={handleAddNewUserForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            handleFocus,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* {console.log("error", errors)} */}
              {/* {console.log("values>>",values)} */}
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Edit Details
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleCloseEdit}
                    alt=""
                  />
                </Index.Box>

                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter first name"
                        name="name"
                        value={values.name}
                        // onChange={han
                        onChange={(e) => {
                          handleChange(e);
                          setFieldTouched("name", true);
                        }}
                        inputProps={{ maxLength: 20 }}
                        // onBlur={handleBlur}
                        focused={false}
                        error={errors.name && touched.name ? true : false}
                        helperText={
                          errors.name && touched.name ? errors.name : null
                        }
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.value.trim() === "") {
                            e.preventDefault();
                          }
                          if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Email Address
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        disabled
                        className="form-control"
                        placeholder="Please enter email"
                        name="email"
                        value={values.email}
                        onChange={(e) => {
                          handleChange(e);
                          // setFieldTouched("email", true);
                        }}
                        onKeyDown={(event) => {
                          if (event.key == " ") {
                            event.preventDefault();
                          }
                        }}
                        onBlur={handleBlur}
                        error={errors.email && touched.email ? true : false}
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Phone Number
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="(123) 456-7890"
                        name="mobileNumber"
                        value={mobileNumber}
                        focused={false}
                        onChange={(e) => {
                          handlePhoneChange(e, setFieldValue);
                        }}
                        inputProps={{ maxLength: 10 }}
                        error={
                          errors.mobileNumber && touched.mobileNumber
                            ? true
                            : false
                        }
                        helperText={
                          errors.mobileNumber && touched.mobileNumber
                            ? errors.mobileNumber
                            : false
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-footer">
                  <Index.Box className="document-btn-grp">
                    <Index.PrimaryButton
                      btnLabel="Update"
                      className="btn-primary bg-approve appr-rej-btn"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Click Me"}
                    </Index.PrimaryButton>

                    <Index.PrimaryButton
                      btnLabel="cancel"
                      className="btn-primary bg-suspend appr-rej-btn"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default EditNewUser;
