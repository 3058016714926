import { createSlice } from "@reduxjs/toolkit";
import { getAgencyList, getDashboardCount, getOfficerList, getRollList, getUserList } from "./CommonServices";

const initialState = {
  officerList: {},
  userList: {},
  rollList: {},
  agencyList: {},
  dashboardCount: {},
  loading: false,
};

export const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.userList = action?.payload?.data;
      })
      .addCase(getUserList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getOfficerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOfficerList.fulfilled, (state, action) => {
        state.officerList = action?.payload?.data;
      })
      .addCase(getOfficerList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getRollList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRollList.fulfilled, (state, action) => {
        state.rollList = action?.payload?.data;
      })
      .addCase(getRollList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAgencyList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgencyList.fulfilled, (state, action) => {
        state.agencyList = action?.payload?.data;
      })
      .addCase(getAgencyList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDashboardCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardCount.fulfilled, (state, action) => {
        state.dashboardCount = action?.payload?.data;
      })
      .addCase(getDashboardCount.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {} = CommonSlice.actions;

export default CommonSlice.reducer;
