import { createTheme } from "@mui/material/styles";

const FONT_PRIMARY = "gilroy";

const theme = createTheme({
  palette: {
    primary: {
      light: "#5CA0CD",
      main: "#0E77BF",
      dark: "#022242",
      contrastText: "#fff",
    },
    secondary: {
      light: "#C6C6C6",
      main: "#7E7E7E",
      dark: "#575757",
      contrastText: "#000",
    },
    typography: {
      allVariants: {
        fontFamily: "Poppins, sans-serif",
        fontWeight: "400",
        lineHeight: "normal",
      },
    },
  },
 
});

export default theme;
