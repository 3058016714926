import React, { useEffect, useState, useRef } from "react";
import Index from "../../index";
import PagesIndex from "../../PageIndex";
import {
  agencyRegister,
  getAgencyRoleList
} from "../../../redux-toolkit/slice/agency-slice/AgencyServices";
import { agencySignupSchema } from "../../../validation/FormikSchema";

export default function AgencySignUp() {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const [roleList, setRoleList] = useState();
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;


  const formRef = useRef(null)
  const addressInputRef = useRef(null);
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionSelected, setIsSuggestionSelected] = useState({
    state: false,
    city: false,
    zipCode: false
  })

  // password
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // confirm password
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const handleAddressChange = async (e) => {

    console.log(e.target.value, ":e.target.value")
    setAddress(e.target.value);
    formRef.current.setFieldValue("address", e.target.value);
    if (e.target.value?.length <= 0) {
      formRef.current.setFieldValue("address", "");
      formRef.current.setFieldValue("state", "");
      formRef.current.setFieldValue("city", "");
      formRef.current.setFieldValue("zipCode", "");
      setIsSuggestionSelected({
        state: false,
        city: false,
        zipCode: false
      })
    }
    if (e.target.value.length > 3) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${e.target.value}`
        );
        const data = await response.json();
        setSuggestions(data);
      } catch (error) {
        console.error('Error fetching address:', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion, setFieldValue) => {
    setAddress(suggestion.display_name);
    let stateName = suggestion.address.state || suggestion.address.state_district || "";
    let cityName = suggestion.address.city || suggestion.address.town || suggestion.address.village || "";
    let zipCode = suggestion.address.postcode || "";
    formRef.current.setFieldValue("address", suggestion.display_name);
    formRef.current.setFieldValue("state", stateName);
    formRef.current.setFieldValue("city", cityName);
    formRef.current.setFieldValue("zipCode", zipCode);
    setIsSuggestionSelected({
      state: stateName?.length ? true : false,
      city: cityName?.length ? true : false,
      zipCode: zipCode?.length ? true : false
    })
    setSuggestions([]);
  };

  const initialValues = {
    roleName: "",
    agencyName: "",
    email: "",
    mobileNumber: "",
    zipCode: "",
    state: "",
    city: "",
    address: "",
    password: "",
    confirmPassword: "",
  };
  const handleAgencySignup = (values) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("agencyName", values?.agencyName);
    urlEncoded.append("email", values?.email.toLowerCase());
    urlEncoded.append("mobileNumber", values?.mobileNumber);
    urlEncoded.append("address", values?.address);
    urlEncoded.append("password", values?.password);
    urlEncoded.append("roleId", values?.roleName);
    urlEncoded.append("state", values.state);
    urlEncoded.append("city", values.city);
    urlEncoded.append("zipCode", values.zipCode);
    console.log("urlEncoded", [...urlEncoded]);
    dispatch(agencyRegister(urlEncoded)).then((res) => {
      const AgencyId = res?.payload?.data?.user_id;
      if (res?.payload?.status === 201) {
        setTimeout(() => {
          setIsSuggestionSelected(false)
          navigate("/agency-otp-verify", { state: { AgencyId } });
        }, 1000);
      }
    });
  };

  const getRole = () => {
    dispatch(getAgencyRoleList()).then((res) => {
      if (res?.payload?.status === 200) {
        console.log(res?.payload?.data, "res?.payload?.data");
        let finalData = res?.payload?.data?.filter((data) => {
          return (
            data.Permission_name === "Department" ||
            data.Permission_name === "Agency"
          );
        });
        console.log(finalData, "finalData");
        setRoleList(finalData);
      }
    });
  };


  useEffect(() => {
    getRole();
    // getZipcodeList();
  }, []);


  // us phone number functionality
  const [mobileNumber, setmobileNumber] = useState("");
  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;
    const cleaned = input.replace(/\D/g, "");

    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] == '0') {
      return; // Do nothing if the first digit is 0
    }

    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);

    // Format only if we have 10 digits
    if (cleaned.length === 10) {
      const formattedPhoneNumber = formatPhoneNumber(cleaned);
      setmobileNumber(formattedPhoneNumber);
    } else {
      // If less than 10 digits, just set the cleaned (unformatted) version
      setmobileNumber(cleaned);
    }
  };

  const formatPhoneNumber = (input) => {
    const match = input?.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return input;
  };

  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main position-relative">
          {/* <PagesIndex.AuthBackground /> */}
          <Index.Box className="login-left-bg "></Index.Box>
          <Index.Box className="login-overlay"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <PagesIndex.Formik
                innerRef={formRef}
                enableReinitialize={false}
                validationSchema={agencySignupSchema}
                initialValues={initialValues}
                onSubmit={handleAgencySignup}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldTouched,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {console.log("error245", errors)}
                    {console.log("values123", values)}

                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Signup
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        {/* Please enter your credentials to sign in! */}
                      </Index.Typography>
                      <Index.Box className="register-inner">
                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">
                            Select Role
                          </Index.FormHelperText>
                          <Index.Box className="dropdown-box">
                            <Index.FormControl className="form-control drop-form-control">
                              <Index.Select
                                className="dropdown-select drop-select"
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                name="roleName"
                                value={values?.roleName}
                                onBlur={handleBlur}
                                renderValue={
                                  values?.roleName
                                    ? undefined
                                    : () => "Select role"
                                }
                                onChange={(e) => {
                                  setFieldValue("roleName", e?.target?.value);
                                }}
                              // error={
                              //   errors.roleName && touched.roleName
                              //     ? true
                              //     : false
                              // }
                              // helperText={
                              //   errors.roleName && touched.roleName
                              //     ? errors.roleName
                              //     : null
                              // }
                              >
                                {roleList && roleList.length > 0
                                  ? roleList.map((data, index) => {
                                    return (
                                      <Index.MenuItem
                                        key={index}
                                        value={data?._id}
                                        className="drop-menuitem"
                                      >
                                        {/* {data?.Permission_name ===
                                            "Department" ||
                                          data?.Permission_name === "Agency" ? ( */}
                                        <div>{data?.Permission_name}</div>
                                        {/* ) : null} */}
                                      </Index.MenuItem>
                                    );
                                  })
                                  : null}
                              </Index.Select>
                            </Index.FormControl>
                            <Index.FormHelperText error>
                              {/* {touched.roleName && errors.roleName
                                ? errors.roleName
                                : null} */}
                              {errors.roleName
                                ? errors.roleName
                                : null}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Agency/Department Name
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              className="form-control"
                              placeholder="Please enter agency/department name"
                              name="agencyName"
                              value={values.agencyName}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              InputProps={{
                                inputProps: {
                                  maxLength: 32,
                                },
                              }}
                              onKeyDown={(e) => {
                                if (
                                  e.key === " " &&
                                  (e.target.value.trim() === "" ||
                                    e.target.value.endsWith(" "))
                                ) {
                                  e.preventDefault();
                                }
                                if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              onBlur={handleBlur}
                              error={
                                errors.agencyName && touched.agencyName
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.agencyName && touched.agencyName
                                  ? errors.agencyName
                                  : null
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Email Address
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="Please enter email address"
                              name="email"
                              value={values.email}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("email", newValue);
                                setFieldTouched("email", true);
                              }}
                              inputProps={{ maxLength: 60 }}
                              error={
                                errors.email && touched.email ? true : false
                              }
                              helperText={
                                errors.email && touched.email
                                  ? errors.email
                                  : false
                              }
                              onBlur={handleBlur}
                            />
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Phone Number
                          </Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextField
                              // type="tel"
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="(123) 456-7890"
                              name="mobileNumber"
                              value={mobileNumber}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                handlePhoneChange(e, setFieldValue);
                              }}
                              inputProps={{ maxLength: 10 }}
                              error={
                                errors.mobileNumber && touched.mobileNumber
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.mobileNumber && touched.mobileNumber
                                  ? errors.mobileNumber
                                  : false
                              }
                            />
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-box modal-input-box">
                          <Index.FormHelperText className="form-lable">Address</Index.FormHelperText>
                          <Index.Box className="form-group">
                            <Index.TextareaAutosize
                              fullWidth
                              id="fullWidth"
                              name="address"
                              className="form-control-textarea hgt-address"
                              placeholder="Please enter address"
                              value={address}
                              onChange={handleAddressChange}
                              error={
                                touched.address && errors.address
                                  ? true
                                  : false
                              }
                              helperText={
                                touched.address && errors.address
                                  ? true
                                  : false
                              }
                            />
                            {suggestions.length > 0 && (
                              <ul className="address-list">
                                {suggestions.map((suggestion, index) => (
                                  <li
                                    key={index}
                                    onClick={() => handleSuggestionClick(suggestion, setFieldValue)}
                                  >
                                    {suggestion.display_name}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </Index.Box>
                          <Index.FormHelperText error className="error-text">
                            {touched.address && errors.address ?
                              errors.address : null}
                          </Index.FormHelperText>
                        </Index.Box>

                        <Index.Box className="input-box ">
                          <Index.FormHelperText className="form-lable">State</Index.FormHelperText>
                          <Index.Box className="form-group ">
                            <Index.TextField
                              id="fullWidth"
                              className="form-control w-100-full"
                              name="state"
                              labelId="stateName-placeholder"
                              placeholder="Please enter state"
                              // value={address.length > 0 ? state : values.state}
                              value={values.state}
                              disabled={isSuggestionSelected?.state ? true : false}
                              onChange={(e) => {
                                if (e.target.value?.length <= 30) {
                                  setFieldValue("state", e.target.value)
                                }
                              }}
                              error={
                                touched.state && errors.state
                                  ? true
                                  : false
                              }
                              helperText={
                                touched.state && errors.state
                                  ? true
                                  : false
                              }
                            >
                            </Index.TextField>
                          </Index.Box>
                          <Index.FormHelperText error className="error-text">
                            {touched.state && errors.state ?
                              errors.state : null}
                          </Index.FormHelperText>
                        </Index.Box>

                        <Index.Box className="input-box ">
                          <Index.FormHelperText className="form-lable">City</Index.FormHelperText>
                          <Index.Box className="form-group ">
                            <Index.TextField
                              id="fullWidth"
                              className="form-control w-100-full"
                              name="city"
                              labelId="stateName-placeholder"
                              placeholder="Please enter city"
                              // value={address.length > 0 ? city : values.city}
                              value={values.city}
                              disabled={isSuggestionSelected?.city ? true : false}
                              // onChange={handleChange}
                              onChange={(e) => {
                                if (e.target.value?.length <= 30) {
                                  setFieldValue("city", e.target.value)
                                }

                              }}
                              error={
                                touched.city && errors.city
                                  ? true
                                  : false
                              }
                              helperText={
                                touched.city && errors.city
                                  ? true
                                  : false
                              }
                            >
                            </Index.TextField>
                          </Index.Box>
                          <Index.FormHelperText error className="error-text">
                            {touched.city && errors.city ?
                              errors.city : null}
                          </Index.FormHelperText>
                        </Index.Box>

                        <Index.Box className="input-box ">
                          <Index.FormHelperText className="form-lable">Zip Code</Index.FormHelperText>
                          <Index.Box className="form-group ">
                            <Index.TextField
                              id="fullWidth"
                              // type="number"
                              className="form-control w-100-full"
                              name="zipCode"
                              labelId="stateName-placeholder"
                              placeholder="Please enter zip code"
                              // value={address.length > 0 ? zipCode : values.zipCode}
                              value={values.zipCode}
                              disabled={isSuggestionSelected?.zipCode ? true : false}
                              // onChange={handleChange}
                              onChange={(e) => {
                                // const zipCodePattern = /^[0-9-]{5,9}$/;
                                // const value = zipCodePattern.test(e.target.value)
                                // console.log("value-zipcode", value)
                                const numberPattern = /^[0-9-]+$/
                                if (e.target?.value?.length <= 0) {
                                  setFieldValue("zipCode", "")
                                }
                                // console.log("e.target.value?.length ", e.target.value?.length)
                                if (numberPattern.test(e.target.value) && e.target.value?.length <= 9
                                ) {
                                  setFieldValue("zipCode", e.target.value)
                                }
                              }}
                              error={
                                touched.zipCode && errors.zipCode
                                  ? true
                                  : false
                              }
                              helperText={
                                touched.zipCode && errors.zipCode
                                  ? true
                                  : false
                              }
                            />
                          </Index.Box>
                          <Index.FormHelperText error className="error-text">
                            {touched.zipCode && errors.zipCode ?
                              errors.zipCode : null}
                          </Index.FormHelperText>
                        </Index.Box>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Password
                          </Index.FormHelperText>
                          <Index.Box className="form-group pwd-icon-btn">
                            <Index.OutlinedInput
                              fullWidth
                              className="form-control-eye"
                              placeholder="Please enter password"
                              name="password"
                              autoComplete="off"
                              onBlur={handleBlur}
                              value={values.password}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("password", newValue);
                                setFieldTouched("password", true);
                              }}
                              error={
                                errors.password && touched.password
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.password && touched.password
                                  ? errors.password
                                  : null
                              }
                              inputProps={{ maxLength: 16 }}
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    // onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {!showPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                            />
                            <Index.FormHelperText error className="error-text">
                              {errors.password && touched.password
                                ? errors.password
                                : null}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="input-box">
                          <Index.FormHelperText className="form-lable">
                            Confirm Password
                          </Index.FormHelperText>
                          <Index.Box className="form-group pwd-icon-btn">
                            <Index.OutlinedInput
                              fullWidth
                              className="form-control-eye"
                              placeholder="Please enter confirm password"
                              name="confirmPassword"
                              autoComplete="off"
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              onChange={(e) => {
                                const newValue = e.target.value.replace(
                                  /\s/g,
                                  ""
                                );
                                setFieldValue("confirmPassword", newValue);
                                setFieldTouched("confirmPassword", true);
                              }}
                              inputProps={{ maxLength: 16 }}
                              helperText={
                                touched.confirmPassword &&
                                errors.confirmPassword
                              }
                              error={Boolean(
                                errors.confirmPassword &&
                                touched.confirmPassword
                              )}
                              type={showConfirmPassword ? "text" : "password"}
                              endAdornment={
                                <Index.InputAdornment position="end">
                                  <Index.IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    // onMouseDown={handleMouseDownConfirmPassword}
                                    edge="end"
                                  >
                                    {!showConfirmPassword ? (
                                      <Index.VisibilityOff />
                                    ) : (
                                      <Index.Visibility />
                                    )}
                                  </Index.IconButton>
                                </Index.InputAdornment>
                              }
                            />
                            <Index.FormHelperText error className="error-text">
                              {errors.confirmPassword && touched.confirmPassword
                                ? errors.confirmPassword
                                : null}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                        >
                          Signup
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="or-text-main">
                        <Index.Typography
                          className="or-cocntent-text"
                          component="p"
                        >
                          OR
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="condition-content">
                        <Index.Typography className="condition-text">
                          {" "}
                          If have an account?{" "}
                          <span className="condition-span">
                            <Index.Link className="condition-span" to="/">
                              Login
                            </Index.Link>
                          </span>
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
