// import chart1 from '../assets/images/jpg/chart-1.jpg';
import profileImg from '../assets/images/jpg/profile-img.jpg'
import loginbg from '../assets/images/jpg/login-bg.jpg';


const Jpg = {
      // chart1,
      profileImg,
      loginbg,
}

export default Jpg;