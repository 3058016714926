import React from 'react';
import Index from '../../container';

const CommonPagination = ({ 
    paginationPerPage, 
    setPaginationPerPage, 
    setPageDefault, 
    totalRecordCount, 
    currentPage, 
    setCurrentPage 
  }) => {
    return (
      <Index.Box className="pagination-design flex-end">
        <Index.Stack spacing={2}>
          <Index.Box className="pagination-count">
            <Index.Box className="pagination-main">
              <Index.Select
                fullWidth
                className='pagination-select'
                value={paginationPerPage}
                onChange={(e) => {
                  setPaginationPerPage(e.target.value);
                  setPageDefault(1);
                }}
              >
                <Index.MenuItem value={10} className='pagination-item'>10</Index.MenuItem>
                <Index.MenuItem value={25} className='pagination-item'>25</Index.MenuItem>
                <Index.MenuItem value={50} className='pagination-item'>50</Index.MenuItem>
                <Index.MenuItem value={100} className='pagination-item'>100</Index.MenuItem>
              </Index.Select>
            </Index.Box>
            { (
              <>

                <Index.Pagination
                  count={Math.ceil(totalRecordCount / paginationPerPage)}
                  page={currentPage}
                  onChange={(e, value) => {
                    setCurrentPage(value);
                  }}
                />
              </>
            )}
          </Index.Box>
        </Index.Stack>
      </Index.Box>
    );
  };
  
  export default CommonPagination;