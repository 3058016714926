import React from "react";
import Index from "../../container/index";

export default function SecondaryButton(props) {
  const { className, onClick, btnLabel, type, disabled } = props;
  return (
    <>
      <Index.Box className="btn-main-secondary">
        <Index.Button disabled={disabled} className={className} onClick={onClick} type={type}>
          {btnLabel}
        </Index.Button>
      </Index.Box>
    </>
  );
}
