import React from 'react'
import { PropagateLoader } from "react-spinners";
const ChatLoader = () => {
  return (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
     backgroundColor:"#928f8f"
    }}>
    <PropagateLoader color="#1a3768" /></div>
  )
}

export default ChatLoader