import React, { useEffect, useState } from "react";
import Loader from "../../../../common/loader/Loader";
import PagesIndex from "../../../../components/PagesIndex";

export default function AgencyPaymentLoader() {
    const location = PagesIndex.useLocation();
    const navigate = PagesIndex.useNavigate();
    const navType = PagesIndex.useNavigationType();
    const [isLoading, setIsLoading] = useState(true);
      // role permission
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  const roleName = agencyLoginData?.roleId?.Permission_name;
    useEffect(() => {
        if (navType == "POP") {
            navigate(`/${roleName}/dashboard`);
        }
    }, [navigate]);

    return (
        <>
            {isLoading ? <Loader /> : null}
        </>
    );
}
