import React, { useEffect, useState } from "react";
import Index from "../../index";
import PagesIndex from "../../PageIndex";
import { styled } from "@mui/material/styles";
import { agencyLogin } from "../../../redux-toolkit/slice/agency-slice/AgencyServices";
import { AgencyloginSchema } from "../../../validation/FormikSchema";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { boolean } from "yup";
import { generateToken } from "../../../firebase";
// for custom checkbox design

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 0,
  border: "1px solid #114A65",
  width: 16,
  height: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export default function AgencyLoginNew() {
  // for password eye hide and show
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const checkRememberMe = () => {
    const rememberMeChecked = localStorage.getItem("rememberMe") === "true";
    if (rememberMeChecked) {
      const savedUseremail = localStorage.getItem("email") || "";
      const savedPassword = localStorage.getItem("password") || "";
      setInitialValues({
        email: savedUseremail,
        password: savedPassword,
        rememberMe: Boolean(rememberMeChecked),
      });
    }
  };

  // Call this function when the component mounts
  useEffect(() => {
    checkRememberMe();
  }, []);

  const handleAgencyLoginForm = (values) => {
    const urlEncoded = new URLSearchParams();

    if (values.rememberMe) {
      localStorage.setItem("email", values.email);
      localStorage.setItem("password", values.password);
      localStorage.setItem("rememberMe", values.rememberMe);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.removeItem("rememberMe");
    }

    Promise.resolve(generateToken()).then(async (token) => {
      urlEncoded.append("email", values?.email.toLowerCase());
      urlEncoded.append("password", values?.password);
      urlEncoded.append("fcmtoken", token);

      dispatch(agencyLogin(urlEncoded)).then((res) => {
        if (res?.payload?.status === 200) {
          if (res?.payload?.data) {
            const isPasswordChanges = res?.payload?.data.isPasswordChanges;
            let roleName = res?.payload?.data?.roleId?.Permission_name;

            if (isPasswordChanges !== undefined && isPasswordChanges) {
              navigate(`/change-password`); 
            } else {
              setTimeout(() => { 
                navigate(`/${roleName?.replace(/ /g, "-")}/dashboard`);
              }, 1000);
            }
          }
        } else if (res?.payload?.status === 400) {
          PagesIndex.toast(res?.payload?.message);
        } else if (res?.payload?.status === 403) {
          navigate("restrict-page");
        }
      });
    });
};


  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main position-relative">
          {/* <PagesIndex.AuthBackground /> */}
          <Index.Box className="login-left-bg "></Index.Box>
          <Index.Box className="login-overlay"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <PagesIndex.Formik
                enableReinitialize
                validationSchema={AgencyloginSchema}
                initialValues={initialValues}
                onSubmit={handleAgencyLoginForm}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldTouched,
                  handleFocus,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* {console.log("error", errors)} */}
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Login!
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Please enter your credentials to login!
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Email Address
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            hiddenLabel
                            className="form-control"
                            placeholder="Enter Email Address"
                            name="email"
                            autoComplete="off"
                            onFocus={handleFocus}
                            value={values.email}
                            inputProps={{ maxLength: 60 }}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.email && errors.email}
                            error={Boolean(errors.email && touched.email)}
                            onKeyDown={(e) => {
                              if (
                                e.key === " " &&
                                e.target.value.trim() === ""
                              ) {
                                e.preventDefault();
                              }
                            }}
                            // onChange={(e) => {
                            //   const newValue = e.target.value
                            //     .replace(/\s/g, "")
                            //     .trim();
                            //   setFieldValue("email", newValue);
                            //   setFieldTouched("email", true);
                            // }}
                            // onBlur={handleBlur}
                            // error={errors.email && touched.email ? true : false}
                            // helperText={
                            //   errors.email && touched.email
                            //     ? errors.email
                            //     : null
                            // }
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group pwd-icon-btn">
                          <Index.OutlinedInput
                            fullWidth
                            id="fullWidth"
                            className="form-control-eye"
                            placeholder="Enter Your Password"
                            name="password"
                            onBlur={handleBlur}
                            value={values.password}
                            onChange={handleChange}
                            inputProps={{ maxLength: 16 }}
                            onKeyDown={(e) => {
                              if (
                                e.key === " " &&
                                e.target.value.trim() === ""
                              ) {
                                e.preventDefault();
                              }
                            }}
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {!showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        <Index.FormHelperText error className="error-text">
                          {errors.password && touched.password
                            ? errors.password
                            : null}
                        </Index.FormHelperText>
                      </Index.Box>
                      <Index.Box className="flex-all forgot-row">
                        <Index.Box className="checkbox-main-new">
                          {/* <BpCheckbox /> */}
                          <FormGroup>
                            <FormControlLabel
                              control={<Checkbox checked={values.rememberMe} />}
                              label="Remember Me"
                              name="rememberMe"
                              value={values.rememberMe}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          {/* <Index.Typography className="checkbox-lable">
                            Remember Me
                          </Index.Typography> */}
                        </Index.Box>
                        <Index.Link
                          className="text-decoration-none admin-forgot-para common-para"
                          to="forgotpassword"
                        >
                          Forgot Password?
                        </Index.Link>
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                        >
                          Login
                        </Index.Button>
                      </Index.Box>
                      <Index.Box className="or-text-main">
                        <Index.Typography
                          className="or-cocntent-text"
                          component="p"
                        >
                          OR
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="condition-content">
                        <Index.Typography className="condition-text">
                          {" "}
                          Don't have an account?{" "}
                          <span className="condition-span">
                            <Index.Link
                              className="condition-span"
                              to="/agencysignup"
                            >
                              Signup here
                            </Index.Link>
                          </span>
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
