import React from "react";
import Index from "../../../index";
import OfficerContentManagement from "./officer-cms/OfficerContentManagement";
import UserContentManagement from "./user-cms/UserContentManagement";

const AdminContentManagement = () => {
  return (
    <>

      <Index.Box className="grid-row">
        <Index.Box sx={{ width: 1 }} className="grid-main">
          <Index.Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          >
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 4",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <OfficerContentManagement />
            </Index.Box>
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 8",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <UserContentManagement />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

    </>
  );
};

export default AdminContentManagement;
