import React from "react";
import Index from "../../index";

export default function AuthBackground() {
  return (
    <>
      <Index.Box className="login-left-bg"></Index.Box>
    </>
  );
}
