import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";



export const adminLogin = createAsyncThunk("admin/adminLogin", async (data) => {
  try {
    const response = await DataService.post(Api.Admin.ADMIN_LOGIN, data);
    localStorage.setItem("AdminToken", response?.data?.data?.token);
    toast.success(response?.data?.message,{toastId: "customId"});
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message,{toastId: "customId"});
  }
});

// export const adminLogin = createAsyncThunk(
//   "admin/adminLogin",
//   async (data, navigate) => {
//     try {
//       const response = await DataService.post(Api.Admin.ADMIN_LOGIN, data);
//       localStorage.setItem("AdminToken", response?.data?.data?.token);
//       if (response.data.status == 200) {
//         toast.success(response?.data?.message,{toastId: "customId"});
//       }
//       return response.data.data;
//     } catch (error) {
//       toast.error(error?.response?.data?.message,{toastId: "customId"});
//     }
//   }
// );


export const getAdminOfficerList = createAsyncThunk(
  "getAdminOfficerList",
  async (data) => {
    try {
      const response = await DataService.get(Api.Admin.GET_OFFICER_LIST,data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

// zip code

export const getZipCodeData = createAsyncThunk("getZipCodeData", async () => {
  try {
    const response = await DataService.get(Api.Admin.GET_ZIPCODE_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

// job type Admin

export const getJobTypeData = createAsyncThunk("getJobTypeData", async () => {
  try {
    const response = await DataService.get(Api.Admin.GET_JOBTYPE_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});


// Skills

export const getSkillsData = createAsyncThunk("getSkillsData", async () => {
  try {
    const response = await DataService.get(Api.Admin.GET_SKILLS_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});


// Client
export const getClientList = createAsyncThunk("getUserList", async () => {
  try {
    const response = await DataService.get(Api.Admin.GET_CLIENT_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

export const getClientJobList = createAsyncThunk(
  "agency/getUserJobList",
  async (data) => {
    try {
      const res = await DataService.post(Api.Admin.GET_CLIENT_JOB_LIST, data);
      return res?.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

// USER
export const getUserList = createAsyncThunk("getUserList", async () => {
  try {
    const response = await DataService.get(Api.Admin.GET_USER_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});
// Job list
export const getAdminJobList = createAsyncThunk(
  "agency/getAdminJobList",
  async (data) => {
    try {
      const res = await DataService.post(Api.Admin.GET_JOB_LIST, data);
      return res?.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);
export const getUserJobList = createAsyncThunk(
  "agency/getUserJobList",
  async (data) => {
    try {
      const res = await DataService.post(Api.Admin.GET_USER_JOB_LIST, data);
      return res?.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

// get Role Master

export const getRoleMasterList = createAsyncThunk(
  "admin/getRoleList",
  async (data) => {
    try {
      const res = await DataService.get(Api.Admin.GET_ROLE_LIST, data);
      return res?.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);


// Add Role Master
export const addRoleMaster = createAsyncThunk(
  "admin/addRoleMaster",
  async (data) => {
    try {
      const res = await DataService.post(Api.Admin.ADD_ROLE_MASTER, data.urlencoded);
      if (res?.data?.status === 200 || res?.data?.status === 201){
        toast.success(res?.data?.message);
        data.setLoading(false);
        data.navigate(`/admin/role`);
      } else {
        toast.error(res?.data?.message);
        setTimeout(() => {
          data.setLoading(false);
        }, 5500);
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message,{toastId: "CustomId"});
      data.setLoading(false);
    }
  }
);


// CMS code

export const getCmsDataList = createAsyncThunk("getCmsDataList", async (data) => {
  try {
    const response = await DataService.get(`${Api.Admin.GET_PRIVACY_POLICY}?type=${data}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

