import React from 'react';
import { useNavigate } from 'react-router-dom';
import Index from "../../..";
import PagesIndex from "../../../PageIndex";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { AgencyChangePasswordSchema } from "../../../../validation/FormikSchema";
import { agencyLogout } from "../../../../redux-toolkit/slice/agency-slice/AgencySlice";

const AgencyChangePasswordModal = (props) => {
  const { addnewhandleClose, addnewopen } = props;
  const navigate = useNavigate();
  const dispatch = PagesIndex.useDispatch();

  // State for password visibility toggling
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false);

  // Handlers for toggling password visibility
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickNewShowPassword = () => setShowNewPassword((show) => !show);
  const handleClickConfirmNewShowPassword = () => setShowConfirmNewPassword((show) => !show);

  // Prevent default behavior for mouse down events on password fields
  const handleMouseDownOldPassword = (event) => event.preventDefault();
  const handleMouseDownNewPassword = (event) => event.preventDefault();
  const handleMouseDownConfirmNewPassword = (event) => event.preventDefault();

  // Modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  // Initial values for the Formik form
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  // Form submit handler
  const handleAgencyChangePasswordForm = async (values) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("oldPassword", values?.oldPassword);
    urlEncoded.append("newPassword", values?.newPassword);

    try {
      const response = await DataService.post(Api.Agency.AGENCY_CHANGE_PASSWORD, urlEncoded);
      if (response?.data?.status === 200) {
        PagesIndex.toast.success(response?.data?.message);
        navigate("");
        dispatch(agencyLogout());
        localStorage.removeItem("AgencyToken");
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Index.Modal
        open={addnewopen}
        onClose={addnewhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <PagesIndex.Formik
          validationSchema={AgencyChangePasswordSchema}
          initialValues={initialValues}
          onSubmit={handleAgencyChangePasswordForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            handleFocus,
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log("error", errors)}

              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Change Password
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={addnewhandleClose}
                    alt=""
                  />
                </Index.Box>
                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Enter Old Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group pwd-icon-btn">
                      <Index.OutlinedInput
                        fullWidth
                        id="fullWidth"
                        className="form-control-eye"
                        name="oldPassword"
                        onBlur={handleBlur}
                        value={values.oldPassword}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(/\s/g, "");
                          setFieldValue("oldPassword", newValue);
                        }}
                        placeholder="Please enter old password"
                        error={Boolean(errors.oldPassword && touched.oldPassword)}
                        type={showOldPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowOldPassword}
                              onMouseDown={handleMouseDownOldPassword}
                              edge="end"
                            >
                              {!showOldPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    <Index.FormHelperText error className="error-text">
                      {errors.oldPassword && touched.oldPassword
                        ? errors.oldPassword
                        : null}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Enter New Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group pwd-icon-btn">
                      <Index.OutlinedInput
                        fullWidth
                        id="fullWidth"
                        className="form-control-eye"
                        name="newPassword"
                        onBlur={handleBlur}
                        value={values.newPassword}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(/\s/g, "");
                          setFieldValue("newPassword", newValue);
                        }}
                        placeholder="Please enter new password"
                        error={Boolean(errors.newPassword && touched.newPassword)}
                        type={showNewPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickNewShowPassword}
                              onMouseDown={handleMouseDownNewPassword}
                              edge="end"
                            >
                              {!showNewPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    <Index.FormHelperText error className="error-text">
                      {errors.newPassword && touched.newPassword
                        ? errors.newPassword
                        : null}
                    </Index.FormHelperText>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Re-Enter New Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group pwd-icon-btn">
                      <Index.OutlinedInput
                        fullWidth
                        id="fullWidth"
                        className="form-control-eye"
                        name="confirmNewPassword"
                        onBlur={handleBlur}
                        value={values.confirmNewPassword}
                        placeholder="Please re-enter new password"
                        onChange={(e) => {
                          const newValue = e.target.value.replace(/\s/g, "");
                          setFieldValue("confirmNewPassword", newValue);
                        }}
                        error={Boolean(
                          errors.confirmNewPassword && touched.confirmNewPassword
                        )}
                        type={showConfirmNewPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickConfirmNewShowPassword}
                              onMouseDown={handleMouseDownConfirmNewPassword}
                              edge="end"
                            >
                              {!showConfirmNewPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    <Index.FormHelperText error className="error-text">
                      {errors.confirmNewPassword && touched.confirmNewPassword
                        ? errors.confirmNewPassword
                        : null}
                    </Index.FormHelperText>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-footer">
                  <Index.Box className="modal-user-btn-flex mt-15">
                    <Index.Box className="discard-btn-main mr-10">
                      <Index.PrimaryButton
                        className="btn-primary bg-approve"
                        btnLabel="Update"
                        type="submit"
                      />
                    </Index.Box>
                    <Index.Box className="save-btn-main border-btn-main">
                        <Index.PrimaryButton
                          className="btn-primary bg-suspend"
                          btnLabel="Cancel"
                          onClick={addnewhandleClose}
                        />
                      </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default AgencyChangePasswordModal;
