import { createSlice } from "@reduxjs/toolkit";
import { getRoleMasterList } from "./AdminServices";

import {
  adminLogin,
  getAdminJobList,
  getAdminOfficerList,
  getJobTypeData,
  getSkillsData,
  getUserJobList,
  getUserList,
  getZipCodeData,
} from "./AdminServices";
export const STATUSES = Object.freeze({
  IDLE: "idle",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  adminLoginData: {},
  loading: false,
  adminAgencyList: [],
  userList: {},
  adminOfficerList: {},
  userjobList: {},
  zipcodeList: {},
  jobTypeList: {},
  skillList: {},
  roleList: [],
  adminRoleData: {},
  token: "",
  loading: false,
  isAuthenticated: false,
  status: STATUSES.IDLE,
};

export const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    getAdminData: (state, action) => {
      state.adminLoginData = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.adminLoginData = {};
      // state.token = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      state.adminLoginData = action?.payload?.data;
      // state.token = action.payload?.data?.token;
      state.loading = false;
    });
    builder.addCase(adminLogin.rejected, (state) => {
      state.loading = false;
    })
    .addCase(getRoleMasterList.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.adminRoleData = action.payload?.data;
    });  
    
    builder
      .addCase(getAdminOfficerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminOfficerList.fulfilled, (state, action) => {
        state.adminOfficerList = action?.payload?.data;
      })
      .addCase(getAdminOfficerList.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getUserList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.userList = action?.payload?.data;
      })
      .addCase(getUserList.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getAdminJobList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAdminJobList.fulfilled, (state, action) => {
        state.adminjobList = action?.payload?.data;
      })
      .addCase(getAdminJobList.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getUserJobList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserJobList.fulfilled, (state, action) => {
        state.userjobList = action?.payload?.data;
      })
      .addCase(getUserJobList.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getZipCodeData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getZipCodeData.fulfilled, (state, action) => {
        state.zipcodeList = action?.payload?.data;
      })
      .addCase(getZipCodeData.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getJobTypeData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getJobTypeData.fulfilled, (state, action) => {
        state.jobtypeList = action?.payload?.data;
      })
      .addCase(getJobTypeData.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(getSkillsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSkillsData.fulfilled, (state, action) => {
        state.skillList = action?.payload?.data;
      })
      .addCase(getSkillsData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { logout, getAdminData } = AdminSlice.actions;

export default AdminSlice.reducer;
