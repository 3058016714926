import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";
import { toast } from "react-toastify";

export const getUserList = createAsyncThunk("getUserList", async () => {
  try {
    const response = await DataService.get(Api.Common.GET_USER_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

export const getOfficerList = createAsyncThunk("getOfficerList", async () => {
  try {
    const response = await DataService.get(Api.Common.GET_OFFICER_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

export const getRollList = createAsyncThunk("getRollList", async () => {
  try {
    const response = await DataService.get(Api.Common.GET_ROLL_LIST);
    console.log(response, "resposen");
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

export const getAgencyList = createAsyncThunk("getAgencyList", async () => {
  try {
    const response = await DataService.get(Api.Common.GET_AGENCY_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message,{toastId : "customId"});
  }
});
// export const getDepartmentByAgencyList = createAsyncThunk("getDepartmentByAgencyList", async (data) => {
//   try {
//     const stringWithoutSpaces = data.replace(/\s/g, '');
//     // const response = await DataService.get(Api.Common.GET_AGENCY_LIST);
//     const response = await DataService.get(`${Api.Common.GET_DEPARTMENT_BY_AGENCY_LIST}/${stringWithoutSpaces}`);
//     return response.data;
    
//   } catch (error) {
//     toast.error(error?.response?.data?.message,{toastId : "customId"});
//   }
// });
export const getSingleAgency = createAsyncThunk(
  "getSingleAgency",
  async (id) => {
    try {
      const response = await DataService.get(
        `${Api.Common.GET_SINGLE_AGENCY}/${id}`
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getSingleOfficer = createAsyncThunk(
  "getSingleOfficer",
  async (id) => {
    try {
      const response = await DataService.get(
        `${Api.Admin.GET_SINGLE_OFFICER}/${id}`
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
// ADMIN Dashboard
export const getDashboardCount = createAsyncThunk(
  "getDashboardCount",
  async () => {
    try {
      const response = await DataService.get(Api.Common.DASHBOARD_COUNT);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
// Agency Dashboard
export const getAgencyDashboardCount = createAsyncThunk(
  "getAgencyDashboardCount",
  async () => {
    try {
      const response = await DataService.get(Api.Common.DASHBOARD_AGENCY_COUNT);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
// get zip code
export const getzipCodeDataCommon = createAsyncThunk(
  "getzipCodeData",
  async () => {
    try {
      const response = await DataService.get(Api.Common.GET_ZIP_CODE_COMMON);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
// Admin Job History
export const getJobHistoryList = createAsyncThunk(
  "agency/getJobHistoryList",
  async (data) => {
    try {
      const res = await DataService.post(Api.Common.ADMIN_JOB_HISTORY_COMMON , data);
      return res?.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

// Admin Agency Officer and Job History
export const getOfficerHistoryList = createAsyncThunk(
  "agency/getOfficerHistoryList",
  async (data) => {
    try {
      const res = await DataService.post(Api.Common.ADMIN_AGENCY_OFFICER_HISTORY_COMMON, data);
      return res?.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

// Dashboard Admin officer Details
export const getDashboardOfficerCount = createAsyncThunk(
  "getDashboardOfficerCount",
  async (id) => {
    console.log(id, "fatmaaaa")
    try {
      const response = await DataService.get(
        `${Api.Common.DASHBOARD_ADMIN_OFFICER_COUNT}/${id}`
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);
