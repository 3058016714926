import React, { useEffect } from "react";
import Index from "../../index";
import PagesIndex from "../../PageIndex";
import { styled } from "@mui/material/styles";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";
import { agencyResetPasswordSchema } from "../../../validation/FormikSchema";


export default function AgencyResetPasswordNew() {
  const [showPassword, setShowPassword] = React.useState(false);
  const location = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();

  // Function to handle showing/hiding password
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Function to prevent default action on mousedown
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Handle form submission for creating new password
  const handleCreateNewPassword = async (values) => {
    const data = {
      id: location.state.ResetId,
      newPassword: values?.newPassword,
    };
    try {
      const response = await DataService.post(
        Api.Agency.AGENCY_RESET_PASSWORD,
        data
      );
      PagesIndex.toast.success(response?.data?.message);
      navigate("/");
      // setTimeout(() => {
      // }, 2000);
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };



  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main position-relative">
          <Index.Box className="login-left-bg "></Index.Box>
          <Index.Box className="login-overlay"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <PagesIndex.Formik
            validationSchema={agencyResetPasswordSchema}
            initialValues={{
              newPassword: "",
            }}
            onSubmit={handleCreateNewPassword}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="admin-login-box">
                  <Index.Box className="admin-login-main">
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Reset Password
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Please enter your new password for reset password.
                      </Index.Typography>
                      <Index.Box className="input-box eye-input-box">
                        <Index.FormHelperText className="form-lable">
                          New Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.OutlinedInput
                            className="form-control-eye"
                            autoComplete="off"
                            id="outlined-adornment-password"
                            placeholder="Plese enter new password"
                            type={showPassword ? "text" : "password"}
                            name="newPassword"
                            value={values.newPassword}
                            onChange={handleChange}
                            inputProps={{ maxLength: 16 }}
                            onBlur={handleBlur}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {!showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                            error={
                              errors.newPassword && touched.newPassword
                                ? true
                                : false
                            }
                          />
                        </Index.Box>
                        {errors.newPassword && touched.newPassword && (
                          <Index.FormHelperText
                            error
                            id="outlined-adornment-newPassword"
                            className="error-text"
                          >
                            {errors.newPassword}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>

                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                        >
                          Reset password
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}
