
import "./assets/css/style.css";
import Routers from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "./assets/css/theme";
import 'react-toastify/dist/ReactToastify.css';
import "./assets/css/responsive.css";

const App = () => {

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Poppins, sans-serif",
        fontWeight: "400",
        lineHeight: "normal",
      },
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <BrowserRouter>
          <Routers />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
