import React, { useState,useEffect } from "react";
import Index from "../../index";
import PagesIndex from "../../PageIndex";
import { styled } from "@mui/material/styles";
import { adminLogin } from "../../../redux-toolkit/slice/admin-slice/AdminServices";
import { adminLoginSchema } from "../../../validation/FormikSchema";
import {generateToken } from '../../../firebase'

// for custom checkbox design

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 0,
  border: "1px solid #114A65",
  width: 16,
  height: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

export default function AdminLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [token, setDeviceToken]= useState("");   

  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
    // Initital values
    let initialValues = {
      email: "",
      password: "",
    };

  const handleAdminLoginForm = (values) => {
    const urlEncoded = new URLSearchParams();
    Promise.resolve(generateToken()).then(async(token)=> {
      urlEncoded.append("email", values?.email.toLowerCase());
      urlEncoded.append("password", values?.password);
      urlEncoded.append("fcmtoken", token);
      dispatch(adminLogin(urlEncoded)).then((res) => {
        if (res?.payload?.status === 200) {
          navigate("/admin/dashboard");
        }
      });
    });
  }
       

  
    // // // Login
    // const handleAdminLoginForm = (values, action) => {
    //   setIsDisabled(true);
    //   dispatch(adminLogin(values, navigate)).then((res) => {
    //     action.resetForm();
    //     setIsDisabled(false);
    //     if (res?.payload?.status === 200) {
    //       navigate("/admin/dashboard");
    //     }
    //   });
    // };
  
 
  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main position-relative">
          {/* <PagesIndex.AuthBackground /> */}
          <Index.Box className="login-left-bg "></Index.Box>
          <Index.Box className="login-overlay"></Index.Box>
        </Index.Box>

        <Index.Box className="admin-login-right-main">
          <PagesIndex.Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={adminLoginSchema}
            onSubmit={handleAdminLoginForm}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="admin-login-box">
                  <Index.Box className="admin-login-main">
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Login!
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Please enter your credentials to login!
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Email Address
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Enter Email Address"
                            name="email"
                            autocomplete="off"
                            value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={touched.email && errors.email}
                              error={Boolean(errors.email && touched.email)}
                              onKeyDown={(e) => {
                                if (
                                  e.key === " " &&
                                  e.target.value.trim() === ""
                                ) {
                                  e.preventDefault();
                                }
                              }}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group pwd-icon-btn">
                          <Index.OutlinedInput
                            className="form-control-eye"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            placeholder="Enter Password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={values.password}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={(e) => {
                              if (
                                e.key === " " &&
                                e.target.value.trim() === ""
                              ) {
                                e.preventDefault();
                              }
                            }}
                            // onChange={(e) => {
                            //   const newValue = e.target.value.replace(
                            //     /\s/g,
                            //     ""
                            //   );
                            //   setFieldValue("password", newValue);
                            //   setFieldTouched("password", true);
                            // }}
                            // onChange={handleChange}
                            // helperText={
                            //   errors.password && touched.password
                            //     ? errors.password
                            //     : null
                            // }
                            // error={
                            //   errors.password && touched.password ? true : false
                            // }
                            helperText={touched.password && errors.password}
                            error={Boolean(
                              errors.password && touched.password
                            )}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  // onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {!showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                        </Index.Box>
                        {errors.password && touched.password && (
                          <Index.FormHelperText
                            error
                            id="outlined-adornment-password"
                            className="error-text"
                          >
                            {errors.password}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                      <Index.Box className="flex-all forgot-row">
                        {/* <Index.Box className="checkbox-main-new">
                          <BpCheckbox />
                          <Index.Typography className="checkbox-lable">
                            Remember Me
                          </Index.Typography>
                        </Index.Box> */}
                        <Index.Link
                          className="text-decoration-none admin-forgot-para common-para"
                          to="/admin/forgotpassword"
                        >
                          Forgot Password?
                        </Index.Link>
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                        >
                          Login
                        </Index.Button>
                      </Index.Box>
                      {/* <Index.Box className="or-text-main">
                        <Index.Typography
                          className="or-cocntent-text"
                          component="p"
                        >
                          OR
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="condition-content">
                        <Index.Typography className="condition-text">
                          Don't have an account?
                          <span className="condition-span">
                            <Index.Link className="condition-span">
                              Sign Up here
                            </Index.Link>
                          </span>
                        </Index.Typography>
                      </Index.Box> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}
