import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import PropTypes from "prop-types";
import Rating from "@mui/material/Rating";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminJobManagementPendingJobRequest() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [ratingvalue, setRatingValue] = React.useState(2);

  // Pending Job request modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Unfilled modal
  const [unfilledopen, setUnfilledOpen] = React.useState(false);
  const unfilledhandleOpen = () => setUnfilledOpen(true);
  const unfilledhandleClose = () => setUnfilledOpen(false);

  // Pending Job request after ofiice modal
  const [pendingjobopen, setPendingJobOpen] = React.useState(false);
  const pendingjobhandleOpen = () => setPendingJobOpen(true);
  const pendingjobhandleClose = () => setPendingJobOpen(false);

  return (
    <>
      <Index.Box className="dashboard-content home-page-content res-home-page-content">
        <Index.Box className="cus-tabs-main-box">
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Index.Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="cus-tabs-main"
              >
                <Index.Tab
                  label="Pending Job Requests (From Users)"
                  {...a11yProps(0)}
                  className="cus-tab"
                />
                <Index.Tab
                  label="Pending Job Requests (After officer has accepted)"
                  {...a11yProps(1)}
                  className="cus-tab"
                />
                <Index.Tab
                  label="Unfilled/Unserved jobs"
                  {...a11yProps(2)}
                  className="cus-tab"
                />
                <Index.Tab
                  label="Active Jobs"
                  {...a11yProps(3)}
                  className="cus-tab"
                />
                <Index.Tab
                  label="Completed jobs"
                  {...a11yProps(4)}
                  className="cus-tab"
                />
              </Index.Tabs>
            </Index.Box>
            <TabPanel value={value} index={0} className="cus-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box className="active-job-main">
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Posted By :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Associated Agency :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Agency Name
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>

                      <Index.PrimaryButton
                        btnLabel="View"
                        onClick={handleOpen}
                        className="btn-primary"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Posted By :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Associated Agency :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Agency Name
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>

                      <Index.PrimaryButton
                        btnLabel="View"
                        onClick={handleOpen}
                        className="btn-primary"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Posted By :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Associated Agency :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Agency Name
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>

                      <Index.PrimaryButton
                        btnLabel="View"
                        onClick={handleOpen}
                        className="btn-primary"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Posted By :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Associated Agency :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Agency Name
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>

                      <Index.PrimaryButton
                        btnLabel="View"
                        onClick={handleOpen}
                        className="btn-primary"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={1} className="cus-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box className="active-job-main">
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Posted By :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Associated Agency :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Agency Name
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>

                      <Index.PrimaryButton
                        btnLabel="View"
                        onClick={pendingjobhandleOpen}
                        className="btn-primary"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Posted By :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Associated Agency :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Agency Name
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>

                      <Index.PrimaryButton
                        btnLabel="View"
                        onClick={pendingjobhandleOpen}
                        className="btn-primary"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Posted By :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Associated Agency :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Agency Name
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>

                      <Index.PrimaryButton
                        btnLabel="View"
                        onClick={pendingjobhandleOpen}
                        className="btn-primary"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Posted By :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Associated Agency :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Agency Name
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>

                      <Index.PrimaryButton
                        btnLabel="View"
                        onClick={pendingjobhandleOpen}
                        className="btn-primary"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={2} className="cus-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box className="card-main">
                  <Index.Box className="card-left">
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Job Id
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Job Title
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Posted By :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        Username
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Posted On :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        00/00/0000
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Accepted By:-&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        / Officer Name (if any officer has accepted)
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Accepted On :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        00/00/0000
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-right">
                    <Index.PrimaryButton
                      btnLabel="View"
                      onClick={unfilledhandleOpen}
                      className="btn-primary"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="card-main">
                  <Index.Box className="card-left">
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Job Id
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Job Title
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Posted By :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        Username
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Posted On :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        00/00/0000
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Accepted By:-&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        / Officer Name (if any officer has accepted)
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Accepted On :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        00/00/0000
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-right">
                    <Index.PrimaryButton
                      btnLabel="View"
                      className="btn-primary"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="card-main">
                  <Index.Box className="card-left">
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Job Id
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Job Title
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Posted By :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        Username
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Posted On :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        00/00/0000
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Accepted By:-&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        / Officer Name (if any officer has accepted)
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Accepted On :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        00/00/0000
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-right">
                    <Index.PrimaryButton
                      btnLabel="View"
                      className="btn-primary"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="card-main">
                  <Index.Box className="card-left">
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Job Id
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Job Title
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Posted By :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        Username
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Posted On :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        00/00/0000
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Accepted By:-&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        / Officer Name (if any officer has accepted)
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Accepted On :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        00/00/0000
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-right">
                    <Index.PrimaryButton
                      btnLabel="View"
                      className="btn-primary"
                    />
                  </Index.Box>
                </Index.Box>
                <Index.Box className="card-main">
                  <Index.Box className="card-left">
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Job Id
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Job Title
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Posted By :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        Username
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Posted On :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        00/00/0000
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Accepted By:-&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        / Officer Name (if any officer has accepted)
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Accepted On :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        00/00/0000
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-right">
                    <Index.PrimaryButton
                      btnLabel="View"
                      className="btn-primary"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={3} className="cus-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box className="active-job-main">
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Client :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          location :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          location
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Starting Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Ending Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Link to="/admin/adminjobmanagementactivejobs">
                        <Index.PrimaryButton
                          btnLabel="View"
                          className="btn-primary"
                        />
                      </Index.Link>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Client :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          location :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          location
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Starting Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Ending Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Link to="/admin/adminjobmanagementactivejobs">
                        <Index.PrimaryButton
                          btnLabel="View"
                          className="btn-primary"
                        />
                      </Index.Link>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Client :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          location :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          location
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Starting Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Ending Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Link to="/admin/adminjobmanagementactivejobs">
                        <Index.PrimaryButton
                          btnLabel="View"
                          className="btn-primary"
                        />
                      </Index.Link>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
            <TabPanel value={value} index={4} className="cus-tabpanel">
              <Index.Box className="tabpanel-main">
                <Index.Box className="complete-job-main">
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Client :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          location :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          location
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Starting Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Ending Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="rating-main">
                        <Index.Box
                          sx={{
                            "& > legend": { mt: 2 },
                          }}
                        >
                          <Rating
                            name="simple-controlled"
                            value={ratingvalue}
                            onChange={(event, newValue) => {
                              setRatingValue(newValue);
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Link to="/admin/adminJobmanagementcompletedjobs">
                        <Index.PrimaryButton
                          btnLabel="View"
                          className="btn-primary"
                        />
                      </Index.Link>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Client :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          location :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          location
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Starting Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Ending Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="rating-main">
                        <Index.Box
                          sx={{
                            "& > legend": { mt: 2 },
                          }}
                        >
                          <Rating
                            name="simple-controlled"
                            value={ratingvalue}
                            onChange={(event, newValue) => {
                              setRatingValue(newValue);
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.PrimaryButton
                        btnLabel="View"
                        className="btn-primary"
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Title
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Client :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Username
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Assigned officer :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          Officer name
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          location :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          location
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Starting Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Job Ending Date :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          00/00/0000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total Hours :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          10
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Pay Rate :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $100/hours
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="card-right">
                      <Index.Box className="job-title-text">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-label"
                        >
                          Total :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                        >
                          $1000
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="rating-main">
                        <Index.Box
                          sx={{
                            "& > legend": { mt: 2 },
                          }}
                        >
                          <Rating
                            name="simple-controlled"
                            value={ratingvalue}
                            onChange={(event, newValue) => {
                              setRatingValue(newValue);
                            }}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.PrimaryButton
                        btnLabel="View"
                        className="btn-primary"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </TabPanel>
          </Index.Box>
        </Index.Box>

        {/* Pending job Request modal */}
        <Index.Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box
            sx={style}
            className="add-user-modal-inner-main modal-inner"
          >
            <Index.Box className="modal-header">
              <Index.Typography
                id="modal-modal-title"
                className="modal-title"
                variant="h6"
                component="h2"
              >
                Pending Job Requests
              </Index.Typography>
              <img
                src={PagesIndex.Svg.closeblack}
                className="modal-close-icon"
                onClick={handleClose}
              />
            </Index.Box>

            {/* <Index.Box className="modal-user-btn-flex">
            <Index.Box className="discard-btn-main border-btn-main btn-modal-both">
                <Index.PrimaryButton className='btn-primary bg-approve' btnLabel="Approved" />
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
                <Index.PrimaryButton className='btn-primary bg-suspend' btnLabel="Reject" />
            </Index.Box>
        </Index.Box> */}
            <Index.Box className="card-left">
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Job Title
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Posted By :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Username
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  00/00/0000
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Phone Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  9876543210
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  username@gmail.com
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Location :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Location
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  required Skill :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  required Skill
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Service :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Service
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Department :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Department
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Date :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  00/00/0000 to 00/00/0000
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Timing :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  00:00:00 AM/PM to 00:00:00 AM/PM
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Pay Rate (Per hour) :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  $100
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Description :&nbsp;
                </Index.Typography>
                {/* <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                >
                    $100
                </Index.Typography> */}
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  "job description will be shown here"
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="document-btn-grp">
              <Index.PrimaryButton
                btnLabel="Approve"
                className="btn-primary bg-approve appr-rej-btn"
              />

              <Index.PrimaryButton
                btnLabel="Reject"
                className="btn-primary bg-suspend appr-rej-btn"
              />
            </Index.Box>
          </Index.Box>
        </Index.Modal>

        {/* Unfilled modal */}
        <Index.Modal
          open={unfilledopen}
          onClose={unfilledhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box
            sx={style}
            className="add-user-modal-inner-main modal-inner"
          >
            <Index.Box className="modal-header">
              <Index.Typography
                id="modal-modal-title"
                className="modal-title"
                variant="h6"
                component="h2"
              >
                Unfilled/Unserved jobs
              </Index.Typography>
              <img
                src={PagesIndex.Svg.closeblack}
                className="modal-close-icon"
                onClick={unfilledhandleClose}
              />
            </Index.Box>
            <Index.Box className="card-left">
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Job Id
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Job Title
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Posted By :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Username
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  00/00/0000
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Phone Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  9876543210
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  username@gmail.com
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Location :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Location
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  required Skill :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  required Skill
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Service :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Service
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Department :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Department
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Date :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  00/00/0000 to 00/00/0000
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Timing :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  00:00:00 AM/PM to 00:00:00 AM/PM
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Pay Rate (Per hour) :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  $100
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Description :&nbsp;
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  "job description will be shown here"
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Acceptrd by :-&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Officer Name
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Acceptrd on :-&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  00/00/0000
                </Index.Typography>
              </Index.Box>
            </Index.Box>
            {/* <Index.Box className='document-btn-grp'>
            <Index.PrimaryButton
                btnLabel="Approve"
                className="btn-primary bg-approve appr-rej-btn" />

            <Index.PrimaryButton
                btnLabel="Reject"
                className="btn-primary bg-suspend appr-rej-btn" />
        </Index.Box> */}
          </Index.Box>
        </Index.Modal>

        {/* Pending job Request after officer accepted modal */}
        <Index.Modal
          open={pendingjobopen}
          onClose={pendingjobhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal"
        >
          <Index.Box
            sx={style}
            className="add-user-modal-inner-main pending-after-officer-modal modal-inner"
          >
            <Index.Box className="modal-header">
              <img
                src={PagesIndex.Svg.closeblack}
                className="modal-close-icon"
                onClick={pendingjobhandleClose}
              />
            </Index.Box>
            <Index.Box className="pending-after-main">
              <Index.Box className="card-left pending-job-detail">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="modal-title"
                >
                  Job Details
                </Index.Typography>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Job Title
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Posted By :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    Username
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Posted On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    00/00/0000
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Phone Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    9876543210
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Email Address :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    username@gmail.com
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Location :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    Location
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    required Skill :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    required Skill
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Service :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    Service
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Department :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    Department
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Date :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    00/00/0000 to 00/00/0000
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Timing :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    00:00:00 AM/PM to 00:00:00 AM/PM
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Pay Rate (Per hour) :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    $100
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Description :&nbsp;
                  </Index.Typography>
                  {/* <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                >
                    $100
                </Index.Typography> */}
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    "job description will be shown here"
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="officer-detail pending-ofiicer-detail">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="modal-title"
                >
                  Officer Details
                </Index.Typography>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Name :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    Full Name
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Email Address :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    username@gmail.com
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Phone Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    9876543210
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Category of work :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    Category of work
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Skill :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    skill
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Rank :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    Rank
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Badge Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    00000000
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    TOD (Time On Duty) :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    10 year 10 month
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Required Pay Rate (Per hour) :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    $100
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Available For :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    Job Type
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Available on :&nbsp;
                  </Index.Typography>
                  <Index.Box className="">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="job-title-text-label"
                    >
                      <span className="day-title">Monday</span> -{" "}
                      <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="job-title-text-label"
                    >
                      <span className="day-title">Tuesday</span> -{" "}
                      <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="job-title-text-label"
                    >
                      <span className="day-title">Wednesday</span> -{" "}
                      <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="job-title-text-label"
                    >
                      <span className="day-title">Thursday</span> -{" "}
                      <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="job-title-text-label"
                    >
                      <span className="day-title">Friday</span> -{" "}
                      <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="job-title-text-label"
                    >
                      <span className="day-title">Saturday</span> -{" "}
                      <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="job-title-text-label"
                    >
                      <span className="day-title">Sunday</span> -{" "}
                      <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Document :&nbsp;
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="document-wrapper">
                  <Index.Box className="document-main">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="job-title-text-label"
                    >
                      Document 1
                    </Index.Typography>
                    <Index.PrimaryButton
                      btnLabel="View Document"
                      className="btn-primary"
                    />
                  </Index.Box>
                  <Index.Box className="document-main">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="job-title-text-label"
                    >
                      Document 2
                    </Index.Typography>
                    <Index.PrimaryButton
                      btnLabel="View Document"
                      className="btn-primary"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="document-btn-grp">
              <Index.PrimaryButton
                btnLabel="Approve"
                className="btn-primary bg-approve appr-rej-btn"
              />

              <Index.PrimaryButton
                btnLabel="Reject"
                className="btn-primary bg-suspend appr-rej-btn"
              />
            </Index.Box>
          </Index.Box>
        </Index.Modal>
      </Index.Box>
    </>
  );
}
