import { combineReducers } from "@reduxjs/toolkit";
import AdminSliceReducer from "./slice/admin-slice/AdminSlice";
import AgencySliceReducer from "./slice/agency-slice/AgencySlice";
// import authReducers from "./slice/auth/authSlice";
const combinedReducer = combineReducers({
  AdminSlice: AdminSliceReducer,
  AgencySlice: AgencySliceReducer,
});

export const rootReducer = (state, action) => {
  return combinedReducer(state, action);
};
