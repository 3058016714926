import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";

const AgencyDetailsofcompletedjobs = () => {
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  const AgencyName = agencyLoginData?.agencyName;
  const roleName = agencyLoginData?.roleId?.Permission_name;
  const [ratingvalue, setRatingValue] = React.useState(2);
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const completejobsDetail = state;

  // us Formate
  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters from the input
    const cleaned = ("" + input).replace(/\D/g, "");

    // Format the cleaned phone number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return cleaned;
  };

  return (
    <>

      <Index.Box className="user-list-flex">
        <Index.Box className="title-header-flex">
          <Index.Box
            onClick={() =>
              navigate(`/${roleName}/agency-job-management`, {
                state: "CompletedJobs",
              })
            }
          >
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            />
          </Index.Box>
          <Index.Typography className="user-list-page-title">
            {completejobsDetail?.title}
          </Index.Typography>

        </Index.Box>
      </Index.Box>
      <Index.Box className="details-active-job-sec active-job-sec">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Assigned Officer
          </Index.Typography>
        </Index.Box>

        {completejobsDetail?.officerId.map((item, index) => {
          const officershortDetail = item?.officerId;
          return (
            <Index.Box className="card-main">
              <Index.Box className="card-left" sx={{ display: "flex", gap: "16px" }}>
                <Index.Box className="officer-profile-flex">
                  <img
                    src={PagesIndex.Png.usericon}
                    className="prof-img"
                    alt=""
                  />
                </Index.Box>
                <Index.Box className="officer-detail">
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      {officershortDetail?.firstName ? (
                        <>
                          {officershortDetail?.firstName +
                            " " +
                            officershortDetail?.lastName}
                        </>
                      ) : null}
                      &nbsp;
                    </Index.Typography>
                    {/* </Index.Box>
                          <Index.Box className="job-title-text"> */}
                    {officershortDetail?.officerType ==
                      "Active Police Officer" ? null : (
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        ({officershortDetail?.officerType})
                      </Index.Typography>
                    )}
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Officer Assigned on :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {PagesIndex.dayjs(item?.jobAcceptedDate).format(
                        "MM-DD-YYYY"
                      )}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Officer Check-In :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {/* {PagesIndex.dayjs(item?.jobCheckInTime).format(
                                "MM-DD-YYYY : HH:mm"
                              )} */}
                      {item?.jobCheckInTime &&
                        PagesIndex.dayjs(item?.jobCheckInTime).isValid()
                        ? PagesIndex.dayjs(item.jobCheckInTime).format(
                          "MM-DD-YYYY - HH:mm"
                        )
                        : " Not checkin"}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Officer Check-Out :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {/* {PagesIndex.dayjs(
                                item?.jobCheckOutTime[0]
                              ).format("MM-DD-YYYY : HH:mm")} */}

                      {item?.jobCheckOutTime[0] &&
                        PagesIndex.dayjs(
                          item?.jobCheckOutTime[0]
                        ).isValid()
                        ? PagesIndex.dayjs(
                          item.jobCheckOutTime[0]
                        ).format("MM-DD-YYYY - HH:mm")
                        : " Not checkout"}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          );
        })}

        <Index.Box className="details-active-job-sec active-job-sec">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              General Information
            </Index.Typography>
          </Index.Box>
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-detail">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Title :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.title}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Posted By :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.userId?.name}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Posted On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {PagesIndex.dayjs(completejobsDetail?.createdAt).format(
                      "MM-DD-YYYY"
                    )}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Total Payment :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Transaction Id :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Phone Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {formatPhoneNumber(completejobsDetail?.mobileNumber)}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Email Address :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.userId?.email}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Point Of Contact Name :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.pointOfContactName}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Point Of Contact Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {formatPhoneNumber(
                      completejobsDetail?.pointOfContactNumber || "-"
                    )}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Location :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.location}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Service :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.service}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Department :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.department}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Type :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.jobType?.jobType}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Zip Code :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.zipCode}
                  </Index.Typography>
                </Index.Box>
                {completejobsDetail?.supervisor ? (
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Supervisor :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {completejobsDetail?.supervisor}
                    </Index.Typography>
                  </Index.Box>
                ) : null}

                {completejobsDetail?.nonSupervisor ? (
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Non Supervisor :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {completejobsDetail?.nonSupervisor}
                    </Index.Typography>
                  </Index.Box>
                ) : null}
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Date/Time :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >

                    {PagesIndex.showDateTime(completejobsDetail)}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Total Hours :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {/* {PagesIndex.timeDifferenceCalculation(
                      completejobsDetail?.jobSchedule[0]?.jobStartTime,
                      completejobsDetail?.jobSchedule[0]?.jobEndTime
                    )} hour */}
                    {PagesIndex.timeDifferenceCalculation(completejobsDetail)}{" "}
                    hour
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Pay Rate :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.rate ? "$" : null}
                    {completejobsDetail?.rate}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    No of Officer :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.totalOfficer}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Information :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.information}
                  </Index.Typography>
                </Index.Box>
                <Index.Box
                  // className="job-title-text align-start-job"
                  className="work-title-flex"
                >
                  <Index.Typography
                    component="p"
                    variant="p"
                    // className="job-title-label job-description-width"
                    className="work-title-lable"
                  >
                    Description :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    // className="job-title-text-label"
                    className="work-value-text"
                  >
                    {completejobsDetail?.description}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="details-active-job-sec active-job-sec">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              Organizer (Client)
            </Index.Typography>
          </Index.Box>
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-detail">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Name :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.userId?.name}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Mobile Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {formatPhoneNumber(
                      completejobsDetail?.userId?.mobileNumber
                    )}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Email Address :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {completejobsDetail?.userId?.email}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="details-active-job-sec active-job-sec">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              Job Log
            </Index.Typography>
          </Index.Box>
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-detail">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Posted On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {PagesIndex.dayjs(completejobsDetail?.createdAt).format(
                      "MM-DD-YYYY"
                    )}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Transferred To :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Transferred On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AgencyDetailsofcompletedjobs;
