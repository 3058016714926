import React from "react";
import Index from "../../container/index";
import PagesIndex from "../../container/PageIndex";

export default function NoDataFound() {
  return (
    <>
      <Index.Box className="data-not-found-main">
        <Index.Typography className='data-not-found-text'>No data available</Index.Typography>
      </Index.Box>
    </>
  );
}
