//import React from "react";
import React, { useState, useEffect } from "react";
import PagesIndex from "../../PagesIndex";
import Index from "../../index";
import { useSelector } from "react-redux";
import { logout } from "../../../redux-toolkit/slice/admin-slice/AdminSlice";
import { useNavigate } from "react-router-dom";

function Sidebar(props) {
  const [open, setOpen] = React.useState(true);
  const location = PagesIndex.useLocation();
  const [screen, setScreen] = useState("");
  const dispatch = PagesIndex.useDispatch();
  // role permission
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  const SubAdminPermission = adminLoginData?.roleId?.SubAdminPermission;
  // const roleName = adminLoginData?.name;
  const roleName = adminLoginData?.roleId?.Permission_name;

  const navigate = useNavigate();

  const handleClickAccount = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (window.screen.width < 769) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);


  const handleAdminlogout = () => {
    dispatch(logout());
    PagesIndex.toast.success("Logged out successfully");
    localStorage.removeItem("AdminToken");
    localStorage.removeItem("notificationcount");
    navigate("/admin");
  };


  return (
    <>


      <Index.Box
        className={`admin-sidebar-main ${(screen === "Mobile" ? !props.open : props.open) ? "active" : ""
          }`}
      >
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <img
              src={PagesIndex.Png.logo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button
              onClick={() => {
                props.setOpen(!props.open);
                document.body.classList[props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }}
              className="sidebar-close-btn"
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="close-icon"
                alt="logo"
              />
            </Index.Button>
          </Index.Box>

          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/dashboard"
                  className={`admin-sidebar-link ${location.pathname.includes("/admin/dashboard")
                    ? "active"
                    : ""
                    }`}
                >
                  <img
                    src={PagesIndex.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Dashboard
                </Index.Link>
              </Index.ListItem>

              {/* {roleName === "Admin" ||
                SubAdminPermission?.zip_code?.value === true ? ( */}
              {(roleName === "Admin" ||
                SubAdminPermission?.zip_code?.permission?.add ||
                SubAdminPermission?.zip_code?.permission?.edit ||
                SubAdminPermission?.zip_code?.permission?.delete) && (
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/admin/zipcode"
                      className={`admin-sidebar-link ${location.pathname.includes("/admin/zipcode")
                        ? "active"
                        : ""
                        }`}
                    >
                      <img
                        src={PagesIndex.Svg.zipCode}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Zip Code Management
                    </Index.Link>
                  </Index.ListItem>
                )}

              {/* {roleName === "Admin" ||
                SubAdminPermission?.Job_Type?.value === true ? ( */}
              {(roleName === "Admin" ||
                SubAdminPermission?.Job_Type?.permission?.add ||
                SubAdminPermission?.Job_Type?.permission?.edit ||
                SubAdminPermission?.Job_Type?.permission?.delete) && (
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/admin/jobtype"
                      // className="admin-sidebar-link"
                      className={`admin-sidebar-link ${location.pathname.includes("/admin/jobtype")
                        ? "active"
                        : ""
                        }`}
                    >
                      <img
                        // src={PagesIndex.Svg.jobmanagement}
                        src={PagesIndex.Svg.job}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Job Type Management
                    </Index.Link>
                  </Index.ListItem>
                )}

              {/* {roleName === "Admin" ||
                SubAdminPermission?.Skills?.value === true ? ( */}
              {(roleName === "Admin" ||
                SubAdminPermission?.Skills?.permission?.add ||
                SubAdminPermission?.Skills?.permission?.edit ||
                SubAdminPermission?.Skills?.permission?.delete) && (
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/admin/skills"
                      // className="admin-sidebar-link"
                      className={`admin-sidebar-link ${location.pathname.includes("/admin/skills")
                        ? "active"
                        : ""
                        }`}
                    >
                      <img
                        src={PagesIndex.Svg.skill}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Skill Management
                    </Index.Link>
                  </Index.ListItem>
                )}

              {roleName === "Admin" ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/usermanage"
                    // className="admin-sidebar-link"
                    className={`admin-sidebar-link ${location.pathname.includes("/admin/usermanage")
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.system}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    System Admin Management
                  </Index.Link>
                </Index.ListItem>
              ) : null}

              {/* {roleName === "Admin" ||
                SubAdminPermission?.Client?.value === true ? ( */}
              {(roleName === "Admin" ||
                SubAdminPermission?.Client?.permission?.add ||
                SubAdminPermission?.Client?.permission?.edit ||
                SubAdminPermission?.Client?.permission?.delete ||
                SubAdminPermission?.Client?.permission?.read ||
                SubAdminPermission?.Client?.permission?.Suspend) && (
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/admin/vendormanage"
                      // className="admin-sidebar-link"
                      className={`admin-sidebar-link ${location.pathname.includes("/admin/vendormanage")
                        ? "active"
                        : ""
                        }`}
                    >
                      <img
                        src={PagesIndex.Svg.client}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Vendor Management
                    </Index.Link>
                  </Index.ListItem>
                )}

              {/* {roleName === "Admin" ||
                SubAdminPermission?.Agency?.value === true ? ( */}
              {(roleName === "Admin" ||
                SubAdminPermission?.Agency?.permission?.add ||
                SubAdminPermission?.Agency?.permission?.edit ||
                SubAdminPermission?.Agency?.permission?.delete ||
                SubAdminPermission?.Agency?.permission?.read ||
                SubAdminPermission?.Agency?.permission?.Suspend) && (
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/admin/agentmanagement"
                      className={`admin-sidebar-link ${location.pathname.includes("/admin/agentmanagement")
                        ? "active"
                        : ""
                        }`}
                    >
                      <img
                        src={PagesIndex.Svg.agentmanagement}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Agency/Department Management
                    </Index.Link>
                  </Index.ListItem>
                )}

              {/* {roleName === "Admin" ||
                SubAdminPermission?.officer?.value === true ? ( */}
              {(roleName === "Admin" ||
                SubAdminPermission?.officer?.permission?.add ||
                SubAdminPermission?.officer?.permission?.edit ||
                SubAdminPermission?.officer?.permission?.delete ||
                SubAdminPermission?.officer?.permission?.read ||
                SubAdminPermission?.officer?.permission?.Suspend) && (
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/admin/officer-management"
                      className={`admin-sidebar-link ${location.pathname.includes("/admin/officer-management")
                        ? "active"
                        : ""
                        }`}
                    >
                      <img
                        src={PagesIndex.Svg.jobmanagement}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Officer Management
                    </Index.Link>
                  </Index.ListItem>
                )}

              {/* <Index.ListItem className="admin-sidebar-listitem">
                      <Index.Link
                        to="/admin/adminextraworkpermit"
                        className={`admin-sidebar-link ${
                          location.pathname.includes(
                            "/admin/adminextraworkpermit"
                          )
                            ? "active"
                            : ""
                        }`}
                      >
                        <img
                          src={PagesIndex.Svg.agentmanagement}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Extra Work Permit Requests
                      </Index.Link>
                    </Index.ListItem> */}

              {roleName === "Admin" ||
                SubAdminPermission?.Jobs?.value === true ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/adminjobmanagement"
                    className={`admin-sidebar-link ${location.pathname.includes("/admin/adminjobmanagement")
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.jobType}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Job Management
                  </Index.Link>
                </Index.ListItem>
              ) : null}

              {/* <Index.ListItem className="admin-sidebar-listitem">
                      <Index.Link
                        to="/admin/admincategorymanagementplatformcategories"
                        className={`admin-sidebar-link ${
                          location.pathname.includes(
                            "/admin/admincategorymanagementplatformcategories"
                          )
                            ? "active"
                            : ""
                        }`}
                      >
                        <img
                          src={PagesIndex.Svg.category}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Category Management
                      </Index.Link>
                    </Index.ListItem> */}

              {/* <Index.ListItem className="admin-sidebar-listitem">
                      <Index.Link
                        to="/admin/adminpaymentmanagement"
                        className={`admin-sidebar-link ${
                          location.pathname.includes(
                            "/admin/adminpaymentmanagement"
                          )
                            ? "active"
                            : ""
                        }`}
                      >
                        <img
                          src={PagesIndex.Svg.payment}
                          alt="sidebar icon"
                          className="admin-sidebar-icons"
                        />
                        Payment Management
                      </Index.Link>
                    </Index.ListItem> */}
              {roleName === "Admin" ||
                SubAdminPermission?.Rating_Reviews?.value === true ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/adminratingandreviewmanagement"
                    className={`admin-sidebar-link ${location.pathname.includes(
                      "/admin/adminratingandreviewmanagement"
                    )
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.star}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Rating & Review Management
                  </Index.Link>
                </Index.ListItem>
              ) : null}

              {roleName === "Admin" ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/payment-config"
                    className={`admin-sidebar-link ${location.pathname.includes(
                      "/admin/payment-config"
                    )
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.crediCard}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Payment Configuration  Management
                  </Index.Link>
                </Index.ListItem>
              ) : null}

              {roleName === "Admin" ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/payment-percentage"
                    className={`admin-sidebar-link ${location.pathname.includes(
                      "/admin/payment-percentage"
                    )
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.star}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Payment Percentage Management
                  </Index.Link>
                </Index.ListItem>
              ) : null}

              {roleName === "Admin" ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/role"
                    className={`admin-sidebar-link ${location.pathname.includes("/admin/role")
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.role}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Role Management
                  </Index.Link>
                </Index.ListItem>
              ) : null}

              {/* {roleName === "Admin" ||
                SubAdminPermission?.CMS?.value === true ? ( */}
              {(roleName === "Admin" ||
                SubAdminPermission?.CMS?.permission?.edit ||
                SubAdminPermission?.CMS?.permission?.delete) && (
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to="/admin/admincontentmanagement"
                      className={`admin-sidebar-link ${location.pathname.includes(
                        "/admin/admincontentmanagement"
                      )
                        ? "active"
                        : ""
                        }`}
                    >
                      <img
                        src={PagesIndex.Svg.cms}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Content Management
                    </Index.Link>
                  </Index.ListItem>
                )}

              {roleName === "Admin" ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/admin/adminprofile"
                    className={`admin-sidebar-link ${location.pathname.includes("/admin/adminprofile")
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.profileConfig}
                      alt="sidebar icon"
                      className="admin-sidebar-icons img-filter-invert"
                    />
                    Profile
                  </Index.Link>
                </Index.ListItem>
              ) : null}

              {roleName === "Admin" ? (
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to={"#"}
                  className="admin-sidebar-link"
                  onClick={() => {
                    handleAdminlogout()
                  }}
                >
                  <img
                    src={PagesIndex.Svg.exit}
                    alt="sidebar icon"
                    className="admin-sidebar-icons img-filter-invert"
                  />
                  Sign Out
                </Index.Link>
              </Index.ListItem>
              ) : null}


            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Sidebar;
