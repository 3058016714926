import React, { useEffect, useState } from "react";
import PagesIndex from "../../../../../PageIndex";
import Index from "../../../../..";
import DataService from "../../../../../../config/DataService";
// import { Api } from "../../../../../../config/Api";
import { Api } from "../../../../../../../src/config/Api";
import { officerPrivacyPolicySchema } from "../../../../../../validation/FormikSchema";
import dataService from "../../../../../../config/CkService";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getCmsDataList } from "../../../../../../redux-toolkit/slice/admin-slice/AdminServices";
import Loader from "../../../../../../common/loader/Loader";

const OfficerPrivacyPolicy = () => {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const [privayPolicyData, setPrivayPolicyData] = useState({
    title: "",
    description: "",
    image: "",
  });
  // const initialValues = {
  //   title: "",
  //   description: "",
  // };

  const handlePrivacyPolicyForm = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("title", values?.title);
    urlEncoded.append("description", values?.description);
    // urlEncoded.append("image", values.image);
    urlEncoded.append("type", location?.state);
    console.log("urlEncoded", [...urlEncoded]);
    try {
      const response = await DataService.post(
        Api.Admin.OFFICER_CREATE_PRIVACY_POLICY,
        urlEncoded
      );
      if (response?.data?.status === 201) {
        PagesIndex.toast.success(response?.data?.message);
        setIsLoading(false);
        navigate("/admin/admincontentmanagement");
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  // get Cms data
  const getCmsList = () => {
    dispatch(getCmsDataList("officer")).then((res) => {
      if (res?.payload?.status == 200) {
        console.log("if", res?.payload?.data);
        setPrivayPolicyData({
          title: res?.payload?.data[0]?.privacyPolicy?.title ?? "",
          description: res?.payload?.data[0]?.privacyPolicy?.description ?? "",
        });
      }
    });
  };

  useEffect(() => {
    getCmsList();
  }, []);

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            dataService
              .post("admin/image-upload", body)
              .then((res) => {
                resolve({
                  default: `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${res.data.image.filename}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  function removeTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/gi, "");
  }
  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          <Index.Link
            to="/admin/admincontentmanagement"
            className="filter-back-link"
          >
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            ></img>
          </Index.Link>
          Privacy Policy
        </Index.Typography>
      </Index.Box>
      <Index.Box className="common-card">
        <PagesIndex.Formik
          enableReinitialize
          validationSchema={officerPrivacyPolicySchema}
          // initialValues={initialValues}
          initialValues={privayPolicyData}
          onSubmit={handlePrivacyPolicyForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleFocus,
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log(values, "values")}
              <Index.Box className="ck_edit_wrap">
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Title
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Title"
                      name="title"
                      value={values.title}
                      onChange={(e) => {
                        // const newValue = e.target.value.replace(
                        //   /\s+/g,
                        //   " "
                        // );
                        // setFieldValue("title", newValue);
                        handleChange(e);
                        setFieldTouched("title", true);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.value.trim() === "") {
                          e.preventDefault(); // Prevent space as the first character
                        } else if (
                          e.target.value.trim().split(" ").length >= 2 &&
                          e.key === " "
                        ) {
                          e.preventDefault(); // Prevent adding additional spaces between words
                        } else if (
                          e.key === " " &&
                          e.target.value.endsWith(" ")
                        ) {
                          e.preventDefault(); // Prevent additional spaces at the end
                        }
                        if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                          e.preventDefault(); // Prevent non-numeric characters
                        }
                      }}
                      onBlur={handleBlur}
                      inputProps={{maxLength: 30}}
                      error={errors.title && touched.title ? true : false}
                      helperText={
                        errors.title && touched.title ? errors.title : null
                      }
                    />
                  </Index.Box>
                </Index.Box>

                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Content
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    {/* <PagesIndex.CKEditor
                      editor={ClassicEditor}
                      data={values.description}
                      name="description"
                      className="ck-editor-img"
                      contenteditable="true"
                      onReady={(editor) => {
                      }}
                      config={{
                        extraPlugins: [uploadPlugin],
                        mediaEmbed: { previewsInData: true },
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue("description", data);

                        const maxCharacterLimit = 5500;
                        if (data.length > maxCharacterLimit) {
                          event.stop();
                        }
                      }}
                    /> */}
                    <PagesIndex.CKEditor
                      config={{
                        toolbar: {
                          items: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "link",
                            "|",
                            "undo",
                            "redo",
                          ],
                        },
                        // extraPlugins: [uploadPlugin],
                        mediaEmbed: { previewsInData: true },
                      }}
                      contenteditable="true"
                      editor={PagesIndex.ClassicEditor}
                      name="description"
                      className="ck-editor-img"
                      data={values?.description}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        const maxCharacterLimit = 5560;
                        if (data.length > maxCharacterLimit) {
                          event.stop();
                        } else {
                          setFieldValue("description", data);
                        }
                      }}
                      // onChange={handleChange}
                    />
                  </Index.Box>
                  <Index.FormHelperText error>
                    {errors.description && touched.description
                      ? errors.description
                      : null}
                  </Index.FormHelperText>
                </Index.Box>
                <Index.Box className="user-btn-flex">
                  <Index.PrimaryButton
                    className="btn-primary"
                    disabled={isLoading}
                    type="submit"
                    // btnLabel="Submit"
                    btnLabel={
                      privayPolicyData?.title !== "" ||
                      privayPolicyData?.description !== ""
                        ? "Update"
                        : "Submit"
                    }
                  >
                    {isLoading ? <Loader /> : "Click Me"}
                  </Index.PrimaryButton>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Box>
    </>
  );
};

export default OfficerPrivacyPolicy;
