import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import PropTypes from "prop-types";
import Rating from "@mui/material/Rating";
import { SwipeableDrawer } from "@mui/material";
import AdminPendingJobModal from "./jobManageModal/AdminPendingJobModal";
import { getAdminJobList } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import AdminUnservedJobModal from "./jobManageModal/AdminUnservedJobModal";
import AdminPendingJobOfficerAceeptModal from "./jobManageModal/AdminPendingJobOfficerAceeptModal";
import Loader from "../../../../common/loader/Loader";
import { Api } from "../../../../config/Api";
import { newExportExcel } from "../../../../Export/ExportAll";
import { current } from "@reduxjs/toolkit";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const AdminJobManagement = () => {
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const { state } = PagesIndex.useLocation();
  const [loading, setLoading] = useState();
  const [tabValue, setTabValue] = useState(state ? state : "PendingUser");
  const [adminjobData, setAdminJobData] = useState([]);
  const [filterJobsList, setFilterjobsList] = useState([]);
  const [viewOfficerDetails, setViewOfficerDetails] = useState(false);
  const [viewOfficerId, setViewOfficerId] = useState(0);

  const handleClickShowOfficerDetails = (data, item) => {
    setViewOfficerDetails((view) => !view);
    // setViewOfficerId(data?._id);
    setPendingjobOfficerAcceptData(item);
    setPendingJobOpen(true);
  };
  const jobStartTime = filterJobsList[0]?.jobSchedule[0]?.jobStartTime;
  const jobEndTime = filterJobsList[0]?.jobSchedule[0]?.jobEndTime;
  const rate = filterJobsList[0]?.rate || 0;

  //Getting data from redux

  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  const SubAdminPermission = adminLoginData?.roleId?.SubAdminPermission;
  // const roleName = adminLoginData?.name;
  const roleName = adminLoginData?.roleId?.Permission_name;

  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    navigate({ state: null });
  }, [navigate]);
  const [ratingvalue, setRatingValue] = useState(2);

  // Pending Job request modal
  const [pendingjobId, setPendingJobId] = useState();
  const [pandingRequestopen, setPandingRequestOpen] = useState(false);
  const handleOpenPandingRequest = (id) => {
    setPendingJobId(id);
    setPandingRequestOpen(true);
  };
  const handleClosePandingRequest = () => setPandingRequestOpen(false);

  // Unfilled modal
  const [unservedJobData, setunservedJobData] = useState();
  const [unservedJobopen, setUnservedJobOpen] = React.useState(false);
  const handleOpenUnservedJob = (data) => {
    setunservedJobData(data);
    setUnservedJobOpen(true);
  };
  const handleCloseUnservedJob = () => {
    setUnservedJobOpen(false);
  };

  // Pending Job request after ofiice modal
  const [pendingjobopen, setPendingJobOpen] = React.useState(false);
  const [pendingjobOfficerAcceptData, setPendingjobOfficerAcceptData] =
    useState();
  const pendingjobhandleOpen = (item) => {
    console.log(item, "104");
    setPendingjobOfficerAcceptData(item);
    setPendingJobOpen(true);
  };

  const pendingjobhandleClose = () => setPendingJobOpen(false);

  // filter
  const [jobFilter, setjobFilter] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setjobFilter({ ...state, [anchor]: open });
  };
  // get job list data
  const getJobListData = () => {
    setLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("type", tabValue);
    dispatch(getAdminJobList(urlEncoded)).then((res) => {
      if (res?.payload?.status == 200) {
        console.log(res?.payload?.data, "dataaaaa");
        setAdminJobData(res?.payload?.data);
        setFilterjobsList(res?.payload?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getJobListData();
  }, [tabValue]);

  // Search on list
  const requestSearch = (searched) => {
    let filteredData = adminjobData.filter((data) => {
      return !searched
        ? data
        : `${data.title.toLowerCase()} ${data.userId?.name.toLowerCase()}  
        ${data.rate} ${data?.approveBy?.agencyName}
         ${data.supervisor} ${data.nonSupervisor}
          ${data.totalOfficer}
        ${PagesIndex.dayjs(data?.createdAt).format(
          "MM-DD-YYYY"
        )} ${PagesIndex.timeDifferenceCalculation(
          data?.jobStartTime,
          data?.jobEndTime
        )}`.includes(searched?.toLowerCase());
    });
    setFilterjobsList(filteredData);
  };

  // when the user export the data then used...
  const handleExportData = () => {
    // console.log("export is clicked....", tabValue);
    if (tabValue === "PendingUser") {
      let arrayParentJObId = adminjobData.reduce((acc, data) => {
        if (data && data._id) {
          acc.push(data._id);
        }
        return acc;
      }, [])
      console.log("174", arrayParentJObId);

      newExportExcel(
        `${Api.Admin.JOB_LIST_CSV_EXPORT_PENDINGUSER}`,
        "Pending_User_List",
        { type: "PendingUser", arrayParentJObId }
      );
    } else if (tabValue === "PendingOfficer") {
      newExportExcel(
        `${Api.Admin.JOB_LIST_CSV_EXPORT_PENDINGOFFICER}`,
        "Pending_Officer_List",
        { type: "PendingOfficer" }
      );
    } else if (tabValue === "ActiveJobs") {
      newExportExcel(
        `${Api.Admin.JOB_LIST_CSV_EXPORT_ACTIVEJOBS}`,
        "Active_Jobs_List",
        { type: "ActiveJobs" }
      );
    } else if (tabValue === "CompletedJobs") {
      newExportExcel(
        `${Api.Admin.JOB_LIST_CSV_EXPORT_COMPLETEDJOBS}`,
        "Completed_Jobs_List",
        { type: "CompletedJobs" }
      );
    } else if (tabValue === "UnservedJobs") {
      newExportExcel(
        `${Api.Admin.JOB_LIST_CSV_EXPORT_UNSERVEDJOBS}`,
        "Unserved_Jobs_List",
        { type: "UnservedJobs" }
      );
    } else {
    }
  };

  console.log("tabValue", tabValue);

  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          {/* <Index.Link to="/admin/dashboard" className="filter-back-link">
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            ></img>
          </Index.Link>{" "} */}
          Job Management
        </Index.Typography>
        <Index.Box className="userlist-btn-flex">
          <Index.Box className="user-search-main">
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => requestSearch(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.value.trim() === "") {
                      e.preventDefault();
                    }
                  }}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* Top filter */}
          <Index.Box className="userlist-inner-btn-flex">
            {/* <Index.Box className="filter-main">
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Index.Box className="border-btn-main">
                    <Index.Button
                      className="btn-primary"
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <img
                        src={PagesIndex.Svg.filter}
                        className="down-icon"
                        alt="download icon"
                      />
                      Filter
                    </Index.Button>
                  </Index.Box>
                  <SwipeableDrawer
                    className="filter-main"
                    anchor={anchor}
                    open={jobFilter[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Index.Box className="filter-header">
                      <Index.Typography className="filter-title">
                        User Filter
                      </Index.Typography>
                      <img
                        src={PagesIndex.Svg.closeicon}
                        className="filter-close-icon"
                        onClick={toggleDrawer(anchor, false)}
                        alt=""
                      />
                    </Index.Box>
                    <Index.Box className="filter-inner-main">
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          City
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Status
                        </Index.FormHelperText>
                        <Index.Box className="checkbox-main filter-checkbox-main input-box">
                          <Index.FormControlLabel
                            control={<Index.Checkbox defaultChecked />}
                            label="Active"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Deactivate"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Pending"
                            className="checkbox-lable"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-footer">
                      <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                        <Index.Button className="border-btn filter-cancel-btn">
                          Cancel
                        </Index.Button>
                        <Index.Button className="btn-primary filter-btn">
                          Filter
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Index.Box>  */}
            <Index.Box className="adduser-btn-main btn-main-primary">
              <Index.Button
                className="adduser-btn btn-primary"
                onClick={handleExportData}
              >
                <img
                  src={PagesIndex.Svg.exports}
                  alt=""
                  className="down-icon"
                />{" "}
                Exports
              </Index.Button>
              {/* <FileExcel apiData={filterJobsList} fileName="abcd" /> */}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <>
        <Index.Box className="cus-tabs-main-box">
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={tabValue}
              onChange={handleTabsChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="basic tabs example"
              className="cus-tabs-main"
            >
              <Index.Tab
                label="Pending Job Requests (From Users)"
                value="PendingUser"
                className="cus-tab"
              />
              <Index.Tab
                label="Pending Job Requests (After officer has accepted)"
                value="PendingOfficer"
                className="cus-tab"
              />
              <Index.Tab
                label="Approved Job"
                value="afterOfficerApprove"
                className="cus-tab"
              />
              <Index.Tab
                label="Active Jobs"
                value="ActiveJobs"
                className="cus-tab"
              />
              <Index.Tab
                label="Completed jobs"
                value="CompletedJobs"
                className="cus-tab"
              />
              <Index.Tab
                label="Unfilled/Unserved jobs"
                value="UnservedJobs"
                className="cus-tab"
              />
            </Index.Tabs>
          </Index.Box>
          <TabPanel
            value={tabValue}
            index="PendingUser"
            className="cus-tabpanel"
          >
            {loading ? (
              <Loader />
            ) : (
              <Index.Box className="tabpanel-main tabpanel-1">
                {filterJobsList.length ? (
                  filterJobsList.map((item, index) => (
                    <Index.Box className="card-main">
                      <Index.Box className="card-left">
                        <Index.Box className="work-title-flex">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-title-lable"
                          >
                            Job Title :&nbsp;
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-value-text"
                          >
                            {item?.title}
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box className="work-title-flex">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-title-lable"
                          >
                            Job Started :&nbsp;
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-value-text"
                          >
                            {PagesIndex.moment(item?.jobStartDate).format(
                              "MM-DD-YYYY"
                            )}
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box className="work-title-flex">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-title-lable"
                          >
                            Total Officer's :&nbsp;
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-value-text"
                          >
                            {item?.totalOfficer}
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box className="work-title-flex">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-title-lable"
                          >
                            Posted On :&nbsp;
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-value-text"
                          >
                            {PagesIndex.moment(item?.createdAt).format(
                              "MM-DD-YYYY"
                            )}
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box className="work-title-flex">
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-title-lable"
                          >
                            Posted By :&nbsp;
                          </Index.Typography>
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-value-text"
                          >
                            {item?.userId?.name}
                          </Index.Typography>
                        </Index.Box>


                        {item?.supervisor ? (
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Supervisor :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {item?.supervisor}
                            </Index.Typography>
                          </Index.Box>
                        ) : null}
                        {item?.nonSupervisor ? (
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Non Supervisor :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {item?.nonSupervisor}
                            </Index.Typography>
                          </Index.Box>
                        ) : null}
                        {item?.status == "APPROVED" ? (
                          <>
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                {item?.department == "Police department"
                                  ? "Associated Department"
                                  : "Associated Agency"}
                                :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.agency?.agencyName}
                              </Index.Typography>
                            </Index.Box>
                          </>
                        ) : null}
                        {item?.rate == undefined ? null : (
                          <Index.Box className="work-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-title-lable"
                            >
                              Pay Rate (Per hour) :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="work-value-text"
                            >
                              {item?.rate ? "$" : null}
                              {item?.rate}
                            </Index.Typography>
                            &nbsp;
                            {item?.isRate == true ? (
                              <img
                                src={PagesIndex.Svg.checkbutton}
                                alt="sidebar icon"
                                className="admin-sidebar-icons"
                              />
                            ) : null}
                          </Index.Box>
                        )}
                      </Index.Box>

                      <Index.Box
                        className="card-right"
                        sx={{ flexDirection: "column" }}
                      >
                        {" "}
                        <Index.Typography
                          component="p"
                          variant="p"
                          // className="status_tag"
                          className={
                            item?.status === "APPROVED"
                              ? "status_tag"
                              : item?.status === "REJECTED"
                                ? "status_red"
                                : "status_blue"
                          }
                        >
                          {item?.status === "APPROVED"
                            ? "Approved"
                            : item?.status === "REJECTED"
                              ? "Rejected"
                              : "In progress"}
                        </Index.Typography>
                        <Index.Box className="border-btn-main">
                          <Index.PrimaryButton
                            btnLabel="View Job"
                            onClick={() => handleOpenPandingRequest(item)}
                            className="btn-primary"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  ))
                ) : (
                  <PagesIndex.NoDataFound />
                )}
              </Index.Box>
            )}
          </TabPanel>
          <TabPanel
            value={tabValue}
            index="PendingOfficer"
            className="cus-tabpanel"
          >
            {loading ? (
              <Loader />
            ) : (
              <Index.Box className="tabpanel-main tabpanel-2">
                {filterJobsList?.length ? (
                  filterJobsList?.map((item, index) => (
                    <>
                      <Index.Box className="common-card job-mamangment-card">
                        <Index.Box className="admin-sub-flex">
                          <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                            <Index.Typography className="admin-sub-title">
                              Job :&nbsp;
                            </Index.Typography>
                            <Index.Typography className="admin-sub-title-value-text">
                              {item?.title}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="btn-main-primary">
                            <Index.PrimaryButton
                              btnLabel="View Job"
                              className="btn-primary"
                              onClick={() =>
                                navigate(
                                  "/admin/adminjobmanagement/admindetailsofpendingofficer",
                                  {
                                    state: {
                                      officerDetails: item,
                                      tab: "PendingOfficer",
                                    },
                                  }
                                )
                              }
                            />
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="card-main">
                          <Index.Box className="card-left">
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Posted By :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.userId?.name}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Date/Time :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.jobSchedule?.map((item, index) => {
                                  return (
                                    <>
                                      <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="work-value-text"
                                      >
                                        <span className="day-title">
                                          {PagesIndex.dayjs(
                                            item?.jobStartDate
                                          ).format("MM-DD-YYYY") +
                                            " to " +
                                            PagesIndex.dayjs(
                                              item?.jobEndDate
                                            ).format("MM-DD-YYYY")}
                                        </span>{" "}
                                        -{" "}
                                        <span>
                                          {item?.jobStartTime
                                            ? item?.jobStartTime + " to "
                                            : null}
                                          {item?.jobEndTime}
                                        </span>
                                      </Index.Typography>
                                    </>
                                  );
                                })}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Total Hours :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {PagesIndex.timeDifferenceCalculation(item)}{" "}
                                hour
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                {item?.department == "Police department"
                                  ? "Associated Department"
                                  : "Associated Agency"}
                                :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.approveBy?.agencyName}
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Pay Rate (Per hour) :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.rate ? "$" : null}
                                {item?.rate}
                              </Index.Typography>
                              &nbsp;
                              {item?.isRate == true ? (
                                <img
                                  src={PagesIndex.Svg.checkbutton}
                                  alt="sidebar icon"
                                  className="admin-sidebar-icons"
                                />
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="accordian-main">
                          <Index.Accordion className="accordian">
                            <Index.AccordionSummary
                              className="accordian-summary"
                              expandIcon={
                                <Index.ExpandMoreIcon className="accordian-expan-icon" />
                              }
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <Index.Box className="page-inner-title-flex">
                                <Index.Typography className="page-inner-title">
                                  View Officer Details
                                </Index.Typography>
                              </Index.Box>
                            </Index.AccordionSummary>
                            <Index.AccordionDetails className="accordian-details">
                              {item?.officerId?.map((row) => {
                                console.log(item, "item666");
                                const shortOfficerDetail = row?.officerId;
                                const particularOfficer = {
                                  officerId: row?.officerId?._id,
                                  jobId: item?._id,
                                  officerJobId: row?._id,
                                };
                                {
                                  console.log(
                                    particularOfficer,
                                    "particularOfficer"
                                  );
                                }
                                return (
                                  <>
                                    {shortOfficerDetail ? (
                                      <>
                                        <Index.Box className="card-main job-mamangment-inner-card">
                                          <Index.Box className="card-left">
                                            <Index.Box className="work-title-flex">
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-title-lable"
                                              >
                                                Name :&nbsp;
                                              </Index.Typography>
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-value-text"
                                              >
                                                {shortOfficerDetail?.firstName ? (
                                                  <>
                                                    {shortOfficerDetail?.firstName +
                                                      " " +
                                                      shortOfficerDetail?.lastName}
                                                  </>
                                                ) : null}
                                              </Index.Typography>
                                            </Index.Box>
                                            <Index.Box className="work-title-flex">
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-title-lable"
                                              >
                                                Email Address :&nbsp;
                                              </Index.Typography>
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-value-text"
                                              >
                                                {shortOfficerDetail?.email}
                                              </Index.Typography>
                                            </Index.Box>
                                            <Index.Box className="work-title-flex">
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-title-lable"
                                              >
                                                Type :&nbsp;
                                              </Index.Typography>
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-value-text"
                                              >
                                                {
                                                  shortOfficerDetail?.officerType
                                                }
                                              </Index.Typography>
                                            </Index.Box>
                                          </Index.Box>
                                          <Index.Box
                                            className="card-right"
                                            sx={{ flexDirection: "column" }}
                                          >
                                            {" "}
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className={
                                                row?.officerStatus ===
                                                  "APPROVED"
                                                  ? "status_tag"
                                                  : row?.officerStatus ===
                                                    "REJECTED"
                                                    ? "status_red"
                                                    : "status_blue"
                                              }
                                            >
                                              {row?.officerStatus === "APPROVED"
                                                ? "Approved"
                                                : row?.officerStatus ===
                                                  "REJECTED"
                                                  ? "Rejected"
                                                  : "In progress"}
                                            </Index.Typography>
                                            <Index.Box className="border-btn-main">
                                              <Index.PrimaryButton
                                                btnLabel="View Officer"
                                                onClick={() => {
                                                  pendingjobhandleOpen(
                                                    particularOfficer
                                                  );
                                                }}
                                                className="btn-primary"
                                              />
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      </>
                                    ) : null}
                                  </>
                                );
                              })}
                            </Index.AccordionDetails>
                          </Index.Accordion>
                        </Index.Box>
                      </Index.Box>
                    </>
                  ))
                ) : (
                  <PagesIndex.NoDataFound />
                )}
              </Index.Box>
            )}
          </TabPanel>
          <TabPanel
            value={tabValue}
            index="afterOfficerApprove"
            className="cus-tabpanel"
          >
            {loading ? (
              <Loader />
            ) : (
              <Index.Box className="tabpanel-main tabpanel-3">
                {filterJobsList?.length ? (
                  filterJobsList?.map((item, index) => (
                    <>
                      <Index.Box className="common-card job-mamangment-card">
                        <Index.Box className="admin-sub-flex">
                          <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="admin-sub-title"
                            >
                              Job :&nbsp;
                            </Index.Typography>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="admin-sub-title-value-text"
                            >
                              {item?.title}
                            </Index.Typography>
                          </Index.Box>

                          <Index.PrimaryButton
                            btnLabel="View Job"
                            className="btn-primary"
                            onClick={() =>
                              navigate(
                                "/admin/adminjobmanagement/admindetailsofpendingofficer",
                                {
                                  state: {
                                    officerDetails: item,
                                    tab: "afterOfficerApprove",
                                  },
                                }
                              )
                            }
                          />
                        </Index.Box>

                        <Index.Box className="card-main">
                          <Index.Box className="card-left">
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Posted By :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.userId?.name}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Date/Time :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.jobSchedule?.map((item, index) => {
                                  return (
                                    <>
                                      <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="work-value-text"
                                      >
                                        <span className="day-title">
                                          {PagesIndex.dayjs(
                                            item?.jobStartDate
                                          ).format("MM-DD-YYYY") +
                                            " to " +
                                            PagesIndex.dayjs(
                                              item?.jobEndDate
                                            ).format("MM-DD-YYYY")}
                                        </span>{" "}
                                        -{" "}
                                        <span>
                                          {item?.jobStartTime
                                            ? item?.jobStartTime + " to "
                                            : null}
                                          {item?.jobEndTime}
                                        </span>
                                      </Index.Typography>
                                    </>
                                  );
                                })}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Total Hours :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {PagesIndex.timeDifferenceCalculation(item)}{" "}
                                hour
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                {item?.department == "Police department"
                                  ? "Associated Department"
                                  : "Associated Agency"}
                                :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.approveBy?.agencyName}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="work-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Pay Rate (Per hour) :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                {item?.rate ? "$" : null}
                                {item?.rate}
                              </Index.Typography>
                              &nbsp;
                              {item?.isRate == true ? (
                                <img
                                  src={PagesIndex.Svg.checkbutton}
                                  alt="sidebar icon"
                                  className="admin-sidebar-icons"
                                />
                              ) : null}
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="card-right"></Index.Box>
                        </Index.Box>

                        <Index.Box className="accordian-main">
                          <Index.Accordion className="accordian">
                            <Index.AccordionSummary
                              className="accordian-summary"
                              expandIcon={
                                <Index.ExpandMoreIcon className="accordian-expan-icon" />
                              }
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <Index.Box className="page-inner-title-flex">
                                <Index.Typography className="page-inner-title">
                                  View Officer Details
                                </Index.Typography>
                              </Index.Box>
                            </Index.AccordionSummary>
                            <Index.AccordionDetails className="accordian-details">
                              {item?.officerId?.map((row) => {
                                const shortOfficerDetail = row?.officerId;
                                const particularOfficer = {
                                  officerId: row?.officerId?._id,
                                  jobId: item?._id,
                                  officerJobId: row?.jobId,
                                };
                                {
                                  console.log(
                                    particularOfficer,
                                    "particularOfficer"
                                  );
                                }
                                return (
                                  <>
                                    <Index.Box className="card-main job-mamangment-inner-card">
                                      <Index.Box className="card-left">
                                        <Index.Box className="work-title-flex">
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-title-lable"
                                          >
                                            Name :&nbsp;
                                          </Index.Typography>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-value-text"
                                          >
                                            {shortOfficerDetail?.firstName ? (
                                              <>
                                                {shortOfficerDetail?.firstName +
                                                  " " +
                                                  shortOfficerDetail?.lastName}
                                              </>
                                            ) : null}
                                          </Index.Typography>
                                        </Index.Box>
                                        <Index.Box className="work-title-flex">
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-title-lable"
                                          >
                                            Email Address :&nbsp;
                                          </Index.Typography>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-value-text"
                                          >
                                            {shortOfficerDetail?.email}
                                          </Index.Typography>
                                        </Index.Box>
                                        <Index.Box className="work-title-flex">
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-title-lable"
                                          >
                                            Type :&nbsp;
                                          </Index.Typography>
                                          <Index.Typography
                                            component="p"
                                            variant="p"
                                            className="work-value-text"
                                          >
                                            {shortOfficerDetail?.officerType}
                                          </Index.Typography>
                                        </Index.Box>
                                      </Index.Box>
                                      <Index.Box className="card-right">
                                        <Index.Box className="border-btn-main">
                                          <Index.PrimaryButton
                                            btnLabel="View Officer"
                                            onClick={() =>
                                              pendingjobhandleOpen(
                                                particularOfficer
                                              )
                                            }
                                            className="btn-primary"
                                          />
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.Box>
                                  </>
                                );
                              })}
                            </Index.AccordionDetails>
                          </Index.Accordion>
                        </Index.Box>
                      </Index.Box>
                    </>
                  ))
                ) : (
                  <PagesIndex.NoDataFound />
                )}
              </Index.Box>
            )}
          </TabPanel>
          <TabPanel
            value={tabValue}
            index="ActiveJobs"
            className="cus-tabpanel"
          >
            {loading ? (
              <Loader />
            ) : (
              <Index.Box className="tabpanel-main tabpanel-4">
                <>
                  {filterJobsList?.length ? (
                    filterJobsList.map((item, parentIndex) => (
                      <>
                        <Index.Box className="common-card job-mamangment-card">
                          <Index.Box className="admin-sub-flex">
                            <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="admin-sub-title"
                              >
                                Job :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="admin-sub-title-value-text"
                              >
                                {item?.title}
                              </Index.Typography>
                            </Index.Box>

                            <Index.PrimaryButton
                              btnLabel="View Job"
                              onClick={() =>
                                navigate("/admin/detail-active-jobs", {
                                  state: item,
                                })
                              }
                              className="btn-primary"
                            />
                          </Index.Box>
                          <Index.Box className="card-main">
                            <Index.Box className="card-left">
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Posted By :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.userId?.name}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Posted On :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {PagesIndex.dayjs(item?.createdAt).format(
                                    "MM-DD-YYYY"
                                  )}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Approved By :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.approveBy?.agencyName}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Pay Rate (Per hour) :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {" "}
                                  {item?.rate ? "$" : null}
                                  {item?.rate}
                                </Index.Typography>
                                &nbsp;
                                {item?.isRate == true ? (
                                  <img
                                    src={PagesIndex.Svg.checkbutton}
                                    alt="sidebar icon"
                                    className="admin-sidebar-icons"
                                  />
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="card-right">
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable work-title-total"
                                >
                                  Total :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  $
                                  {PagesIndex.TotalCalculateByTime(
                                    item,
                                    item.rate
                                  )}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          {item?.jobSchedule.map((row, jobIndex) => {
                            console.log(row, "row34434");
                            return (
                              <>
                                <Index.Box className="accordian-main">
                                  <Index.Accordion className="accordian">
                                    <Index.AccordionSummary
                                      className="accordian-summary"
                                      expandIcon={
                                        <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                      }
                                      aria-controls="panel1-content"
                                      id="panel1-header"
                                    >
                                      <Index.Box className="page-inner-title-flex">
                                        <Index.Typography className="page-inner-title">
                                          Job Details
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.AccordionSummary>
                                    <Index.AccordionDetails className="accordian-details">
                                      <Index.Box
                                        className="card-main job-mamangment-inner-card"
                                        key={row?._id}
                                      >
                                        <Index.Box className="card-left">
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Date/Time :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              <span className="day-title">
                                                {PagesIndex.dayjs(
                                                  row?.jobStartDate
                                                ).format("MM-DD-YYYY") +
                                                  " to " +
                                                  PagesIndex.dayjs(
                                                    row?.jobEndDate
                                                  ).format("MM-DD-YYYY")}
                                              </span>{" "}
                                              -{" "}
                                              <span>
                                                {row?.jobStartTime
                                                  ? row?.jobStartTime + " to "
                                                  : null}
                                                {row?.jobEndTime}
                                              </span>
                                            </Index.Typography>
                                          </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="card-right">
                                          {row?.extendHours == 0 ||
                                            row?.extendHours ==
                                            undefined ? null : (
                                            <>
                                              <Index.Box className="work-title-flex">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="work-title-lable work-title-total"
                                                >
                                                  Extend Time :&nbsp;
                                                </Index.Typography>
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="work-value-text"
                                                >
                                                  {row?.extendHours}
                                                </Index.Typography>
                                              </Index.Box>
                                            </>
                                          )}
                                          {item?.officerId?.map((row) => {
                                            const particularOfficer = {
                                              officerId: row?.officerId?._id,
                                              jobId: item?._id,
                                              officerJobId: row?.jobId,
                                            };
                                            {
                                              console.log(
                                                particularOfficer,
                                                "particularOfficer"
                                              );
                                            }
                                            return (
                                              <Index.Box className="border-btn-main">
                                                <Index.PrimaryButton
                                                  btnLabel="View Officer"
                                                  onClick={() =>
                                                    pendingjobhandleOpen(
                                                      particularOfficer
                                                    )
                                                  }
                                                  className="btn-primary"
                                                />
                                              </Index.Box>
                                            );
                                          })}
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.AccordionDetails>
                                  </Index.Accordion>
                                </Index.Box>
                              </>
                            );
                          })}
                        </Index.Box>
                      </>
                    ))
                  ) : (
                    <PagesIndex.NoDataFound />
                  )}
                </>
              </Index.Box>
            )}
          </TabPanel>
          <TabPanel
            value={tabValue}
            index="CompletedJobs"
            className="cus-tabpanel"
          >
            {loading ? (
              <Loader />
            ) : (
              <Index.Box className="tabpanel-main tabpanel-5">
                <>
                  {filterJobsList.length ? (
                    filterJobsList.map((item, index) => (
                      <>
                        <Index.Box className="common-card job-mamangment-card">
                          <Index.Box className="admin-sub-flex">
                            <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="admin-sub-title"
                              >
                                Job :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="admin-sub-title-value-text"
                              >
                                {item?.title}
                              </Index.Typography>
                            </Index.Box>

                            <Index.PrimaryButton
                              btnLabel="View Job"
                              className="btn-primary"
                              onClick={() =>
                                navigate(
                                  `/admin/adminjobmanagement/${item?._id}`,
                                  {
                                    state: item,
                                  }
                                )
                              }
                            />
                          </Index.Box>
                          <Index.Box className="card-main">
                            <Index.Box className="card-left">
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Posted By :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.userId?.name}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Location :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.location}
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Date/Time :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.jobSchedule?.map((item, index) => {
                                    return (
                                      <>
                                        <Index.Typography
                                          component="p"
                                          variant="p"
                                          className="work-value-text"
                                        >
                                          <span className="day-title">
                                            {PagesIndex.dayjs(
                                              item?.jobStartDate
                                            ).format("MM-DD-YYYY") +
                                              " to " +
                                              PagesIndex.dayjs(
                                                item?.jobEndDate
                                              ).format("MM-DD-YYYY")}
                                          </span>{" "}
                                          -{" "}
                                          <span>
                                            {item?.jobStartTime
                                              ? item?.jobStartTime + " to "
                                              : null}
                                            {item?.jobEndTime}
                                          </span>
                                        </Index.Typography>
                                      </>
                                    );
                                  })}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Total Hours :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {PagesIndex.timeDifferenceCalculation(item)}{" "}
                                  hour
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  {item?.department == "Police department"
                                    ? "Associated Department"
                                    : "Associated Agency"}
                                  :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.approveBy?.agencyName}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Pay Rate (Per hour) :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.rate ? "$" : null}
                                  {item?.rate}
                                </Index.Typography>
                                &nbsp;
                                {item?.isRate == true ? (
                                  <img
                                    src={PagesIndex.Svg.checkbutton}
                                    alt="sidebar icon"
                                    className="admin-sidebar-icons"
                                  />
                                ) : null}
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="card-right">
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable work-title-total"
                                >
                                  Total :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  $
                                  {PagesIndex.TotalCalculateByTime(
                                    item,
                                    item.rate
                                  )}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          {item?.officerId.map((row) => {
                            const shortOfficerDetail = row?.officerId;
                            const particularOfficer = {
                              officerId: row?.officerId?._id,
                              jobId: item?._id,
                            };
                            return (
                              <>
                                <Index.Box className="accordian-main">
                                  <Index.Accordion className="accordian">
                                    <Index.AccordionSummary
                                      className="accordian-summary"
                                      expandIcon={
                                        <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                      }
                                      aria-controls="panel1-content"
                                      id="panel1-header"
                                    >
                                      <Index.Box className="page-inner-title-flex">
                                        <Index.Typography className="page-inner-title">
                                          View Officer Details
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.AccordionSummary>
                                    <Index.AccordionDetails className="accordian-details">
                                      <Index.Box className="card-main job-mamangment-inner-card">
                                        <Index.Box className="card-left">
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Name :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.firstName ? (
                                                <>
                                                  {shortOfficerDetail?.firstName +
                                                    " " +
                                                    shortOfficerDetail?.lastName}
                                                </>
                                              ) : null}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Email Address :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.email}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Type :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.officerType}
                                            </Index.Typography>
                                          </Index.Box>
                                        </Index.Box>

                                        <Index.Box className="card-right">
                                          <Index.Box className="border-btn-main">
                                            <Index.PrimaryButton
                                              btnLabel="View Officer"
                                              onClick={() =>
                                                pendingjobhandleOpen(
                                                  particularOfficer
                                                )
                                              }
                                              className="btn-primary"
                                            />
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </Index.AccordionDetails>
                                  </Index.Accordion>
                                </Index.Box>
                              </>
                            );
                          })}
                        </Index.Box>
                      </>
                    ))
                  ) : (
                    <PagesIndex.NoDataFound />
                  )}
                </>
              </Index.Box>
            )}
          </TabPanel>
          <TabPanel
            value={tabValue}
            index="UnservedJobs"
            className="cus-tabpanel"
          >
            {loading ? (
              <Loader />
            ) : (
              <Index.Box className="tabpanel-main tabpanel-6">
                <>
                  {filterJobsList.length ? (
                    filterJobsList.map((item, index) => (
                      <>
                        <Index.Box className="common-card job-mamangment-card">
                          <Index.Box className="admin-sub-flex">
                            <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="admin-sub-title"
                              >
                                Job :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="admin-sub-title-value-text"
                              >
                                {item?.title}
                              </Index.Typography>
                            </Index.Box>

                            <Index.PrimaryButton
                              btnLabel="View Job"
                              className="btn-primary"
                              onClick={() => handleOpenUnservedJob(item)}
                            />
                          </Index.Box>
                          <Index.Box className="card-main">
                            <Index.Box className="card-left">
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  Posted By :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.userId?.name}
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="work-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-title-lable"
                                >
                                  {/* Associated Agency :&nbsp; */}
                                  {item?.department == "Police department"
                                    ? "Associated Department"
                                    : "Associated Agency"}
                                  :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="work-value-text"
                                >
                                  {item?.approveBy?.agencyName !== undefined
                                    ? item?.approveBy?.agencyName
                                    : ""}
                                </Index.Typography>
                              </Index.Box>
                              {item?.rate == undefined ? null : (
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Pay Rate (Per hour) :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.rate ? "$" : null}
                                    {item?.rate}
                                  </Index.Typography>
                                  &nbsp;
                                  {item?.isRate == true ? (
                                    <img
                                      src={PagesIndex.Svg.checkbutton}
                                      alt="sidebar icon"
                                      className="admin-sidebar-icons"
                                    />
                                  ) : null}
                                </Index.Box>
                              )}
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="accordian-main">
                            <Index.Accordion className="accordian">
                              <Index.AccordionSummary
                                className="accordian-summary"
                                expandIcon={
                                  <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                }
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                <Index.Box className="page-inner-title-flex">
                                  <Index.Typography className="page-inner-title">
                                    View Officer Details
                                  </Index.Typography>
                                </Index.Box>
                              </Index.AccordionSummary>
                              <Index.AccordionDetails className="accordian-details">
                                {item?.officerId.map((row) => {
                                  const shortOfficerDetail = row?.officerId;
                                  const particularOfficer = {
                                    officerId: row?.officerId?._id,
                                    jobId: item?._id,
                                  };
                                  return (
                                    <>
                                      <Index.Box className="card-main job-mamangment-inner-card">
                                        <Index.Box className="card-left">
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Name :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.firstName ? (
                                                <>
                                                  {shortOfficerDetail?.firstName +
                                                    " " +
                                                    shortOfficerDetail?.lastName}
                                                </>
                                              ) : null}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Email Address :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.email}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Type :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.officerType}
                                            </Index.Typography>
                                          </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="card-right">
                                          {" "}
                                          <Index.Box className="border-btn-main">
                                            <Index.PrimaryButton
                                              btnLabel="View Officer"
                                              onClick={() =>
                                                pendingjobhandleOpen(
                                                  particularOfficer
                                                )
                                              }
                                              className="btn-primary"
                                            />
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    </>
                                  );
                                })}
                              </Index.AccordionDetails>
                            </Index.Accordion>
                          </Index.Box>
                        </Index.Box>
                      </>
                    ))
                  ) : (
                    <PagesIndex.NoDataFound />
                  )}
                </>
              </Index.Box>
            )}
          </TabPanel>
        </Index.Box>

        {/* Pending job Request modal */}
        <AdminPendingJobModal
          pandingRequestopen={pandingRequestopen}
          handleClosePandingRequest={handleClosePandingRequest}
          pendingjobId={pendingjobId}
          getJobListData={getJobListData}
        />

        {/* Unfilled modal */}
        <AdminUnservedJobModal
          unservedJobopen={unservedJobopen}
          handleCloseUnservedJob={handleCloseUnservedJob}
          unservedJobData={unservedJobData}
        />

        {/* Pending job Request after officer accepted modal */}
        <AdminPendingJobOfficerAceeptModal
          pendingjobopen={pendingjobopen}
          pendingjobhandleClose={pendingjobhandleClose}
          pendingjobOfficerAcceptData={pendingjobOfficerAcceptData}
          getJobListData={getJobListData}
          isShowApproveRejectButton={
            tabValue == "PendingOfficer" ? true : false
          }
        />
      </>
    </>
  );
};

export default AdminJobManagement;
