import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import { Formik } from "formik";
import { addRoleMaster } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import {
  webRoleMaster,
  zipManagement,
  jobTypeManagement,
  agencyManagement,
  userManagement,
  officerManagement,
  jobManagement,
} from "../../../../config/CommonRoleMaster";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

function RoleAdd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const [loading, setLoading] = useState(false);

  let initialValues = {
    roleName: "",
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("Permission_name", values.roleName)

    dispatch(addRoleMaster({ urlencoded, navigate, setLoading }));
  };

  const BackBtn = () => {
    navigate("/admin/role");
  };

  return (
    <>
      {/* New Design Code Changes */}
      <Index.Box className="user-list-flex">
        <Index.Typography className='admin-page-title user-list-page-title' component='h2' variant='h2'>
          <Index.Link to="/admin/role" className="filter-back-link">
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            ></img>
          </Index.Link>{" "}
          Add Role
        </Index.Typography>
      </Index.Box>
      {/* End New Design Code Changes */}

      <Index.Box className="">
        <Index.Box className="barge-common-box">

          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={PagesIndex.roleMasterValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="common-card">
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 4",
                            lg: "span 4",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="input-box">
                            <Index.FormHelperText className="form-lable">
                              Role Name
                            </Index.FormHelperText>
                            <Index.Box className="form-group">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Please enter role name"
                                variant="filled"
                                className="form-control w-100 remove-brder"
                                inputProps={{ maxlength: 30 }}
                                onChange={(e) => {
                                  handleChange(e);
                                  setFieldTouched("name", true);
                                }}
                                onBlur={handleBlur}
                                name="roleName"
                                value={values?.roleName}
                                helperText={touched.roleName && errors.roleName}
                                error={Boolean(errors.roleName && touched.roleName)}
                                onKeyDown={(e) => {
                                  if (e.key === " " && e.target.value.trim() === "") {
                                    e.preventDefault(); // Prevent space as the first character
                                  } else if (e.target.value.trim().split(" ").length >= 2 && e.key === " ") {
                                    e.preventDefault(); // Prevent adding additional spaces between words
                                  } else if (e.key === " " && e.target.value.endsWith(" ")) {
                                    e.preventDefault(); // Prevent additional spaces at the end
                                  }
                                  if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                    e.preventDefault(); // Prevent non-numeric characters
                                  }
                                }}
                                onPaste={(e) => e.preventDefault()} 
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="btn-main-primary user-btn-flex">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    className="btn-primary"
                    disabled={loading}
                  >
                    Submit
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default RoleAdd;
