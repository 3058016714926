import React, { useEffect, useState } from "react";
import PagesIndex from "../../../../../PageIndex";
import Index from "../../../../../index";
import { officerRefundPolicySchema, userRefundPolicySchema } from "../../../../../../validation/FormikSchema";
import dataService from "../../../../../../config/CkService";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DataService from "../../../../../../config/DataService";
import { Api } from "../../../../../../config/Api";
import { getCmsDataList } from "../../../../../../redux-toolkit/slice/admin-slice/AdminServices";
import Loader from "../../../../../../common/loader/Loader";

const UserRefundPolicy = () => {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const [refundPolicyData, setRefundPolicyData] = useState({
    title: "",
    description: "",
  })
  const handleRefundPolicy = async (values) => {
    console.log("values", values);
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("title", values?.title);
    urlEncoded.append("description", values?.description);
    urlEncoded.append("type", location?.state);
    console.log("urlEncoded", [...urlEncoded]);
    try {
      const response = await DataService.post(
        Api.Admin.OFFICER_REFUND_POLICY,
        urlEncoded
      );
      if (response?.data?.status === 201) {
        PagesIndex.toast.success(response?.data?.message);
        setIsLoading(false);
        navigate("/admin/admincontentmanagement");
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  // get Cms data
  const getCmsList = () => {
    dispatch(getCmsDataList("user")).then((res) => {
      if (res?.payload?.status == 200) {
        setRefundPolicyData({
          title: res?.payload?.data[0]?.refundPolicy?.title ?? "",
          description: res?.payload?.data[0]?.refundPolicy?.description ?? ''
        });
      }

    });
  };

  useEffect(() => {
    getCmsList();
  }, []);


  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("image", file);
            dataService
              .post("admin/image-upload", body)
              .then((res) => {
                console.log(res, "resss")
                resolve({
                  default: `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${res.data.image.filename}`,
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    if (editor.plugins) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
      };
    }
  }
  console.log("refundPolicyData", refundPolicyData);
  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          <Index.Link
            to="/admin/admincontentmanagement"
            className="filter-back-link"
          >
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            ></img>
          </Index.Link>
          Refund Policy
        </Index.Typography>
      </Index.Box>
      <Index.Box className="common-card">


        <PagesIndex.Formik
          enableReinitialize
          validationSchema={userRefundPolicySchema}
          initialValues={refundPolicyData}
          onSubmit={handleRefundPolicy}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            handleFocus,
          }) => (
            <form onSubmit={handleSubmit}>
              <Index.Box className="ck_edit_wrap">
                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Title
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Title"
                      name="title"
                      value={values.title}
                      onChange={(e) => {
                        // const newValue = e.target.value.replace(
                        //   /\s+/g,
                        //   " "
                        // );
                        // setFieldValue("title", newValue);
                        handleChange(e);
                        setFieldTouched("title", true);
                      }}
                      onKeyDown={(e) => {
                        if (
                          e.key === " " &&
                          e.target.value.trim() === ""
                        ) {
                          e.preventDefault(); // Prevent space as the first character
                        } else if (
                          e.target.value.trim().split(" ").length >=
                          2 &&
                          e.key === " "
                        ) {
                          e.preventDefault(); // Prevent adding additional spaces between words
                        } else if (
                          e.key === " " &&
                          e.target.value.endsWith(" ")
                        ) {
                          e.preventDefault(); // Prevent additional spaces at the end
                        }
                        if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                          e.preventDefault(); // Prevent non-numeric characters
                        }
                      }}
                      onBlur={handleBlur}
                      inputProps={{maxLength: 30}}
                      error={errors.title && touched.title ? true : false}
                      helperText={
                        errors.title && touched.title
                          ? errors.title
                          : null
                      }
                    />
                  </Index.Box>
                </Index.Box>

                <Index.Box className="input-box modal-input-box">
                  <Index.FormHelperText className="form-lable">
                    Content
                  </Index.FormHelperText>
                  <Index.Box className="form-group">
                    <PagesIndex.CKEditor
                      editor={ClassicEditor}
                      data={values.description}
                      name="description"
                      contenteditable="true"
                      onReady={(editor) => {
                      }}
                      // config={{
                      //   extraPlugins: [uploadPlugin],
                      //   mediaEmbed: { previewsInData: true },
                      // }}
                      config={{
                        toolbar: {
                          items: [
                            "heading",
                            "|",
                            "bold",
                            "italic",
                            "|",
                            "bulletedList",
                            "numberedList",
                            "|",
                            "link",
                            "|",
                            "undo",
                            "redo",
                          ],
                        },
                        mediaEmbed: { previewsInData: true },
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFieldValue("description", data);

                        const maxCharacterLimit = 5500;
                        if (data.length > maxCharacterLimit) {
                          event.stop();
                        }
                      }}
                    />
                  </Index.Box>
                  <Index.FormHelperText error>
                    {errors.description && touched.description
                      ? errors.description
                      : null}
                  </Index.FormHelperText>
                </Index.Box>

                <Index.Box className="user-btn-flex">
                <Index.PrimaryButton
                  className="btn-primary"
                  type="submit"
                  btnLabel={
                    refundPolicyData?.title !== "" ||
                      refundPolicyData?.description !== ""
                      ? "Update"
                      : "Submit"
                  }
                  disabled={isLoading}
                >
                  {isLoading ? <Loader /> : "Click Me"}
                </Index.PrimaryButton>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Box>
    </>
  );
};

export default UserRefundPolicy;
