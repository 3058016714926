import React, { useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";
import { AdminEditUserSchema } from "../../../../../validation/FormikSchema";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const EditNewVendor = (props) => {
  const { openEditModal, handleCloseEdit, editUserData, getUserListData } =
    props;
    const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  // console.log("editUserData", editUserData);
  
  // us phone number functionality
  const [mobileNumber, setmobileNumber] = useState("");

  console.log(mobileNumber,"mobileNumber")
//  const handlePhoneChange = (e, setFieldValue) => {
//   const input = e.target.value;
//   const cleaned = input.replace(/\D/g, "");
  
//   // Check if the first digit is 0
//   if (cleaned.length > 0 && cleaned[0] == '0') {
//     return; // Do nothing if the first digit is 0
//   }
  
//   // Set the raw input to Formik state
//   setFieldValue("mobileNumber", cleaned);
  
//   // Format only if we have 10 digits
//   if (cleaned.length === 10) {
//     const formattedPhoneNumber = formatPhoneNumber(cleaned);
//     setmobileNumber(formattedPhoneNumber);
//   } else {
//     // If less than 10 digits, just set the cleaned (unformatted) version
//     setmobileNumber(cleaned);
//   }
// };

// const formatPhoneNumber = (input) => {
//   const strInput = String(input); // Ensure input is a string
//   const match = strInput?.match(/^(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     return "(" + match[1] + ") " + match[2] + "-" + match[3];
//   }
//   return strInput;
// };
const handlePhoneChange = (e, setFieldValue) => {
  const input = e.target.value;
  const cursorPosition = e.target.selectionStart;
  const previousValue = mobileNumber;
  
  // Remove all non-digit characters
  const cleaned = input.replace(/\D/g, "");
  
  // Check if the first digit is 0
  if (cleaned.length > 0 && cleaned[0] === '0') {
    return; // Do nothing if the first digit is 0
  }
  
  // Set the raw input to Formik state
  setFieldValue("mobileNumber", cleaned);
  
  let formattedNumber;
  if (cleaned.length === 10) {
    formattedNumber = formatPhoneNumber(cleaned);
  } else {
    formattedNumber = formatPartialPhoneNumber(cleaned);
  }
  
  setmobileNumber(formattedNumber);
  
  // Adjust cursor position
  setTimeout(() => {
    const newCursorPosition = calculateCursorPosition(previousValue, formattedNumber, cursorPosition);
    e.target.setSelectionRange(newCursorPosition, newCursorPosition);
  }, 0);
};

const formatPartialPhoneNumber = (input) => {
  if (input.length <= 3) return input;
  if (input.length <= 6) return `(${input.slice(0, 3)}) ${input.slice(3)}`;
  return `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6)}`;
};

const calculateCursorPosition = (previousValue, newValue, previousPosition) => {
  const beforeCursor = previousValue.slice(0, previousPosition);
  const digitsBeforeCursor = beforeCursor.replace(/\D/g, "").length;
  const newValueDigits = newValue.replace(/\D/g, "");
  let newPosition = 0;
  let digitCount = 0;

  for (let i = 0; i < newValue.length; i++) {
    if (/\d/.test(newValue[i])) {
      digitCount++;
    }
    if (digitCount > digitsBeforeCursor) {
      break;
    }
    newPosition++;
  }

  return newPosition;
};

// const formatPhoneNumber = (input) => {
//   const match = input?.match(/^(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     return `(${match[1]}) ${match[2]}-${match[3]}`;
//   }
//   return input;
// };

const formatPhoneNumber = (input) => {
  const strInput = String(input); // Ensure input is a string
  const match = strInput?.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return strInput;
};


  const initialValues = {
    name: editUserData ? editUserData?.name : "",
    email: editUserData ? editUserData?.email : "",
    mobileNumber: editUserData ? formatPhoneNumber(editUserData?.mobileNumber) : "",
  };

  const handleAddNewUserForm = async (values) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", editUserData?._id);
    urlEncoded.append("name", values?.name);
    urlEncoded.append("mobileNumber", values?.mobileNumber);
    try {
      const response = await DataService.post(
        Api.Admin.ADD_EDIT_CLIENT,
        urlEncoded
      );
      if (response?.data?.status === 201) {
        PagesIndex.toast.success(response?.data?.message);
        handleCloseEdit();
        getUserListData();
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Index.Modal
        open={openEditModal}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          validationSchema={AdminEditUserSchema}
          initialValues={initialValues}
          onSubmit={handleAddNewUserForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            handleFocus,
            resetForm 
          }) => (
            <form onSubmit={handleSubmit}>
              {/* {console.log("error", errors)} */}
              {/* {console.log("values>>",values)} */}
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Edit Details
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleCloseEdit}
                    alt=""
                  />
                </Index.Box>

                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Name 
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter first name"
                        name="name"
                        value={values.name}
                        // onChange={han
                        onChange={(e) => {
                          handleChange(e);
                          setFieldTouched("name", true);
                        }}
                        onBlur={handleBlur}
                        error={errors.name && touched.name ? true : false}
                        helperText={
                          errors.name && touched.name ? errors.name : null
                        }
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.value.trim() === "") {
                            e.preventDefault();
                          }
                          if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        inputProps={{maxLength:20}}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Email Address
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        disabled
                        className="form-control"
                        placeholder="Please enter email address"
                        name="email"
                        value={values.email}
                        onChange={(e) => {
                          handleChange(e);
                          // setFieldTouched("email", true);
                        }}
                        onKeyDown={(event) => {
                          if (event.key == " ") {
                            event.preventDefault();
                          }
                        }}
                        onBlur={handleBlur}
                        error={errors.email && touched.email ? true : false}
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Phone Number
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                        <Index.TextField
                              fullWidth
                              id="fullWidth"
                              className="form-control"
                              placeholder="(123) 456-7890"
                              name="mobileNumber"
                              value={mobileNumber}
                              onChange={(e)=>{handlePhoneChange(e,setFieldValue)}}
                              inputProps={{ maxLength: 14 }}
                              error={
                                errors.mobileNumber && touched.mobileNumber
                                  ? true
                                  : false
                              }
                              helperText={
                                errors.mobileNumber && touched.mobileNumber
                                  ? errors.mobileNumber
                                  : false
                              }
                            />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-footer">
                  <Index.Box className="document-btn-grp">
                    <Index.PrimaryButton
                      btnLabel="Update"
                      className="btn-primary bg-approve appr-rej-btn"
                      type="submit"
                    />

                    <Index.PrimaryButton
                      btnLabel="cancel"
                      className="btn-primary bg-suspend appr-rej-btn"
                      onClick={handleCloseEdit}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default EditNewVendor;
