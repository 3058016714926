import Svg from "../assets/svg";
import Png from "../assets/png";
import { Link, useNavigate, useLocation, useNavigationType } from "react-router-dom";
import AuthFooter from "./admin/defaulLayout/AuthFooter";
import AuthBackground from "./admin/defaulLayout/AuthBackground";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import DataService from "../config/DataService";
import { Api } from "../config/Api";
import { Formik } from "formik";
import NoDataFound from "../common/noDataFound/noDataFound";


export default {
  Formik,
  Svg,
  Png,
  Api,
  useSelector,
  useDispatch,
  toast,
  useNavigate,
  useNavigationType,
  useLocation,
  ToastContainer,
  useLocation,
  AuthFooter,
  AuthBackground,
  DataService,
  NoDataFound,
};
