import React, { useRef, useState } from "react";

const GoogleAddress = () => {
  const addressInputRef = useRef(null);
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleAddressChange = async (e) => {
    setAddress(e.target.value);
    if (e.target.value.length > 3) {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${e.target.value}`
      );
      console.log("177",`https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${e.target.value}`);
      const data = await response.json();
      console.log("18",data)
      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setAddress(suggestion.display_name);
    setState(
      suggestion.address.state || suggestion.address.state_district || ""
    );


    setCity(suggestion.address.city || suggestion.address.town || suggestion.address.village || "");
    setZipCode(suggestion.address.postcode || "");
    setSuggestions([]);
  };

  return (
    <div>
      <div>
        <label>Address</label>
        <input
          ref={addressInputRef}
          type="text"
          id="address"
          placeholder="Enter address"
          onChange={handleAddressChange}
          value={address}
        />
        {suggestions.length > 0 && (
          <ul>
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion.display_name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div>
        <label>State</label>
        <input type="text" id="state" value={state} readOnly />
      </div>
      <div>
        <label>City</label>
        <input type="text" id="city" value={city} readOnly />
      </div>
      <div>
        <label>ZIP Code</label>
        <input type="text" id="zipCode" value={zipCode} readOnly />
      </div>
    </div>
  );
};

export default GoogleAddress;
