import React from 'react'
import PagesIndex from '../../../PageIndex';
import Index from '../../../index';
// import PropTypes from 'prop-types';
import { SwipeableDrawer } from '@mui/material';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

function createData(id, clientName, email, phoneNumber,  date, description, action) {
    return { id, clientName, email, phoneNumber,  date, description, action};
}

const rows = [
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "00/00/0000",
        "Description",
        // "Action",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "00/00/0000",
        "Description",
        // "Action",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "00/00/0000",
        "Description",
        // "Action",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "00/00/0000",
        "Description",
        // "Action",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "00/00/0000",
        "Description",
        // "Action",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "00/00/0000",
        "Description",
        // "Action",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "00/00/0000",
        "Description",
        // "Action",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "00/00/0000",
        "Description",
        // "Action",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "00/00/0000",
        "Description",
        // "Action",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "00/00/0000",
        "Description",
        // "Action",
    ),
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Index.Box sx={{ p: 3 }}>
                    <Index.Typography>{children}</Index.Typography>
                </Index.Box>
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function AdminHelpandSupportRequests() {

 // filter
 const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
});

const toggleDrawer = (anchor, open) => (event) => {
    if (
        event &&
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
    ) {
        return;
    }

    setState({ ...state, [anchor]: open });
};

const list = (anchor) => (
    <Index.Box className="filter-main"
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
    >
    </Index.Box>
);
  


    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
              
        <Index.Box className="user-list-flex">
            <Index.Typography className='admin-page-title user-list-page-title' component='h2' variant='h2'>
            <Index.Link to="/admin/dashboard" className="filter-back-link">
              <img
                src={PagesIndex.Svg.leftArrow}
                className="left-arrow-ic"
                alt="search grey img"
              ></img>
            </Index.Link>{" "}Help & Support Requests
            </Index.Typography>
            <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                    <Index.Box className="user-search-box">
                        <Index.Box className="form-group">
                            <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Search"
                            />
                            <img
                                src={PagesIndex.Svg.search}
                                className="search-grey-img" alt='search grey img'
                            ></img>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
                <Index.Box className="filter-main">
                    {['right'].map((anchor) => (
                        <React.Fragment key={anchor}>
                            <Index.Box className="export-btn-main border-btn-main">
                                <Index.Button className='export-btn border-btn' onClick={toggleDrawer(anchor, true)}><img src={PagesIndex.Svg.filter} className="down-icon" alt='download icon' />Filter</Index.Button>
                            </Index.Box>
                            <SwipeableDrawer
                                className='filter-main'
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}
                                onOpen={toggleDrawer(anchor, true)}
                            >
                                <Index.Box className="filter-header">
                                    <Index.Typography className='filter-title'>User Filter</Index.Typography>
                                    <img src={PagesIndex.Svg.closeicon} className="filter-close-icon" onClick={toggleDrawer(anchor, false)} />
                                </Index.Box>
                                <Index.Box className="filter-inner-main">
                                    <Index.Box className="input-box filter-input-box">
                                        <Index.FormHelperText className='form-lable'>Name</Index.FormHelperText>
                                        <Index.Box className="form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                placeholder=""
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="input-box filter-input-box">
                                        <Index.FormHelperText className='form-lable'>City</Index.FormHelperText>
                                        <Index.Box className="form-group">
                                            <Index.TextField
                                                fullWidth
                                                id="fullWidth"
                                                className="form-control"
                                                placeholder=""
                                            />
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="input-box filter-input-box">
                                        <Index.FormHelperText className='form-lable'>Status</Index.FormHelperText>
                                        <Index.Box className='checkbox-main filter-checkbox-main input-box'>
                                            <Index.FormControlLabel control={<Index.Checkbox defaultChecked />} label="Active" className='checkbox-lable' />
                                            <Index.FormControlLabel control={<Index.Checkbox />} label="Deactivate" className='checkbox-lable' />
                                            <Index.FormControlLabel control={<Index.Checkbox />} label="Pending" className='checkbox-lable' />
                                        </Index.Box>
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="filter-footer">
                                    <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                                        <Index.Button className="border-btn filter-cancel-btn">Cancel</Index.Button>
                                        <Index.Button className="btn-primary filter-btn">Filter</Index.Button>
                                    </Index.Box>
                                </Index.Box>
                            </SwipeableDrawer>
                        </React.Fragment>
                    ))}
                </Index.Box>
                {/* <Index.Box className="userlist-inner-btn-flex">

                    <Index.Box className="adduser-btn-main btn-main-primary">
                        <Index.Button className='adduser-btn btn-primary'>Export</Index.Button>
                    </Index.Box>
                </Index.Box> */}
            </Index.Box>
        </Index.Box>
        
            <Index.Box className="dashboard-content officer-manag-main help-support-page-wrape">
                <Index.Box className='officer-tab-main'>
                    <Index.Box className="cus-tabs-main-box">
                        <Index.Box sx={{ width: '100%' }}>
                            <Index.Box value={value} index={0} className="cus-tabpanel">
                                <Index.Box className="tabpanel-main">
                                <Index.Box className="page-table-main payment-table-main admin-page-table-main">
                                    <Index.TableContainer
                                        component={Index.Paper}
                                        className="table-container p-0"
                                    >
                                        <Index.Table
                                            sx={{ minWidth: 650 }}
                                            aria-label="simple table"
                                            className="table"
                                        >
                                            <Index.TableHead className="table-head">
                                                <Index.TableRow className="table-row">
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        ID
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        User Name
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Email Address
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Phone Number
                                                    </Index.TableCell>
                                                  
                                                  
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Date
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Description
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Action
                                                    </Index.TableCell>
                                                 
                                                </Index.TableRow>
                                            </Index.TableHead>
                                            <Index.TableBody className="table-body">
                                                {rows.map((row) => (
                                                    <Index.TableRow
                                                        key={row.id}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": {
                                                                border: 0,
                                                            },
                                                        }}
                                                    >
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            scope="row"
                                                            className="table-td"
                                                        >
                                                            {row.id}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.clientName}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.email}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.phoneNumber}
                                                        </Index.TableCell>
                                                      
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.date}
                                                         
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.description}
                                                        
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.action}
                                                            <Index.PrimaryButton
                                btnLabel="View"
                                onClick={handleOpen}
                                className="btn-primary" />
                                                        </Index.TableCell>
                                                      
                                                    </Index.TableRow>
                                                ))}
                                            </Index.TableBody>
                                        </Index.Table>
                                    </Index.TableContainer>
                                </Index.Box>

                                </Index.Box>
                            </Index.Box>
                            

                        </Index.Box>
                    </Index.Box>
                </Index.Box>



                 {/* Request Description modal */}
                 <Index.Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className='modal'
                >
                    <Index.Box sx={style} className="add-user-modal-inner-main rating-main-modal modal-inner">
                        <Index.Box className="modal-header" >
                            <Index.Typography id="modal-modal-title" className='modal-title' variant="h6" component="h2">
                            Request Description
                            </Index.Typography>
                            <img src={PagesIndex.Svg.closeblack} className="modal-close-icon" onClick={handleClose} />
                        </Index.Box>
                        <Index.Box className="modal-body" >
                            <Index.Box className="card-left">
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        Request Description will be shown here.
                                    </Index.Typography>
                                </Index.Box>
                            
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Modal>
            </Index.Box>
        </>
    )
}

