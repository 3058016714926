import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import PropTypes from "prop-types";
import Rating from "@mui/material/Rating";
import EditAdminAgentModal from "./admin-agent-modal/EditAdminAgentModal";
import {
  getOfficerHistoryList,
  getSingleAgency,
  getSingleOfficer,
} from "../../../../redux-toolkit/slice/common-slice/CommonServices";
import { useLocation, useParams } from "react-router-dom";
import AdminPaymentModal from "./admin-agent-modal/AdminPaymentModal";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AdminAgentDetails = () => {
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const { state } = PagesIndex.useLocation();
  const { agentId } = useParams();
  // console.log(state, "statestate");
  // const {state} = useLocation();
  // const agentId = state;
  // console.log(agentId, "agentIdagentId");
  const [agentsignleData, setAgentsignleData] = useState({});
  const [editAgentData, setEditAgentData] = useState([]);
  const [signleAgentLoading, setSignleAgentLoading] = useState(false);
  const [totalOfficerList, setTotalOfficerList] = useState(false);
  const [officerJobData, setOfficerJobData] = useState([]);
  const [filteredOfficerJobData, setFilteredOfficerJobData] = useState([]);


  // const [directPayment, setDirectPayment] = useState(false);

  const officerId = new URLSearchParams(location.search).get("id");
  // console.log(officerId, "officerId");
  const [tabValue, setTabValue] = useState("TotalOfficer");
  const [agencyId, setAgencyId] = useState(agentId);
  const handleTabsChange = (event, newValue) => {
    setTabValue(newValue);
    setAgencyId(agentId);
  };


  // Payment modal
  const [paymentAgentId, setPaymentAgentId] = useState("");
  const [jobId, setJobId] = useState([]);
  const [paymentType, setpaymentType] = useState("");
  const [paymentAmount, setPaymentpaymentAmount] = useState("");
  const [paymentAgentModalopen, setPaymentAgentModalopen] = useState(false);
  const [roleName, seRoleName] = useState(null);
  const paymentAgenthandleOpen = (data) => {
    console.log("88", data)
    if (data?.officerId?.isPaymentConfig !== undefined && data?.officerId?.isPaymentConfig) {
      setPaymentAgentModalopen(true);
      setPaymentpaymentAmount(data?.finalAmount)
      setPaymentAgentId(data?.officerId._id);
      setJobId(data?.jobId);
      setpaymentType(agentsignleData?.paymentType !== undefined ? agentsignleData?.paymentType : undefined);
      seRoleName(agentsignleData?.departmentName);
    }
    // 
    else if (data?.approveBy?.isPaymentConfig !== undefined && data?.approveBy?.isPaymentConfig) {
      setPaymentAgentModalopen(true);
      setPaymentpaymentAmount(data?.finalPayableAmount)
      setPaymentAgentId(data?.approveBy?._id);
      setJobId(data?._id);
      setpaymentType(agentsignleData?.paymentType !== undefined ? agentsignleData?.paymentType : undefined);
      seRoleName(agentsignleData?.departmentName);
    }
    else {
      PagesIndex.toast.info("Payment Config is remaining")
    }

  };
  const paymentAgenthandleClose = () => setPaymentAgentModalopen(false);

  // const [value, setValue] = useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // edit modal
  const [EditAgentModalopen, setEditAgentModalOpen] = React.useState(false);
  const handleEditAgentModalOpenEdit = (data) => {
    // setEditAgentData({...data,redirectURLId:agentId });
    setEditAgentData(data);
    setEditAgentModalOpen(true);
  };
  const handleEditAgentModalCloseEdit = () => setEditAgentModalOpen(false);
  // get single Agnecy
  // const getSingleAgentData = (agentId) => {
  //   setSignleAgentLoading(true);
  //   dispatch(getSingleAgency(agentId)).then((res) => {
  //     if (res?.payload?.status == 200) {
  //       setAgentsignleData(res?.payload?.data);
  //       setSignleAgentLoading(false);
  //     }
  //   });
  // };

  const getSingleAgentData = () => {
    console.log("api call")
    PagesIndex.DataService.get(
      PagesIndex.Api.Common.GET_SINGLE_AGENCY + "/" + agentId
    )
      .then(({ data }) => {
        setAgentsignleData(data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  PagesIndex.useEffect(() => {
    getSingleAgentData(agentId);
  }, []);

  const [ratingvalue, setRatingValue] = React.useState(2);

  // Us formate
  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters from the input
    const cleaned = ("" + input).replace(/\D/g, "");

    // Format the cleaned phone number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return cleaned;
  };

  // get data
  const officerDataList = () => {
    console.log("officerDataList calling", tabValue);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("type", tabValue);
    urlEncoded.append("agencyId", agentId);
    dispatch(getOfficerHistoryList(urlEncoded)).then((res) => {
      if (res?.payload?.status == 200) {
        setOfficerJobData(res?.payload?.data);
        setFilteredOfficerJobData(res?.payload?.data);
      }
    });
  };
  useEffect(() => {
    officerDataList();
  }, [tabValue]);

  // useEffect(() => {
  //   if (location.state) {
  //     setTabValue(location.state.tabValue);
  //     officerDataList(location.state.tabValue);
  //   } else {
  //     setTabValue("TotalOfficer");
  //   }
  // }, [location]);

  // useEffect(() => {
  //   if (!location?.state?.tabValue) {
  //     officerDataList("TotalOfficer");
  //   }
  // }, []);



  return (
    <>
      {!signleAgentLoading ? (
        <>
          <Index.Box className="user-list-flex">
            <Index.Link to="/admin/agentmanagement">
              <img
                src={PagesIndex.Svg.leftArrow}
                className="left-arrow-ic"
                alt="search grey img"
              />
            </Index.Link>
            <Index.Box className="btn-main-primary">
              <Index.PrimaryButton
                btnLabel="Edit"
                className="btn-primary"
                onClick={() => handleEditAgentModalOpenEdit(agentsignleData)}
              >
                {/* <img src={PagesIndex.Svg.editNew} alt="" className="admin-icons" /> */}
              </Index.PrimaryButton>
            </Index.Box>
          </Index.Box>
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-profile-flex">
                <img
                  src={
                    agentsignleData?.image
                      ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${agentsignleData?.image}`
                      : PagesIndex.Png.usericon
                  }
                  alt=""
                  className="prof-img"
                />
                <>

                  <Index.Box className="officer-detail">
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        {agentsignleData?.agencyName || "-"}

                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {agentsignleData?.email} &nbsp; | {" "} &nbsp;
                        {formatPhoneNumber(agentsignleData?.mobileNumber)} &nbsp; | {" "} &nbsp;
                        {agentsignleData?.address || "-"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="work-title-flex">

                    </Index.Box>

                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Work Policy
                      </Index.Typography>

                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        Day Work Limit :

                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {agentsignleData?.workPolicy?.dayWorkLimit || "-"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        {/* Day Work Limit : */}

                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        Week Work Limit :

                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {agentsignleData?.workPolicy?.weekWorkLimit || "-"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="work-title-flex">

                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Job Transfer
                      </Index.Typography>

                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        Job Hours Limit :

                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {agentsignleData?.jobTransferData?.jobTransferHours ||
                          "-"}
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Payment Type :

                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {agentsignleData.departmentName == "Department" && agentsignleData?.paymentType !== undefined ? agentsignleData?.paymentType : agentsignleData.departmentName == "Department" && agentsignleData?.paymentType == undefined ? " " : agentsignleData.departmentName !== "Department" ? "Security Agency" : ""}
                      </Index.Typography>
                    </Index.Box>

                  </Index.Box>
                </>
              </Index.Box>
            </Index.Box>
            <Index.Box className="card-right"></Index.Box>
          </Index.Box>
          {/* {
            agentsignleData.departmentName !== "Agency" ? (
              <>
                <Index.Box className="admin-switch-main agent-details-switch-main">
                  <Index.Switch
                    checked={directPayment}
                    onClick={() => setDirectPayment(!directPayment)}
                  >
                  </Index.Switch>
                </Index.Box>
              </>
            ) : null
          } */}
          <Index.Box className="agency-details-sec">
            <Index.Box className="cus-tabs-main-box">
              <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Index.Box
                  variant="scrollable"
                  aria-label="basic tabs example"
                  className="cus-tabs-main"
                >

                  <Index.Tabs
                    value={tabValue}
                    onChange={handleTabsChange}
                    variant="scrollable"
                    // scrollButtons
                    aria-label="basic tabs example"
                    className="cus-tabs-main"
                  >
                    <Index.Tab
                      label="Total Officers"
                      value="TotalOfficer"
                      className="cus-tab"
                    />
                    <Index.Tab
                      label="Active Jobs"
                      value="ActiveJobs"
                      className="cus-tab"
                    />
                    <Index.Tab
                      label="Completed Jobs"
                      value="CompletedJobs"
                      className="cus-tab"
                    />
                  </Index.Tabs>
                </Index.Box>
              </Index.Box>
              <TabPanel
                value={tabValue}
                index="TotalOfficer"
                className="cus-tabpanel"
              >
                <Index.Box className="tabpanel-main tabpanel-1">
                  {filteredOfficerJobData.length ? (
                    filteredOfficerJobData.map((item, index) => {
                      return (
                        <Index.Box className="card-main">
                          <Index.Box className="card-left">
                            <Index.Box className="officer-profile-flex">
                              <img
                                src={PagesIndex.Png.usericon}
                                className="prof-img"
                                alt=""
                              />
                              <Index.Box className="officer-detail">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="officer-detail-title"
                                >
                                  {/* Officer Name */}
                                  {item?.officerId?.firstName + " " + item?.officerId?.lastName || "-"}
                                </Index.Typography>

                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="officer-detail-email"
                                >
                                  {item?.officerId?.email} |{" "}
                                  {formatPhoneNumber(item?.officerId?.mobileNumber)}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>


                          <Index.Box className="card-right">
                            <Index.Box className="officer-detail officer-detail-flex">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="officer-detail-title"
                              >
                                {/* Officer Name */}

                                {item?.finalAmount !== 0 ? `Total: ${parseFloat(item?.finalAmount).toFixed(2)}` : null}
                                {/* Total :  ${item?.finalAmount !== 0 ? item?.finalAmount : 0} */}
                              </Index.Typography>
                              {
                                // item?.finalAmount > 0
                                agentsignleData?.departmentName == "Department" && agentsignleData.paymentType == "Direct Pay" ?
                                  <Index.PrimaryButton
                                    btnLabel={item?.officerId.isOfficerPayment == undefined ? "Paid" : "Pay"}
                                    className="btn-primary"
                                    disabled={item?.officerId.isOfficerPayment == undefined}
                                    onClick={() =>
                                      paymentAgenthandleOpen(item)
                                    }
                                  />
                                  : null

                              }


                              <Index.PrimaryButton
                                btnLabel="View"
                                className="btn-primary"
                                onClick={() =>

                                  navigate(
                                    {
                                      pathname: `/admin/admin-officer-detail/${item?.officerId?._id}`,
                                      // pathname: `/admin/admin-officer-detail/${item?._id}`,

                                    },
                                    { state: { isPending: true } }
                                  )

                                }
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      );
                    })
                  ) : (
                    <PagesIndex.NoDataFound />
                  )}
                </Index.Box>
              </TabPanel>
              <TabPanel
                value={tabValue}
                index="ActiveJobs"
                className="cus-tabpanel"
              >
                <Index.Box className="tabpanel-main  tabpanel-2">
                  <>
                    {filteredOfficerJobData?.length ? (
                      filteredOfficerJobData?.map((item, index) => (
                        <>
                          <Index.Box className="common-card job-mamangment-card">
                            <Index.Box className="admin-sub-flex">
                              <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title"
                                >
                                  Job :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title-value-text"
                                >
                                  {item?.title || "-"}
                                </Index.Typography>
                              </Index.Box>
                              <Index.PrimaryButton
                                btnLabel="View"
                                onClick={() =>
                                  navigate("/admin/adminagentdetailsactive", {
                                    // state: item,
                                    state: { item, agentId },
                                  })
                                }
                                className="btn-primary"
                              />
                            </Index.Box>
                            <Index.Box className="card-main">
                              <Index.Box className="card-left">
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Posted By :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.userId?.name || "-"}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Posted On :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {PagesIndex.dayjs(item?.createdAt).format(
                                      "MM-DD-YYYY"
                                    )}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Approved By :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.approveBy?.agencyName || "-"}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Total Hours :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {PagesIndex.timeDifferenceCalculation(item)} hour
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Pay Rate (Per hour) :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    ${item?.rate}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="card-right">
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Total :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    ${PagesIndex.TotalCalculateByTime(item, item.rate)}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="accordian-main">
                              <Index.Accordion className="accordian">
                                <Index.AccordionSummary
                                  className="accordian-summary"
                                  expandIcon={
                                    <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                  }
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <Index.Box className="page-inner-title-flex">
                                    <Index.Typography className="page-inner-title">
                                      View Officer Details
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.AccordionSummary>
                                <Index.AccordionDetails className="accordian-details">
                                  {Array.isArray(item?.officerId) && item?.officerId?.map((row) => {
                                    const shortOfficerDetail = row?.officerId;
                                    return (
                                      <Index.Box className="card-main job-mamangment-inner-card">
                                        <Index.Box className="card-left">
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Name :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.firstName ? (
                                                <>
                                                  {shortOfficerDetail?.firstName +
                                                    " " +
                                                    shortOfficerDetail?.lastName}
                                                </>
                                              ) : null}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Email Address :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.email}
                                            </Index.Typography>
                                          </Index.Box>
                                          <Index.Box className="work-title-flex">
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-title-lable"
                                            >
                                              Type :&nbsp;
                                            </Index.Typography>
                                            <Index.Typography
                                              component="p"
                                              variant="p"
                                              className="work-value-text"
                                            >
                                              {shortOfficerDetail?.officerType}
                                            </Index.Typography>
                                          </Index.Box>
                                        </Index.Box>
                                      </Index.Box>
                                    );
                                  })}
                                </Index.AccordionDetails>
                              </Index.Accordion>
                            </Index.Box>
                          </Index.Box>
                        </>
                      ))
                    ) : (
                      <PagesIndex.NoDataFound />
                    )}
                  </>
                </Index.Box>
              </TabPanel>
              <TabPanel
                value={tabValue}
                index="CompletedJobs"
                className="cus-tabpanel"
              >
                <Index.Box className="tabpanel-main  tabpanel-3">
                  <>
                    {filteredOfficerJobData.length ? (
                      filteredOfficerJobData.map((item, index) => (
                        <>
                          <Index.Box className="common-card job-mamangment-card">
                            <Index.Box className="admin-sub-flex">
                              <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title"
                                >
                                  Job Title :&nbsp;
                                </Index.Typography>
                                <Index.Typography
                                  component="p"
                                  variant="p"
                                  className="admin-sub-title-value-text"
                                >
                                  {item?.title}
                                </Index.Typography>
                              </Index.Box>
                              <Index.PrimaryButton
                                btnLabel="View"
                                className="btn-primary"
                                onClick={() =>
                                  navigate("/admin/admingentdetailscompleted", {
                                    state: { item, agentId },
                                  })
                                }
                              />
                            </Index.Box>
                            <Index.Box className="card-main">
                              <Index.Box className="card-left">
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Posted By :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.userId?.name}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Associated Agency :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {item?.approveBy?.agencyName || "-"}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Total Hours :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    {PagesIndex.timeDifferenceCalculation(item)} hour
                                    {/* {console.log("863", item.jobSchedule !== undefined  ? item.jobSchedule[0]?.jobStartTime : 0)} */}
                                    {/* {item.jobSchedule !== undefined  ? PagesIndex.timeDifferenceCalculation(
                                    item?.jobSchedule[0]?.jobStartTime,
                                    item?.jobSchedule[0]?.jobEndTime) : 0} */}

                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="work-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-title-lable"
                                  >
                                    Pay Rate (Per hour) :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="work-value-text"
                                  >
                                    ${item?.rate}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="card-right">
                                <Index.Box className="work-title-flex">
                                  {/* <Index.Typography
                                component="p"
                                variant="p"
                                className="work-title-lable"
                              >
                                Total :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="work-value-text"
                              >
                                ${PagesIndex.TotalCalculateByTime(item, item.rate)}
                              </Index.Typography>
                            </Index.Box> */}

                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="officer-detail-title"
                                  >
                                    {/* Officer Name */}

                                    {item.finalPayableAmount !== 0 ? ` Total ${parseFloat(item.finalPayableAmount).toFixed(2)}` : null}
                                    {/* Total :  ${item?.rate !== undefined ? item?.rate : 0} */}
                                  </Index.Typography>

                                  {item.department == "Security department" || agentsignleData.paymentType !== "Direct Pay"
                                    ?
                                    <Index.SecondaryButton
                                      btnLabel={item.finalPayableAmount !== 0 ? "Pay" : "Paid"}
                                      className="btn-secondary"
                                      disabled={item.finalPayableAmount == 0}
                                      onClick={() =>
                                        paymentAgenthandleOpen(item)
                                      }
                                    /> :
                                    null

                                  }
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className="accordian-main">
                              <Index.Accordion className="accordian">
                                <Index.AccordionSummary
                                  className="accordian-summary"
                                  expandIcon={
                                    <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                  }
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <Index.Box className="page-inner-title-flex">
                                    <Index.Typography className="page-inner-title">
                                      View Officer Details
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.AccordionSummary>
                                <Index.AccordionDetails className="accordian-details">
                                  {Array.isArray(item?.officerId) && item?.officerId?.map((row) => {
                                    const shortOfficerDetail = row?.officerId;
                                    return (
                                      <>
                                        <Index.Box className="card-main">
                                          <Index.Box className="card-left">
                                            <Index.Box className="work-title-flex">
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-title-lable"
                                              >
                                                Name :&nbsp;
                                              </Index.Typography>
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-value-text"
                                              >
                                                {shortOfficerDetail?.firstName ? (
                                                  <>
                                                    {shortOfficerDetail?.firstName +
                                                      " " +
                                                      shortOfficerDetail?.lastName}
                                                  </>
                                                ) : null}
                                              </Index.Typography>
                                            </Index.Box>
                                            <Index.Box className="work-title-flex">
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-title-lable"
                                              >
                                                Email Address :&nbsp;
                                              </Index.Typography>
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-value-text"
                                              >
                                                {shortOfficerDetail?.email}
                                              </Index.Typography>
                                            </Index.Box>
                                            <Index.Box className="work-title-flex">
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-title-lable"
                                              >
                                                Type :&nbsp;
                                              </Index.Typography>
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-value-text"
                                              >
                                                {shortOfficerDetail?.officerType}
                                              </Index.Typography>
                                            </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="card-right">
                                          </Index.Box>
                                        </Index.Box>
                                      </>
                                    );
                                  })}
                                </Index.AccordionDetails>
                              </Index.Accordion>
                            </Index.Box>
                          </Index.Box>
                        </>
                      ))
                    ) : (
                      <PagesIndex.NoDataFound />
                    )}
                  </>
                </Index.Box>
              </TabPanel>
            </Index.Box>
          </Index.Box>
        </>
      ) : null}
      {/* EditDetails start */}
      <EditAdminAgentModal
        editAgentData={editAgentData}
        getSingleAgentData={getSingleAgentData}
        EditAgentModalopen={EditAgentModalopen}
        handleEditAgentModalCloseEdit={handleEditAgentModalCloseEdit}
        isAdminagentdetails={true}
      />
      {/* Payment modal start */}
      {paymentAgentModalopen && (
        <AdminPaymentModal
          paymentAgentId={paymentAgentId}
          paymentAmount={paymentAmount}
          paymentAgentModalopen={paymentAgentModalopen}
          paymentAgenthandleClose={paymentAgenthandleClose}
          jobId={jobId}
          paymentType={paymentType}
          officerDataList={officerDataList}
          roleName={roleName}
        // getAgentListData={getAgentListData}
        />
      )}


    </>
  );
};

export default AdminAgentDetails;
