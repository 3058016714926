import React from "react";
import Index from "../../container";
import "../../assets/css/style.css";
import PagesIndex from "../../components/PagesIndex";
import { useNavigate } from "react-router-dom";




export default function UserLoggedInAnotherDevice() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    localStorage.removeItem("AgencyToken")
    navigate("/")
  }

  return (
    <>

      <Index.Box className="woops-body">
        <img
          src={require("../../assets/images/jpg/unAuthImage.jpg")}
          alt="restrict"
          className="woops-icon"
        />
        <Index.Typography component="h6" className="woops-title">Whoops </Index.Typography>
        <Index.Typography component="h6" className="woops-sub-title">your session has expired</Index.Typography>
        <Index.Typography component="p" className="woops-message">
          Due to multiple login or inactivity your session has been expired please login again
        </Index.Typography>
        <Index.Box className="woops-btn">
          <Index.PrimaryButton
            className="btn-primary"
            btnLabel="Login"
            onClick={handleNavigate}
          />
      </Index.Box>
      </Index.Box>
    </>
  );
}
