import React from 'react'
import PagesIndex from '../../../PageIndex';
import Index from '../../../index';
import PropTypes from 'prop-types';

function createData(id, clientName, email, phoneNumber, jobId, payPerHour, totalHours, amount, date) {
    return { id, clientName, email, phoneNumber, jobId, payPerHour, totalHours, amount, date };
}

function createData2(id, clientName, email, phoneNumber, jobId, payPerHour, totalHours, amount, date) {
    return { id, clientName, email, phoneNumber, jobId, payPerHour, totalHours, amount, date };
}

const rows2 = [
    createData2(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "AgencyName",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData2(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "AgencyName",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData2(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "AgencyName",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData2(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "AgencyName",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData2(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "AgencyName",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData2(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "AgencyName",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
]


const rows = [
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
    createData(
        "Id ",
        //   <PagesIndex.PaidLable />,
        "Username",
        "username@gmail.com",
        "9826856235",
        "job id",
        "$100",
        "10",
        "$1000",
        "00/00/0000",
    ),
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Index.Box sx={{ p: 3 }}>
                    <Index.Typography>{children}</Index.Typography>
                </Index.Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AdminPaymentManagement() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
        <Index.Box className="user-list-flex">
            <Index.Typography className='admin-page-title user-list-page-title' component='h2' variant='h2'>
            <Index.Link to="/admin/dashboard" className="filter-back-link">
              <img
                src={PagesIndex.Svg.leftArrow}
                className="left-arrow-ic"
                alt="search grey img"
              ></img>
            </Index.Link>{" "}
                Payment Details</Index.Typography>
            <Index.Box className="userlist-btn-flex">
                <Index.Box className="user-search-main">
                    <Index.Box className="user-search-box">
                        <Index.Box className="form-group">
                            <Index.TextField
                                fullWidth
                                id="fullWidth"
                                className="form-control"
                                placeholder="Search"
                            />
                            <img
                                src={PagesIndex.Svg.search}
                                className="search-grey-img" alt='search grey img'
                            ></img>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
                
                <Index.Box className="userlist-inner-btn-flex">
                    <Index.Box className="adduser-btn-main btn-main-primary">
                        {/* <Index.Button className='adduser-btn btn-primary' >Make a Payment</Index.Button> */}
                        <Index.Button className='adduser-btn btn-primary' ><img
                                src={PagesIndex.Svg.pay}
                                className="money-img" alt='money'
                            /> Make a Payment</Index.Button>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </Index.Box>
            <Index.Box className="dashboard-content officer-manag-main p-t-0 payment-page-wrape">
                <Index.Box className='officer-tab-main'>
                    <Index.Box className="cus-tabs-main-box">
                        <Index.Box sx={{ width: '100%' }}>
                            <Index.Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="m-b-20">
                                <Index.Tabs value={value} onChange={handleChange} variant="scrollable"
  scrollButtons
  allowScrollButtonsMobile aria-label="basic tabs example" className='cus-tabs-main'>
                                    <Index.Tab label="Payment Received (from user)" {...a11yProps(0)} className="cus-tab" />
                                    <Index.Tab label="Payment Sent (to agent)" {...a11yProps(1)} className="cus-tab" />

                                </Index.Tabs>
                            </Index.Box>
                            <TabPanel value={value} index={0} className="cus-tabpanel">
                                <Index.Box className="tabpanel-main">
                                <Index.Box className="page-table-main payment-table-main">
                                    <Index.TableContainer
                                        component={Index.Paper}
                                        className="table-container res-over-hide"
                                    >
                                        <Index.Table
                                            sx={{ minWidth: 650 }}
                                            aria-label="simple table"
                                            className="table res-plateform-tbl res-paymnt-tbl"
                                        >
                                            <Index.TableHead className="table-head">
                                                <Index.TableRow className="table-row">
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Id
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Client Name
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Email Address
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Phone Number
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Job Id
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Pay Per hour
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Total Hours
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Amount
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Date
                                                    </Index.TableCell>
                                                    {/* <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Overall Rating
                                                    </Index.TableCell> */}
                                                </Index.TableRow>
                                            </Index.TableHead>
                                            <Index.TableBody className="table-body">
                                                {rows.map((row) => (
                                                    <Index.TableRow
                                                        key={row.id}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": {
                                                                border: 0,
                                                            },
                                                        }}
                                                    >
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            scope="row"
                                                            className="table-td"
                                                        >
                                                            {row.id}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.clientName}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.email}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.phoneNumber}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.jobId}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.payPerHour}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.totalHours}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.amount}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.date}
                                                        </Index.TableCell>
                                                        {/* <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.overallRating}
                                                        </Index.TableCell> */}
                                                    </Index.TableRow>
                                                ))}
                                            </Index.TableBody>
                                        </Index.Table>
                                    </Index.TableContainer>
                                </Index.Box>

                                </Index.Box>
                            </TabPanel>
                            <TabPanel value={value} index={1} className="cus-tabpanel">
                                <Index.Box className="tabpanel-main">
                                <Index.Box className="page-table-main payment-table-main">
                                    <Index.TableContainer
                                        component={Index.Paper}
                                        className="table-container"
                                    >
                                        <Index.Table
                                            sx={{ minWidth: 650 }}
                                            aria-label="simple table"
                                            className="table"
                                        >
                                            <Index.TableHead className="table-head">
                                                <Index.TableRow className="table-row">
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Id
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Agency Name
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Email Address
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Phone Number
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Job Id
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Pay Per hour
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Total Hours
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Amount
                                                    </Index.TableCell>
                                                    <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Date
                                                    </Index.TableCell>
                                                    {/* <Index.TableCell
                                                        component="th"
                                                        variant="th"
                                                        className="table-th"
                                                    >
                                                        Overall Rating
                                                    </Index.TableCell> */}
                                                </Index.TableRow>
                                            </Index.TableHead>
                                            <Index.TableBody className="table-body">
                                                {rows2.map((row) => (
                                                    <Index.TableRow
                                                        key={row.id}
                                                        sx={{
                                                            "&:last-child td, &:last-child th": {
                                                                border: 0,
                                                            },
                                                        }}
                                                    >
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            scope="row"
                                                            className="table-td"
                                                        >
                                                            {row.id}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.clientName}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.email}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.phoneNumber}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.jobId}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.payPerHour}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.totalHours}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.amount}
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.date}
                                                        </Index.TableCell>
                                                        {/* <Index.TableCell
                                                            component="td"
                                                            variant="td"
                                                            className="table-td"
                                                        >
                                                            {row.overallRating}
                                                        </Index.TableCell> */}
                                                    </Index.TableRow>
                                                ))}
                                            </Index.TableBody>
                                        </Index.Table>
                                    </Index.TableContainer>
                                </Index.Box>
                                </Index.Box>
                            </TabPanel>

                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )
}
