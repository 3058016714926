import React from "react";
import Index from "../../../../index";
import PagesIndex from "../../../../PageIndex";
// import Index from '../../../index';

export default function OfficerDetails() {
  const { state } = PagesIndex.useLocation();
  const OfficerDetails = state;
  const adminId = state?.adminId;

  return (
    <>
      <Index.Box className="dashboard-content bg-none officer-manag-main officer-tab-main officer-detail p-0">
        <Index.Box className="detail-pending-profile-main ">
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-profile">
                <img src={PagesIndex.Png.usericon} className="prof-img" />
              </Index.Box>
              <Index.Box className="officer-detail">
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  ></Index.Typography>
                </Index.Box>

                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    username@gmail.com / <span>9876543210</span>
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="card-right">
              <Index.Link to="/agency/agency-job-history">
                <Index.PrimaryButton
                  btnLabel="View Job History"
                  className="btn-primary"
                />
              </Index.Link>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-dashboad-row res-admin-dashboard-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
              >
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Completed Working Hours of the week
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h6"
                          component="h6"
                        >
                          9/10asfd
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Accepted jobs
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h6"
                          component="h6"
                        >
                          20
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box
                  gridColumn={{
                    xs: "span 12",
                    sm: "span 6",
                    md: "span 6",
                    lg: "span 4",
                  }}
                  className="grid-column"
                >
                  <Index.Box className="admin-dashboard-box box-shadow">
                    <Index.Box className="admin-dashboard-inner-box">
                      <Index.Box className="admin-dash-left">
                        <Index.Typography
                          className="admin-dash-text"
                          variant="p"
                          component="p"
                        >
                          Total Completedjobs
                        </Index.Typography>
                        <Index.Typography
                          className="admin-dash-price"
                          variant="h6"
                          component="h6"
                        >
                          30
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="assigned-officer-main">
            <Index.Box className="assigned-officer-header">
              <Index.Typography
                component="p"
                variant="p"
                className="main-title"
              >
                Work Information
              </Index.Typography>
            </Index.Box>
            <Index.Box className="officer-detail">
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Name :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Full Name
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  username@gmail.com
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Phone Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  9876543210
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Category of work :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Category of work
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Skill :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  skill
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Rank :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Rank
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Badge Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  00000000
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  TOD (Time On Duty) :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  10 year 10 month
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Required Pay Rate (Per hour) :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  $100
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Available For :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Job Type
                </Index.Typography>
              </Index.Box>
              <Index.Box className="job-title-text cus-item-top">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Available on :&nbsp;
                </Index.Typography>
                <Index.Box className="">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    <span className="day-title">Monday</span> -{" "}
                    <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    <span className="day-title">Tuesday</span> -{" "}
                    <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    <span className="day-title">Wednesday</span> -{" "}
                    <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    <span className="day-title">Thursday</span> -{" "}
                    <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    <span className="day-title">Friday</span> -{" "}
                    <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    <span className="day-title">Saturday</span> -{" "}
                    <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    <span className="day-title">Sunday</span> -{" "}
                    <span>00:00:00 AM/PM</span> to <span>00:00:00 AM/PM</span>
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="assigned-officer-main res-doc-main">
            <Index.Box className="assigned-officer-header">
              {/* <img
                            src={PagesIndex.Svg.leftArrow}
                            className="left-arrow-ic" alt='leftArrow img'
                        ></img> */}
              <Index.Typography
                component="p"
                variant="p"
                className="main-title"
              >
                Document
              </Index.Typography>
            </Index.Box>
            <Index.Box className="document-wrapper">
              <Index.Box className="document-main">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Document 1
                </Index.Typography>
                <Index.PrimaryButton
                  btnLabel="View Document"
                  className="btn-primary"
                />
              </Index.Box>
              <Index.Box className="document-main">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  Document 2
                </Index.Typography>
                <Index.PrimaryButton
                  btnLabel="View Document"
                  className="btn-primary"
                />
              </Index.Box>
              {/* <Index.Box className='document-btn-grp'>
                                <Index.PrimaryButton
                                    btnLabel="Approve"
                                    className="btn-primary bg-approve appr-rej-btn" />

                                <Index.PrimaryButton
                                    btnLabel="Reject"
                                    className="btn-primary bg-suspend appr-rej-btn" />
                            </Index.Box> */}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
