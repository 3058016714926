import React, { useState, useEffect, useRef } from "react";
import Index from "../../../index";
// import TimePicker from 'react-time-picker';
// import 'react-time-picker/dist/TimePicker.css';
// import 'react-clock/dist/Clock.css';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TableRow } from "@mui/material";
import PagesIndex from "../../../PageIndex";
import { editOfficerDetailSchema } from "../../../../validation/FormikSchema";
import moment from "moment";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import dayjs from "dayjs";
import {
  getJobTypeData,
  getSkillsData,
  getZipCodeData,
} from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import Loader from "../../../../common/loader/Loader";

const demo = [
  { title: "one", id: "1" },
  { title: "two", id: "2" },
  { title: "three", id: "3" },
];
const skills = [
  { title: "skill one", id: "1" },
  { title: "skill two", id: "2" },
  { title: "skill three", id: "3" },
];

export const officerList = [
  {
    id: 1,
    name: "Active Police Officer",
  },
  {
    id: 2,
    name: "Retired Police Officer",
  },
  {
    id: 3,
    name: " Retired Military Officer",
  },
  {
    id: 4,
    name: " Security Officer",
  },
];

const Editofficerdetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const formik = useRef();
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const { state } = PagesIndex.useLocation();
  const officerZip = state.item;
  const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
  const [updateData, setUpdateData] = useState({});
  const [updateDataLoading, setUpdateDataLoading] = useState(false);
  const location = PagesIndex.useLocation();
  const officerId = new URLSearchParams(location?.search).get("id");
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const currentTime = new Date();
  const [todyear, setTodyear] = React.useState("");
  const handleChangetodyear = (event) => {
    setTodyear(event.target.value);
  };
  // zip code
  const [zipCodeList, setZipCodeList] = useState();
  // job type
  const [jobTypeList, setJobTypeList] = useState();
  console.log(jobTypeList, "jobTypeList");
  // Skill list
  const [skillList, setSkillList] = useState();
  // select All
  const [searchedData, setSearchedData] = useState([]);
  const [selectProducts, setSelectProducts] = useState([]);
  // us phone number functionality
  const [mobileNumber, setmobileNumber] = useState("");

  const [isDayCheck, setDayCheckAll] = useState(false);

  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;
    const cleaned = input.replace(/\D/g, "");

    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] == "0") {
      return; // Do nothing if the first digit is 0
    }

    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);

    // Format only if we have 10 digits
    if (cleaned.length === 10) {
      const formattedPhoneNumber = formatPhoneNumber(cleaned);
      setmobileNumber(formattedPhoneNumber);
    } else {
      // If less than 10 digits, just set the cleaned (unformatted) version
      setmobileNumber(cleaned);
    }
  };

  const formatPhoneNumber = (input) => {
    const strInput = String(input); // Ensure input is a string
    const match = strInput?.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return strInput;
  };

  useEffect(() => {
    setmobileNumber(formatPhoneNumber(updateData?.mobileNumber));
  }, [updateData]);

  const [todmonth, setTodmonth] = React.useState("");
  const handleChangetodmonth = (event) => {
    setTodmonth(event.target.value);
  };

  // get single Officer Detail for update
  const getSingleOfficerDeatil = () => {
    setUpdateDataLoading(true);
    DataService.get(Api.Admin.GET_SINGLE_OFFICER + "/" + officerId)
      .then((response) => {
        if (response?.data?.status === 200) {
          setUpdateData(response?.data?.data);
          setTimeout(() => {
            setUpdateDataLoading(false);
          }, 1000);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getSingleOfficerDeatil();
  }, []);

  // get zip code
  const getZipCode = () => {
    dispatch(getZipCodeData()).then((res) => {
      if (res?.payload?.status == 200) {
        setZipCodeList(res?.payload?.data);
      }
    });
  };
  //getSkillsData
  const getSkillList = () => {
    dispatch(getSkillsData()).then((res) => {
      if (res?.payload?.status == 200) {
        setSkillList(res?.payload?.data);
      }
    });
  };
  // get job type list
  const getJobTypeList = () => {
    dispatch(getJobTypeData()).then((res) => {
      if (res?.payload?.status == 200) {
        setJobTypeList(res?.payload?.data);
      }
    });
  };

  useEffect(() => {
    getZipCode();
    getJobTypeList();
    getSkillList();
  }, []);

  const initialValues = {
    firstName: updateData ? updateData?.firstName : "",
    lastName: updateData ? updateData?.lastName : "",
    email: updateData ? updateData?.email : "",
    mobileNumber: updateData ? updateData?.mobileNumber : "",
    category: updateData ? updateData?.category : "",
    // skills: updateData ? updateData?.skills?.[0]?.split(",") : [],
    skills: updateData ? updateData?.skills : [],
    zipCode: updateData ? updateData?.zipCode : "",
    rank: updateData ? updateData?.rank : "",
    // zipCode: updateData ? updateData?.zipCode?.split(",") : [],
    // rank: updateData ? officerList?.find((item)=>item?.name == updateData?.rank)?.id : "",
    badgeNumber: updateData ? updateData?.badgeNumber : "",
    rate: updateData ? updateData?.rate : "",
    yearsOnDuty: updateData ? updateData?.yearsOnDuty : "",
    monthsOnDuty: updateData ? updateData?.monthsOnDuty : "",
    // jobType: updateData ? updateData?.jobType?.jobType : "",
    jobType: updateData ? updateData?.jobType?._id : "",

    days: updateData
      ? updateData?.days
      : [
        { isActive: false, day: "Monday", startTime: "", endTime: "" },
        { isActive: false, day: "Tuesday", startTime: "", endTime: "" },
        { isActive: false, day: "Wednesday", startTime: "", endTime: "" },
        { isActive: false, day: "Thursday", startTime: "", endTime: "" },
        { isActive: false, day: "Friday", startTime: "", endTime: "" },
        { isActive: false, day: "Saturday", startTime: "", endTime: "" },
        { isActive: false, day: "Sunday", startTime: "", endTime: "" },
      ],
  };
  
  const handleSelectCheckAll = (e, setFieldValue, setTouched) => {
    const isChecked = e.target.checked;
    setDayCheckAll(isChecked);
    updateData.days.forEach((_, index) => {
      setFieldValue(`days[${index}].isActive`, isChecked, false);
      if (!isChecked) {
        setFieldValue(`days[${index}].startTime`, "", false);
        setFieldValue(`days[${index}].endTime`, "", false);
        setTouched({
          [`days[${index}].startTime`]: false,
          [`days[${index}].endTime`]: false,
        });
      } else {
        setTouched({
          [`days[${index}].startTime`]: true,
          [`days[${index}].endTime`]: true,
        });
      }
    });

    const allSelectData = updateData?.days?.map((c) => c?._id);
    setSelectProducts(isChecked ? allSelectData : []);
  };

  const handleSubmitEdit = (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    if (officerId) {
      urlEncoded.append("id", officerId);
    }
    urlEncoded.append("firstName", values?.firstName);
    urlEncoded.append("lastName", values?.lastName);
    urlEncoded.append("email", values?.email.toLowerCase());
    urlEncoded.append("mobileNumber", values.mobileNumber);
    urlEncoded.append("rank", values.rank);
    urlEncoded.append("rate", values?.rate);
    // urlEncoded.append("badgeNumber", values?.badgeNumber);
    urlEncoded.append("yearsOnDuty", values?.yearsOnDuty);
    urlEncoded.append("monthsOnDuty", values?.monthsOnDuty);
    urlEncoded.append("zipCode", values?.zipCode);
    // urlEncoded.append("skills", values?.skills);
    for (let i = 0; i < values.skills.length; i++) {
      urlEncoded.append(`skills[${i}]`, values.skills[i]);
    }

    urlEncoded.append("jobType", values?.jobType);
    // urlEncoded.append("skills", JSON.stringify(values?.skills));
    // urlEncoded.append("zipCode", JSON.stringify(values?.zipCode));

    // console.log("266",values.days);
    values.days = values.days.map(day => {
      if (!day.isActive) {
        return {
          ...day,
          startTime: "",
          endTime: ""
        };
      }
      return day;
    });

    urlEncoded.append("days", JSON.stringify(values?.days));

    PagesIndex?.DataService?.post(
      PagesIndex?.Api?.Admin?.UPDATE_OFFICER,
      urlEncoded
    )
      .then(({ data }) => {
        PagesIndex?.toast?.success(data?.message);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        navigate("/admin/officer-management");
      })
      .catch((error) => {
        PagesIndex.toast.error(error?.response?.data?.message, {
          toastId: "customId",
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h3"
          variant="h3"
        >
          <Index.Link
            to="/admin/officer-management"
            className="filter-back-link"
          >
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            ></img>
          </Index.Link>{" "}
          Edit Officer
        </Index.Typography>
      </Index.Box>
      <>
        {!updateDataLoading ? (
          <>
            <PagesIndex.Formik
              enableReinitialize
              validationSchema={editOfficerDetailSchema}
              initialValues={initialValues}
              onSubmit={handleSubmitEdit}
              innerRef={formik}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                setTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  {console.log(errors, "errors")}
                  {console.log(values, "values")}

                  {console.log(touched, "touched")}
                  <Index.Box className="common-card">
                    <Index.Box className="grid-row">
                      <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                        >
                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                First Name
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder="Please enter your first name"
                                  name="firstName"
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    setFieldValue("firstName", "");
                                  }}
                                  value={values.firstName}
                                  inputProps={{ maxLength: 15 }}
                                  onChange={(e) => {
                                    // const newValue = e.target.value.replace(
                                    //   /\s+/g,
                                    //   " "
                                    // );
                                    // setFieldValue("firstName", newValue);
                                    handleChange(e);
                                    setFieldTouched("firstName", true);
                                  }}
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === " " &&
                                      e.target.value.trim() === ""
                                    ) {
                                      e.preventDefault(); // Prevent space as the first character
                                    } else if (
                                      e.target.value.trim().split(" ").length >=
                                      2 &&
                                      e.key === " "
                                    ) {
                                      e.preventDefault(); // Prevent adding additional spaces between words
                                    } else if (
                                      e.key === " " &&
                                      e.target.value.endsWith(" ")
                                    ) {
                                      e.preventDefault(); // Prevent additional spaces at the end
                                    }
                                    if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                      e.preventDefault(); // Prevent non-numeric characters
                                    }
                                  }}
                                  // onBlur={handleBlur}
                                  focused={false}
                                  error={
                                    errors.firstName && touched.firstName
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors.firstName && touched.firstName
                                      ? errors.firstName
                                      : null
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 8",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                Last Name
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="lastname"
                                  placeholder="Please enter your last name"
                                  className="form-control"
                                  name="lastName"
                                  value={values.lastName}
                                  onPaste={(e) => {
                                    e.preventDefault();
                                    setFieldValue("lastName", "");
                                  }}
                                  inputProps={{ maxLength: 15 }}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldTouched("lastName", true);
                                  }}
                                  onKeyDown={(e) => {
                                    if (
                                      e.key === " " &&
                                      e.target.value.trim() === ""
                                    ) {
                                      e.preventDefault(); // Prevent space as the first character
                                    } else if (
                                      e.target.value.trim().split(" ").length >=
                                      2 &&
                                      e.key === " "
                                    ) {
                                      e.preventDefault(); // Prevent adding additional spaces between words
                                    } else if (
                                      e.key === " " &&
                                      e.target.value.endsWith(" ")
                                    ) {
                                      e.preventDefault(); // Prevent additional spaces at the end
                                    }
                                    if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                                      e.preventDefault(); // Prevent non-numeric characters
                                    }
                                  }}
                                  // onBlur={handleBlur}
                                  focused={false}
                                  error={
                                    errors.lastName && touched.lastName
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors.lastName && touched.lastName
                                      ? errors.lastName
                                      : null
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                Email Address
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="email"
                                  className="form-control"
                                  placeholder="Please enter your email address"
                                  name="email"
                                  value={values.email}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  onKeyDown={(event) => {
                                    if (event.key == " ") {
                                      event.preventDefault();
                                    }
                                  }}
                                  inputProps={{ maxLength: 60 }}
                                  onBlur={handleBlur}
                                  error={
                                    errors.email && touched.email ? true : false
                                  }
                                  helperText={
                                    errors.email && touched.email
                                      ? errors.email
                                      : null
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                Phone Number
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="mobile number"
                                  className="form-control"
                                  placeholder="(123) 456-7890"
                                  name="mobileNumber"
                                  value={mobileNumber}
                                  focused={false}
                                  onChange={(e) => {
                                    handlePhoneChange(e, setFieldValue);
                                  }}
                                  inputProps={{ maxLength: 10 }}
                                  error={
                                    errors.mobileNumber && touched.mobileNumber
                                      ? true
                                      : false
                                  }
                                  helperText={
                                    errors.mobileNumber && touched.mobileNumber
                                      ? errors.mobileNumber
                                      : false
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                Add Zip Code
                              </Index.FormHelperText>
                              <Index.Box className="dropdown-box">
                                <Index.FormControl className="form-control drop-form-control">
                                  <Index.Select
                                    className="dropdown-select drop-select"
                                    fullWidth
                                    displayEmpty
                                    name="zipCode"
                                    // readOnly
                                    disabled
                                    value={values.zipCode}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    onChange={(e) => {
                                      console.log(e.target.value, "434344");
                                      setFieldValue("zipCode", e.target.value);
                                    }}
                                  >
                                    <Index.MenuItem value={""}>
                                      <Index.MenuItem
                                        disabled
                                        className="all-select-label"
                                      >
                                        Select Zip Code
                                      </Index.MenuItem>
                                    </Index.MenuItem>
                                    {zipCodeList?.map((items, index) => (
                                      <Index.MenuItem
                                        value={items?.zipCode}
                                        key={items?._id}
                                      >
                                        {items?.zipCode}
                                      </Index.MenuItem>
                                    ))}
                                  </Index.Select>
                                </Index.FormControl>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 4",
                              lg: "span 4",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                {" "}
                                Rank
                              </Index.FormHelperText>

                              <Index.Box className="dropdown-box">
                                <Index.FormControl className="form-control drop-form-control">
                                  <Index.Select
                                    className="dropdown-select drop-select"
                                    displayEmpty
                                    name="rank"
                                    // readOnly
                                    disabled
                                    value={values?.rank}
                                    onChange={(e) => {
                                      setFieldValue("rank", e?.target?.value);
                                    }}
                                    error={
                                      errors.rank && touched.rank ? true : false
                                    }
                                  // renderValue={
                                  //   values?.rank ? undefined : () => "Select Rank"
                                  // }
                                  >
                                    {officerList.length > 0
                                      ? officerList.map((data, index) => {
                                        return (
                                          <Index.MenuItem
                                            key={index}
                                            value={data?.name}
                                            className="drop-menuitem"
                                          >
                                            {data?.name}
                                          </Index.MenuItem>
                                        );
                                      })
                                      : null}
                                  </Index.Select>
                                </Index.FormControl>
                              </Index.Box>
                              {touched.rank && errors.rank && (
                                <Index.FormHelperText error>
                                  {errors.rank}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 12",
                              md: "span 12",
                              lg: "span 12",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                Select Skill
                              </Index.FormHelperText>
                              <Index.Box className="dropdown-box">
                                <Index.FormControl className="form-control drop-form-control">
                                  <Index.Autocomplete
                                    className="dropdown-select drop-select"
                                    multiple
                                    // options={["Skill1", "Skill2", "Skill3"]}
                                    options={
                                      skillList
                                        ? skillList.map((item) => item.skill)
                                        : []
                                    }
                                    getOptionLabel={(option) => option}
                                    value={values?.skills}
                                    disableCloseOnSelect
                                    renderOption={(
                                      props,
                                      option,
                                      { selected }
                                    ) => {
                                      return (
                                        <Index.MenuItem
                                          key={option}
                                          value={option}
                                          sx={{
                                            justifyContent: "space-between",
                                            fontSize: "12px",
                                          }}
                                          {...props}
                                        >
                                          <Index.ListItemText>
                                            {option}
                                          </Index.ListItemText>
                                          {selected ? <Index.Check /> : null}
                                        </Index.MenuItem>
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <Index.TextField
                                        {...params}
                                        error={
                                          errors.skills && touched.skills
                                            ? true
                                            : false
                                        }
                                        placeholder={
                                          values?.skills?.length === 0
                                            ? "Please select skill"
                                            : ""
                                        }
                                      />
                                    )}
                                    onChange={(e, val) => {
                                      let idArray = [];
                                      val?.map((item) => {
                                        idArray.push(item);
                                      });
                                      setFieldValue("skills", idArray);
                                    }}
                                  />
                                </Index.FormControl>
                                {touched.skills && errors.skills && (
                                  <Index.FormHelperText error>
                                    {errors.skills}
                                  </Index.FormHelperText>
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                Required Pay Rate
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.TextField
                                  fullWidth
                                  id="fullWidth"
                                  className="form-control"
                                  placeholder="Please enter required pay rate"
                                  name="rate"
                                  value={values?.rate}
                                  onChange={(e) => {
                                    const newValue = e.target.value.replace(
                                      ONLY_NUMBER_NOT_SPACE_SPACIALCHAR,
                                      ""
                                    );
                                    setFieldValue("rate", newValue);
                                    setFieldTouched("rate", true);
                                  }}
                                  inputProps={{ maxLength: 5 }}
                                  // onBlur={handleBlur}
                                  focused={false}
                                  error={errors.rate && touched.rate ? true : false}
                                  helperText={
                                    errors.rate && touched.rate
                                      ? errors.rate
                                      : null
                                  }
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 6",
                              lg: "span 6",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                {" "}
                                Job Type{" "}
                              </Index.FormHelperText>
                              <Index.Box className="dropdown-box">
                                <Index.FormControl className="form-control drop-form-control">
                                  <Index.Select
                                    className="dropdown-select drop-select"
                                    displayEmpty
                                    name="jobType"
                                    value={values?.jobType}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "jobType",
                                        e?.target?.value
                                      );
                                    }}
                                    error={
                                      errors.jobType && touched.jobType
                                        ? true
                                        : false
                                    }
                                  >
                                    {jobTypeList?.map((data, index) => {
                                      return (
                                        <Index.MenuItem
                                          key={index}
                                          value={data?._id}
                                          className="drop-menuitem"
                                        >
                                          {data?.jobType}
                                        </Index.MenuItem>
                                      );
                                    })}
                                  </Index.Select>
                                </Index.FormControl>
                              </Index.Box>
                              {touched.jobType && errors.jobType && (
                                <Index.FormHelperText error>
                                  {errors.jobType}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>

                          <Index.Box
                            gridColumn={{
                              xs: "span 12",
                              sm: "span 6",
                              md: "span 3",
                              lg: "span 3",
                            }}
                            className="grid-column"
                          >
                            <Index.Box className="input-box">
                              <Index.FormHelperText className="form-lable">
                                TOD (Time on Duty) :
                              </Index.FormHelperText>
                              <Index.Box className="form-group">
                                <Index.Box className="double-dd-wrape">
                                  <Index.Box className="dd-section">
                                    <Index.Select
                                      className="dropdown-select drop-select"
                                      name="yearsOnDuty"
                                      value={values.yearsOnDuty}
                                      // onChange={handleChangetodyear}
                                      onChange={handleChange}
                                      placeholder="Please enter years"
                                    >
                                      <Index.MenuItem
                                        value={1}
                                        className="drop-menuitem"
                                      >
                                        1
                                      </Index.MenuItem>
                                      <Index.MenuItem
                                        value={2}
                                        className="drop-menuitem"
                                      >
                                        2
                                      </Index.MenuItem>
                                      <Index.MenuItem
                                        value={3}
                                        className="drop-menuitem"
                                      >
                                        3
                                      </Index.MenuItem>
                                      <Index.MenuItem
                                        value={4}
                                        className="drop-menuitem"
                                      >
                                        4
                                      </Index.MenuItem>
                                      <Index.MenuItem
                                        value={5}
                                        className="drop-menuitem"
                                      >
                                        5
                                      </Index.MenuItem>
                                      <Index.MenuItem
                                        value={6}
                                        className="drop-menuitem"
                                      >
                                        6
                                      </Index.MenuItem>
                                      <Index.MenuItem
                                        value={7}
                                        className="drop-menuitem"
                                      >
                                        7
                                      </Index.MenuItem>
                                      <Index.MenuItem
                                        value={8}
                                        className="drop-menuitem"
                                      >
                                        8
                                      </Index.MenuItem>
                                      <Index.MenuItem
                                        value={9}
                                        className="drop-menuitem"
                                      >
                                        9
                                      </Index.MenuItem>
                                      <Index.MenuItem
                                        value={10}
                                        className="drop-menuitem"
                                      >
                                        10
                                      </Index.MenuItem>
                                    </Index.Select>
                                    <Index.Typography
                                      component="span"
                                      className="form-lable"
                                    >
                                      Years
                                    </Index.Typography>
                                  </Index.Box>

                                  <Index.Box className="dd-section">
                                    <Index.Select
                                      className="dropdown-select drop-select"
                                      name="monthsOnDuty"
                                      value={values.monthsOnDuty}
                                      // onChange={handleChangetodmonth}
                                      onChange={handleChange}
                                      placeholder="Please enter months"
                                    >
                                      <Index.MenuItem value={1}>
                                        1
                                      </Index.MenuItem>
                                      <Index.MenuItem value={2}>
                                        2
                                      </Index.MenuItem>
                                      <Index.MenuItem value={3}>
                                        3
                                      </Index.MenuItem>
                                      <Index.MenuItem value={4}>
                                        4
                                      </Index.MenuItem>
                                      <Index.MenuItem value={5}>
                                        5
                                      </Index.MenuItem>
                                      <Index.MenuItem value={6}>
                                        6
                                      </Index.MenuItem>
                                      <Index.MenuItem value={7}>
                                        7
                                      </Index.MenuItem>
                                      <Index.MenuItem value={8}>
                                        8
                                      </Index.MenuItem>
                                      <Index.MenuItem value={9}>
                                        9
                                      </Index.MenuItem>
                                      <Index.MenuItem value={10}>
                                        10
                                      </Index.MenuItem>
                                      <Index.MenuItem value={11}>
                                        11
                                      </Index.MenuItem>
                                      <Index.MenuItem value={12}>
                                        12
                                      </Index.MenuItem>
                                    </Index.Select>
                                    <Index.Typography
                                      component="span"
                                      className="form-lable"
                                    >
                                      Months
                                    </Index.Typography>
                                    <Index.Box></Index.Box>
                                  </Index.Box>
                                </Index.Box>
                                {touched.monthsOnDuty &&
                                  errors.monthsOnDuty && (
                                    <Index.FormHelperText error>
                                      {errors.monthsOnDuty}
                                    </Index.FormHelperText>
                                  )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="admin-sub-title-main available-title-flex">
                    <Index.Typography
                      className="admin-sub-title"
                      component="h3"
                      variant="h3"
                    >
                      Available
                    </Index.Typography>
                    <Index.FormControlLabel
                      className="admin-officer-checkbox"
                      control={
                        <Index.Checkbox
                          checked={ 
                            isDayCheck 
                          }
                          onChange={(e) => {
                            handleSelectCheckAll(e, setFieldValue, setTouched, touched);
                          }}
                        />
                      }
                      label="All"
                    />
                  </Index.Box>
                  <Index.Box className="common-card">
                    <Index.Box className="card-body">
                      {values?.days?.map((row, index) => (
                        <>
                          <Index.Box className="grid-row">
                            <Index.Box sx={{ width: 1 }} className="grid-main">
                              <Index.Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                                key={index}
                              >
                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 4",
                                    lg: "span 2",
                                  }}
                                  className="grid-column"
                                >
                                  {/* {console.log(touched, "touched")}
                                  {console.log(values, "values1")}
                                  {console.log(errors, "errors")} */}
                                  {
                                    setDayCheckAll(values.days.every((day, i) => day.isActive))
                                  }
                                  <div className="input-box">
                                    <Index.FormHelperText className="form-lable"></Index.FormHelperText>
                                    <Index.Box className="input-switch-flex">
                                      <Index.FormHelperText className="form-lable input-switch-lable">
                                        {row?.day}
                                      </Index.FormHelperText>
                                      <Index.Switch
                                        checked={row?.isActive}
                                        onChange={(e) => {
                                          setFieldValue(`days[${index}].isActive`, e.target.checked, false);
                                          if (!e.target.checked) {
                                            setFieldValue(`days[${index}].startTime`, "", false);
                                            setFieldValue(`days[${index}].endTime`, "", false);
                                            setTouched({
                                              [`days[${index}].startTime`]: false,
                                              [`days[${index}].endTime`]: false,
                                            });
                                          } else {
                                            setTouched({
                                              [`days[${index}].startTime`]: true,
                                              [`days[${index}].endTime`]: true,
                                            });
                                          }
                                        }}
                                      ></Index.Switch>
                                    </Index.Box>
                                  </div>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 4",
                                    lg: "span 2",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box">
                                    <Index.FormHelperText className="form-lable ">
                                      From
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group pwd-icon-btn time-form-group">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          className="text-center"
                                          components={[
                                            "MobileTimePicker",
                                            "MobileTimePicker",
                                            "MobileTimePicker",
                                          ]}
                                        >
                                          <TimePicker
                                            className="form-control"
                                            disabled={
                                              !values.days[index]?.isActive
                                            }
                                            maxTime={
                                              values.days[index].endTime
                                                ? dayjs(
                                                  `2023-12-25T${values.days[index].endTime}`
                                                )
                                                : null
                                            }
                                            ampm={false}
                                            value={
                                              values.days[index].startTime
                                                ? dayjs(
                                                  `2023-12-25T${values.days[index].startTime}`
                                                )
                                                : null
                                            }
                                            views={["hours", "minutes"]}
                                            name={`days[${index}].startTime`}
                                            defaultValue={dayjs(
                                              `2023-12-25T${row?.startTime}`
                                            )}
                                            onChange={(e) => {
                                              const formatedTime = moment(
                                                e?.$d
                                              ).format("HH:mm");
                                              setFieldValue(
                                                `days[${index}].startTime`,
                                                formatedTime,
                                                true
                                              );
                                            }}
                                            slotProps={{
                                              textField: { readOnly: true },
                                            }}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>
                                      {errors.days && errors.days[index]?.startTime && touched.days && touched.days[index]?.startTime && (
                                        <Index.FormHelperText error className="error-text">
                                          {errors.days[index].startTime}
                                        </Index.FormHelperText>
                                      )}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box
                                  gridColumn={{
                                    xs: "span 12",
                                    sm: "span 6",
                                    md: "span 4",
                                    lg: "span 2",
                                  }}
                                  className="grid-column"
                                >
                                  <Index.Box className="input-box">
                                    <Index.FormHelperText className="form-lable">
                                      To
                                    </Index.FormHelperText>
                                    <Index.Box className="form-group pwd-icon-btn time-form-group">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DemoContainer
                                          components={[
                                            "MobileTimePicker",
                                            "MobileTimePicker",
                                            "MobileTimePicker",
                                          ]}
                                        >
                                          <TimePicker
                                            className="form-control"
                                            disabled={
                                              !values.days[index]?.isActive
                                            }
                                            minTime={dayjs(
                                              `2023-12-25T${values.days[index].startTime}`
                                            )}
                                            ampm={false}
                                            views={["hours", "minutes"]}
                                            name={`days[${index}].endTime`}
                                            defaultValue={dayjs(
                                              `2023-12-25T${row.endTime}`
                                            )}
                                            value={
                                              values.days[index].endTime
                                                ? dayjs(
                                                  `2023-12-25T${values.days[index].endTime}`
                                                )
                                                : null
                                            }
                                            onChange={(e) => {
                                              const formatedTime = moment(
                                                e?.$d
                                              ).format("HH:mm");
                                              setFieldValue(
                                                `days[${index}].endTime`,
                                                formatedTime,
                                                true
                                              );
                                            }}
                                            slotProps={{
                                              textField: { readOnly: true },
                                            }}
                                          />
                                        </DemoContainer>
                                      </LocalizationProvider>

                                      {errors.days && errors.days[index]?.endTime && touched.days && touched.days[index]?.endTime && (
                                        <Index.FormHelperText error className="error-text">
                                          {errors.days[index].endTime}
                                        </Index.FormHelperText>
                                      )}
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </>
                      ))}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="user-btn-flex">
                    <Index.PrimaryButton
                      btnLabel="Update"
                      type="submit"
                      title="Update"
                      className="btn-primary bg-approve"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Click Me"}
                    </Index.PrimaryButton>
                    <Index.PrimaryButton
                      btnLabel="Cancel"
                      title="Cancel"
                      className="btn-primary bg-suspend"
                      onClick={() => navigate("/admin/officer-management")}
                    />
                  </Index.Box>
                </form>
              )}
            </PagesIndex.Formik>
          </>
        ) : null}
      </>
    </>
  );
};
export default Editofficerdetail;
