import { createSlice } from "@reduxjs/toolkit";
import {
  agencyLogin,
  getAgencyOfficerList,
  getAgencyRoleList,
  getCityList,
  getJobList,
  getStateList,
} from "./AgencyServices";

const initialState = {
  agencyLoginData: {},
  loading: false,
  agencyListData: {},
  agencyStateList: {},
  agencyCityList: {},
  // agencyRoleData: {},
  agencyOfficerList: {},
  agencyjobList: {},
};

export const AgencySlice = createSlice({
  name: "agency",
  initialState,
  reducers: {
    getAdminData: (state, action) => {
      state.agencyLoginData = action.payload;
    },
    agencyLogout: (state) => {
      localStorage.removeItem("token");
      state.isAgencyLoggedIn = false;
      state.agencyLoginData = [];
      // state.token = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(agencyLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(agencyLogin.fulfilled, (state, action) => {
        state.agencyLoginData = action?.payload?.data;
        // state.token = action?.payload?.data?.token;
        state.isAgencyLoggedIn = true;
        state.loading = false;
      })
      .addCase(agencyLogin.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getStateList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStateList.fulfilled, (state, action) => {
        state.agencyStateList = action?.payload?.data;
      })
      .addCase(getStateList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCityList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCityList.fulfilled, (state, action) => {
        state.agencyCityList = action?.payload?.data;
      })
      .addCase(getCityList.rejected, (state) => {
        state.loading = false;
      })     
      .addCase(getAgencyOfficerList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgencyOfficerList.fulfilled, (state, action) => {
        state.agencyOfficerList = action?.payload?.data;
      })
      .addCase(getAgencyOfficerList.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getJobList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getJobList.fulfilled, (state, action) => {
        state.agencyjobList = action?.payload?.data;
      })
      .addCase(getJobList.rejected, (state) => {
        state.loading = false;
      })
      // .addCase(getAgencyRoleList.fulfilled, (state, action) => {
      //   state.loading = true;
      //   state.agencyRoleData = action.payload?.data;
      // })
      .addCase(getAgencyRoleList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAgencyRoleList.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { agencyLogout, getAdminData } = AgencySlice.actions;
export default AgencySlice.reducer;
