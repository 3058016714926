//import React from "react";
import React, { useState, useEffect } from "react";
import PagesIndex from "../../PagesIndex";
import Index from "../../index";
import Loader from "../../../common/loader/Loader";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";
import AgencyPaymentLoader from "../../../container/agency/pages/paymentmanagement/AgencyPaymentLoader";
import { useNavigate } from "react-router-dom";
import PaymentType from "./PaymentType";
import { agencyLogout } from "../../../redux-toolkit/slice/agency-slice/AgencySlice";

function Sidebar(props) {
  const [open, setOpen] = useState(true);
  const location = PagesIndex.useLocation();
  const [screen, setScreen] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [paymentTypeModelopen, setPaymnetTypeModelOpen] = useState(false);
  const handlePaymnetTypeOpen = () => setPaymnetTypeModelOpen(true);
  const handlePaymnetTypeModelClose = () => setPaymnetTypeModelOpen(false);
  const [paymentType, setPaymentType] = useState(null);

  const paymentMethods = localStorage.getItem('agencypayment');
  const dispatch = PagesIndex.useDispatch();

  // role permission
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );

  // console.log("32",agencyLoginData)


  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;
  // console.log(permission, "agency permission")
  useEffect(() => {
    if (window.screen.width < 769) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);


  const handlePaymentConfig = async () => {
    try {
      setIsLoading(true);
      const res = await DataService.get(Api.Agency.PAYMENT_ACCOUNT_CREATE);
      const url = res.data.data.url;
      // Redirect to the URL
      if (url) {
        setIsLoading(false);
        window.location.href = url
        // window.open(url);
      } else {
        setIsLoading(true);
        console.error("URL not found in the response");
      }
    } catch (error) {
      setIsLoading(true);
      console.error(error);
    }
    // navigate("/");
  };



  useEffect(() => {
    if (isLoading) {
      navigate(`/${roleName}/loaderPage`, { state: { roleName: roleName } });
    }
  }, [isLoading]);

  const getNotification = async () => {
    try {
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.Agency.GET_NOTIFICATION_HISTORY
      );
      if (res.status === 200) {
        let unreadCount = res?.data?.data.filter((data) => !data?.isRead)?.length
        localStorage.setItem("agencynotificationcount", unreadCount);
        if (res?.data?.paymentType !== undefined) {
          setPaymentType(res?.data?.paymentType);
          localStorage.setItem("agencypayment", res?.data?.paymentType);
        }
      } else {
        PagesIndex.toast.error(res?.message);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if ((roleName === "Agency" || roleName === "Department") && (location.pathname.includes(`/${roleName}/dashboard`))) {
      getNotification();
    }
  }, [location.pathname]);

  const handleAgencylogout = () => {
    dispatch(agencyLogout());
    PagesIndex.toast.success("Logged out successfully");
    localStorage.removeItem("AgencyToken");
    localStorage.removeItem("agencynotificationcount");
    localStorage.removeItem("agencypayment");
    navigate("/");
  };


  return (
    <>
      <Index.Box
        className={`admin-sidebar-main ${(screen === "Mobile" ? !props.open : props.open) ? "active" : ""
          }`}
      >
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <img
              src={PagesIndex.Png.logo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button
              onClick={() => {
                props.setOpen(!props.open);
                document.body.classList[props.open ? "add" : "remove"](
                  "body-overflow"
                );
              }}
              className="sidebar-close-btn"
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="close-icon"
                alt="logo"
              />
            </Index.Button>
          </Index.Box>

          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to={`/${roleName?.replace(/ /g, '-')}/dashboard`}
                  className={`admin-sidebar-link ${location.pathname.includes(`/${roleName}/dashboard`)
                    ? "active"
                    : ""
                    }`}
                >
                  <img
                    src={PagesIndex.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Dashboard
                </Index.Link>
              </Index.ListItem>

              {roleName === "Agency" ||
                roleName === "Department" ||
                AgencyPermission?.Jobs?.value === true ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to={`/${roleName.replace(/ /g, '-')}/agency-job-management`}
                    className={`admin-sidebar-link ${location.pathname.includes(
                      `/${roleName}/agency-job-management`
                    )
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.jobType}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Job Management
                  </Index.Link>
                </Index.ListItem>
              ) : null}

              {/* {roleName === "Agency" ||
                roleName === "Department" ||
                AgencyPermission?.Officer?.value === true ? ( */}
              {(roleName === "Agency" ||
                roleName === "Department" ||
                AgencyPermission?.Officer?.permission?.add ||
                AgencyPermission?.Officer?.permission?.edit ||
                AgencyPermission?.Officer?.permission?.delete ||
                AgencyPermission?.Officer?.permission?.read ||
                AgencyPermission?.Officer?.permission?.Suspend) && (
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      to={`/${roleName.replace(/ /g, '-')}/officer-management`}
                      className={`admin-sidebar-link ${location.pathname.includes(
                        `/${roleName}/officer-management`
                      )
                        ? "active"
                        : ""
                        }`}
                    >
                      <img
                        src={PagesIndex.Svg.jobmanagement}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Officer Management
                    </Index.Link>
                  </Index.ListItem>
                )}

              {roleName === "Agency" || roleName === "Department" ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to={`/${roleName.replace(/ /g, '-')}/agency-usermanage`}
                    className={`admin-sidebar-link ${location.pathname.includes(
                      `/${roleName}/agency-usermanage`
                    )
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.system}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    System Agency Management
                  </Index.Link>
                </Index.ListItem>
              ) : null}

              {/* {roleName === "Agency" ||
                roleName === "Department" ||
                AgencyPermission?.Extra_Work_Premit?.value === true ? ( */}
              {(roleName === "Agency" ||
                roleName === "Department" ||
                AgencyPermission?.Extra_Work_Premit?.permission?.add ||
                AgencyPermission?.Extra_Work_Premit?.permission?.edit ||
                AgencyPermission?.Extra_Work_Premit?.permission?.delete ||
                AgencyPermission?.Extra_Work_Premit?.permission?.read ||
                AgencyPermission?.Extra_Work_Premit?.permission?.Suspend) && (
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      // to="/agency/extra-work-permit-requests"
                      to={`/${roleName.replace(/ /g, '-')}/work-policy`}
                      className={`admin-sidebar-link ${location.pathname.includes(`/${roleName}/work-policy`)
                        ? "active"
                        : ""
                        }`}
                    >
                      <img
                        src={PagesIndex.Svg.work}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Work Policy
                    </Index.Link>
                  </Index.ListItem>
                )}

              {(roleName === "Agency" ||
                roleName === "Department" ||
                AgencyPermission?.job_Transfer?.permission?.add ||
                AgencyPermission?.job_Transfer?.permission?.edit) && (
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      // to="/agency/extra-work-permit-requests"
                      to={`/${roleName.replace(/ /g, '-')}/transfer-job`}
                      className={`admin-sidebar-link ${location.pathname.includes(`/${roleName}/transfer-job`)
                        ? "active"
                        : ""
                        }`}
                    >
                      <img
                        src={PagesIndex.Svg.transfer}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Transfer Job
                    </Index.Link>
                  </Index.ListItem>
                )}

              {/* {roleName === "Agency" ||
                roleName === "Department" ||
                AgencyPermission?.Client?.value === true ? ( */}
              {(roleName === "Agency" ||
                roleName === "Department" ||
                AgencyPermission?.Client?.permission?.add ||
                AgencyPermission?.Client?.permission?.edit ||
                AgencyPermission?.Client?.permission?.delete ||
                AgencyPermission?.Client?.permission?.read ||
                AgencyPermission?.Client?.permission?.Suspend) && (
                  <Index.ListItem className="admin-sidebar-listitem">
                    <Index.Link
                      // to="/agency/vendor-management"
                      to={`/${roleName.replace(/ /g, '-')}/vendor-management`}
                      className={`admin-sidebar-link ${location.pathname.includes(
                        `/${roleName}/vendor-management`
                      )
                        ? "active"
                        : ""
                        }`}
                    >
                      <img
                        src={PagesIndex.Svg.client}
                        alt="sidebar icon"
                        className="admin-sidebar-icons"
                      />
                      Vendor Management
                    </Index.Link>
                  </Index.ListItem>
                )}

              {/* { roleName === "Agency" ||
              AgencyPermission?.payment?.value === true ? (
                  <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to="/agency/agency-payment-management"
                    className={`admin-sidebar-link ${
                      location.pathname.includes(
                        "/agency/agency-payment-management"
                      )
                        ? "active"
                        : ""
                    }`}
                  >
                    <img
                      src={PagesIndex.Svg.payment}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Payment Management
                  </Index.Link>
                </Index.ListItem>
                  ) : null }  */}

              {roleName === "Agency" ||
                AgencyPermission?.rating?.value === true ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    // to="/agency/rating-review-management"
                    to={`/${roleName.replace(/ /g, '-')}/rating-review-management`}
                    className={`admin-sidebar-link ${location.pathname.includes(
                      "/agency/rating-review-management"
                    )
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.star}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Rating & Review Management
                  </Index.Link>
                </Index.ListItem>
              ) : null}

              {roleName === "Agency" || roleName === "Department" ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to={`/${roleName}/role`}
                    className={`admin-sidebar-link ${location.pathname.includes(`/${roleName}/role`)
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.role}
                      alt="sidebar icon"
                      className="admin-sidebar-icons"
                    />
                    Role Management
                  </Index.Link>
                </Index.ListItem>
              ) : null}


              {roleName === "Agency" || roleName === "Department" ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to={`/${roleName}/profile`}
                    className={`admin-sidebar-link ${location.pathname.includes(`/${roleName}/profile`)
                      ? "active"
                      : ""
                      }`}
                  >
                    <img
                      src={PagesIndex.Svg.profileConfig}
                      alt="sidebar icon"
                      className="admin-sidebar-icons img-filter-invert"
                    />
                    Profile
                  </Index.Link>
                </Index.ListItem>
              ) : null}


              {roleName === "Agency" || roleName === "Department" ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    to={'#'}
                    className="admin-sidebar-link"
                    onClick={() => {
                      handlePaymentConfig();
                      // handleClose();
                      // handleAgencylogout();
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.paymentConfig}
                      alt="sidebar icon"
                      className="admin-sidebar-icons img-filter-invert"
                    />
                    Payment Config
                  </Index.Link>
                </Index.ListItem>
              ) : null}

              {roleName === "Department" && paymentType == null && paymentMethods == undefined ? (
                <Index.ListItem className="admin-sidebar-listitem">
                  <Index.Link
                    // to={'#'}
                    to={`/${roleName}/payment-type`}
                    // navigate(`/${roleName}/profile`);
                    className={`admin-sidebar-link ${location.pathname.includes(`/${roleName}/payment-type`)
                      ? "active"
                      : ""
                      }`}
                    onClick={() => {
                      setPaymnetTypeModelOpen(true);
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.paymentType}
                      alt="sidebar icon"
                      className="admin-sidebar-icons img-filter-invert"
                    />
                    Payment Type
                  </Index.Link>
                </Index.ListItem>
              ) : null}


              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to={"#"}
                  className="admin-sidebar-link"
                  onClick={() => {
                    handleAgencylogout()
                  }}
                >
                  <img
                    src={PagesIndex.Svg.exit}
                    alt="sidebar icon"
                    className="admin-sidebar-icons img-filter-invert"
                  />
                  Sign Out
                </Index.Link>
              </Index.ListItem>


              {/* <PaymentType
                open={paymentTypeModelopen}
                setOpen={setPaymnetTypeModelOpen}
                handleClose={handlePaymnetTypeModelClose}
                getNotification={getNotification}
              /> */}

            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Sidebar;
