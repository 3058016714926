import React, { useEffect, useState } from "react";
import Index from "../../index";
import PagesIndex from "../../PageIndex";
import { adminResetPasswordSchema } from "../../../validation/FormikSchema";

// for custom checkbox design

export default function ResetPassword() {
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleBackButton = (event) => {
    event.preventDefault();
    window.history.pushState(null, null, window.location.href);
};

  const handleCreateNewPassword = async (values) => {
    const data = {
      id: state?.ResetId,
      newPassword: values?.newPassword,
      //   confirmPassword: values.confirmPassword,
    };

    try {
      const response = await PagesIndex.DataService.post(
        PagesIndex.Api.Admin.RESET_PASSWORD,
        data
      );
      PagesIndex.toast.success(response?.data?.message,{toastId: "customId"});
      setTimeout(() => {
        navigate("/admin");
      }, 2000);
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message,{toastId: "customId"});
    }
  };

  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main position-relative">
          {/* <PagesIndex.AuthBackground /> */}
          <Index.Box className="login-left-bg "></Index.Box>
          <Index.Box className="login-overlay"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <PagesIndex.Formik
            validationSchema={adminResetPasswordSchema}
            initialValues={{
              newPassword: "",
            }}
            onSubmit={handleCreateNewPassword}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="admin-login-box">
                  <Index.Box className="admin-login-main">
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Reset Password
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Please enter your new password here.
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          New Password
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.OutlinedInput
                            className="form-control-eye"
                            autocomplete="off"
                            id="outlined-adornment-password"
                            placeholder="New Password"
                            type={showPassword ? "text" : "password"}
                            name="newPassword"
                            value={values.newPassword}
                            onChange={(e) => {
                              const newValue = e.target.value
                                .replace(/\s/g, "")
                                .trim();
                              setFieldValue("newPassword", newValue);
                              setFieldTouched("newPassword", true);
                            }}
                            onBlur={handleBlur}
                            endAdornment={
                              <Index.InputAdornment position="end">
                                <Index.IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {!showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                            error={
                              errors.newPassword && touched.newPassword
                                ? true
                                : false
                            }
                          />
                        </Index.Box>
                        {errors.newPassword && touched.newPassword && (
                          <Index.FormHelperText
                            error
                            id="outlined-adornment-newPassword"
                            className="error-text"
                          >
                            {errors.newPassword}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                        >
                          Reset password 
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}
