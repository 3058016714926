import React, { useEffect, useState } from "react";
import Index from "../../..";
import PagesIndex from "../../../PageIndex";

import { getSkillsData } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import AddSkillsModal from "./skills-modal/AddSkillsModal";
import SkillsDeleteModal from "./skills-modal/SkillsDeleteModal";
import Loader from "../../../../common/loader/Loader";

const SkillsManagement = (props) => {
  const [isActive, setActive] = useState(Array(0).fill(false));
  const dispatch = PagesIndex.useDispatch();
  const [loading, setLoading] = useState(false);
  const [skillsData, setSkillsData] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  //Getting data from redux

  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  const SubAdminPermission = adminLoginData?.roleId?.SubAdminPermission;
  // const roleName = adminLoginData?.name;
  const roleName = adminLoginData?.roleId?.Permission_name;

  // Add user modal
  const [addSkillsopen, setAddSkillsopen] = useState(false);
  const [editSkillsData, setEditSkillsData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const handleAddSkillsOpen = () => setAddSkillsopen(true);
  const handleAddSkillsClose = () => {
    setAddSkillsopen(false);
    setEditSkillsData();
  };

  // Delete functionality
  const [deleteSkillsId, setDeleteSkillsId] = useState([]);
  const [skillsOpenDelete, setSkillsOpenDelete] = useState(false);
  const handleOpenSkillsDelete = () => setSkillsOpenDelete(true);
  const handleCloseSkillsDelete = () => setSkillsOpenDelete(false);

  // get user list
  const getSkillsList = () => {
    setLoading(true);
    dispatch(getSkillsData()).then((res) => {
      if (res?.payload?.status == 200) {
        setSkillsData(res?.payload?.data);
        setFilteredData(res?.payload?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getSkillsList();
  }, []);

  // Search on table
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const filteredList = skillsData?.filter((data) =>
      `${data.skill.toLowerCase()}`.includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredList);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Index.Box>
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title"
              component="h2"
              variant="h2"
            >
              {/* <Index.Link to="/admin/dashboard" className="filter-back-link">
                <img
                  src={PagesIndex.Svg.leftArrow}
                  className="left-arrow-ic"
                  alt="search grey img"
                ></img>
              </Index.Link>{" "} */}
              Skill Management
            </Index.Typography>

            <Index.Box className="userlist-btn-flex">
              {/* Top search */}
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      value={searchValue}
                      onChange={handleSearch}
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.value.trim() === "") {
                          e.preventDefault();
                        }
                      }}
                    />
                    <img
                      src={PagesIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {/* Add user Button */}
              <Index.Box className="userlist-inner-btn-flex">
                <Index.Box className="adduser-btn-main btn-main-primary">
                  {roleName === "Admin" ||
                  SubAdminPermission?.Skills?.permission?.add == true ? (
                    <Index.Button
                      className="adduser-btn btn-primary"
                      onClick={() => handleAddSkillsOpen()}
                    >
                      <img
                        src={PagesIndex.Svg.plus}
                        className="plus-icon"
                        alt="plus icon"
                      />
                      Add Skill
                    </Index.Button>
                  ) : null}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Zip code list */}
          <Index.Box className="skill-mamangement-sec">
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <Index.Box className="card-main">
                  <Index.Box className="card-left">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="job-title-label"
                    >
                      {item?.skill}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="card-right btn-main-secondary btn-main-red">
                    <Index.Box
                      id="btn-toggle-popup"
                      className={isActive[index] ? "toggle-pop-show" : null}
                    >
                      {roleName === "Admin" ||
                      SubAdminPermission?.Skills?.permission?.edit == true ? (
                        <Index.Tooltip
                          title="Edit"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            btnLabel="Edit"
                            className="btn-secondary"
                            onClick={() => {
                              handleAddSkillsOpen();
                              setEditSkillsData(item);
                            }}
                          >
                            <img
                              src={PagesIndex.Svg.editNew}
                              alt="delete"
                              className="admin-icons"
                            />
                          </Index.Button>
                        </Index.Tooltip>
                      ) : null}

                      {roleName === "Admin" ||
                      SubAdminPermission?.Skills?.permission?.delete == true ? (
                        <Index.Tooltip
                          title="Delete"
                          arrow
                          placement="bottom"
                          className="admin-tooltip"
                        >
                          <Index.Button
                            btnLabel="delete"
                            className="btn-red"
                            onClick={() => {
                              handleOpenSkillsDelete();
                              setDeleteSkillsId(item?._id);
                            }}
                          >
                            <img
                              src={PagesIndex.Svg.deletewhite}
                              alt="delete"
                              className="admin-icons"
                            />
                          </Index.Button>
                        </Index.Tooltip>
                      ) : null}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              ))
            ) : (
              <PagesIndex.NoDataFound />
            )}
          </Index.Box>
        </Index.Box>
      )}

      {/* Add job type modal */}

      <AddSkillsModal
        getSkillsList={getSkillsList}
        handleAddSkillsClose={handleAddSkillsClose}
        addSkillsopen={addSkillsopen}
        editSkillsData={editSkillsData}
      />

      {/* delete job type modal */}
      <SkillsDeleteModal
        handleCloseSkillsDelete={handleCloseSkillsDelete}
        skillsOpenDelete={skillsOpenDelete}
        getSkillsList={getSkillsList}
        deleteSkillsId={deleteSkillsId}
      />
    </div>
  );
};

export default SkillsManagement;
