import React from "react";
import Index from "../../index";
import PagesIndex from "../../PageIndex";

export default function ForgotPassword() {
  const navigate = PagesIndex.useNavigate();

  const handleAdminForgotPassword = async (values) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("email", values?.email.toLowerCase());
    try {
      const response = await PagesIndex.DataService.post(
        PagesIndex.Api.Admin.FORGOT_PASSWORD,
        urlEncoded
      );

      const ResetId = response?.data?.data?.id;
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message,{toastId: "customId"});
        setTimeout(() => {
          navigate("/admin/otp-verification", { state: { ResetId, email: values?.email.toLowerCase() } });
        }, 2000);
      }
    } catch (error) {
      console.log(error?.response?.data?.status, "error");
      if (error?.response?.data?.status == 400) {
        PagesIndex.toast.error(error?.response?.data?.message,{toastId: "customId"});
      }
    }
  };

  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main position-relative">
          {/* <PagesIndex.AuthBackground /> */}
          <Index.Box className="login-left-bg "></Index.Box>
          <Index.Box className="login-overlay"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <PagesIndex.Formik
            validationSchema={PagesIndex.adminForgotPasswordSchema}
            initialValues={{
              email: "",
            }}
            onSubmit={handleAdminForgotPassword}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="admin-login-box">
                  <Index.Box className="admin-login-main">
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        Forgot Password
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Enter your email and we'll send you a link to reset your
                        password
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.FormHelperText className="form-lable">
                          Email Address
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Enter Email Address"
                            autocomplete="off"
                            name="email"
                            value={values.email}
                            onChange={(e) => {
                              const newValue = e.target.value
                                .replace(/\s/g, "")
                                .trim();
                              setFieldValue("email", newValue);
                              // setFieldTouched("email", true);
                            }}
                            onBlur={handleBlur}
                            error={errors.email && touched.email ? true : false}
                            helperText={
                              errors.email && touched.email
                                ? errors.email
                                : null
                            }
                          />
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                        >
                          Send OTP
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}
