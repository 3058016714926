import React from "react";
import Index from "../../src/container/index";
import PagesIndex from "../container/PageIndex";
import "../assets/css/style.css";
import DataService from "../config/DataService";
import { Api } from "../config/Api";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const DeleteModal = (props) => {
  const {
    handleDeleteClose,
    handleDeleteRecord,
    deleteOpen,
    getRole,
    deleteRoleId,
  } = props;

  console.log(deleteRoleId, "deleteRoleId")
  const handleRoleDelete = async () => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", deleteRoleId);
    try {
      const response = await DataService.post(Api.Admin.ROLE_MASTER_DELETE_PERMISSION, urlEncoded);
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message, { toastId: "customId" });
        handleDeleteClose();
        getRole();
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, { toastId: "customId" });
    }
  };

  const handleRoleDeleteConfirm = () => {
    handleRoleDelete();
  };

  return (
    <Index.Modal
      open={deleteOpen}
      onClose={handleDeleteClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-delete modal"
    >
      <Index.Box
        sx={style}
        className="add-user-modal-inner-main modal-inner extra-delete"
      >
        <Index.Box className="modal-header">
          <Index.Typography
            id="modal-modal-title"
            className="modal-title"
            variant="h6"
            component="h2"
          > Delete Role
          </Index.Typography>
          <img
            src={PagesIndex.Svg.closeblack}
            className="modal-close-icon"
            onClick={handleDeleteClose}
            alt=""
          />
        </Index.Box>
        <Index.Box className="modal-body">
          <Index.Box className="admin-modal-hgt-scroll">
            <Index.Box className="delete-modal-body">
              <img
                src={PagesIndex.Svg.deletecross}
                alt="Delete"
                className="delete-icon"
              />
              <Index.Typography component="h6" className="delete-title">Are you sure?</Index.Typography>
              <Index.Typography component="p" className=" delete-message">
                Do you really want to delete this role?
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="modal-footer">
          <Index.Box className="modal-footer-btn-flex">
            <Index.PrimaryButton
              className="btn-primary bg-approve"
              btnLabel="Delete"
              // onClick={props.handleDeleteClose}
              onClick={() => handleRoleDeleteConfirm()}
            />

            <Index.PrimaryButton
              // className="modal-delete-btn modal-btn"
              className="btn-primary bg-suspend appr-rej-btn"
              btnLabel="Cancel"
              onClick={handleDeleteRecord}
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Modal>
  );
};

export default DeleteModal;
