import React, { useState } from "react";
import PagesIndex from "../../../PageIndex";
import { adminchangePasswordSchema } from "../../../../validation/FormikSchema";
import Index from "../../..";
import { Navigate } from "react-router-dom";

export const AdminPasswordChangeModal = ({ addnewopen, addnewhandleClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const navigate = PagesIndex.useNavigate();

  const handleClickShowOldPassword = () => {
    setShowOldPassword((show) => !show);
  };
  const handleClickShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const changeAdminPassword = async (values) => {
    const newObj = {
      oldPassword: values?.oldPassword,
      newPassword: values?.newPassword,
    };

    try {
      const response = await PagesIndex.DataService.post(
        PagesIndex.Api.Admin.ADMIN_CHANGE_PASSWORD,
        newObj
      );
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message);
        setTimeout(() => {
          addnewhandleClose();
          localStorage.removeItem("AdminToken");
          navigate("/admin/"); 
        }, 1000);

        
      }
    } catch (error) {
      console.log("respon", error?.response?.data);

      if (error?.response?.data?.status == 400) {
        PagesIndex.toast.error(error?.response?.data?.message);
      } else if (error?.response?.data?.status == 409) {
        PagesIndex.toast.error(error?.response?.data?.message);
      }
    }
  };
  return (
    <div>
      <Index.Modal
        open={addnewopen}
        onClose={addnewhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              Change Password
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={addnewhandleClose}
              alt=""
            />
          </Index.Box>
          <PagesIndex.Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={adminchangePasswordSchema}
            onSubmit={changeAdminPassword}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                {/* {console.log(errors, "error")} */}
                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box ">
                    <Index.FormHelperText className="form-lable">
                      Enter Old Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group pwd-icon-btn">
                      <Index.OutlinedInput
                        fullWidth
                        id="fullWidth"
                        className="form-control-eye"
                        name="oldPassword"
                        placeholder="Please enter old password"
                        onBlur={handleBlur}
                        value={values.oldPassword}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(/\s/g, "");
                          setFieldValue("oldPassword", newValue);
                          setFieldTouched("oldPassword", true);
                        }}
                        inputProps={{ maxLength: 16 }}
                        helperText={touched.oldPassword && errors.oldPassword}
                        error={Boolean(
                          errors.oldPassword && touched.oldPassword
                        )}
                        type={showOldPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowOldPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {!showOldPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    {touched.oldPassword && errors.oldPassword && (
                      <Index.FormHelperText error>
                        {errors.oldPassword}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box modal-password-border">
                    <Index.FormHelperText className="form-lable">
                      Enter New Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group pwd-icon-btn">
                      <Index.OutlinedInput
                        fullWidth
                        id="fullWidth"
                        className="form-control-eye"
                        placeholder="Please enter new password"
                        name="newPassword"
                        onBlur={handleBlur}
                        value={values.newPassword}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(/\s/g, "");
                          setFieldValue("newPassword", newValue);
                          setFieldTouched("newPassword", true);
                        }}
                        inputProps={{ maxLength: 16 }}
                        helperText={touched.newPassword && errors.newPassword}
                        error={Boolean(
                          errors.newPassword && touched.newPassword
                        )}
                        type={showNewPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowNewPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {!showNewPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    {touched.newPassword && errors.newPassword && (
                      <Index.FormHelperText error>
                        {errors.newPassword}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box modal-password-border">
                    <Index.FormHelperText className="form-lable">
                      Re-enter New Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group pwd-icon-btn">
                      <Index.OutlinedInput
                        fullWidth
                        id="fullWidth"
                        className="form-control-eye"
                        placeholder="Please re-enter new password"
                        name="confirmPassword"
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(/\s/g, "");
                          setFieldValue("confirmPassword", newValue);
                          setFieldTouched("confirmPassword", true);
                        }}
                        inputProps={{ maxLength: 16 }}
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        error={Boolean(
                          errors.confirmPassword && touched.confirmPassword
                        )}
                        type={showConfirmPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {!showConfirmPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                    </Index.Box>
                    {touched.confirmPassword && errors.confirmPassword && (
                      <Index.FormHelperText error>
                        {errors.confirmPassword}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="modal-footer">
                  <Index.Box className="modal-user-btn-flex mt-15">
                    <Index.Box className="discard-btn-main mr-10">
                      <Index.PrimaryButton
                        className="btn-primary bg-approve"
                        btnLabel="Update"
                        type="submit"
                      />
                    </Index.Box>
                    <Index.Box className="save-btn-main border-btn-main">
                      <Index.PrimaryButton
                        className="btn-primary bg-suspend"
                        btnLabel="Cancel"
                        onClick={addnewhandleClose}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Modal>
    </div>
  );
};
