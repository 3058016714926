import axios from "axios";

// const API_ENDPOINT = "http://35.177.56.74:3033/api/"; // live
const API_ENDPOINT = "https://api.protekt-security.appworkdemo.com/api/"; // live https
// const API_ENDPOINT = "http://localhost:3033/api/"; //Local ujjwal 
// const API_ENDPOINT = "http://192.168.1.24:3033/api/"; //Digu  
// const API_ENDPOINT = "http://192.168.1.76:3033/api/"; //fatma 
//export const ADMIN_IMAGE_ENDPOINT = "http://192.168.29.237:3033/images/"; // local
export const ADMIN_IMAGE_ENDPOINT = "https://api.protekt-security.appworkdemo.com/images/"; // live https


const DataService = axios.create({
  baseURL: API_ENDPOINT,
  // headers: {
  //   "Content-type": "application/x-www-form-urlencoded",
  //   Accept: "application/json",

  // },

});

// DataService.interceptors.request.use(
//   async function (config) {
//     try {
//       const userToekn = localStorage.getItem("token");
//       console.log("usertoken", userToekn);
//       config.headers.authorization = userToekn;
//     } catch (error) {
//       console.log(error);
//     }
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );



export default DataService;
