import React from "react";

import { Outlet } from "react-router-dom";
import PagesIndex from "../../container/PageIndex";

const AgencyPublicLayout = ({ children }) => {
  const location = PagesIndex.useLocation();
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  const roleName = agencyLoginData?.roleId?.Permission_name;

  const isAgencyLoggedIn = localStorage.getItem("AgencyToken");

  if (agencyLoginData?.isPasswordChanges !== undefined && agencyLoginData?.isPasswordChanges) {
    PagesIndex.DataService.defaults.headers.common.auth = isAgencyLoggedIn;
  }
  return agencyLoginData?.isPasswordChanges !== undefined && agencyLoginData?.isPasswordChanges || !isAgencyLoggedIn ? (
    <Outlet />
  ) : (
    <PagesIndex.Navigate
      to={`/${roleName?.replace(/ /g, "-")}/dashboard`}
      state={{ from: location }}
      replace={true}
    />
  );

};

export default AgencyPublicLayout;
