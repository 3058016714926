import logo from "./images/png/logo-img.png";
import usericon from "./images/png/user.png";
import avataricon from "./images/png/avatar.png";
import zipCode from "./images/png/zip-code.png";
import job from "./images/png/job (1).png";
import skill from "./images/png/settings.png"
import client from "./images/png/collaboration.png";


export default {
  logo,
  usericon,
  avataricon,
  zipCode,
  job,
  skill,
  client
 
};

// export default Png;
