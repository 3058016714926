import React, { useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import Loader from "../../../../../common/loader/Loader";
import { suspendAgencyDateValidationSchema } from "../../../../../validation/FormikSchema";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const AgencyOfficerSuspendModal = (props) => {
  const {
    suspendhandleClose,
    suspendopen,
    officerData,
    getAgencyOfficerListData,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { agencyLoginData} = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );
  console.log(agencyLoginData,"agencyLoginData")
  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;
  const navigate = PagesIndex.useNavigate();

  const changeStatus = (values) => {
    setIsLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", officerData?._id);
    urlencoded.append(
      "status",
      officerData?.status === "ACTIVATE" ? "SUSPEND" : "ACTIVATE"
    );
    if (values?.startDate && values?.endDate) {
      urlencoded.append(
        "suspendStartDate",
        PagesIndex.moment(new Date(values?.startDate)).format("MM-DD-YYYY")
      );
      urlencoded.append(
        "suspendEndDate",
        PagesIndex.moment(new Date(values?.endDate)).format("MM-DD-YYYY")
      );
    }
    PagesIndex.DataService.post(
      PagesIndex.Api.Agency.CHANGE_OFFICER_STATUS,
      urlencoded
    ).then(({ data }) => {
      suspendhandleClose();
      navigate(`/${roleName}/officer-management`, { state: {tabValue: "ACCEPTED" } });
      // getAgencyOfficerListData();
      PagesIndex.toast.success(data.message);
      setIsLoading(false);
     
    });
  };
  return (
    <>
      <Index.Modal
        open={suspendopen}
        onClose={suspendhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box
          sx={style}
          className="add-user-modal-inner-main modal-inner extra-delete"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              {officerData.status === "ACTIVATE" ? "Suspend" : "Activate"}{" "}
              Officer
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={suspendhandleClose}
              alt=""
            />
          </Index.Box>
          <PagesIndex.Formik
            enableReinitialize={true}
            validationSchema={
              officerData.status === "ACTIVATE"
                ? suspendAgencyDateValidationSchema
                : ""
            }
            initialValues={{
              startDate: "",
              endDate: "",
            }}
            onSubmit={changeStatus}
            // innerRef={formik}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="modal-body">
                  {officerData.status === "SUSPEND" ? (
                    <Index.Box className="admin-modal-hgt-scroll">
                      <Index.Box className="delete-modal-body">
                        <Index.Typography
                          component="h6"
                          className="delete-title"
                        >
                          Are you sure?
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          className=" delete-message"
                        >
                          Do you really want to activate this officer?
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  ) : (
                    <Index.Box className="job-title-text">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        Officer Name :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-text-label"
                      >
                        {officerData?.firstName} {officerData?.lastName}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  {officerData.status === "ACTIVATE" ? (
                    <>
                      <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className="form-lable">
                          Suspend From :&nbsp;
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.Box className="cus-datepicker">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                            >
                              <Index.MobileDatePicker
                                value={
                                  values?.startDate ? dayjs(values?.startDate) : null
                                }
                                disablePast={true}
                                maxDate={values?.endDate ? dayjs(values?.endDate) : null}
                                onChange={(e) => {
                                  console.log(new Date(e), "startDate");
                                  setFieldValue("startDate", e);
                                }}
                                format="MM-DD-YYYY"
                              />
                            </LocalizationProvider>
                               <Index.FormHelperText error>
                        {errors.startDate && touched.startDate
                          ? errors.startDate
                          : null}
                      </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className="form-lable">
                        Suspend To :&nbsp;
                        </Index.FormHelperText>
                        {console.log(values?.endDate , ":values?.endDate")}
                        <Index.Box className="form-group">
                          <Index.Box className="cus-datepicker">
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                            >
                              <Index.MobileDatePicker
                                // value={values?.endDate}
                                value={
                                  values?.endDate ? dayjs(values?.endDate) : null
                                }
                                disablePast={true}
                                // minDate={PagesIndex.dayjs()}
                                minDate={values?.startDate ? dayjs(values?.startDate) : null}
                                onChange={(e) => {
                                  setFieldValue("endDate", e);
                                }}
                                format="MM-DD-YYYY"
                              
                              />
                            </LocalizationProvider>
                               <Index.FormHelperText error>
                        {errors.endDate && touched.endDate
                          ? errors.endDate
                          : null}
                      </Index.FormHelperText>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </>
                  ) : (
                    ""
                  )}
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.Box class="btn-main-primary">
                      <Index.PrimaryButton
                        btnLabel="Confirm"
                        type="submit"
                        className="btn-primary bg-approve"
                        disabled={isLoading}
                      >
                        {isLoading ? <Loader /> : "Click Me"}
                      </Index.PrimaryButton>
                    </Index.Box>
                    <Index.Box class="btn-main-primary">
                      <Index.PrimaryButton
                        btnLabel="Cancel"
                        className="btn-primary bg-suspend"
                        onClick={suspendhandleClose}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AgencyOfficerSuspendModal;
