import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import Loader from "../../../../common/loader/Loader";
import PagesIndex from "../../../PageIndex";
import Index from '../../..';
import { Link } from 'react-router-dom';

const DynamicTabPanel = ({ value, index, loading, data, className, tabNumber }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={className}
    >
      {value === index && (
        <Box>
          {loading ? (
            <Loader />
          ) : (
            <Box className={`tabpanel-main tabpanel-${tabNumber}`}>
              {data.length ? (
                <Index.Box className="page-table-main payment-config-table-main">
                  <TableContainer component={Paper} className="table-container">
                    <Table aria-label="simple table" className="table">
                      <TableHead className="table-head">
                        <TableRow className="table-row">
                          <TableCell align="left" component="th" variant="th" className="table-th">Transaction Id</TableCell>
                          <TableCell align="left" component="th" variant="th" className="table-th">Job Id</TableCell>
                          <TableCell align="left" component="th" variant="th" className="table-th">Job Title</TableCell>
                          <TableCell align="left" component="th" variant="th" className="table-th">Transaction From</TableCell>
                          <TableCell align="left" component="th" variant="th" className="table-th">Transaction To</TableCell>
                          <TableCell align="left" component="th" variant="th" className="table-th">Amount</TableCell>
                          <TableCell align="left" component="th" variant="th" className="table-th">Email</TableCell>
                          <TableCell align="left" component="th" variant="th" className="table-th">Status</TableCell>
                          <TableCell align="left" component="th" variant="th" className="table-th">Date & Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.map((item, idx) => (
                          console.log("item", item),
                          value == "enduserpayment" ? <EndUserPayment key={idx} item={item} /> : value == "paymenttodepartment" ? 
                            <DepartmentHistory key={idx} item={item} /> : value == "paymenttoagency" ?  <AgencyHistory key={idx} item={item} /> : value =="paymenttoofficers" ? <OfficerHistory key={idx} item={item} /> : null
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Index.Box>
              ) : (
                <PagesIndex.NoDataFound />
              )}
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

const EndUserPayment = ({ item }) => (
  <>
    <TableRow>
      <TableCell className='table-td'>{item?.paymentIntentId}</TableCell>
      <TableCell className='table-td'>
      {/* <Link to={`/admin/adminjobmanagement/667e494d3a8a386cd911df10`}> */}
        {/* {item?.jobInfo[0]?._id} */}
        {item?.jobInfo[0]?.aliasJobId}
      {/* </Link> */}
    </TableCell>
      {/* <TableCell className='table-td'>{item?.jobInfo[0]?._id}</TableCell> */}
      <TableCell className='table-td'>{item?.jobInfo[0]?.title}</TableCell>
      <TableCell className='table-td'>{Array.isArray(item.clientInfo) ? item?.clientInfo[0]?.name : ""}</TableCell>
      <TableCell className='table-td'>{item?.adminInfo}</TableCell>
      <TableCell className='table-td'>{item?.amount}</TableCell>
      <TableCell className='table-td'>{Array.isArray(item?.clientInfo) ? item.clientInfo[0]?.email : ""}</TableCell>
      <TableCell className='table-td'>{item?.status}</TableCell>
      <TableCell className='table-td'>{PagesIndex.moment(item.createdAt).utc("+05:30").format("MM-DD-YYYY hh:mm A") || "-"}</TableCell>
    </TableRow>
  </>
);


const DepartmentHistory = ({ item }) => (
  <TableRow>
    <TableCell className='table-td'>{item?.transferId}</TableCell>
    <TableCell>{Array.isArray(item?.jobInfo) ? item.jobInfo[0]?.aliasJobId : ""}</TableCell>
    <TableCell>{Array.isArray(item?.jobInfo) ? item?.jobInfo[0]?.title : ""}</TableCell>
    <TableCell className='table-td'>{item?.adminInfo}</TableCell>
    <TableCell>{Array.isArray(item?.agencyInfo) ? item.agencyInfo[0]?.agencyName : ""}</TableCell>
    <TableCell className='table-td'>{item?.amount}</TableCell>
    <TableCell>{Array.isArray(item?.agencyInfo) ? item?.agencyInfo[0]?.email : ""}</TableCell>
    <TableCell className='table-td'>{item?.status}</TableCell>
    <TableCell className='table-td'>{PagesIndex.moment(item.createdAt).utc("+05:30").format("MM-DD-YYYY hh:mm A") || "-"}</TableCell>
  </TableRow>
);

const AgencyHistory = ({ item }) => ( 
  <TableRow>
    <TableCell className='table-td'>{item?.transferId}</TableCell>
    <TableCell>{Array.isArray(item?.jobInfo) ? item.jobInfo[0]?.aliasJobId : ""}</TableCell>
    <TableCell>{Array.isArray(item?.jobInfo) ? item?.jobInfo[0]?.title : ""}</TableCell>
    <TableCell className='table-td'>{item?.adminInfo}</TableCell>
    <TableCell>{Array.isArray(item?.agencyInfo) ? item.agencyInfo[0]?.agencyName : ""}</TableCell>
    <TableCell className='table-td'>{item?.amount}</TableCell>
    <TableCell>{Array.isArray(item?.agencyInfo) ? item?.agencyInfo[0]?.email : ""}</TableCell>
    <TableCell className='table-td'>{item?.status}</TableCell>
    <TableCell className='table-td'>{PagesIndex.moment(item.createdAt).utc("+05:30").format("MM-DD-YYYY hh:mm A") || "-"}</TableCell>
  </TableRow>
);

const OfficerHistory = ({ item }) => (
  <TableRow>
    <TableCell className='table-td'>{item?.transferId}</TableCell>
    <TableCell>{Array.isArray(item?.jobInfo) ? item.jobInfo[0]?.aliasJobId : ""}</TableCell>
    <TableCell>{Array.isArray(item?.jobInfo) ? item?.jobInfo[0]?.title : ""}</TableCell>
    <TableCell className='table-td'>{item?.adminInfo}</TableCell>
    <TableCell>{Array.isArray(item?.officerInfo) ? item.officerInfo[0]?.firstName + " " + item.officerInfo[0]?.lastName : ""}</TableCell>
    <TableCell className='table-td'>{item?.amount}</TableCell>
    <TableCell>{Array.isArray(item?.officerInfo) ? item?.officerInfo[0]?.email : ""}</TableCell>
    <TableCell className='table-td'>{item?.status}</TableCell>
    <TableCell className='table-td'>{PagesIndex.moment(item.createdAt).utc("+05:30").format("MM-DD-YYYY hh:mm A") || "-"}</TableCell>
  </TableRow>
);

export default DynamicTabPanel;
