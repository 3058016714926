import React from "react";
import Index from "../../index";
import OTPInput from "react-otp-input";
import PagesIndex from "../../PageIndex";
import "./auth.style.css";
import DataService from "../../../config/DataService";
import { Api } from "../../../config/Api";
import { AgencyotpSchema } from "../../../validation/FormikSchema";

const AgencyOtpVerifiy = () => {
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();

  const handleAdminOtpVerification = async (values) => {
    console.log(values, "values");
    const data = {
      id: state.AgencyId,
      otp: values.otp,
    };
    try {
      const response = await DataService.post(
        Api.Agency.AGENCY_VERIFY_OTP,
        data
      );
      console.log(response?.data?.data, "respose");
      PagesIndex.toast.success(response?.data?.message);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main position-relative">
          {/* <PagesIndex.AuthBackground />  */}
          <Index.Box className="login-left-bg "></Index.Box>
          <Index.Box className="login-overlay"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <PagesIndex.Formik
            validationSchema={AgencyotpSchema}
            initialValues={{
              otp: "",
            }}
            onSubmit={handleAdminOtpVerification}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="admin-login-box">
                  <Index.Box className="admin-login-main">
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        OTP Verification
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Enter OTP
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.Box className="otp-main">
                          <Index.Box className="form-group  ">
                            <Index.Box className="form-control">
                              <OTPInput
                                name="otp"
                                numInputs={4}
                                inputStyle="otp-verification-input"
                                containerStyle="otp-verification-input-wrap"
                                value={values.otp}
                                onChange={(file) => setFieldValue("otp", file)}
                                error={Boolean(errors.otp)}
                                renderInput={(props) => <input {...props} />}
                              />
                            </Index.Box>

                            {errors.otp && touched.otp && (
                              <Index.FormHelperText error>
                                {errors.otp}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                        >
                          Next
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default AgencyOtpVerifiy;
