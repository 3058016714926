import React, { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";
import Index from "../../../index";
import PagesIndex from "../../../PageIndex";

function LayOut() {
  const [open, setOpen] = useState(true);
  return (
    <>

      <Index.Box className="dashboard-main">
        <Index.Box
          className={`dashboard-left-main ${open ? "active" : "admin-sidebar-deactive"
            }`}
        >
          <PagesIndex.Sidebar open={open} setOpen={setOpen} />
        </Index.Box>
        <Index.Box className="dashboard-right-main">
          <PagesIndex.Header setOpen={setOpen} open={open} />
          <Index.Box className="dashboard-containt-main">
            <Outlet />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default LayOut;
