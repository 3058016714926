import Svg from "../assets/svg";
import Png from "../assets/png";
import Jpg from "../assets/jpg";

import {
  Link,
  useNavigate,
  Navigate,
  useLocation,
  createSearchParams,
  useNavigationType,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";

import Sidebar from "../components/admin/defaulLayout/Sidebar";
import Header from "../components/admin/defaulLayout/Header";
import DataService, { ADMIN_IMAGE_ENDPOINT } from "../config/DataService";
import { Api } from "../config/Api";
import AgencySidebar from "../components/agency/defaulLayout/Sidebar";
import AgencyHeader from "../components/agency/defaulLayout/Header";
import { Formik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import {
  AgencyProfileSchema,
  AgencyloginSchema,
  AgencyForgotPasswordSchema,
  AgencyChangePasswordSchema,
  // adminschema
  adminLoginSchema,
  adminProfileUpdateSchema,
  adminForgotPasswordSchema,
  roleMasterValidationSchema,
  roleMasterAgencyValidationSchema,
  adminOtpVerfiySchema,
  AgencyAddOfficerSchema,
  adminAddOfficerSchema,
  adminchangePasswordSchema,
  suspendDateValidationSchema,
} from "../validation/FormikSchema";
import NoDataFound from "../common/noDataFound/noDataFound";
import Loader from "../common/loader/Loader";
import moment from "moment";
import dayjs from "dayjs";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CostCalculateByTime } from "../common/timeCalculation/CostCalculateByTime";
import { timeDifferenceCalculation } from "../common/timeCalculation/CalculateTimeDifference";
import {TotalCalculateByTime} from "../common/timeCalculation/CalculateCostByTime";
import {showDateTime} from "../common/dateTime/DateTime"
import ChatLoader from "../common/loader/ChatLoader";
import { useParams } from "react-router-dom";
const PagesIndex = {
  Svg,
  Png,
  Jpg,
  DataService,
  useParams,
  Api,
  Link,
  dayjs,
  useSelector,
  useDispatch,
  useLocation,
  useNavigate,
  useNavigationType,
  Navigate,
  useEffect,
  toast,
  ToastContainer,
  MuiOtpInput,
  Formik,
  CircularProgress,
  Sidebar,
  Header,
  AgencySidebar,
  AgencyHeader,
  NoDataFound,
  Loader,
  ClassicEditor,
  CKEditor,
  ChatLoader,
  
  
  adminLoginSchema,
  adminForgotPasswordSchema,
  roleMasterValidationSchema,
  roleMasterAgencyValidationSchema,
  adminOtpVerfiySchema,
  adminAddOfficerSchema,
  adminchangePasswordSchema,
  adminProfileUpdateSchema,
  
  AgencyProfileSchema,
  AgencyloginSchema,
  AgencyForgotPasswordSchema,
  AgencyChangePasswordSchema,
  AgencyAddOfficerSchema,
  ADMIN_IMAGE_ENDPOINT,
  moment,
  createSearchParams,
  suspendDateValidationSchema,
  CostCalculateByTime,
  timeDifferenceCalculation,
  showDateTime,
  TotalCalculateByTime,
};
export default PagesIndex;
