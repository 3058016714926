import React, { useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import { getAgencyDashboardCount } from "../../../../redux-toolkit/slice/common-slice/CommonServices";
import Loader from "../../../../common/loader/Loader";

const Dashboard = () => {
  const dispatch = PagesIndex.useDispatch();
  const [dashboardCount, setDashboardCount] = useState(false);
  const [loading, setLoading] = useState(false);


  // get dashboard count
  const getDashboardCountData = () => {
    setLoading(true);
    dispatch(getAgencyDashboardCount()).then((res) => {
      if (res?.payload?.status == 200) {
        setDashboardCount(res?.payload?.data);
        setLoading(false);
      }
    });
  };
  
  PagesIndex.useEffect(() => {
    getDashboardCountData();
  }, []);
  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title admin-res-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          Dashboard
        </Index.Typography>
      </Index.Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Index.Box className="dashboard-page">
            <Index.Box className="admin-dashboad-row">
              <Index.Box sx={{ width: 1 }} className="grid-main">
                <Index.Box
                  display="grid"
                  className="display-row"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Pending Job Requests
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalPendingJobs}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Active Jobs
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalActiveJobs}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>


                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Completed Jobs
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalCompletedJobs}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>


                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Officers
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalOfficers}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 3",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-dashboard-box box-shadow">
                      <Index.Box className="admin-dashboard-inner-box">
                        <Index.Box className="admin-dash-left">
                          <Index.Typography
                            className="admin-dash-text"
                            variant="p"
                            component="p"
                          >
                            Total Unserved Jobs
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box className="admin-dash-right">
                          <Index.Box className="admin-dash-digit-bg">
                            <Index.Typography
                              className="admin-dash-price"
                              variant="h6"
                              component="h6"
                            >
                              {dashboardCount?.TotalUnservedJobs}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Report Analysis */}
          <Index.Box className="res-con report-section">
            <Index.Box
              className="card-wrape grid-column box-shadow"
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 3",
              }}
            >
            </Index.Box>
          </Index.Box>
        </>
      )}

    </>
  );
};

export default Dashboard;
