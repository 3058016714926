import React from "react";
import Index from "../../..";
import PagesIndex from "../../../PageIndex";
import { adminOfficerFilterSchema } from "../../../../validation/FormikSchema";

const FilterAdminOfficer = (props) => {
  const { dataFilterstate, filterToggleDrawer, side } = props;
  const initialValues = {
    name: "",
    city: "",
  };
  const handleAdminOfficerFilter = async (values) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("name", values.name);
    urlEncoded.append("city", values.city);
    console.log("values", values);
    // filter logic
    // setSearchValue(e.target.value);
    // const filteredList = officerListData.filter((data) =>
    //   `${data.firstName.toLowerCase()} ${data.lastName.toLowerCase()} ${
    //     data.mobileNumber
    //   }  ${data.email.toLowerCase()}`.includes(e.target.value.toLowerCase())
    // );
    // setFilterList(filteredList);
  };
  return (
    <>
      <Index.Box className="filter-main">
        {[side].map((anchor) => (
          <React.Fragment key={anchor}>
            <Index.SwipeableDrawer
              className="filter-main"
              anchor={anchor}
              open={dataFilterstate[anchor]}
              onClose={filterToggleDrawer(anchor, false)}
              onOpen={filterToggleDrawer(anchor, true)}
            >
              <Index.Box className="filter-header">
                <Index.Typography className="filter-title">
                  User Filter
                </Index.Typography>
                <img
                  src={PagesIndex.Svg.closeicon}
                  className="filter-close-icon"
                  onClick={filterToggleDrawer(anchor, false)}
                  alt=""
                />
              </Index.Box>
              <PagesIndex.Formik
                initialValues={initialValues}
                onSubmit={handleAdminOfficerFilter}
                // validationSchema={adminOfficerFilterSchema}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {/* {console.log(errors, "error")} */}
                    {/* {console.log(values, "values>>")} */}
                    <Index.Box className="filter-inner-main">
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            name="name"
                            value={values.name}
                            onChange={(e) => {
                              const newValue = e.target.value.replace(
                                /\s+/g,
                                " "
                              );
                              setFieldValue("name", newValue);
                              setFieldTouched("name", true);
                            }}
                            error={errors.name && touched.name ? true : false}
                            helperText={
                              errors.name && touched.name ? errors.name : false
                            }
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          City
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            // onChange={(e) => {
                            //   const newValue = e.target.value.replace(
                            //     /\s+/g,
                            //     " "
                            //   );
                            //   setFieldValue("city", newValue);
                            //   setFieldTouched("city", true);
                            // }}
                            error={errors.city && touched.city ? true : false}
                            helperText={
                              errors.city && touched.city ? errors.city : false
                            }
                            onBlur={handleBlur}
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Status
                        </Index.FormHelperText>
                        <Index.Box className="checkbox-main filter-checkbox-main input-box">
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            name="status"
                            value="Active"
                            onChange={handleChange}
                            label="Active"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Deactivate"
                            onChange={handleChange}
                            name="status"
                            value="Deactivate"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Pending"
                            onChange={handleChange}
                            name="status"
                            value="Pending"
                            className="checkbox-lable"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-footer">
                      <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                        <Index.Button className="border-btn filter-cancel-btn">
                          Cancel
                        </Index.Button>
                        <Index.Button
                          className="btn-primary filter-btn"
                          type="submit"
                        >
                          Filter
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </form>
                )}
              </PagesIndex.Formik>
            </Index.SwipeableDrawer>
          </React.Fragment>
        ))}
      </Index.Box>
    </>
  );
};

export default FilterAdminOfficer;
