import React, { useEffect, useState } from "react";
import Index from "../../..";
import PagesIndex from "../../../PageIndex";
import { AddNewOfficerModal } from "./officermodal/AddNewOfficerModal";
import OfficerDeleteModal from "./officermodal/OfficerDeleteModal";
import { getAdminOfficerList } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import FilterAdminOfficer from "./FilterAdminOfficer";
import OfficerSuspendModal from "./officermodal/OfficerSuspendModal";
import Loader from "../../../../common/loader/Loader";
import { useLocation } from "react-router-dom";

const OfficerManagement = () => {
  // add user modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const [loading, setLoading] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [officerListData, setOfficerListData] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [deleteOfficerId, setDeleteOfficerId] = useState([]);

  console.log(officerListData, "officerListData")
  //Getting data from redux

  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  const SubAdminPermission = adminLoginData?.roleId?.SubAdminPermission;
  // const roleName = adminLoginData?.name;
  const roleName = adminLoginData?.roleId?.Permission_name;

  // suspend and Active modal
  const [suspendopen, setSuspendOpen] = useState(false);
  const [officerData, setOfficerData] = useState({});
  const suspendhandleOpen = (data) => {
    setOfficerData(data);
    setSuspendOpen(true);
  };
  const suspendhandleClose = () => {
    setOfficerData({});
    setSuspendOpen(false);
  };

  // Delete new
  const [openDeleteNew, setOpenDeleteNew] = useState(false);
  const handleOpenDeleteNew = () => setOpenDeleteNew(true);
  const handleCloseDeleteNew = () => setOpenDeleteNew(false);

  const [dataFilterstate, setDataFilterState] = React.useState({
    left: false,
  });

  // console.log("filterList", filterList);

  const [isActive, setActive] = useState(false);

  const toggleMenuClass = () => {
    setActive(!isActive);
  };
  const filterToggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDataFilterState({ ...dataFilterstate, [anchor]: open });
  };

  // get Officer List
  const getOfficerListData = () => {
    setLoading(true);
    dispatch(getAdminOfficerList()).then((res) => {
      if (res?.payload?.status === 200) {
        setOfficerListData(res?.payload?.data);
        setFilterList(res?.payload?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getOfficerListData();
  }, []);

  // search
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const filteredList = officerListData?.filter((data) =>
      `${data.firstName.toLowerCase()} ${data.lastName.toLowerCase()} ${data.mobileNumber
        }  ${data.email.toLowerCase()}`.includes(e.target.value.toLowerCase())
    );
    setFilterList(filteredList);
  };

  // US Formate
  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters from the input
    const cleaned = ("" + input).replace(/\D/g, "");

    // Format the cleaned phone number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return cleaned;
  };

  // const showMessage = (message) => {
  //   PagesIndex.toast.info(message, {
  //     position: PagesIndex.toast.POSITION.TOP_RIGHT,
  //     autoClose: 3000,
  //     // Add other customization options as needed
  //   });
  // };
  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          {/* <Index.Link to="/admin/dashboard" className="filter-back-link">
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            ></img>
          </Index.Link>{" "} */}
          Officer Management
        </Index.Typography>
        <Index.Box className="userlist-btn-flex">
          <Index.Box className="user-search-main">
            {/* search */}
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleSearch}
                  onKeyDown={(e) => {
                    if (
                      e.key === " " &&
                      e.target.value.trim() === ""
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* Top Filter */}
          {/* <Index.Box className="export-btn-main border-btn-main">
            <Index.Button
              className="export-btn border-btn"
              onClick={filterToggleDrawer("right", true)}
            >
              <img
                src={PagesIndex.Svg.filter}
                className="down-icon"
                alt="download icon"
              />
              Filter
            </Index.Button>
          </Index.Box> */}
          <FilterAdminOfficer
            filterToggleDrawer={filterToggleDrawer}
            dataFilterstate={dataFilterstate}
            side="right"
          />

          <Index.Box className="userlist-inner-btn-flex">
            <Index.Box className="adduser-btn-main btn-main-primary">
              {roleName === "Admin" ||
                SubAdminPermission?.officer?.permission?.add == true ? (
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={handleOpen}
                >
                  <img
                    src={PagesIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add Officer
                </Index.Button>
              ) : null}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Officer List data */}
      {loading ? (
        <Loader />
      ) : (
        <Index.Box className="agent-mamangement-sec">
          {filterList?.length > 0 ? (
            filterList?.map((item, index) => (
              <Index.Box key={item._id} className="card-main">
                <Index.Box className="card-left">
                  <Index.Box className="officer-profile-flex">
                    <img
                      src={
                        item?.image
                          ? `${PagesIndex.ADMIN_IMAGE_ENDPOINT}/${item?.image}`
                          : PagesIndex.Png.usericon
                      }
                      alt=""
                      className="prof-img"
                    />
                    <Index.Box className="officer-detail">
                      {/* <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-title"
                      >
                        {item?.firstName + " " + item?.lastName}
                      </Index.Typography> */}
                      <Index.Typography
                        component="p"
                        variant="p"
                        // className="officer-detail-title"
                        className="job-title-label"
                      >
                        {item?.firstName + " " + item?.lastName}
                        {item?.isBuild == true ? (
                          <>
                            {item?.jobName == "Supervisor" ? (
                              <Index.Box className="admin-list-pd-btn-main">
                                <Index.PrimaryButton
                                  btnLabel="Supervisor "
                                  className="admin-list-pd-btn"
                                />
                              </Index.Box>
                            ) : (
                              <Index.Box className="admin-list-pd-btn-main">
                                <Index.PrimaryButton
                                  btnLabel="Non-Supervisor"
                                  className="admin-list-pd-btn"
                                />
                              </Index.Box>
                            )}
                          </>
                        ) : (null)}

                      </Index.Typography>

                      <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-email"
                      >
                        {item?.email}
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="officer-detail-numebr"
                      >
                        {formatPhoneNumber(item?.mobileNumber)}
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className={`officer-detail-numebr suspend_label ${item.status == "SUSPEND" ? "text-danger" : "text-dark"}`}
                        
                      >
                        {item?.status === "SUSPEND" && "Suspended"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="card-right btn-main-secondary btn-main-red btn-main-orange btn-main-yellow">

                  <Index.Box
                    id="btn-toggle-popup"
                    className={isActive ? "toggle-pop-show" : null}
                  >
                    {roleName === "Admin" ||
                      SubAdminPermission?.officer?.permission?.read ==
                      true ? (
                      <Index.Tooltip
                        title="View"
                        arrow
                        placement="bottom"
                        className="admin-tooltip"
                      >
                        <Index.Button
                          btnLabel="View"
                          className="btn-orange"
                          onClick={() => {
                            navigate(
                              {
                                pathname: `/admin/admin-officer-detail/${item?._id}`,
                              },
                              { state: { isPending: true } }
                            );
                          }}
                        >
                          <img src={PagesIndex.Svg.eyeIcon} alt="delete" className="admin-icons" />
                        </Index.Button>
                      </Index.Tooltip>
                    ) : null}

                    {roleName === "Admin" ||
                      SubAdminPermission?.officer?.permission
                        ?.Suspend == true ? (
                      <Index.Tooltip
                        title={`${item.status == "ACTIVATE" ? "Suspend" : "Activate"}`}
                        arrow
                        placement="bottom"
                        className="admin-tooltip"
                      >
                        <Index.Button
                          btnLabel={
                            item.status === "ACTIVATE"
                              ? "Suspend"
                              : "Activate"
                          }
                          onClick={() => suspendhandleOpen(item)}
                          className={`${item.status === "ACTIVATE"
                            ? "btn-red bg-suspend"
                            : "btn-green bg-active"
                            } btn-red`}
                        >
                          {
                            item.status == "ACTIVATE" ? (
                              <img src={PagesIndex.Svg.prohibitionIcon} alt="delete" className="admin-icons" />
                            ) : (
                              <img src={PagesIndex.Svg.checkMark} alt="CheckMark" className="admin-icons checkmark-icon" />
                            )
                          }

                          {/* <img src={PagesIndex.Svg.checkMark} alt="CheckMark" className="admin-icons" /> */}
                          {/* <img src={PagesIndex.Svg.prohibitionIcon} alt="delete" className="admin-icons" /> */}
                        </Index.Button>
                      </Index.Tooltip>
                    ) : null}


                    {roleName === "Admin" ||
                      SubAdminPermission?.officer?.permission?.edit ==
                      true ? (
                      <Index.Tooltip
                        title="Edit"
                        arrow
                        placement="bottom"
                        className="admin-tooltip"
                      >
                        <Index.Button
                          btnLabel="Edit"
                          className="btn-secondary"
                          onClick={() => {
                            if (item?.isBuild) {
                              navigate(
                                {
                                  pathname: `/admin/officer-management/edit-detail`,
                                  search: PagesIndex?.createSearchParams({
                                    id: item?._id,
                                  }).toString(),
                                },
                                { state: { item } }
                              );
                            } else {
                              PagesIndex.toast.info("Build is remaining")
                            }
                          }}
                        >
                          <img src={PagesIndex.Svg.editNew} alt="delete" className="admin-icons" />
                        </Index.Button>
                      </Index.Tooltip>
                    ) : null}


                    {roleName === "Admin" ||
                      SubAdminPermission?.officer?.permission?.delete ==
                      true ? (
                      <Index.Tooltip
                        title="Delete"
                        arrow
                        placement="bottom"
                        className="admin-tooltip"
                      >
                        <Index.Button
                          btnLabel="delete"
                          className="btn-red"
                          onClick={() => {
                            setDeleteOfficerId(item);
                            handleOpenDeleteNew();
                          }}
                        >
                          <img src={PagesIndex.Svg.deletewhite} alt="delete" className="admin-icons" />
                        </Index.Button>
                      </Index.Tooltip>
                    ) : null}


                  </Index.Box>
                </Index.Box>
              </Index.Box>
            ))
          ) : (
            <PagesIndex.NoDataFound />
          )}
        </Index.Box>
      )}

      {/* Suspend Officer modal */}
      <OfficerSuspendModal
        suspendopen={suspendopen}
        suspendhandleClose={suspendhandleClose}
        officerData={officerData}
        getOfficerListData={getOfficerListData}
      />

      {/* Add New Officer modal */}
      <AddNewOfficerModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
        getOfficerListData={getOfficerListData}
      />

      {/* Delete Officer Modal */}
      <OfficerDeleteModal
        handleCloseDeleteNew={handleCloseDeleteNew}
        openDeleteNew={openDeleteNew}
        getOfficerListData={getOfficerListData}
        deleteOfficerId={deleteOfficerId}
      />
    </>
  );
};

export default OfficerManagement;
