import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";

const AdminDetailsofcompletedjobs = () => {
  const [ratingvalue, setRatingValue] = React.useState(2);
const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const adminCompletejobsDetail = state;
  console.log(adminCompletejobsDetail, "adminCompletejobsDetail")
  // us Formate
  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters from the input
    const cleaned = ("" + input).replace(/\D/g, "");

    // Format the cleaned phone number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return cleaned;
  };
  return (
    <>
      <Index.Box className="user-list-flex" >
        <Index.Box className="title-header-flex" onClick={() =>
          navigate("/admin/adminjobmanagement", { state: "CompletedJobs" })
        }>
          <img
            src={PagesIndex.Svg.leftArrow}
            className="left-arrow-ic"
            alt="search grey img"
          />
          <Index.Typography className="user-list-page-title">
            {adminCompletejobsDetail?.title}
          </Index.Typography>
        </Index.Box>
      </Index.Box>
      <Index.Box className="details-active-job-sec active-job-sec">


        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Assigned Officer & Associated Agency/Department
          </Index.Typography>
        </Index.Box>


        <>
          {adminCompletejobsDetail?.officerId.map((item, index) => {
            const officershortDetail = item?.officerId;
            { console.log(item, "faaaaaa") }
            return (
              <Index.Box className="card-main">
                <Index.Box className="card-left">
                  <Index.Box className="officer-profile-flex">
                    <img
                      src={PagesIndex.Png.usericon}
                      className="prof-img"
                      alt=""
                    />
                    <Index.Box className="officer-detail">
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          {officershortDetail?.firstName ? (
                            <>
                              {officershortDetail?.firstName +
                                " " +
                                officershortDetail?.lastName}
                            </>
                          ) : null}
                          &nbsp;
                        </Index.Typography>

                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          ({officershortDetail?.officerType})
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          Officer Assigned on :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          {PagesIndex.dayjs(item?.jobAcceptedDate).format(
                            "MM-DD-YYYY"
                          )}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          Officer Check-In :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          {/* {PagesIndex.dayjs(item?.jobCheckInTime).format(
                                  "MM-DD-YYYY : HH:mm"
                                )} */}
                          {item?.jobCheckInTime && PagesIndex.dayjs(item.jobCheckInTime).isValid() ? PagesIndex.dayjs(item.jobCheckInTime).format("MM-DD-YYYY - HH:mm") : " Not checkin"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          Officer Check-Out :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          {PagesIndex.dayjs(item?.jobCheckOutTime).format(
                            "MM-DD-YYYY - HH:mm"
                          )}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            );
          })}
        </>
        <Index.Box className="details-active-job-sec active-job-sec">
          {[1].map((item, index) => (
            <Index.Box className="card-main">
              <Index.Box className="card-left">
                <Index.Box className="officer-detail">
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      {adminCompletejobsDetail?.department == "Police department" ? "Associated Department" : "Associated Agency"}:&nbsp;
                    </Index.Typography>

                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {adminCompletejobsDetail?.approveBy?.agencyName}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          ))}
        </Index.Box>

        <Index.Box className="details-active-job-sec active-job-sec">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              General Information
            </Index.Typography>
          </Index.Box>
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-detail">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Title :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.title}
                  </Index.Typography>
                </Index.Box>


                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Posted By :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.userId?.name}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Posted On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {PagesIndex.dayjs(adminCompletejobsDetail?.createdAt).format(
                      "MM-DD-YYYY"
                    )}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Total Payment :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Transaction Id  :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Phone Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {formatPhoneNumber(adminCompletejobsDetail?.mobileNumber)}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Email Address :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.userId?.email}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Point Of Contact Name :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.pointOfContactName}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Point Of Contact Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {formatPhoneNumber(
                      adminCompletejobsDetail?.pointOfContactNumber || "-"
                    )}
                  </Index.Typography>
                </Index.Box>



                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Location :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.location}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Service :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.service}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Department :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.department}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Type :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.jobType?.jobType}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Zip Code :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.zipCode}
                  </Index.Typography>
                </Index.Box>
                {adminCompletejobsDetail?.supervisor ? (
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Supervisor :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {adminCompletejobsDetail?.supervisor}
                    </Index.Typography>
                  </Index.Box>) : (null)}
                {adminCompletejobsDetail?.nonSupervisor ? (
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Non Supervisor :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {adminCompletejobsDetail?.nonSupervisor}
                    </Index.Typography>
                  </Index.Box>
                ) : (null)}

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Date/Time :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >

                    {/* {adminCompletejobsDetail?.jobSchedule?.map((item, index) => {
                        return (
                          <>
                           
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-text-label"
                            >
                              <span className="day-title">
                                {
                               
                                  PagesIndex.dayjs(item?.jobStartDate).format(
                                    "MM-DD-YYYY"
                                  ) +
                                    " to " +
                                    PagesIndex.dayjs(item?.jobEndDate).format(
                                      "MM-DD-YYYY"
                                    )
                                }
                              </span>{" "}
                              -{" "}
                              <span>
                                {item?.jobStartTime
                                  ? item?.jobStartTime + " to "
                                  : null}
                                {item?.jobEndTime}
                              </span>
                            </Index.Typography>
                         
                          </>
                        );
                      })} */}
                    {PagesIndex.showDateTime(adminCompletejobsDetail)}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Total Hours :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {/* {PagesIndex.timeDifferenceCalculation(
                      adminCompletejobsDetail?.jobSchedule[0]?.jobStartTime,
                      adminCompletejobsDetail?.jobSchedule[0]?.jobEndTime
                    )} */}
                    {PagesIndex.timeDifferenceCalculation(adminCompletejobsDetail)} hour
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Pay Rate :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.rate ? "$" : null}
                    {adminCompletejobsDetail?.rate}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    No of Officer :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.totalOfficer}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Information :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.information}
                  </Index.Typography>
                </Index.Box>
                <Index.Box
                  // className="job-title-text align-start-job"
                  className="work-title-flex"
                >
                  <Index.Typography
                    component="p"
                    variant="p"
                    // className="job-title-label job-description-width"
                    className="work-title-lable"
                  >
                    Description :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    // className="job-title-text-label"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.description}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="details-active-job-sec active-job-sec">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              Organizer (Client)
            </Index.Typography>
          </Index.Box>
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-detail">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Name :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.userId?.name}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Mobile Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {formatPhoneNumber(adminCompletejobsDetail?.userId?.mobileNumber)}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Email Address :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminCompletejobsDetail?.userId?.email}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="details-active-job-sec active-job-sec">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              Job Log
            </Index.Typography>
          </Index.Box>
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-detail">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Posted On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {PagesIndex.dayjs(
                      adminCompletejobsDetail?.createdAt
                    ).format("MM-DD-YYYY")}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Transferred To :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Transferred On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box >


    </>
  );
};

export default AdminDetailsofcompletedjobs;
