import React, { useEffect, useState } from "react";
import PagesIndex from "../../../../components/PagesIndex";
import Index from "../../../index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import "./Role.css";
import { getRoleMasterList } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import { getAgencyRoleMasterList } from "../../../../redux-toolkit/slice/agency-slice/AgencyServices";

// import { getRoleList } from "../../../../redux-toolkit/slice/agency-slice/AgencyServices";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const ActionItem = Index.styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function List() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [ListData, setListData] = useState(false);
  // const { roleMasters } = Index.useSelector((state) => state.AdminReducer);
  //  cosnt {roleMasters} = useSelector((state)=>state.)
   //Getting data from redux
    const { agencyLoginData } = PagesIndex.useSelector(
      (state) => state.AgencySlice
    );
    const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
    const roleName = agencyLoginData?.roleId?.Permission_name;

  // Pagination states and methods
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  // End Pagination
  const [roleMasterList, setRoleMasterList] = useState([]);
  // Delete record state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  // const [roleListData, setRoleListData] =useState([]);
  const [roleListData, setRoleListData] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  // Delete functionality
  const [deleteRoleId, setDeleteRoleId] = useState([]);
  // Edit functionality
  const [editRoleId, setEditRoleId] = useState([]);

  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };


  // get Agency role list
  const getRole = () => {
    dispatch(getAgencyRoleMasterList()).then((res) => {
      // console.log("res 78", res, res?.payload?.status == 200);
      if (res?.payload?.status == 200) {
        console.log("if", res?.payload?.data);
        setCurrentRows(res?.payload?.data);
        setFilteredData(res?.payload?.data);
      }
    });
  };

  useEffect(() => {
    getRole();
  }, []);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(roleMasterList?.reverse());
  }, [roleMasterList]);

  // search on table
  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
    const filteredList = currentRows?.filter((data) =>
      `${data?.Permission_name?.toLowerCase()}`?.includes(
        e?.target?.value.toLowerCase()
      )
    );
    setFilteredData(filteredList);
    setPage(0);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDeleteOpen = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    const data = {
      id: rowId,
    };
    //   dispatch(deleteRoleMaster(data));
    handleDeleteClose();
  };

  const handleEdit = (row) => {
    navigate(`/${roleName}/role/edit`, {
      state: { row },
    });
  };

  console.log(currentRows, "item");
  return (
    <>
      <Index.Box className="user-list-flex">
        <Index.Typography
          className="admin-page-title user-list-page-title"
          component="h2"
          variant="h2"
        >
          {/* <Index.Link to="/admin/dashboard" className="filter-back-link">
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            ></img>
          </Index.Link>{" "} */}
          Role Management
        </Index.Typography>
        <Index.Box className="userlist-btn-flex">
          <Index.Box className="user-search-main">
            <Index.Box className="user-search-box">
              <Index.Box className="form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="form-control"
                  placeholder="Search"
                  inputProps={{ "aria-label": "search" }}
                  value={searchValue}
                  onChange={handleSearch}
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.value.trim() === "") {
                      e.preventDefault();
                    }
                  }}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="search-grey-img"
                  alt="search grey img"
                ></img>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="userlist-inner-btn-flex">
            <Index.Box className="adduser-btn-main btn-main-primary">
                {" "}
                <Index.Button className="adduser-btn btn-primary"
                onClick={()=>{
                  navigate(`/${roleName}/role/add`)
                }}
                disableRipple
                >
                  <img
                    src={PagesIndex.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add Role
                </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* End New Design Code Changes*/}

      <Index.Box className="common-card">
        <Index.Box className="page-table-main role-table-main">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table aria-label="simple table" className="table">
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="10%"
                  >
                    Sr. No
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="80%"
                  >
                    Role Name
                  </Index.TableCell>
                  <Index.TableCell
                    align="center"
                    component="th"
                    variant="th"
                    className="table-th"
                    width="10%"
                  >
                    Action
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              {loading ? (
                <Index.TableBody>
                  <Index.TableRow>
                    <Index.TableCell
                      colSpan={12}
                      align="center"
                      className="loading-purchase"
                    >
                      <Index.CircularProgress color="secondary" size={20} />
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              ) : (
                <Index.TableBody className="table-body">
                  {filteredData?.length ? (
                    filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                          >
                            {rowsPerPage * page + (index + 1)}
                          </Index.TableCell>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="table-td"
                          >
                            {item?.Permission_name
                              ? item?.Permission_name
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            component="td"
                            variant="td"
                            className="table-td btn-main-secondary btn-main-red"
                            sx={{ justifyContent: "center" }}
                          >
                            <Index.Box className="page-table-btn-main">
                              <Index.Tooltip
                                title="Edit"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Button
                                  className="btn-secondary"
                                  onClick={() => {
                                    handleEdit(item);
                                  }}
                                >
                                  {/* <img src={PagesIndex.Svg.editNew} className="plus-icon" alt='edit icon' /> */}
                                  <img
                                    src={PagesIndex.Svg.editNew}
                                    className="admin-icons"
                                    alt="edit icon"
                                  />
                                </Index.Button>
                              </Index.Tooltip>

                              <Index.Tooltip
                                title="Delete"
                                arrow
                                placement="bottom"
                                className="admin-tooltip"
                              >
                                <Index.Button
                                  className="btn-red bg-suspend"
                                  onClick={() => {
                                    handleDeleteOpen();
                                    setDeleteRoleId(item?._id);
                                  }}
                                >
                                  {/* <img src={PagesIndex.Svg.deletewhite} className="plus-icon" alt='delete icon' /> */}
                                  <img
                                    src={PagesIndex.Svg.deletewhite}
                                    className="admin-icons"
                                    alt="delete icon"
                                  />
                                </Index.Button>
                              </Index.Tooltip>
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="table-td"
                        colSpan={15}
                        align="center"
                      >
                        <PagesIndex.NoDataFound />
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              )}
            </Index.Table>
          </Index.TableContainer>

          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.TablePagination
                  className="paginationColor"
                  component="div"
                  page={page}
                  count={filteredData?.length}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  // rowsPerPageOptions={[10, 25, 50]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  // labelDisplayedRows={(page) =>
                  //   `Records ${page.from} to ${
                  //     page.to === -1 ? page.count : page.to
                  //   } of ${page.count}`
                  // }
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
          {/* </Index.Box> */}
        </Index.Box>
      </Index.Box>
      <Index.DeleteAgencyModal
        deleteOpen={deleteOpen}
        getRole={getRole}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
        deleteRoleId={deleteRoleId}
      />
    </>
  );
}
