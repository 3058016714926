import { createAsyncThunk } from "@reduxjs/toolkit";
import { Api } from "../../../config/Api";
import DataService from "../../../config/DataService";
import { toast } from "react-toastify";


export const agencyLogin = createAsyncThunk(
  "agency/agencyLogin",
  async (data) => {
    try {
      const response = await DataService.post(Api.Agency.AGENCY_LOGIN, data);
      localStorage.setItem("AgencyToken", response?.data?.data?.token);
      toast.success(response?.data?.message,{toastId:"customId"});
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message,{toastId:"customId"});
    }
  }
);

export const agencyRegister = createAsyncThunk(
  "agency/agencyRegister",
  async (data) => {
    try {
      const response = await DataService.post(Api.Agency.AGENCY_REGISTER, data);
      toast.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
);

export const getStateList = createAsyncThunk(
  "agency/getStateList",
  async () => {
    try {
      const res = await DataService.get(Api.Agency.GET_STATE_LIST);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

export const getCityList = createAsyncThunk(
  "agency/getCityList",
  async (data) => {
    try {
      const response = await DataService.post(Api.Agency.GET_CITY_LIST, data);
      return response?.data;
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
  }
);

export const getAgencyOfficerList = createAsyncThunk(
  "agency/getAgencyOfficerList",
  async (data) => {
    try {
      const res = await DataService.get(
        `${Api.Agency.AGENCY_OFFICER_LIST}?officerStatus=${data}`
      );
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

export const getJobList = createAsyncThunk(
  "agency/getJobList",
  async (data) => {
    try {
      const res = await DataService.post(Api.Agency.GET_JOB_LIST, data);
      return res?.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// role list
export const getAgencyRoleList = createAsyncThunk(
  "agency/getAgencyRoleList",
  async () => {
    try {
      const res = await DataService.get(Api.Agency.GET_ROLE_LIST_AGENCY_SIGNUP);
      return res.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
    }
  }
);

// job type Agency

export const getJobTypeAgency = createAsyncThunk("getJobTypeAgency", async () => {
  try {
    const response = await DataService.get(Api.Agency.GET_JOBTYPE_AGENCY_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});


// get Role Agency Master

export const getRoleAgencyMasterList = createAsyncThunk(
  "Agency/getRoleAgencyMasterList",
  async (data) => {
    try {
      const res = await DataService.get(Api.Agency.GET_ROLE_AGENCY_LIST, data);
      return res?.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

// Add Role Agency Master
export const addRoleAgencyMaster = createAsyncThunk(
  "Agency/addRoleMaster",
  async (data) => {
    console.log(data,"data45")
    try {
      const res = await DataService.post(Api.Agency.ADD_ROLE_AGENCY_MASTER, data.urlencoded);
      if (res?.data?.status === 200 || res?.data?.status === 201){
        toast.success(res.data.message);
        data.setLoading(false);
        data.navigate(`/agency/agency-role`);
      } else {
        toast.error(res?.res?.data?.message);
        setTimeout(() => {
          data.setLoading(false);
        }, 5500);
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

// Agency get Skills

export const getAgencySkillsData = createAsyncThunk("getSkillsData", async () => {
  try {
    const response = await DataService.get(Api.Agency.GET_AGENCY_SKILLS_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

// zip code

export const getAgencyZipCodeData = createAsyncThunk("getZipCodeData", async () => {
  try {
    const response = await DataService.get(Api.Agency.GET_AGENCY_ZIPCODE_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});
// Agency USER
export const getAgencyUserList = createAsyncThunk("getAgencyUserList", async () => {
  try {
    const response = await DataService.get(Api.Agency.AGENCY_GET_USER_LIST);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message);
  }
});

// get Agency Role Master

export const getAgencyRoleMasterList = createAsyncThunk(
  "agency/getAgencyRoleMasterList",
  async (data) => {
    try {
      const res = await DataService.get(Api.Agency.GET_ROLE_LIST_AGENCY, data);
      return res?.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

// Add Agency Role Master
export const addAgencyRoleMaster = createAsyncThunk(
  "agency/addAgencyRoleMaster",
  async (data) => {
    try {
      const res = await DataService.post(Api.Agency.ADD_AGENCY_ROLE_MASTER, data.urlencoded);
      if (res?.data?.status === 200 || res?.data?.status === 201){
        toast.success(res.data.message);
        data.setLoading(false);
        data.navigate(`/agency/role`);
      } else {
        toast.error(res?.res?.data?.message);
        setTimeout(() => {
          data.setLoading(false);
        }, 5500);
      }
    } catch (error) {
      toast.error(error.response.data.message || error.message,{toastId: "CustomId"});
      data.setLoading(false);
    }
  }
);


