import React from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const UnservedJobModal = (props) => {
  const { unservedJobsopen, handleCloseUnservedJobs, unservedjobsData } = props;
  console.log("unservedjobsData", unservedjobsData);
  // us Formate
  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters from the input
    const cleaned = ("" + input).replace(/\D/g, "");

    // Format the cleaned phone number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return cleaned;
  };
  return (
    <>
      <Index.Modal
        open={unservedJobsopen}
        onClose={handleCloseUnservedJobs}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              Unfilled/Unserved jobs
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={handleCloseUnservedJobs}
              alt=""
            />
          </Index.Box>
          <Index.Box className="modal-body">
            <Index.Box className="card-left job-text-width-content">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Title : &nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedjobsData?.title}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted By :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedjobsData?.userId?.name}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.dayjs(unservedjobsData?.jobStartDate).format(
                    "MM-DD-YYYY"
                  )}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Payment :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Transaction Id  :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Phone Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {formatPhoneNumber(unservedjobsData?.mobileNumber)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedjobsData?.userId?.email}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Location :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedjobsData?.location}
                </Index.Typography>
              </Index.Box>
              {/* <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Required Skill :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  {unservedjobsData?.skills?.length > 0
                    ? unservedjobsData?.skills.map((row, index) => (
                        <React.Fragment key={index}>
                          <span>{row}</span>
                          {index < unservedjobsData?.skills?.length - 1 && (
                            <span>, </span>
                          )}
                        </React.Fragment>
                      ))
                    : null}
                </Index.Typography>
              </Index.Box> */}
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Service :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedjobsData?.service}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Department :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedjobsData?.department}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Type :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedjobsData?.jobType?.jobType}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Zip Code :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedjobsData?.zipCode}
                </Index.Typography>
              </Index.Box>
              {unservedjobsData?.supervisor ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Supervisor :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {unservedjobsData?.supervisor}
                  </Index.Typography>
                </Index.Box>
              ) : null}

              {unservedjobsData?.nonSupervisor ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Non Supervisor :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {unservedjobsData?.nonSupervisor}
                  </Index.Typography>
                </Index.Box>
              ) : null}
              {/* <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Date :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  {PagesIndex.dayjs(unservedjobsData?.jobStartDate).format(
                    "MM-DD-YYYY"
                  ) +
                    " to " +
                    PagesIndex.dayjs(unservedjobsData?.jobEndDate).format(
                      "MM-DD-YYYY"
                    )}
                </Index.Typography>
              </Index.Box> */}
              {/* <Index.Box className="job-title-text">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  Timing :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-text-label"
                >
                  {unservedjobsData?.jobStartTime +
                    " to " +
                    unservedjobsData?.jobEndTime}
                </Index.Typography>
              </Index.Box> */}
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Date/Time :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {/* {unservedjobsData?.jobSchedule?.map((item, index) => {
                        return (
                          <>
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-text-label"
                            >
                              <span className="day-title">
                                {
                                  PagesIndex.dayjs(item?.jobStartDate).format(
                                    "MM-DD-YYYY"
                                  ) +
                                    " to " +
                                    PagesIndex.dayjs(item?.jobEndDate).format(
                                      "MM-DD-YYYY"
                                    )
                                }
                              </span>{" "}
                              -{" "}
                              <span>
                                {item?.jobStartTime
                                  ? item?.jobStartTime + " to "
                                  : null}
                                {item?.jobEndTime}
                              </span>
                            </Index.Typography>
                          </>
                        );
                      })} */}
                  {PagesIndex.showDateTime(unservedjobsData)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Pay Rate (Per hour) :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedjobsData?.rate ? "$" : null}
                  {unservedjobsData?.rate}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Hours :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.timeDifferenceCalculation(unservedjobsData)} hour
                  {/* {PagesIndex.timeDifferenceCalculation(
                      unservedjobsData?.jobSchedule[0]?.jobStartTime,
                      unservedjobsData?.jobSchedule[0]?.jobEndTime
                    )} */}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  No of Officer :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedjobsData?.totalOfficer}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Information :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {unservedjobsData?.information}
                  </Index.Typography>
                </Index.Box>
              <Index.Box
                // className="job-title-text"
                className="work-title-flex"
              >
                <Index.Typography
                  component="p"
                  variant="p"
                  // className="job-title-label"
                  className="work-title-lable"
                >
                  Description :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  // className="job-title-text-label"
                  className="work-value-text"
                >
                  {unservedjobsData?.description}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Approved By : &nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {unservedjobsData?.approveBy?.agencyName}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Approved on : &nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.moment(
                    unservedjobsData?.officerId[0]?.jobAcceptedDate
                  ).format("MM-DD-YYYY")}

                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Accepted By : &nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {/* / Officer Name */}
                  {/* {unservedjobsData?.officerId[0]?.officerId?.firstName} */}
                  {unservedjobsData?.officerId[0]?.officerId?.firstName ? (
                    <>
                      {unservedjobsData?.officerId[0]?.officerId?.firstName +
                        " " +
                        unservedjobsData?.officerId[0]?.officerId?.lastName}
                    </>
                  ) : null}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Accepted On : &nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.moment(
                    unservedjobsData?.officerId[0]?.jobAcceptedDate
                  ).format("MM-DD-YYYY")}
                  {/* {unservedJobData?.officerId[0]?.jobAcceptedDate} */}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* <Index.Box className="modal-footer">
            <Index.Box className="document-btn-grp">
              <Index.PrimaryButton
                btnLabel="Approve"
                className="btn-primary bg-approve appr-rej-btn"
              />

              <Index.PrimaryButton
                btnLabel="Reject"
                className="btn-primary bg-suspend appr-rej-btn"
              />
            </Index.Box>
          </Index.Box> */}
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default UnservedJobModal;
