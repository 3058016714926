// DateRangePickerWrapper.js
import React from 'react';
import Index from '../../container';
import dayjs from 'dayjs';


const DateRangePickerMaster = ({ selectedDate, setSelectedDate, shortcutsItems }) => {

  // let todayDate = dayjs();
  return (
    <Index.Box className="card-head datepicker-wrape">
      <Index.Box className="input-box" sx={{marginBottom:"0px"}}>
        <Index.Box className="form-group">
          <Index.LocalizationProvider dateAdapter={Index.AdapterDayjs}>
            <Index.DemoContainer components={["SingleInputDateRangeField"]} slotProps={{ textField: { size: "small" } }}>
              <Index.DateRangePicker
                className="date-picker-custom form-control"
                format="MM-DD-YYYY"
                value={selectedDate ? dayjs(selectedDate) : null}
                // minDate={todayDate}
                slots={{ field: Index.SingleInputDateRangeField }}
                slotProps={{
                  shortcuts: {
                    items: shortcutsItems,
                  },
                //   textField: {
                //     readOnly: true,
                //     size: "small",
                //   },
                  actionBar: { actions: [] },
                }}
                onChange={(newValue) => setSelectedDate(newValue)}
              />
            </Index.DemoContainer>
          </Index.LocalizationProvider>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
};

export default DateRangePickerMaster;
