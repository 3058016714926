import React, { useEffect, useState } from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";
import Loader from "../../../../common/loader/Loader";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { AgencyAddOfficerSchema } from "../../../../validation/FormikSchema";
// import {
//   getAgencyList,
//   getRollList,
// } from "../../../../../redux-toolkit/slice/common-slice/CommonServices";

const AddPercentageModel = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { open, handleClose } = props;
    const dispatch = PagesIndex.useDispatch();
    const agencyLoginData = PagesIndex.useSelector(
        (state) => state.AgencySlice.agencyLoginData
    );
    const roleName = agencyLoginData?.roleId?.Permission_name;
    console.log(agencyLoginData, "agencyLoginData")
    console.log(roleName, "roleName222")

    let type = [];

    if (true) {
        type.push(
            { title: "Direct Pay" },
            { title: "Through Police Department" },
            { title: " Security Agency" }
        )
    }
// for validation
const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
const ONLY_ALPHA_NOT_SPACE_SPACIALCHAR = /[^a-zA-Z]+|\s+/g;
const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;

const [officerRoleId, setOfficerRoleId] = useState();
console.log(officerRoleId, "officerRoleId")
const [agencyList, setAgencyList] = useState();
const [showPassword, setShowPassword] = useState(false);
const [departmentList, setDepartmentList] = useState();
const [initialValues, setInitialValues] = useState({
    police_departmnet: "",
    security_Agency: "",
    officer: "",
    admin: "",
    type: "",
});
const handleClickShowPassword = () => setShowPassword((show) => !show);
const handleMouseDownPassword = (event) => {
    event.preventDefault();
};

const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
const handleClickConfirmShowPassword = () =>
    setShowConfirmPassword((show) => !show);
const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
};

// const initialValues = {
//   police_departmnet: "",
//   security_Agency: "",
//   officer: "",
//   mobileNumber: "",
//   // zipCode: "",
//   admin: "",
//   type: "",
//   password: "",
//   confirmPassword: "",
// };

//   useEffect(() => {
//     dispatch(getAgencyList()).then((res) => {
//       if (res?.payload?.status == 200) {
//         setAgencyList(res?.payload?.data);

//         let selectedAgency = res?.payload?.data?.find(
//           (item) => item?._id === agencyLoginData?._id
//         );

//         setInitialValues((prev) => ({
//           ...prev,
//           admin: selectedAgency?._id,
//         }));
//       }
//     });
//   }, [dispatch]);

// Roll List  Api call
//   useEffect(() => {
//     dispatch(getRollList()).then((res) => {
//       if (res?.payload?.status == 200) {
//         const rolematch = res?.payload?.data.filter((matchData) => {
//           return matchData.Permission_name?.toLowerCase() === "officer";
//         });
//         if (rolematch?.length > 0) {
//           setOfficerRoleId(rolematch?.[0]?._id);
//         }
//       }
//     });
//   }, [dispatch]);

// console.log("rollList", rollList);
const handlePaymentPercentageForm = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("police_departmnet", values?.police_departmnet);
    urlEncoded.append("security_Agency", values?.security_Agency);
    urlEncoded.append("officer", values?.officer);
    urlEncoded.append("admin", values?.admin);
    urlEncoded.append("type", values?.type);
    try {
        const response = await DataService.post(
            Api.Admin.CREATE_PAYMENT_PERCENTAGE,
            urlEncoded
        );
        if (response?.data?.status === 201) {
            PagesIndex.toast.success(response?.data?.message);
            handleClose();
            setIsLoading(false);
        }
    } catch (error) {
        PagesIndex.toast.error(error?.response?.data?.message);
        setIsLoading(false);
    }
};


useEffect(() => {
    return () => {
        // setmobileNumber("");
        // setShowPassword(false);
        // setShowConfirmPassword(false);
    };
}, [handleClose]);
return (
    <>
        <Index.Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modal"
        >
            <PagesIndex.Formik
                enableReinitialize
                // validationSchema={AgencyAddOfficerSchema}
                initialValues={initialValues}
                onSubmit={handlePaymentPercentageForm}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    touched,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    handleFocus,
                }) => (
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        {/* {console.log("error", errors)} */}
                        {/* {console.log("values>>", values)} */}
                        <Index.Box
                            sx={style}
                            className="add-modal-inner-main modal-inner"
                        >
                            <Index.Box className="modal-header">
                                <Index.Typography
                                    id="modal-modal-title"
                                    className="modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    Add Payment Type Percentage
                                </Index.Typography>
                                <img
                                    src={PagesIndex.Svg.closeblack}
                                    className="modal-close-icon"
                                    onClick={handleClose}
                                    alt=""
                                />
                            </Index.Box>
                            <Index.Box className="modal-body">
                                <Index.Box sx={{ flexGrow: 1 }}>
                                    <Index.Grid container spacing={2}>

                                        <Index.Grid container spacing={2}>
                                            <Index.Grid item xs={6}>
                                                <Index.Box className="input-box modal-input-box">
                                                    <Index.FormHelperText className="form-lable">
                                                        {" "}
                                                        Payment Type
                                                    </Index.FormHelperText>
                                                    <Index.Box className="dropdown-box">
                                                        <Index.FormControl className="form-control drop-form-control">
                                                            <Index.Select
                                                                className="dropdown-select drop-select"
                                                                displayEmpty
                                                                inputProps={{ "aria-label": "Without label" }}
                                                                name="type"
                                                                value={values?.type}
                                                                onChange={handleChange}
                                                                renderValue={
                                                                    values?.type
                                                                        ? undefined
                                                                        : () => "Select payment type"
                                                                }
                                                                error={
                                                                    errors.type && touched.type
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                {type && type.length > 0
                                                                    ? type.map((data, index) => {
                                                                        return (
                                                                            <Index.MenuItem
                                                                                key={index}
                                                                                value={data?.title}
                                                                                className="drop-menuitem"
                                                                            //   onClick={()=>getDepartmentByAgencyList(data?.title)}
                                                                            >
                                                                                {data?.title}
                                                                            </Index.MenuItem>
                                                                        );
                                                                    })
                                                                    : null}

                                                                {/* <Index.MenuItem
                                  value={data?._id}
                                  className="drop-menuitem"
                                >
                                  {data?.agencyName}
                                </Index.MenuItem> */}
                                                            </Index.Select>
                                                            <Index.FormHelperText error>
                                                                {errors.type && touched.type
                                                                    ? errors.type
                                                                    : null}
                                                            </Index.FormHelperText>
                                                        </Index.FormControl>
                                                    </Index.Box>
                                                </Index.Box>
                                            </Index.Grid>

                                            <Index.Grid item xs={6}>
                                                <Index.Box className="input-box modal-input-box">
                                                    <Index.FormHelperText className="form-lable">
                                                    Admin %
                                                        {/* {agencyLoginData?.roleId?.Permission_name == "Department" ? "Department" : "Agency"} */}
                                                    </Index.FormHelperText>
                                                    <Index.Box className="form-group">
                                                        <Index.TextField
                                                            fullWidth
                                                            id="fullWidth"
                                                            className="form-control"
                                                            placeholder="Please enter Admin Percentage"
                                                            name="admin"
                                                            value={agencyLoginData?.agencyName}
                                                            onChange={(e) => {
                                                                const newValue = e.target.value.replace(
                                                                    ONLY_NUMBER_NOT_SPACE_SPACIALCHAR,
                                                                    ""
                                                                  );
                                                                handleChange(e);
                                                                setFieldValue("admin", newValue);
                                                            }}
                                                            // onKeyDown={(e) => {
                                                            //     if (

                                                            //         (e.key === " " &&
                                                            //             e.target.value.trim() === "")
                                                            //     ) {
                                                            //         e.preventDefault();
                                                            //     }
                                                            // }}
                                                            onBlur={handleBlur}
                                                        />
                                                    </Index.Box>
                                                </Index.Box>
                                            </Index.Grid>
                                        </Index.Grid>


                                        <Index.Grid item xs={6}>
                                            <Index.Box className="input-box modal-input-box">
                                                <Index.FormHelperText className="form-lable">
                                                Police Department %
                                                </Index.FormHelperText>
                                                <Index.Box className="form-group">
                                                    <Index.TextField
                                                        fullWidth
                                                        id="fullWidth"
                                                        className="form-control"
                                                        placeholder="Please enter police department percentage"
                                                        name="police_departmnet"
                                                        value={values.police_departmnet}
                                                        inputProps={{ maxLength: 25 }}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setFieldValue("police_departmnet", e.target.value);
                                                        }}  
                                                    />
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Grid>
                                        <Index.Grid item xs={6}>
                                            <Index.Box className="input-box modal-input-box">
                                                <Index.FormHelperText className="form-lable">
                                                    Security Agency %
                                                </Index.FormHelperText>
                                                <Index.Box className="form-group">
                                                    <Index.TextField
                                                        fullWidth
                                                        id="fullWidth"
                                                        className="form-control"
                                                        placeholder="Please enter security agency percenatge"
                                                        name="security_Agency"
                                                        value={values.security_Agency}
                                                        inputProps={{ maxLength: 25 }}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setFieldValue("security_Agency", e.target.value);
                                                        }}
                                                       
                                                    />
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Grid>
                                    </Index.Grid>
                                    <Index.Grid container spacing={2}>
                                        <Index.Grid item xs={6}>
                                            <Index.Box className="input-box modal-input-box">
                                                <Index.FormHelperText className="form-lable">
                                                    Officer %
                                                </Index.FormHelperText>
                                                <Index.Box className="form-group">
                                                    <Index.TextField
                                                        fullWidth
                                                        id="fullWidth"
                                                        className="form-control"
                                                        placeholder="Please enter officer percentage"
                                                        name="officer"
                                                        value={values.officer}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setFieldValue("officer", e.target.value);
                                                        }}
                                                        
                                                    />
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Grid>
                                    </Index.Grid>
                                </Index.Box>
                            </Index.Box>
                            <Index.Box className="modal-footer">
                                <Index.Box className="modal-user-btn-flex document-btn-grp">
                                    <Index.PrimaryButton
                                        className="btn-primary bg-approve appr-rej-btn"
                                        type="submit"
                                        btnLabel="Add"
                                        disabled={isLoading}
                                    >
                                        {isLoading ? <Loader /> : "Click Me"}
                                    </Index.PrimaryButton>
                                    <Index.PrimaryButton
                                        className="btn-primary bg-suspend appr-rej-btn"
                                        onClick={handleClose}
                                        btnLabel="Cancel"
                                    />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </form>
                )}
            </PagesIndex.Formik>
        </Index.Modal>
    </>
);
};

export default AddPercentageModel;
