import React from "react";

import { Outlet } from "react-router-dom";
import PagesIndex from "../../container/PageIndex";

const AdminPublicLayout = ({ children }) => {
  const location = PagesIndex.useLocation();
  const isAdminLoggedIn = localStorage.getItem("AdminToken");

  return !isAdminLoggedIn ? (
    <Outlet />
  ) : (
    <PagesIndex.Navigate
      to="/admin/dashboard"
      state={{ from: location }}
      replace={true}
    />
  );
};

export default AdminPublicLayout;
