import React, { useEffect, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import dayjs from "dayjs";
import Loader from "../../../../../common/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const PendingJobModal = (props) => {
  const {
    pandingRequestopen,
    handleClosePandingRequest,
    pendingjobId,
    getJobListData,
  } = props;
  const { agencyLoginData } = PagesIndex.useSelector(
    (state) => state.AgencySlice
  );

  const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
  const roleName = agencyLoginData?.roleId?.Permission_name;

  const [jobList, setJobList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approveRejectStatus, setapproveRejectStatus] = useState(false);
  // console.log(roleName, "roleName33");
  // console.log(jobList, "jobList");
  const paymentType = localStorage.getItem("agencypayment");

  useEffect(() => {
    setLoading(true);
    if (pandingRequestopen) {
      PagesIndex.DataService.get(
        PagesIndex.Api.Agency.GET_SINGLE_JOB_LIST + `/${pendingjobId?._id}`
      )
        .then((res) => {
          if (res?.data?.status === 200) {
            setJobList(res?.data?.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          PagesIndex.toast.error(err?.response?.data?.message);
        });
    }
  }, [pendingjobId]);
  //pendingjobId , pandingRequestopen

  // job approverd / Rejected
  const handleApproveRequest = (status) => {
    // if (!paymentType && roleName == "Department") {
    //   PagesIndex?.toast?.error("Please select your payment type from profile section");
    // }

    // return false;

    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", pendingjobId?._id);
    urlEncoded.append("status", status);
    PagesIndex.DataService.post(
      PagesIndex.Api.Agency.JOB_APPROVE_REJECT,
      urlEncoded
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          setapproveRejectStatus(true);
          PagesIndex?.toast?.success(res?.data?.message);
          handleClosePandingRequest();
          getJobListData();
          setIsLoading(false);
        }
      })
      .catch((err) => {
        PagesIndex?.toast?.error(err?.response?.data?.mes);
        setIsLoading(false);
      });
  };
  // us formate
  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters from the input
    const cleaned = ("" + input).replace(/\D/g, "");

    // Format the cleaned phone number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return cleaned;
  };

  return (
    <>
      <Index.Modal
        open={pandingRequestopen}
        onClose={handleClosePandingRequest}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          {loading ? (
            <Loader />
          ) : (
            <Index.Box>
              <Index.Box className="modal-header">
                <Index.Typography
                  id="modal-modal-title"
                  className="modal-title"
                  variant="h6"
                  component="h2"
                >
                  Pending Job Requests (From Users)
                </Index.Typography>
                <img
                  src={PagesIndex.Svg.closeblack}
                  className="modal-close-icon"
                  onClick={handleClosePandingRequest}
                  alt=""
                />
              </Index.Box>
              <Index.Box className="modal-body">
                <Index.Box className="card-left">
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Job Title :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.title}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Posted By :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.userId?.name}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Posted On :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {dayjs(jobList?.jobStartDate).format("MM-DD-YYYY")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Total Payment :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      -
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Transaction Id  :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      -
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Phone Number :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {formatPhoneNumber(jobList?.mobileNumber)}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Email Address :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.userId?.email}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Point Of Contact Name :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.pointOfContactName}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Point Of Contact Number :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {formatPhoneNumber(jobList?.pointOfContactNumber || "-")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Location :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.location}
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Service :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.service}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Department :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.department}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Zip Code :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.zipCode}
                    </Index.Typography>
                  </Index.Box>

                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Date/Time :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                      sx={{ whiteSpace: "break-spaces" }}
                    >

                      {PagesIndex.showDateTime(jobList)}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Type :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.jobType?.jobType}
                      {console.log(jobList, "jobListjobListjobList")}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      No of Officer  :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.totalOfficer}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Information :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {jobList?.information}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="work-title-flex">
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-title-lable"
                    >
                      Total Hours :&nbsp;
                    </Index.Typography>
                    <Index.Typography
                      component="p"
                      variant="p"
                      className="work-value-text"
                    >
                      {PagesIndex.timeDifferenceCalculation(jobList)} hour
                      {/* {jobList?.jobSchedule !== undefined  ? PagesIndex.timeDifferenceCalculation(
                                    jobList?.jobSchedule[0]?.jobStartTime,
                                    jobList?.jobSchedule[0]?.jobEndTime) : 0} */}
                    </Index.Typography>
                  </Index.Box>
                  {jobList?.rate == undefined ? null : (
                    <Index.Box className="work-title-flex">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-title-lable"
                      >
                        Pay Rate (Per hour) :&nbsp;
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="work-value-text"
                      >
                        {jobList?.rate ? "$" : null}
                        {jobList?.rate}
                      </Index.Typography>
                    </Index.Box>
                  )}
                  <Index.Box
                    // className="job-title-text"
                    className="work-title-flex"
                  >
                    <Index.Typography
                      component="p"
                      variant="p"
                      // className="job-title-label"
                      className="work-title-lable"
                    >
                      Description :&nbsp;
                    </Index.Typography>
                    {/* <Index.Typography
                          component="p"
                          variant="p"
                          className="job-title-text-label"
                      >
                          $100
                      </Index.Typography> */}
                    <Index.Typography
                      component="p"
                      variant="p"
                      // className="job-title-text-label"
                      className="work-value-text"
                    >
                      {jobList?.description}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="modal-footer">
                <Index.Box className="modal-footer-btn-flex">
                    {jobList?.isRate === true && jobList?.status !== "APPROVED" && jobList?.status !== "REJECTED" ? (
                      <>
                        <Index.PrimaryButton
                          btnLabel={jobList?.status === "APPROVED" ? "Approved" : "Approve"}
                          disabled={isLoading || jobList?.status === "REJECTED"}
                          className="btn-primary bg-approve"
                          onClick={() => handleApproveRequest("APPROVED")}
                        >
                          {isLoading ? <Loader /> : "Click Me"}
                        </Index.PrimaryButton>

                        <Index.PrimaryButton
                          btnLabel={jobList?.status === "REJECTED" ? "Rejected" : "Reject"}
                          disabled={jobList?.status === "APPROVED"}
                          className="btn-primary bg-suspend"
                          onClick={() => handleApproveRequest("REJECTED")}
                        >
                          {isLoading ? <Loader /> : "Click Me"}
                        </Index.PrimaryButton>
                      </>
                    ) : null}

                </Index.Box>
              </Index.Box>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default PendingJobModal;
