import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";

export default function AdminClientHistoryDetailsofcompletedjobs({ userJobDetail }) {
  const [ratingvalue, setRatingValue] = React.useState(2);
  const navigate = PagesIndex.useNavigate();
  const { state } = PagesIndex.useLocation();
  const userJobDeatil = state;
  console.log("userJobDeatil", userJobDeatil);
  // us Formate
  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters from the input
    const cleaned = ("" + input).replace(/\D/g, "");

    // Format the cleaned phone number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return cleaned;
  };
  return (
    <>
      <>
        <Index.Box className="user-list-flex">
          <Index.Box className="title-header-flex">
            <img
              src={PagesIndex.Svg.leftArrow}
              onClick={() => navigate(-1)}
              className="left-arrow-ic"
              alt="search grey img"
            />
            <Index.Box>
              <Index.Typography
                component="p"
                variant="p"
                className="admin-page-title user-list-page-title"
              >
                {userJobDeatil?.title}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-client-history-sec">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              Assigned Officer & Associated Agency/Department
            </Index.Typography>
          </Index.Box>
          <>
            {userJobDeatil?.officerId.map((item, index) => {
              const officershortDetail = item?.officerId;
              return (
                <Index.Box className="card-main">
                  <Index.Box className="card-left">
                    <Index.Box className="officer-profile-flex">
                      <img
                        src={PagesIndex.Png.usericon}
                        className="prof-img"
                        alt=""
                      />
                      <Index.Box className="officer-detail">
                        <Index.Box className="work-title-flex">
                          <Index.Typography component="p" variant="p" className="work-title-lable">
                            {officershortDetail?.firstName ? (
                              <>
                                {officershortDetail?.firstName + " " + officershortDetail?.lastName}
                              </>
                            ) : null}
                          </Index.Typography>
                          <Index.Typography component="p" variant="p" className="work-value-text">
                            {officershortDetail?.officerType}
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box className="work-title-flex">
                          <Index.Typography
                            component="p" variant="p" className="work-title-lable">
                            Officer Assigned on :&nbsp;
                          </Index.Typography>
                          <Index.Typography component="p" variant="p" className="work-value-text">
                            {PagesIndex.dayjs(item?.jobAcceptedDate).format("MM-DD-YYYY")}
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box className="work-title-flex">
                          <Index.Typography component="p" variant="p" className="work-title-lable">
                            Officer Check-In :&nbsp;
                          </Index.Typography>
                          <Index.Typography component="p" variant="p" className="work-value-text">
                            {item?.jobCheckInTime && PagesIndex.dayjs(item.jobCheckInTime).isValid() ? PagesIndex.dayjs(item.jobCheckInTime).format("MM-DD-YYYY - HH:mm") : " Not checkin"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              );
            })}
          </>
          <>
            {[1].map((item, index) => (
              <Index.Box className="card-main">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                   {userJobDeatil?.department == "Police department"? "Associated Department:" : "Associated Agency:"}

                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                          {userJobDeatil?.approveBy?.agencyName}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            ))}
          </>
        </Index.Box>
        <Index.Box className="admin-client-history-sec">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              General Information
            </Index.Typography>
          </Index.Box>
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-detail">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Title :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.title}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Posted By :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.userId?.name}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex ">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Posted On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {PagesIndex.dayjs(userJobDeatil?.createdAt).format(
                      "MM-DD-YYYY"
                    )}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Total Payment :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Transaction Id :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Phone Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {formatPhoneNumber(userJobDeatil?.mobileNumber)}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Email Address :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.userId?.email}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Point Of Contact Name :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.pointOfContactName}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Point Of Contact Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {formatPhoneNumber(
                      userJobDeatil?.pointOfContactNumber || "-"
                    )}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Location :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.location}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Service :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.service}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Department :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.department}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Type :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.jobType?.jobType}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Zip Code :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.zipCode}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Date/Time :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {PagesIndex.showDateTime(userJobDeatil)}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Total Hours :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {PagesIndex.timeDifferenceCalculation(userJobDeatil)} hour
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Pay Rate :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.rate ? "$" : null}
                    {userJobDeatil?.rate}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    No of Officer :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.totalOfficer}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Description :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.description}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-client-history-sec">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              Organizer (Client)
            </Index.Typography>
          </Index.Box>
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-detail">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Name :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.userId?.name}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Mobile Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.userId?.mobileNumber}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Email Address :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {userJobDeatil?.userId?.email}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-client-history-sec">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              Job Log
            </Index.Typography>
          </Index.Box>
          <Index.Box className="card-main">
            <Index.Box className="card-left">
              <Index.Box className="officer-detail">
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Posted On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {PagesIndex.dayjs(userJobDeatil?.createdAt).format(
                      "MM-DD-YYYY"
                    )}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Transferred To :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Job Transferred On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </>
    </>
  );
}
