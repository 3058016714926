import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { rootReducer } from "./RootReducer";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import AdminSliceReducer from "./slice/admin-slice/AdminSlice";
import AgencySliceReducer from "./slice/agency-slice/AgencySlice";
import CommonSliceReducer from "./slice/common-slice/CommonSlice";
import thunk from "redux-thunk";
// import { rootReducer } from "./RootReducer";

const persistConfig = {
  key: "root",
  storage,
};

const combinedReducer = combineReducers({
  AdminSlice: AdminSliceReducer,
  AgencySlice: AgencySliceReducer,
  CommonSlice: CommonSliceReducer,
});

const persistedReducer = persistReducer(persistConfig, combinedReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

// const persistedReducer = persistReducer(persistConfig, AdminSliceReducer);

// export const store = configureStore({
//   reducer: {
//     admin: persistedReducer,
//   },
// });

export const persistor = persistStore(store);
