import axios from "axios";
var toToken = localStorage.getItem("AdminToken");
// const API_ENDPOINT = "http://35.177.56.74:3027/api/";
// const API_ENDPOINT = "http://192.168.1.20:3033/api/"; // live
const API_ENDPOINT = "https://api.protekt-security.appworkdemo.com/api/"; // https live
// const API_ENDPOINT = "http://localhot:3033/api/"; // live



const dataService = axios.create({
  baseURL: API_ENDPOINT,
});

dataService.interceptors.request.use(
  (config) => {
    config.headers.auth = localStorage.getItem("AdminToken");
    return config;
  },
  (error) => {
    return error;
  }
);

export default dataService;
