import React, { useEffect, useState } from "react";
import Index from "../../index";
import PagesIndex from "../../PageIndex";
// import { createBrowserHistory } from "history";
import OTPInput from "react-otp-input";
import { SignalCellularNull } from "@mui/icons-material";

export default function OtpVerification() {
  // const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const location = PagesIndex.useLocation();
  const navType = PagesIndex.useNavigationType();
  const state = location.state || {};
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  // const history = createBrowserHistory();

  const handleBackButton = (event) => {
    event.preventDefault();
    window.history.pushState(null, null, window.location.href);
  };


  const handleAdminOtpVerification = async (values) => {
    const data = {
      id: state.ResetId,
      otp: values.otp,
    };
    try {
      const response = await PagesIndex.DataService.post(
        PagesIndex.Api.Admin.OTP_VERIFY,
        data
      );
      const ResetId = response?.data?.data[0]?._id;
      PagesIndex.toast.success(response?.data?.message, {
        toastId: "customId",
      });
      if (ResetId) navigate("/admin/resetpassword", { state: { ResetId } });
      // setTimeout(() => {
      // }, 2000);
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, {
        toastId: "customId",
      });
    }
  };
  // Resend Otp functionality
  const resendOTP = async (setFieldValue) => {
    setMinutes(1);
    setSeconds(30);
    setFieldValue("otp", ""); // Clear the OTP field
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("email", state.email);
    try {
      const response = await PagesIndex.DataService.post(
        PagesIndex.Api.Admin.FORGOT_PASSWORD,
        urlEncoded
      );

      const ResetId = response?.data?.data?.id;
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message, { toastId: 'customId' });
      }
    } catch (error) {
      if (error?.response?.data?.status == 400) {
        PagesIndex.toast.error(error?.response?.data?.message, { toastId: 'customId' });
      }
    }

  };
  //Timeing counting functinality
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

//Browser back to specific url.
  useEffect(() => {
    if (navType == "POP") {
      navigate('/admin')
    }
  }, [navigate])

  return (
    <>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main position-relative">
          {/* <PagesIndex.AuthBackground /> */}
          <Index.Box className="login-left-bg "></Index.Box>
          <Index.Box className="login-overlay"></Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <PagesIndex.Formik
            validationSchema={PagesIndex.adminOtpVerfiySchema}
            initialValues={{
              otp: "",
            }}
            onSubmit={handleAdminOtpVerification}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                {console.log(values, "values")}
                <Index.Box className="admin-login-box">
                  <Index.Box className="admin-login-main">
                    <Index.Box className="admin-login-inner">
                      <Index.Typography
                        component="h2"
                        variant="h2"
                        className="admin-wel-text"
                      >
                        OTP Verification
                      </Index.Typography>
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="admin-sign-para common-para"
                      >
                        Enter OTP
                      </Index.Typography>
                      <Index.Box className="input-box">
                        <Index.Box className="otp-main">
                          <Index.Box className="form-group">
                            <Index.Box className="form-control">
                              <OTPInput
                                name="otp"
                                numInputs={4}
                                inputType="number"
                                inputStyle="otp-verification-input"
                                containerStyle="otp-verification-input-wrap"
                                value={values.otp}
                                onKeyDown={(e) => {
                                  if (/\D/g.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                onChange={(file) => {
                                  const numericOtp = file.replace(/\D/g, "");
                                  setFieldValue("otp", numericOtp);
                                }}
                                // onChange={({target})=>{
                                //   setOtp(target.value);
                                // }}
                                error={Boolean(errors.otp)}
                                renderInput={(props) => <input {...props} />}
                              />
                              {errors.otp && touched.otp && (
                                <Index.FormHelperText error>
                                  {errors.otp}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                            <Index.Box className="countdown-box">
                              <Index.Box>
                                <Index.Typography className="countdown-text">
                                  Time Remaining : {" "}
                                  <span style={{ fontWeight: 600 }}>
                                    {minutes < 10 ? `0${minutes}` : minutes}:
                                    {seconds < 10 ? `0${seconds}` : seconds}
                                  </span>
                                </Index.Typography>
                              </Index.Box>
                              {minutes === 0 && seconds === 0 ? (
                                <>
                                  <Index.Typography
                                    className="resend-text"
                                    onClick={() => resendOTP(setFieldValue)}
                                    style={{ cursor: "pointer", color: "blue" }}
                                  >
                                    Resend OTP ?
                                  </Index.Typography>
                                </>
                              ) : (
                                // <Index.Box>
                                // <Index.Typography
                                //   className="resend-text"
                                //   style={{ color: "gray" }}
                                // >
                                //   Resend OTP in{" "}
                                //   {minutes < 10 ? `0${minutes}` : minutes}:
                                //   {seconds < 10 ? `0${seconds}` : seconds}
                                // </Index.Typography>
                                // </Index.Box>
                                null
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="btn-main-primary login-btn-main">
                        <Index.Button
                          className="btn-primary login-btn"
                          type="submit"
                        >
                          Next
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}
