export const webRoleMaster = [
    // {
    //   name: "Dashboard",
    //   link: "/admin/companyDashboard",
    //   tag: "companyDashboard",
    //   isChild: false,
    // },
 
    //   name: "Water Supply Orders",
    //   link: "/admin/waterSupply",
    //   tag: "waterSupply",
    //   isChild: false,
   
    {
      name: "Setup",
      link: "/admin/#",
      tag: "setup",
      isChild: true,
      child: [
        {
          name: "Job Type Management",
          link: "/admin/jobtype",
          tag: "Job_Type",
        },
        // {
        //   name: "Active Users",
        //   link: "/admin/activeUsers",
        //   tag: "activeUsers",
        // },
        // {
        //   name: "Port",
        //   link: "/admin/port",
        //   tag: "port",
        // },
        // {
        //   name: "Craft",
        //   link: "/admin/craft",
        //   tag: "craft",
        // },
        // {
        //   name: "GST Rate",
        //   link: "/admin/rate",
        //   tag: "rate",
        // },
        // {
        //   name: "Party",
        //   link: "/admin/party",
        //   tag: "party",
        // },
        // {
        //   name: "Trader Master",
        //   link: "/admin/traders",
        //   tag: "traders",
        // },
        // {
        //   name: "Zip code Management",
        //   link: "/admin/zipcode",
        //   tag: "zipcode",
        // },
        // {
        //   name: "Tanker Master",
        //   link: "/admin/tankers",
        //   tag: "tankers",
        // },
        // {
        //   name: "Sales Master",
        //   link: "/admin/salesMaster",
        //   tag: "salesMaster",
        // },
        // {
        //   name: "Notification",
        //   link: "/admin/notification",
        //   tag: "notification",
        // },
      ],
    },
  ];
  
  export const zipManagement = [
    // {
    //   tag: "zip_code",
    //   name: "Zip code Management",
    // },
    // {
    //   tag: "value",
    //   name: "view",
    // },
    {
      tag: "add",
      name: "Add",
    },
    // {
    //   tag: "Suspend",
    //   name: "Suspend",
    // },
    // {
    //   tag: "read",
    //   name: "Read",
    // },
    {
      tag: "edit",
      name: "Edit",
    },
    {
      tag: "delete",
      name: "Delete",
    },
    // {
    //   tag: "appBreakdown",
    //   name: "Breakdown",
    // },
    // {
    //   tag: "directory",
    //   name: "Directory",
    // },
    // {
    //   tag: "tankerDashboard",
    //   name: "Tanker Dashboard",
    // },
    // {
    //   tag: "tankerSetup",
    //   name: "Tanker Setup"
    // },
    // {
    //   tag: "tugsTimings",
    //   name: "Tugs Timings"
    // },
    // {
    //   tag: "waterSupplyTimings",
    //   name: "Water Supply Timings"
    // },
    // {
    //   tag: "slopeSludgeTimings",
    //   name: "Slope Sludge Timings"
    // },
    // {
    //   tag: "pipeline",
    //   name: "Pipeline Orders"
    // },
  ];

  export const jobTypeManagement = [
    // {
    //   tag: "Job_Type",
    //   name: "Job Type Management",
    // },
    // {
    //   tag: "value",
    //   name: "view",
    // },
    {
      tag: "add",
      name: "Add",
    },
    {
      tag: "read",
      name: "Read",
    },
    // {
    //   tag: "Suspend",
    //   name: "Suspend",
    // },
    {
      tag: "edit",
      name: "Edit",
    },
    {
      tag: "delete",
      name: "Delete",
    },
   
   
  ];
  export const skillManagement = [
    // {
    //   tag: "value",
    //   name: "view",
    // },
    {
      tag: "add",
      name: "Add",
    },
    {
      tag: "read",
      name: "Read",
    },
    {
      tag: "edit",
      name: "Edit",
    },
    {
      tag: "delete",
      name: "Delete",
    },
   
   
  ];

  export const agencyManagement = [
    // {
    //   tag: "Skills",
    //   name: "Agency Management",
    // },
    // {
    //   tag: "value",
    //   name: "view",
    // },
    {
      tag: "add",
      name: "Add",
    },
    {
      tag: "read",
      name: "Read",
    },
    {
      tag: "Suspend",
      name: "Suspend",
    },
    {
      tag: "edit",
      name: "Edit",
    },
    {
      tag: "delete",
      name: "Delete",
    },
   
   
  ];

  export const clientManagement = [
    // {
    //   tag: "value",
    //   name: "view",
    // },
    {
      tag: "add",
      name: "Add",
    },
    {
      tag: "read",
      name: "Read",
    },
    {
      tag: "Suspend",
      name: "Suspend",
    },
    {
      tag: "edit",
      name: "Edit",
    },
    {
      tag: "delete",
      name: "Delete",
    },
   
   
  ];
  export const officerManagement = [
    // {
    //   tag: "officer",
    //   name: "Officer Management",
    // },
    // {
    //   tag: "value",
    //   name: "Access Sidebar",
    // },
    {
      tag: "add",
      name: "Add",
    },
    {
      tag: "read",
      name: "Read",
    },
    {
      tag: "Suspend",
      name: "Suspend",
    },
    {
      tag: "edit",
      name: "Edit",
    },
    {
      tag: "delete",
      name: "Delete",
    },
   
   
  ];
  export const jobManagement = [
    {
      tag: "value",
      name: "Access Sidebar",
    }
    // {
    //   tag: "add",
    //   name: "Add",
    // },
    // {
    //   tag: "read",
    //   name: "Read",
    // },
    // {
    //   tag: "Suspend",
    //   name: "Suspend",
    // },
    // {
    //   tag: "edit",
    //   name: "Edit",
    // },
    // {
    //   tag: "delete",
    //   name: "Delete",
    // },
   
  ];
  export const cmsManagement = [
    {
      tag: "edit",
      name: "Edit",
    },
   
  ];
  export const extraWorkPermit = [
    {
      tag: "add",
      name: "Add",
    },
    {
      tag: "read",
      name: "Read",
    },
    {
      tag: "Suspend",
      name: "Suspend",
    },
    {
      tag: "edit",
      name: "Edit",
    },
    {
      tag: "delete",
      name: "Delete",
    },
   
   
  ];

  export const PaymentDetails = [
    // {
    //   tag: "value",
    //   name: "Access Sidebar",
    // },
    {
      tag: "add",
      name: "Add",
    },
    {
      tag: "read",
      name: "Read",
    },
    {
      tag: "Suspend",
      name: "Suspend",
    },
    {
      tag: "edit",
      name: "Edit",
    },
    {
      tag: "delete",
      name: "Delete",
    },
   
   
  ];
  export const RatingAndReviewManagement = [
    {
      tag: "value",
      name: "Access Sidebar",
    },
    // {
    //   tag: "add",
    //   name: "Add",
    // },
    // {
    //   tag: "read",
    //   name: "Read",
    // },
    // {
    //   tag: "Suspend",
    //   name: "Suspend",
    // },
    // {
    //   tag: "edit",
    //   name: "Edit",
    // },
    // {
    //   tag: "delete",
    //   name: "Delete",
    // },
   
  ];
  export const TransferJobManagement = [
    // {
    //   tag: "value",
    //   name: "Access Sidebar",
    // },
    {
      tag: "add",
      name: "Add",
    },
    // {
    //   tag: "read",
    //   name: "Read",
    // },
    // {
    //   tag: "Suspend",
    //   name: "Suspend",
    // },
    {
      tag: "edit",
      name: "Edit",
    },
    // {
    //   tag: "delete",
    //   name: "Delete",
    // },
   
  ];

