import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DataService from "../config/DataService";
// NEW_EXPORT_EXCEL
export const newExportExcel = async (newUrl, newFileName, parameters) => {
  try {
    // const excelResponse = await DataService(token).post(
    //   API.Admin.EXPORT_MERCHANAT_DATA,
    //   { exportDetails, "Kyc List": "Kyc List" },
    //   { responseType: "blob" }
    // );

    const excelResponse = await DataService.post(
      `${newUrl}`,
      parameters !== null && parameters !== undefined ? parameters : "",
      // { newFileName: "newFileName" },
      { responseType: "blob" }
    );

    console.log("excelResponse", excelResponse);
    // Create a Blob object from the excelResponse data.
    const blob = new Blob([excelResponse.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    // Create a temporary URL for the Blob
    const url = window.URL.createObjectURL(blob);
    const filename = `${newFileName}${Date.now()}.xlsx`;
    // Create a link element and trigger a click event to start the download
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    // Clean up the temporary URL
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("CSV export failed:", error);
  }
};
