import React from "react";
import Index from "../../container/index";
import PagesIndex from "../../components/PagesIndex";

export default function SearchMaster({ setSearchValue }) {
    return (
        <>
            <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                    <Index.Box className="form-group">
                        <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setSearchValue(e?.target?.value?.toLowerCase())}
                        />
                        <img
                            src={PagesIndex.Svg.search}
                            className="search-grey-img"
                            alt="search grey img"
                        ></img>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )

}
