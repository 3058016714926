import React, { memo, useEffect, useRef, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import { io } from "socket.io-client";
const ENDPOINT = "https://api.protekt-security.appworkdemo.com";

const ChatModal = ({
  open,
  onClose,
  chatId,
  setChatId,
  agencyJobData,
  selectedData,
}) => {
  const [chatList, setChatList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [messageInput, setMessageInput] = useState("");
  const [socketData, setSocketData] = useState(null);
  const token = localStorage.getItem("AgencyToken");
  const agencyLoginData = PagesIndex.useSelector(
    (state) => state.AgencySlice.agencyLoginData
  );

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, []);

  useEffect(() => {
    const socket = io(ENDPOINT, {
      autoConnect: true,
      transports: ["websocket"],
    });

    socket.on("connect", () => {
      setSocketData(socket);

      socket.emit("JoinChat", {
        id: chatId,
      });

      socket.on("Message", (newMessage) => {
        console.log("New message received:", newMessage);
        if (Array.isArray(newMessage)) {
          setChatList(newMessage);
        } else if (newMessage && typeof newMessage === "object") {
          setChatList((prevChatList) => {
            const messageExists = prevChatList.some(
              (msg) => msg._id === newMessage._id
            );
            if (!messageExists) {
              return [...prevChatList, newMessage];
            }
            return prevChatList;
          });
        } else {
          console.error(
            "Received message is not in the expected format:",
            newMessage
          );
        }
      });
    });

    socket.on("connect_error", (error) => {
      console.log("Socket Error", error);
    });

    return () => {
      socket.disconnect();
    };
  }, [chatId]);

  const sendMessage = () => {
    if (messageInput) {
      const newMessage = {
        _id: Date.now().toString(),
        jobId: chatId,
        messages: [
          {
            senderId: agencyLoginData?._id,
            message: messageInput,
            from: agencyLoginData?.agencyName,
            _id: Date.now().toString(),
            time: new Date().toISOString(),
          },
        ],
        isDeleted: false,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        __v: 0,
      };

      // Append the message to chatList immediately for faster feedback
      setChatList((prevChatList) => {
        const messageExists = prevChatList.some(
          (msg) => msg._id === newMessage._id
        );
        if (!messageExists) {
          return [...prevChatList, newMessage];
        }
        return prevChatList;
      });

      socketData.emit("NewMessage", {
        id: chatId,
        from: agencyLoginData?.agencyName,
        message: messageInput,
        senderId: agencyLoginData?._id,
      });

      setMessageInput("");
    }
  };

  const divRef = useRef(null);
  useEffect(() => {
    if (chatList.length !== 0) {
      divRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [chatList]);

  useEffect(() => {
    if (!open) {
      setChatList([]);
    }
  }, [open]);

  return (
    <Index.SwipeableDrawer
      className="filter-main chat-box-section"
      anchor="right"
      open={open}
      onClose={() => {
        onClose();
        setChatId("");
        setTabValue(0);
      }}
      onOpen={() => {}}
    >
      <Index.Box className="filter-header chat-header">
        <Index.Typography className="filter-title chat-title">
          {tabValue === 0 ? (
            <>
              <Index.ArrowBackIosNewIcon
                onClick={() => {
                  setTabValue(1);
                }}
                className="chat-menu-icon"
              />
              <Index.Typography className="chat-user-name">
                {selectedData?.title ?? ""}
              </Index.Typography>
            </>
          ) : (
            "User List"
          )}
        </Index.Typography>

        <img
          src={PagesIndex.Svg.closeicon}
          onClick={() => {
            onClose();
            setChatId("");
            setTabValue(0);
          }}
          alt=""
        />
      </Index.Box>

      {console.log("138", chatList)}
      {tabValue === 0 && (
        <Index.Box className="chat-userlist-main-box">
          {chatList &&
            chatList.map((list, index) => (
              <React.Fragment key={index}>
                {list.messages && list.messages.length > 0 && (
                  <>
                    {agencyLoginData?._id === list.messages[0]?.senderId ? (
                      <Index.Box className="chat-right-text">
                        <Index.Typography className="chat-userlist-text">
                          {list.messages[0]?.message}
                        </Index.Typography>
                      </Index.Box>
                    ) : (
                      <Index.Box className="chat-left-text">
                        <Index.Typography className="chat-userlist-text">
                          {list.messages[0]?.message}
                        </Index.Typography>
                      </Index.Box>
                    )}
                  </>
                )}
                <div ref={divRef} />
              </React.Fragment>
            ))}
        </Index.Box>
      )}
      <Index.Box className="chat-input-btn-box">
        <Index.TextField
          fullWidth
          className="chat-text-fied"
          size="small"
          value={messageInput}
          multiline
          row={3}
          maxRows={3}
          onChange={(e) => setMessageInput(e.target.value)}
        />
        <Index.Button className="chat-btn" onClick={sendMessage}>
          <Index.SendIcon className="chat-menu-icon" />
        </Index.Button>
      </Index.Box>
    </Index.SwipeableDrawer>
  );
};

export default memo(ChatModal);
