import React, { useState } from "react";
import PagesIndex from "../../../PageIndex";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
// import Loader from "../../../../common/loader";
import Index from "../../../index";
import { useNavigate } from "react-router-dom";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AgencyPaymentConfigureSuccess = () => {

    const navigate = useNavigate();
    const { agencyLoginData } = PagesIndex.useSelector(
        (state) => state.AgencySlice
      );
    
      const AgencyPermission = agencyLoginData?.roleId?.AgencyPermission;
      const roleName = agencyLoginData?.roleId?.Permission_name;
      console.log(roleName,"roleName26")

  
//   const [isLoading, setIsLoading] = useState(false);
  const [PaymentAgentSuccessModalopen, setPaymentAgentSuccessModalopen] = useState(true);
  const paymentAgenthandleClose = () => setPaymentAgentSuccessModalopen(false);

  const handlePaymentConfirmation = () => {
    // admin/payout'
    DataService.get(Api.Agency.PAYMENT_UPDATE_CONFIG)
      .then((response) => {
        console.log(response);
        if (response?.data?.status === 200) {
          PagesIndex.toast.success(response?.data?.message, {
            toastId: "customId",
          });
          setPaymentAgentSuccessModalopen(false);
          setTimeout(() => {
            navigate(`/${roleName}/dashboard`)
            // setIsLoading(false);
          }, 1000);
        }
      })
      .catch((err) => {
        PagesIndex.toast.error(err.response.data.message, {
          toastId: "customId",
        });
        // setIsLoading(false);
      });
  };

  return (
    <>
      <Index.Modal
        open={PaymentAgentSuccessModalopen}
        onClose={paymentAgenthandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box
          sx={style}
          className="add-user-modal-inner-main modal-inner extra-delete"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            > Payment
            </Index.Typography>
            {/* <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={paymentAgenthandleClose}
              alt=""
            /> */}
          </Index.Box>
          <Index.Box className="modal-body">
            <Index.Box className="admin-modal-hgt-scroll">
            <Index.Box className="delete-modal-body">
                  {/* <img
                    src={PagesIndex.Svg.deletecross}
                    alt="Delete"
                    className="delete-icon"
                  /> */}
                  <Index.Typography component="h4" className="delete-title"> Congratulations  Your Payment Configuartion  Process successfully </Index.Typography>
                 
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="modal-footer">
            <Index.Box className="modal-footer-btn-flex">
              {/* <Index.PrimaryButton
                btnLabel="No"
                className="btn-primary bg-approve"
                onClick={paymentAgenthandleClose}
                // disabled={isLoading}
              > */}
                {/* {isLoading ? <Loader /> : "Click Me"} */}
              {/* </Index.PrimaryButton> */}

              <Index.PrimaryButton
                btnLabel="Yes, Please"
                onClick={() => handlePaymentConfirmation()}
                className="btn-primary bg-suspend"                                                                                            
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
    </Index.Modal >
    </>
  )
};

export default AgencyPaymentConfigureSuccess;
