import React from 'react'
import PagesIndex from '../../../PageIndex';
import Index from '../../../index';

export default function AdminAgentDetailsActive() {
  const { state } = PagesIndex.useLocation();
  const navigate = PagesIndex.useNavigate();
  const adminActivejobsDetail = state.item;
  const agentId = state.agentId
  // role permission
  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  // const AdminAgencyName = adminLoginData?.roleId?.SubAdminPermission;
  // const roleName = adminLoginData?.name;
  const AdminAgencyName = adminLoginData?.name;
  // us Formate
  const formatPhoneNumber = (input) => {
    // Remove all non-digit characters from the input
    const cleaned = ("" + input).replace(/\D/g, "");

    // Format the cleaned phone number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return cleaned;
  };

  return (
    <div>
      <Index.Box className="user-list-flex">
        <Index.Box className="admin-page-title user-list-page-title">

          <Index.Box
            onClick={() => navigate(-1)}
            // onClick={() => {
            //   navigate('/admin/agentmanagement/adminagentdetails', {
            //     state: agentId
            //   })
            // }}
          >
            <img
              src={PagesIndex.Svg.leftArrow}
              className="left-arrow-ic"
              alt="search grey img"
            />
            {adminActivejobsDetail?.title} <span>for</span> $
            {adminActivejobsDetail?.rate}<span> Per hour</span>
          </Index.Box>

        </Index.Box>
      </Index.Box>
      <Index.Box className="mob-inner-scroll-area">
        <Index.Box className="admin-sub-flex">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              component="p"
              variant="p"
              className="admin-sub-title"
            >
              Assigned Officer
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        {adminActivejobsDetail?.officerId.map((item, index) => {
          const officershortDetail = item?.officerId;
          return (
            <>
              <Index.Box className="card-main">
                <Index.Box className="card-left">
                  <Index.Box className="officer-profile-flex">
                    <img
                      src={PagesIndex.Png.usericon}
                      className="prof-img"
                      alt=""
                    />
                    <Index.Box className="officer-detail">
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          {officershortDetail?.firstName ? (
                            <>
                              {officershortDetail?.firstName +
                                " " +
                                officershortDetail?.lastName}
                            </>
                          ) : null}{" "}
                          : &nbsp;
                          &nbsp;
                        </Index.Typography>
                        {/* </Index.Box>
                    <Index.Box className="job-title-text"> */}
                        {officershortDetail?.officerType == "Active Police Officer" ? (null) : (
                          <Index.Typography
                            component="p"
                            variant="p"
                            className="work-value-text"
                          >
                            ({officershortDetail?.officerType})
                          </Index.Typography>
                        )}
                      </Index.Box>
                      <Index.Box className="work-title-flex">
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-title-lable"
                        >
                          Officer Assigned on :&nbsp;
                        </Index.Typography>
                        <Index.Typography
                          component="p"
                          variant="p"
                          className="work-value-text"
                        >
                          {PagesIndex.dayjs(item?.jobAcceptedDate).format(
                            "MM-DD-YYYY"
                          )}
                        </Index.Typography>
                      </Index.Box>

                    </Index.Box>
                  </Index.Box>

                </Index.Box>
              </Index.Box>
            </>
          );
        })}


        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            General Information
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Title :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.title}
                </Index.Typography>
              </Index.Box>


              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted By :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.userId?.name}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.dayjs(adminActivejobsDetail?.createdAt).format(
                    "MM-DD-YYYY"
                  )}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Payment :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Transaction Id  :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Phone Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {formatPhoneNumber(adminActivejobsDetail?.mobileNumber)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.userId?.email}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Point Of Contact Name :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.pointOfContactName}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Point Of Contact Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {formatPhoneNumber(
                    adminActivejobsDetail?.pointOfContactNumber || "-"
                  )}
                </Index.Typography>
              </Index.Box>



              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Location :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.location}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Service :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.service}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Department :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.department}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Type :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.jobType?.jobType}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Zip Code :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.zipCode}
                </Index.Typography>
              </Index.Box>
              {adminActivejobsDetail?.supervisor ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Supervisor :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminActivejobsDetail?.supervisor}
                  </Index.Typography>
                </Index.Box>) : (null)}
              {adminActivejobsDetail?.nonSupervisor ? (
                <Index.Box className="work-title-flex">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-title-lable"
                  >
                    Non Supervisor :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="work-value-text"
                  >
                    {adminActivejobsDetail?.nonSupervisor}
                  </Index.Typography>
                </Index.Box>
              ) : (null)}

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Date/Time :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >


                  {PagesIndex.showDateTime(adminActivejobsDetail)}

                </Index.Typography>
              </Index.Box>

              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Total Hours :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.timeDifferenceCalculation(adminActivejobsDetail)} hour

                  {/* {PagesIndex.timeDifferenceCalculation(
                adminActivejobsDetail?.jobSchedule[0]?.jobStartTime,
                adminActivejobsDetail?.jobSchedule[0]?.jobEndTime
              )} */}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Pay Rate :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.rate ? "$" : null}
                  {adminActivejobsDetail?.rate}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  No of Officer :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.totalOfficer}
                </Index.Typography>
              </Index.Box>
              <Index.Box
                className="work-title-flex"
              >
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Description :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.description}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

        </Index.Box>

        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Organizer (Client)
          </Index.Typography>
        </Index.Box>
        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Name :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.userId?.name}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Mobile Number :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {formatPhoneNumber(adminActivejobsDetail?.userId?.mobileNumber)}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Email Address :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {adminActivejobsDetail?.userId?.email}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="admin-sub-title-main">
          <Index.Typography
            component="p"
            variant="p"
            className="admin-sub-title"
          >
            Job Log
          </Index.Typography>
        </Index.Box>

        <Index.Box className="card-main">
          <Index.Box className="card-left">
            <Index.Box className="officer-detail">
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Posted On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  {PagesIndex.dayjs(adminActivejobsDetail?.createdAt).format(
                    "MM-DD-YYYY"
                  )}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Transferred To :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
              <Index.Box className="work-title-flex">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-title-lable"
                >
                  Job Transferred On :&nbsp;
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="work-value-text"
                >
                  -
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  )
}
