import React from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";
import { Api } from "../../../../../config/Api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AdminAgentRestrict = (props) => {
  const {
    restrictAgenthandleClose,
    restrictAgentModalopen,
    restrictAgentStatus,
    getAgentListData,
    itmeData
  } = props;

  const handleAgentRestrictedConfirm = async () => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("isApprove", restrictAgentStatus?.status ? true : false);
    urlEncoded.append(
      "status",
      restrictAgentStatus?.status ? "RESTRICT" : "ACTIVE"
    );
    urlEncoded.append("id", restrictAgentStatus?.agentId);
    console.log("urlEncoded", [...urlEncoded]);
    try {
      const response = await DataService.post(
        PagesIndex.Api.Admin.AGENT_RESTRICT_ACTIVE,
        urlEncoded
      );
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message, { toastId: "customId" });
        getAgentListData();
        restrictAgenthandleClose();
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, { toastId: "customId" });
    }
  };
  return (
    <>
      <Index.Modal
        open={restrictAgentModalopen}
        onClose={restrictAgenthandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >
        <Index.Box
          sx={style}
          className="add-user-modal-inner-main modal-inner extra-delete"
        >

          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            > {restrictAgentStatus?.status == false ? "Active" : "Restrict"} {itmeData.departmentName == "Agency" ? "Agency" : "Department" }
            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={restrictAgenthandleClose}
              alt=""
            />
          </Index.Box>

          <Index.Box className="modal-body">
            <Index.Box className="delete-modal-body">
              <img
                src={PagesIndex.Svg.deletecross}
                alt="Delete"
                className="delete-icon"
              />
              <Index.Typography component="h6" className="delete-title">Are you sure?</Index.Typography>
              <Index.Typography component="p" className=" delete-message">
                Do you really want to {restrictAgentStatus?.status == false ? "active" : "restrict"} {itmeData.departmentName == "Agency" ? "agency" : "department" }?
              </Index.Typography>
            </Index.Box>
          </Index.Box>

          <Index.Box className="modal-footer">
            <Index.Box className="modal-footer-btn-flex">
              <Index.PrimaryButton
                btnLabel="Confirm"
                className="btn-primary bg-approve"
                onClick={() => handleAgentRestrictedConfirm()}
              />

              <Index.PrimaryButton
                btnLabel="Cancel"
                className="btn-primary bg-suspend"
                onClick={() => restrictAgenthandleClose()}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AdminAgentRestrict;
