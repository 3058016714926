import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";

function RatingManagement() {
  
  return (
    <>
      <Index.Box className="rating-sec ">
        <Index.Box className="rating-title-left">
          <Index.Typography
            component="h6"
            variant="h6"
            className="rating-head-label"
          >
            Job Title
          </Index.Typography>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Organizer/Client:</span> User Name
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Location:</span> Location
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Officer:</span> Officer Name
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Associated Agency:</span> Agency Name
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Date:</span> 00/00/0000
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="rating-title-right">
          <Index.PrimaryButton btnLabel="View"  className="btn-primary" />
        </Index.Box>
      </Index.Box>

      <Index.Box className="rating-sec ">
        <Index.Box className="rating-title-left">
          <Index.Typography
            component="h6"
            variant="h6"
            className="rating-head-label"
          >
            Job Title
          </Index.Typography>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Organizer/Client:</span> User Name
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Location:</span> Location
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Officer:</span> Officer Name
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Associated Agency:</span> Agency Name
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Date:</span> 00/00/0000
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="rating-title-right">
          <Index.PrimaryButton btnLabel="View" className="btn-primary" />
        </Index.Box>
      </Index.Box>

      <Index.Box className="rating-sec ">
        <Index.Box className="rating-title-left">
          <Index.Typography
            component="h6"
            variant="h6"
            className="rating-head-label"
          >
            Job Title
          </Index.Typography>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Organizer/Client:</span> User Name
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Location:</span> Location
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Officer:</span> Officer Name
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Associated Agency:</span> Agency Name
            </Index.Typography>
          </Index.Box>
          <Index.Box className="rating-title-text">
            <Index.Typography
              component="p"
              variant="p"
              className="rating-title-text-label"
            >
              <span>Date:</span> 00/00/0000
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box className="rating-title-right">
          <Index.PrimaryButton btnLabel="View" className="btn-primary" />
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default RatingManagement;
