import React, { useEffect, useState } from "react";
import Index from "../../../index";
import PagesIndex from "../../../PageIndex";
import PropTypes from "prop-types";
import { SwipeableDrawer } from "@mui/material";
import { Api } from "../../../../config/Api";
import DataService from "../../../../config/DataService";
import { getClientJobList } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import { getJobHistoryList } from "../../../../redux-toolkit/slice/common-slice/CommonServices";
import { InsertEmoticon } from "@mui/icons-material";
import { timeDifferenceCalculation } from "../../../../common/timeCalculation/CalculateTimeDifference";
import AdminPendingJobOfficerAceeptModal from "../jobmanagement/jobManageModal/AdminPendingJobOfficerAceeptModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdminJobHistory() {
  // Pending Job request modal
  const navigate = PagesIndex.useNavigate();
  const dispatch = PagesIndex.useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const location = PagesIndex.useLocation();
  const {officerId} = PagesIndex.useParams();
  const [searchValue, setSearchValue] = useState("");
  const [tabValue, setTabValue] = useState(location?.state ? location?.state : "ActiveJobs");
  // const officerId = new URLSearchParams(location.search).get("id");
  const [jobHistoryData, setJobHistoryData] = useState([]);
  const [filteredJobHistoryData, setFilteredJobHistoryData] = useState([]);
  // Pagination states and methods
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Pending Job request after ofiice modal
  const [pendingjobopen, setPendingJobOpen] = React.useState(false);
  const [pendingjobOfficerAcceptData, setPendingjobOfficerAcceptData] =
    useState();
  const pendingjobhandleOpen = (item) => {
    setPendingjobOfficerAcceptData(item);
    setPendingJobOpen(true);
  };

  console.log(tabValue, "tabValue");
  const pendingjobhandleClose = () => setPendingJobOpen(false);

  // filter
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Index.Box
      className="filter-main"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    ></Index.Box>
  );
// Get Job History
  const AdminJobHistory = () => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("type", tabValue);
    urlEncoded.append("officerId", officerId);
    dispatch(getJobHistoryList(urlEncoded)).then((res) => {
      if (res?.payload?.status == 200) {
        setJobHistoryData(res?.payload?.data);
        setFilteredJobHistoryData(res?.payload?.data);
      }
    });
  };
  // useEffect(() => {
  //   AdminJobHistory();
  // }, [tabValue]);
  useEffect(() => {
    if (location.state) {
      console.log(location.state,"sonam")
      AdminJobHistory(location?.state?.tabValue || location.state);
    } else {
      setTabValue("ActiveJobs");
    }
  }, [location]);
  useEffect(() => {
    AdminJobHistory("ActiveJobs");
  }, [tabValue]);

  // Search on table
  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
    const filteredList = jobHistoryData?.filter((data) =>
      `${data?.name?.toLowerCase()} 
    ${data?.title?.toLowerCase()} ${data?.location?.toLowerCase()}${data.mobileNumber}
    ${data?.approveBy?.agencyName.toLowerCase()}${data?.rate}
    ${data?.email?.toLowerCase()}`.includes(e?.target?.value?.toLowerCase())
    );
    setFilteredJobHistoryData(filteredList);
    setPage(0);
  };

  return (
    <>
      <Index.Box className="agency-job-history-wrape">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            <Index.Box
              onClick={() => {
                // navigate(
                //   {
                //     pathname:`/${roleName}/officer-management-detail`,
                //     search: PagesIndex?.createSearchParams({
                //       id: officerId,
                //     }).toString(),
                //   },
                //   { state: { isPending: false } }
                // );
                navigate(`/admin/admin-officer-detail/${officerId}`,
                  // search: PagesIndex?.createSearchParams({
                  //   id: officerId,
                  // }).toString(),
                );
              }}
              component="span"
            >
              <img
                src={PagesIndex.Svg.leftArrow}
                className="left-arrow-ic"
                alt="search grey img"
              />
            </Index.Box>
            Officer's Job History
          </Index.Typography>
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search"
                    value={searchValue}
                    onChange={handleSearch}
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.value.trim() === "") {
                        e.preventDefault();
                      }
                    }}
                  />
                  <img
                    src={PagesIndex.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            {/* <Index.Box className="filter-main">
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Index.Box className="export-btn-main border-btn-main">
                    <Index.Button
                      className="export-btn border-btn"
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <img
                        src={PagesIndex.Svg.filter}
                        className="down-icon"
                        alt="download icon"
                      />
                      Filter
                    </Index.Button>
                  </Index.Box>
                  <SwipeableDrawer
                    className="filter-main"
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Index.Box className="filter-header">
                      <Index.Typography className="filter-title">
                        User Filter
                      </Index.Typography>
                      <img
                        src={PagesIndex.Png.close}
                        className="filter-close-icon"
                        onClick={toggleDrawer(anchor, false)}
                      />
                    </Index.Box>
                    <Index.Box className="filter-inner-main">
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          City
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Status
                        </Index.FormHelperText>
                        <Index.Box className="checkbox-main filter-checkbox-main input-box">
                          <Index.FormControlLabel
                            control={<Index.Checkbox defaultChecked />}
                            label="Active"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Deactivate"
                            className="checkbox-lable"
                          />
                          <Index.FormControlLabel
                            control={<Index.Checkbox />}
                            label="Pending"
                            className="checkbox-lable"
                          />
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-footer">
                      <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                        <Index.Button className="border-btn filter-cancel-btn">
                          Cancel
                        </Index.Button>
                        <Index.Button className="btn-primary filter-btn">
                          Filter
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
        {/* className="dashboard-content job-history-main p-t-0" */}
        <Index.Box>
          <Index.Box className="officer-tab-main">
            <Index.Box className="cus-tabs-main-box">
              <Index.Box sx={{ width: "100%" }}>
                <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Index.Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="cus-tabs-main"
                  >
                    <Index.Tab
                      label="Active Job"
                      // {...a11yProps(0)}
                      value="ActiveJobs"
                      className="cus-tab"
                    />
                    <Index.Tab
                      label="Completed Job"
                      // {...a11yProps(1)}
                      value="CompletedJobs"
                      className="cus-tab"
                    />
                  </Index.Tabs>
                </Index.Box>
                <TabPanel
                  value={tabValue}
                  index="ActiveJobs"
                  className="cus-tabpanel"
                >
                  <Index.Box className="tabpanel-main">
                    {filteredJobHistoryData?.length ? (
                      filteredJobHistoryData.map((item, index) => {
                        return (
                          <>
                            <Index.Box className="common-card job-mamangment-card">
                              <Index.Box className="admin-sub-flex">
                                <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="admin-sub-title"
                                  >
                                    Job Title :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="admin-sub-title-value-text"
                                  >
                                    {item?.title}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.PrimaryButton
                                  btnLabel="View"
                                  className="btn-primary"
                                  onClick={() =>
                                    navigate(`/admin/admin-job-history-view/${officerId}`,
                                      {state:{item,tabValue: "ActiveJobs"}},
                                  )
                                  }
                                />
                              </Index.Box>
                              <Index.Box className="card-main">
                                <Index.Box className="card-left">
                                  <Index.Box className="work-title-flex">
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-title-lable"
                                    >
                                      Location :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-value-text"
                                    >
                                      {item?.location}
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="work-title-flex">
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-title-lable"
                                    >
                                      {item?.department == "Police department"
                                        ? "Associated Department:"
                                        : "Associated Agency:"}
                                      :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-value-text"
                                    >
                                      {item?.approveBy?.agencyName}
                                    </Index.Typography>
                                  </Index.Box>

                                  <Index.Box className="work-title-flex">
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-title-lable"
                                    >
                                      Total Hours :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-value-text"
                                    >
                                      {PagesIndex.timeDifferenceCalculation(
                                        item
                                      )}{" "}
                                      hour
                                    </Index.Typography>
                                  </Index.Box>

                                  <Index.Box className="work-title-flex">
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-title-lable"
                                    >
                                      Pay Rate :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-value-text"
                                    >
                                      ${item?.rate}
                                    </Index.Typography>
                                  </Index.Box>

                                  <Index.Box className="work-title-flex">
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-title-lable"
                                    >
                                      Total :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-value-text"
                                    >
                                      $
                                      {PagesIndex.TotalCalculateByTime(
                                        item,
                                        item.rate
                                      )}
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="card-right">
                                  <Index.Box className="work-title-flex">
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-title-lable work-title-total"
                                    >
                                      Total :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-value-text"
                                    >
                                      $
                                      {PagesIndex.TotalCalculateByTime(
                                        item,
                                        item.rate
                                      )}
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="accordian-main">
                                <Index.Accordion className="accordian">
                                  <Index.AccordionSummary
                                    className="accordian-summary"
                                    expandIcon={
                                      <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                    }
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    <Index.Box className="page-inner-title-flex">
                                      <Index.Typography className="page-inner-title">
                                        View Officer Details
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.AccordionSummary>
                                  <Index.AccordionDetails className="accordian-details">
                                    {item?.officerId.map((row) => {
                                      const shortOfficerDetail = row?.officerId;
                                      const particularOfficer = {
                                        officerId: row?.officerId?._id,
                                        jobId: item?._id,
                                        officerJobId: row?._id,
                                      };
                                      return (
                                        <Index.Box className="card-main job-mamangment-inner-card">
                                          <Index.Box className="card-left">
                                            <Index.Box className="work-title-flex">
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-title-lable"
                                              >
                                                Name :&nbsp;
                                              </Index.Typography>
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-value-text"
                                              >
                                                {shortOfficerDetail
                                                  ? shortOfficerDetail?.firstName
                                                  : "" +
                                                    " " +
                                                    shortOfficerDetail?.lastName}
                                              </Index.Typography>
                                            </Index.Box>
                                            <Index.Box className="work-title-flex">
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-title-lable"
                                              >
                                                Email Address :&nbsp;
                                              </Index.Typography>
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-value-text"
                                              >
                                                {shortOfficerDetail?.email}
                                              </Index.Typography>
                                            </Index.Box>
                                            <Index.Box className="work-title-flex">
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-title-lable"
                                              >
                                                Type :&nbsp;
                                              </Index.Typography>
                                              <Index.Typography
                                                component="p"
                                                variant="p"
                                                className="work-value-text"
                                              >
                                                {
                                                  shortOfficerDetail?.officerType
                                                }
                                              </Index.Typography>
                                            </Index.Box>
                                          </Index.Box>
                                          <Index.Box className="card-right">
                                            <Index.Box className="border-btn-main">
                                              <Index.PrimaryButton
                                                btnLabel="View Officer"
                                                onClick={() =>
                                                  pendingjobhandleOpen(
                                                    particularOfficer
                                                  )
                                                }
                                                className="btn-primary"
                                              />
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.Box>
                                      );
                                    })}
                                  </Index.AccordionDetails>
                                </Index.Accordion>
                              </Index.Box>
                            </Index.Box>
                          </>
                        );
                      })
                    ) : (
                      <PagesIndex.NoDataFound />
                    )}
                  </Index.Box>
                </TabPanel>
                <TabPanel
                  value={tabValue}
                  index="CompletedJobs"
                  className="cus-tabpanel"
                >
                  <Index.Box className="tabpanel-main">
                    {filteredJobHistoryData.length ? (
                      filteredJobHistoryData.map((item, index) => {
                        return (
                          <>
                            <Index.Box className="common-card job-mamangment-card">
                              <Index.Box className="admin-sub-flex">
                                <Index.Box className="admin-sub-title-main admin-sub-title-flex">
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="admin-sub-title"
                                  >
                                    Job Title :&nbsp;
                                  </Index.Typography>
                                  <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="admin-sub-title-value-text"
                                  >
                                    {item?.title}
                                  </Index.Typography>
                                </Index.Box>
                                <Index.PrimaryButton
                                  btnLabel="View"
                                  className="btn-primary"
                                  onClick={() =>
                                    navigate(`/admin/admin-job-history-view/${officerId}`,
                                      {state:{item,tabValue: "CompletedJobs"}}
                                  )
                                  }
                                />
                              </Index.Box>
                              <Index.Box className="card-main">
                                <Index.Box className="card-left">
                                  <Index.Box className="work-title-flex">
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-title-lable"
                                    >
                                      Location :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-value-text"
                                    >
                                      {item?.location}
                                    </Index.Typography>
                                  </Index.Box>
                                  <Index.Box className="work-title-flex">
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-title-lable"
                                    >
                                      {item?.department == "Police department"
                                        ? "Associated Department:"
                                        : "Associated Agency:"}
                                    </Index.Typography>
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-value-text"
                                    >
                                      {item?.approveBy?.agencyName}
                                    </Index.Typography>
                                  </Index.Box>

                                  <Index.Box className="work-title-flex">
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-title-lable"
                                    >
                                      Total Hours :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-value-text"
                                    >
                                      {PagesIndex.timeDifferenceCalculation(
                                        item
                                      )}{" "}
                                      hour
                                    </Index.Typography>
                                  </Index.Box>

                                  <Index.Box className="work-title-flex">
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-title-lable"
                                    >
                                      Pay Rate :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-value-text"
                                    >
                                      ${item?.rate}
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                                <Index.Box className="card-right">
                                  <Index.Box className="work-title-flex">
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-title-lable work-title-total"
                                    >
                                      Total :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                      component="p"
                                      variant="p"
                                      className="work-value-text"
                                    >
                                      $
                                      {PagesIndex.TotalCalculateByTime(
                                        item,
                                        item.rate
                                      )}
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="accordian-main">
                                <Index.Accordion className="accordian">
                                  <Index.AccordionSummary
                                    className="accordian-summary"
                                    expandIcon={
                                      <Index.ExpandMoreIcon className="accordian-expan-icon" />
                                    }
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    <Index.Box className="page-inner-title-flex">
                                      <Index.Typography className="page-inner-title">
                                        View Officer Details
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.AccordionSummary>
                                  <Index.AccordionDetails className="accordian-details">
                                    {item?.officerId.map((row) => {
                                      const shortOfficerDetail = row?.officerId;
                                      const particularOfficer = {
                                        officerId: row?.officerId?._id,
                                        jobId: item?._id,
                                        officerJobId: row?._id,
                                      };
                                      return (
                                        <>
                                          <Index.Box className="card-main job-mamangment-inner-card">
                                            <Index.Box className="card-left">
                                              <Index.Box className="work-title-flex">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="work-title-lable"
                                                >
                                                  Name :&nbsp;
                                                </Index.Typography>
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="work-value-text"
                                                >
                                                  {shortOfficerDetail
                                                    ? shortOfficerDetail?.firstName
                                                    : "" +
                                                      " " +
                                                      shortOfficerDetail?.lastName}
                                                </Index.Typography>
                                              </Index.Box>
                                              <Index.Box className="work-title-flex">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="work-title-lable"
                                                >
                                                  Email Address :&nbsp;
                                                </Index.Typography>
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="work-value-text"
                                                >
                                                  {shortOfficerDetail?.email}
                                                </Index.Typography>
                                              </Index.Box>
                                              <Index.Box className="work-title-flex">
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="work-title-lable"
                                                >
                                                  Type :&nbsp;
                                                </Index.Typography>
                                                <Index.Typography
                                                  component="p"
                                                  variant="p"
                                                  className="work-value-text"
                                                >
                                                  {
                                                    shortOfficerDetail?.officerType
                                                  }
                                                </Index.Typography>
                                              </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="card-right">
                                              <Index.Box className="border-btn-main">
                                                <Index.PrimaryButton
                                                  btnLabel="View Officer"
                                                  onClick={() =>
                                                    pendingjobhandleOpen(
                                                      particularOfficer
                                                    )
                                                  }
                                                  className="btn-primary"
                                                />
                                              </Index.Box>
                                            </Index.Box>
                                          </Index.Box>
                                        </>
                                      );
                                    })}
                                  </Index.AccordionDetails>
                                </Index.Accordion>
                              </Index.Box>
                            </Index.Box>
                          </>
                        );
                      })
                    ) : (
                      <PagesIndex.NoDataFound />
                    )}
                  </Index.Box>
                </TabPanel>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* Pending job Request modal */}
      <AdminPendingJobOfficerAceeptModal
        pendingjobopen={pendingjobopen}
        pendingjobhandleClose={pendingjobhandleClose}
        pendingjobOfficerAcceptData={pendingjobOfficerAcceptData}
        AdminJobHistory={AdminJobHistory}
        isShowApproveRejectButton={tabValue == "PendingOfficer" ? true : false}
      />
    </>
  );
}
