import React, { useEffect, useState } from "react";
import Index from "../../..";
import PagesIndex from "../../../PageIndex";

import { getZipCodeData } from "../../../../redux-toolkit/slice/admin-slice/AdminServices";
import AddZipCodeModal from "./zipcode-modal/AddZipCodeModal";
import ZipCodeDeleteModal from "./zipcode-modal/ZipCodeDeleteModal";
import Loader from "../../../../common/loader/Loader";

const ZipCodeManagement = (props) => {
  const [isActive, setActive] = useState(Array(0).fill(false));
  const [actionIndex, setActionIndex] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = PagesIndex.useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [zipCodeData, setZipCodeData] = useState(false);
  // Pagination states and methods
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  // End Pagination
  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };

  //Getting data from redux

  const { adminLoginData } = PagesIndex.useSelector(
    (state) => state.AdminSlice
  );
  const SubAdminPermission = adminLoginData?.roleId?.SubAdminPermission;
  // const roleName = adminLoginData?.name;
  const roleName = adminLoginData?.roleId?.Permission_name;

  // Add user modal
  const [addZipcodeopen, setAddZipcodeopen] = useState(false);
  const [editZipcodeData, setEditZipcodeData] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const handleAddZipcodeOpen = () => setAddZipcodeopen(true);
  const handleAddZipcodeClose = () => {
    setAddZipcodeopen(false);
    setEditZipcodeData();
  };

  // Delete functionality
  const [deleteZipcodeId, setDeleteZipcodeId] = useState([]);
  const [zipcodeOpenDelete, setZipcodeOpenDelete] = useState(false);
  const handleOpenZipcodeDelete = () => setZipcodeOpenDelete(true);
  const handleCloseZipcodeDelete = () => setZipcodeOpenDelete(false);

  // get zip code list
  const getZipcodeList = () => {
    setLoading(true);
    dispatch(getZipCodeData()).then((res) => {
      if (res?.payload?.status == 200) {
        setZipCodeData(res?.payload?.data);
        setFilteredData(res?.payload?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    getZipcodeList();
  }, []);

  // Search on table
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    const filteredList = zipCodeData?.filter((data) =>
      `${data.zipCode.toLowerCase()}`.includes(e.target.value.toLowerCase())
    );
    setFilteredData(filteredList);
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Index.Box>
          <Index.Box className="user-list-flex">
            <Index.Typography
              className="admin-page-title user-list-page-title"
              component="h2"
              variant="h2"
            >
              {/* <Index.Link to="/admin/dashboard" className="filter-back-link">
                <img
                  src={PagesIndex.Svg.leftArrow}
                  className="left-arrow-ic"
                  alt="search grey img"
                ></img>
              </Index.Link>{" "} */}
              Zip code Management
            </Index.Typography>

            <Index.Box className="userlist-btn-flex">
              {/* Top search */}
              <Index.Box className="user-search-main">
                <Index.Box className="user-search-box">
                  <Index.Box className="form-group">
                    <Index.TextField
                      fullWidth
                      id="fullWidth"
                      className="form-control"
                      placeholder="Search"
                      value={searchValue}
                      onChange={handleSearch}
                      onKeyDown={(e) => {
                        if (
                          e.key === " " &&
                          e.target.value.trim() === ""
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <img
                      src={PagesIndex.Svg.search}
                      className="search-grey-img"
                      alt="search grey img"
                    ></img>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {/* Add user Button */}
              {/* <Index.Box className="userlist-inner-btn-flex">
                <Index.Box className="adduser-btn-main btn-main-primary">
                  {roleName === "Admin" ||
                    SubAdminPermission?.zip_code?.permission?.add == true ? (
                    <Index.Button
                      className="adduser-btn btn-primary"
                      onClick={() => handleAddZipcodeOpen()}
                    >
                      <img
                        src={PagesIndex.Svg.plus}
                        className="plus-icon"
                        alt="plus icon"
                      />
                      Add Zip Code
                    </Index.Button>
                  ) : null}
                </Index.Box>
              </Index.Box> */}
            </Index.Box>
          </Index.Box>

          {/* Zip code list */}

          <Index.Box className="zip-code-mamangement-sec">
            {filteredData.length ? (
              filteredData
                .slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map((item, index) => (
                  <Index.Box className="card-main">
                    <Index.Box className="card-left">
                      <Index.Typography
                        component="p"
                        variant="p"
                        className="job-title-label"
                      >
                        {item.zipCode}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="card-right btn-main-secondary btn-main-red">
                      <Index.Box
                        id="btn-toggle-popup"
                        className={
                          isActive[index] ? "toggle-pop-show" : null
                        }
                      >

                        {/* {roleName === "Admin" ||
                          SubAdminPermission?.zip_code?.permission
                            ?.edit == true ? (
                          <Index.Tooltip
                            title="Edit"
                            arrow
                            placement="bottom"
                            className="admin-tooltip"
                          >
                            <Index.Button
                              className="btn-secondary"
                              onClick={() => {
                                handleAddZipcodeOpen();
                                setEditZipcodeData(item);
                              }}
                            >
                              <img src={PagesIndex.Svg.editNew} alt="delete" className="admin-icons" />
                            </Index.Button>
                          </Index.Tooltip>
                        ) : null} */}

                        {/* {roleName === "Admin" ||
                          SubAdminPermission?.zip_code?.permission
                            ?.delete == true ? (
                          <Index.Tooltip
                            title="Delete"
                            arrow
                            placement="bottom"
                            className="admin-tooltip"
                          >
                            <Index.Button
                              className="btn-red"
                              onClick={() => {
                                handleOpenZipcodeDelete();
                                setDeleteZipcodeId(item?._id);
                              }}
                            >
                              <img src={PagesIndex.Svg.deletewhite} alt="delete" className="admin-icons" />
                            </Index.Button>
                          </Index.Tooltip>
                        ) : null} */}

                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                ))
            ) : (
              <PagesIndex.NoDataFound />
            )}
          </Index.Box>
          <Index.TablePagination
            className="paginationColor"
            component="div"
            page={page}
            count={filteredData?.length}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            // rowsPerPageOptions={[10, 25, 50]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            // labelDisplayedRows={(page) =>
            //   `Records ${page.from} to ${page.to === -1 ? page.count : page.to
            //   } of ${page.count}`
            // }
          />
        </Index.Box>
      )}

      {/* Add New User modal */}

      {/* <AddZipCodeModal
        getZipcodeList={getZipcodeList}
        handleAddZipcodeClose={handleAddZipcodeClose}
        addZipcodeopen={addZipcodeopen}
        editZipcodeData={editZipcodeData}
      /> */}

      {/* delete modal */}
      {/* <ZipCodeDeleteModal
        handleCloseZipcodeDelete={handleCloseZipcodeDelete}
        zipcodeOpenDelete={zipcodeOpenDelete}
        getZipcodeList={getZipcodeList}
        deleteZipcodeId={deleteZipcodeId}
      /> */}
    </>
  );
};

export default ZipCodeManagement;
