import React, { useEffect, useRef, useState } from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import {
  getAgencyRoleList,
} from "../../../../../redux-toolkit/slice/agency-slice/AgencyServices";
import { adminEditAgentSchema } from "../../../../../validation/FormikSchema";
import DataService from "../../../../../config/DataService";
import Loader from "../../../../../common/loader/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const EditAdminAgentModal = (props) => {
  const {
    EditAgentModalopen,
    handleEditAgentModalCloseEdit,
    getAgentListData,
    getSingleAgentData,
    editAgentData,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
  const formRef = useRef(null)
  console.log(getSingleAgentData, "getSingleAgentData");
  // states
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  const [roleList, setRoleList] = useState();

  // validation regex
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;

  // us phone number functionality

  const [mobileNumber, setmobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isSuggestionSelected, setIsSuggestionSelected] = useState({
    state: false,
    city: false,
    zipCode: false
  })

  const handlePhoneChange = (e, setFieldValue) => {
    const input = e.target.value;
    const cleaned = input.replace(/\D/g, "");

    // Check if the first digit is 0
    if (cleaned.length > 0 && cleaned[0] == '0') {
      return; // Do nothing if the first digit is 0
    }

    // Set the raw input to Formik state
    setFieldValue("mobileNumber", cleaned);

    // Format only if we have 10 digits
    if (cleaned.length === 10) {
      const formattedPhoneNumber = formatPhoneNumber(cleaned);
      setmobileNumber(formattedPhoneNumber);
    } else {
      // If less than 10 digits, just set the cleaned (unformatted) version
      setmobileNumber(cleaned);
    }
  };

  const formatPhoneNumber = (input) => {
    const strInput = String(input); // Ensure input is a string
    const match = strInput?.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return strInput;
  };

  useEffect(() => {
    setmobileNumber(formatPhoneNumber(editAgentData?.mobileNumber));
  }, [editAgentData]);
  useEffect(() => {
    if (editAgentData?.address?.length) {
      setAddress(editAgentData?.address);
    }
  }, [editAgentData]);
  const initialValues = {
    roleName: editAgentData
      ? editAgentData?.roleId?._id
        ? editAgentData?.roleId?._id
        : editAgentData?.roleId
      : "",
    agencyName: editAgentData ? editAgentData?.agencyName : "",
    email: editAgentData ? editAgentData?.email : "",
    mobileNumber: editAgentData ? editAgentData?.mobileNumber : "",
    zipCode: editAgentData ? editAgentData?.zipCode?.length ? editAgentData?.zipCode[0] : "" : "",
    state: editAgentData ? editAgentData?.state : "",
    city: editAgentData ? editAgentData?.city : "",
    address: editAgentData ? editAgentData?.address : "",
    dayWorkLimit: editAgentData ? editAgentData?.workPolicy?.dayWorkLimit : "",
    weekWorkLimit: editAgentData
      ? editAgentData?.workPolicy?.weekWorkLimit
      : "",
    jobTransferHours: editAgentData
      ? editAgentData?.jobTransferData?.jobTransferHours
      : "",
  };
  console.log(editAgentData, "editAgentDataeditAgentDataeditAgentData")
  const handleTimeChange = (e, setFieldValue, fieldName) => {
    let value = e.target.value;
    value = value.replace(/[^\d]/g, "");
    if (value.length > 2) {
      value = value.slice(0, 2);
    }
    setFieldValue(fieldName, value);
  };
  const handleJobTimeChange = (e, setFieldValue, fieldName) => {
    let value = e.target.value;
    value = value.replace(/[^\d]/g, "");
    if (value.length > 2) {
      value = value.slice(0, 2);
    }
    console.log(value, "fatgma777")
    setFieldValue(fieldName, value);
  };
  const getRole = () => {
    dispatch(getAgencyRoleList()).then((res) => {
      if (res?.payload?.status === 200) {
        console.log(res?.payload?.data, "res?.payload?.data");
        let finalData = res?.payload?.data?.filter((data) => {
          return (
            data.Permission_name === "Department" ||
            data.Permission_name === "Agency"
          );
        });
        console.log(finalData, "finalData");
        setRoleList(finalData);
      }
    });
  };
  const handleEditNewAgent = async (values) => {
    setIsLoading(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("roleId", values?.roleName);
    urlEncoded.append("agencyName", values?.agencyName);
    urlEncoded.append("email", values?.email.toLowerCase());
    urlEncoded.append("mobileNumber", values?.mobileNumber);
    urlEncoded.append("dayWorkLimit", values?.dayWorkLimit !== undefined ? values?.dayWorkLimit : "");
    urlEncoded.append("weekWorkLimit", values?.weekWorkLimit !== undefined ? values?.weekWorkLimit : "");
    urlEncoded.append("jobTransferHours", values?.jobTransferHours !== undefined ? values?.jobTransferHours : "");
    urlEncoded.append("zipCode", values?.zipCode);
    urlEncoded.append("state", values?.state);
    urlEncoded.append("city", values?.city);
    urlEncoded.append("address", values?.address);
    try {
      const response = await DataService.post(
        `${PagesIndex.Api.Admin.EDIT_AGENT}/${editAgentData?._id}`,
        urlEncoded
      );
      if (response?.data?.status == 200) {
        console.log(response, "inside if", "response34");
        PagesIndex.toast.success(response?.data?.message, {
          toastId: "customID",
        });

        handleEditAgentModalCloseEdit();
        if (getAgentListData != undefined) {
          getAgentListData();
        } else {
          getSingleAgentData();
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, {
        toastId: "customID",
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getRole();
  }, []);
  const handleAddressChange = async (e) => {
    setAddress(e.target.value);
    formRef.current.setFieldValue("address", e.target.value);
    if (e.target.value?.length <= 0) {
      formRef.current.setFieldValue("address", "");
      formRef.current.setFieldValue("state", "");
      formRef.current.setFieldValue("city", "");
      formRef.current.setFieldValue("zipCode", "");
      setIsSuggestionSelected({
        state: false,
        city: false,
        zipCode: false
      })
    }
    if (e.target.value.length > 3) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&q=${e.target.value}`
        );
        const data = await response.json();
        setSuggestions(data);
      } catch (error) {
        console.error('Error fetching address:', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
    }
  };
  const handleSuggestionClick = (suggestion, setFieldValue) => {
    setAddress(suggestion.display_name);
    let stateName = suggestion.address.state || suggestion.address.state_district || "";
    let cityName = suggestion.address.city || suggestion.address.town || suggestion.address.village || "";
    let zipCode = suggestion.address.postcode || "";
    formRef.current.setFieldValue("address", suggestion.display_name);
    formRef.current.setFieldValue("state", stateName);
    formRef.current.setFieldValue("city", cityName);
    formRef.current.setFieldValue("zipCode", zipCode);
    setIsSuggestionSelected({
      state: stateName?.length ? true : false,
      city: cityName?.length ? true : false,
      zipCode: zipCode?.length ? true : false
    })
    setSuggestions([]);
  };
  return (
    <>
      <Index.Modal
        open={EditAgentModalopen}
        onClose={handleEditAgentModalCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={handleEditNewAgent}
          validationSchema={adminEditAgentSchema}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Edit Agency/Department
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleEditAgentModalCloseEdit}
                    alt=""
                  />
                </Index.Box>

                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Select Role
                    </Index.FormHelperText>
                    <Index.Box className="dropdown-box">
                      <Index.FormControl className="drop-form-control">
                        <Index.Select
                          className="dropdown-select drop-select"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="roleName"
                          onBlur={handleBlur}
                          renderValue={
                            values?.roleName
                              ? undefined
                              : () => "Select role"
                          }
                          value={values?.roleName}
                          onFocus={() => setFieldTouched("roleName", true)}
                          onChange={(e) => {
                            setFieldValue("roleName", e?.target?.value);
                          }}
                          error={
                            errors.roleName && touched.roleName ? true : false
                          }
                          helperText={
                            errors.roleName && touched.roleName
                              ? errors.roleName
                              : null
                          }
                        >
                          {roleList && roleList.length > 0
                            ? roleList.map((data, index) => {
                              return (
                                <Index.MenuItem
                                  key={index}
                                  value={data?._id}
                                  className="drop-menuitem"
                                >
                                  <div>{data?.Permission_name}</div>
                                </Index.MenuItem>
                              );
                            })
                            : null}
                        </Index.Select>
                        <Index.FormHelperText error>
                          {errors.roleName && touched.roleName
                            ? errors.roleName
                            : null}
                        </Index.FormHelperText>
                      </Index.FormControl>
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Edit Agency/Department Name
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter agency name"
                        name="agencyName"
                        value={values.agencyName}
                        // onChange={han
                        onChange={(e) => {
                          handleChange(e);
                          setFieldTouched("agencyName", true);
                        }}
                        // onBlur={handleBlur}
                        focused={false}
                        error={
                          errors.agencyName && touched.agencyName ? true : false
                        }
                        helperText={
                          errors.agencyName && touched.agencyName
                            ? errors.agencyName
                            : null
                        }
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.value.trim() === "") {
                            e.preventDefault(); // Prevent space as the first character
                          } else if (
                            e.target.value.trim().split(" ").length >= 2 &&
                            e.key === " "
                          ) {
                            e.preventDefault(); // Prevent adding additional spaces between words
                          } else if (
                            e.key === " " &&
                            e.target.value.endsWith(" ")
                          ) {
                            e.preventDefault(); // Prevent additional spaces at the end
                          }
                          if (!NUMERIC_REGEX_ONLY.test(e.key)) {
                            e.preventDefault(); // Prevent non-numeric characters
                          }
                        }}
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Edit Email Address
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        disabled
                        className="form-control"
                        placeholder="Please enter email"
                        name="email"
                        value={values.email}
                        onChange={(e) => {
                          handleChange(e);
                          // setFieldTouched("email", true);
                        }}
                        onKeyDown={(event) => {
                          if (event.key == " ") {
                            event.preventDefault();
                          }
                        }}
                        onBlur={handleBlur}
                        error={errors.email && touched.email ? true : false}
                        helperText={
                          errors.email && touched.email ? errors.email : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Edit Phone Number
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      {/* <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="Please enter phone number"
                        name="mobileNumber"
                        onBlur={handleBlur}
                        value={values.mobileNumber}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(
                            ONLY_NUMBER_NOT_SPACE_SPACIALCHAR,
                            ""
                          );
                          setFieldValue("mobileNumber", newValue);
                        }}
                        inputProps={{ maxLength: 10 }}
                        error={
                          errors.mobileNumber && touched.mobileNumber
                            ? true
                            : false
                        }
                        helperText={
                          errors.mobileNumber && touched.mobileNumber
                            ? errors.mobileNumber
                            : null
                        }
                      /> */}
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="(123) 456-7890"
                        // type="number"
                        name="mobileNumber"
                        value={values?.mobileNumber}
                        onChange={(e) => {
                          handlePhoneChange(e, setFieldValue);
                          setFieldTouched("mobileNumber", true);
                        }}
                        focused={false}
                        inputProps={{ maxLength: 10 }}
                        error={
                          errors.mobileNumber && touched.mobileNumber
                            ? true
                            : false
                        }
                        helperText={
                          errors.mobileNumber && touched.mobileNumber
                            ? errors.mobileNumber
                            : false
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Day Work Limit
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="HH"
                        name="dayWorkLimit"
                        value={values.dayWorkLimit}
                        onChange={(e) => {
                          handleTimeChange(e, setFieldValue, "dayWorkLimit");
                        }}
                        onKeyDown={(event) => {
                          if (event.key == " ") {
                            event.preventDefault();
                          }
                        }}
                        onBlur={handleBlur}
                        error={
                          errors.dayWorkLimit && touched.dayWorkLimit
                            ? true
                            : false
                        }
                        helperText={
                          errors.dayWorkLimit && touched.dayWorkLimit
                            ? errors.dayWorkLimit
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Week Work Limit
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="HH"
                        name="weekWorkLimit"
                        value={values.weekWorkLimit}
                        onChange={(e) => {
                          handleTimeChange(e, setFieldValue, "weekWorkLimit");
                        }}
                        onKeyDown={(event) => {
                          if (event.key == " ") {
                            event.preventDefault();
                          }
                        }}
                        onBlur={handleBlur}
                        error={
                          errors.weekWorkLimit && touched.weekWorkLimit
                            ? true
                            : false
                        }
                        helperText={
                          errors.weekWorkLimit && touched.weekWorkLimit
                            ? errors.weekWorkLimit
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Job Hours Limit
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="HH"
                        name="jobTransferHours"
                        value={values?.jobTransferHours}
                        onChange={(e) => {
                          handleJobTimeChange(
                            e,
                            setFieldValue,
                            "jobTransferHours"
                          );
                        }}
                        onKeyDown={(event) => {
                          if (event.key == " ") {
                            event.preventDefault();
                          }
                        }}
                        onBlur={handleBlur}
                        error={
                          errors.jobTransferHours && touched.jobTransferHours
                            ? true
                            : false
                        }
                        helperText={
                          errors.jobTransferHours && touched.jobTransferHours
                            ? errors.jobTransferHours
                            : null
                        }
                      />
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Edit Address
                    </Index.FormHelperText>
                    <Index.Box className="form-group">

                      <Index.Box className="form-group">
                        <Index.TextareaAutosize
                          fullWidth
                          id="fullWidth"
                          name="address"
                          className="form-control-textarea hgt-address"
                          placeholder="Please enter address"
                          value={address}
                          onChange={handleAddressChange}
                          error={
                            touched.address && errors.address
                              ? true
                              : false
                          }
                          helperText={
                            touched.address && errors.address
                              ? true
                              : false
                          }
                        />
                        {suggestions.length > 0 && (
                          <ul className="address-list">
                            {suggestions.map((suggestion, index) => (
                              <li
                                key={index}
                                onClick={() => handleSuggestionClick(suggestion, setFieldValue)}
                              >
                                {suggestion.display_name}
                              </li>
                            ))}
                          </ul>
                        )}
                      </Index.Box>
                      {errors.address && touched.address
                        ? <Index.FormHelperText error>{errors.address}
                        </Index.FormHelperText>
                        : <></>}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      State
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        id="fullWidth"
                        className="form-control w-100-full"
                        name="state"
                        labelId="stateName-placeholder"
                        placeholder="Please enter state"
                        // value={address.length > 0 ? state : values.state}
                        value={values.state}
                        disabled={isSuggestionSelected?.state ? true : false}
                        onChange={(e) => {
                          if (e.target.value?.length <= 30) {
                            setFieldValue("state", e.target.value)
                          }
                        }}
                        error={
                          touched.state && errors.state
                            ? true
                            : false
                        }
                        helperText={
                          touched.state && errors.state
                            ? true
                            : false
                        }
                      >
                      </Index.TextField>

                      {errors.state && touched.state
                        ? <Index.FormHelperText error>{errors.state}
                        </Index.FormHelperText>
                        : <></>}
                    </Index.Box>
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      City
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        id="fullWidth"
                        className="form-control w-100-full"
                        name="city"
                        labelId="stateName-placeholder"
                        placeholder="Please enter city"
                        // value={address.length > 0 ? city : values.city}
                        value={values.city}
                        disabled={isSuggestionSelected?.city ? true : false}
                        // onChange={handleChange}
                        onChange={(e) => {
                          if (e.target.value?.length <= 30) {
                            setFieldValue("city", e.target.value)
                          }

                        }}
                        error={
                          touched.city && errors.city
                            ? true
                            : false
                        }
                        helperText={
                          touched.city && errors.city
                            ? true
                            : false
                        }
                      >
                      </Index.TextField>
                    </Index.Box>
                    {errors.city && touched.city
                      ? <Index.FormHelperText error>{errors.city}
                      </Index.FormHelperText>
                      : <></>}
                  </Index.Box>

                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Edit Zip Code
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        id="fullWidth"
                        // type="number"
                        className="form-control w-100-full"
                        name="zipCode"
                        labelId="stateName-placeholder"
                        placeholder="Please enter zip code"
                        value={values.zipCode}
                        disabled={isSuggestionSelected?.zipCode ? true : false}
                        onChange={(e) => {
                          const numberPattern = /^[0-9-]+$/
                          if (e.target?.value?.length <= 0) {
                            setFieldValue("zipCode", "")
                          }
                          if (numberPattern.test(e.target.value) && e.target.value?.length <= 9
                          ) {
                            setFieldValue("zipCode", e.target.value)
                          }
                        }}
                        error={
                          touched.zipCode && errors.zipCode
                            ? true
                            : false
                        }
                        helperText={
                          touched.zipCode && errors.zipCode
                            ? true
                            : false
                        }
                      />
                    </Index.Box>
                    {errors.zipCode && touched.zipCode
                      ? <Index.FormHelperText error>{errors.zipCode}
                      </Index.FormHelperText>
                      : <></>}
                  </Index.Box>

                </Index.Box>

                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel="Update"
                      className="btn-primary bg-approve"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "Click Me"}
                    </Index.PrimaryButton>
                    <Index.PrimaryButton
                      btnLabel="Cancel"
                      className="btn-primary bg-suspend"
                      onClick={() => handleEditAgentModalCloseEdit()}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default EditAdminAgentModal;
