import React from "react";
import PagesIndex from "../../../PageIndex";
import Index from "../../../index";

export default function AdminUserHistoryDetailsofcompletedjobs() {
  const [ratingvalue, setRatingValue] = React.useState(2);
  const { state } = PagesIndex.useLocation();
  const userJobDeatil = state;
  // console.log("userJobData", userJobDeatil);

    // us Formate
    const formatPhoneNumber = (input) => {
      // Remove all non-digit characters from the input
      const cleaned = ("" + input).replace(/\D/g, "");
  
      // Format the cleaned phone number
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
  
      return cleaned;
    };
  return (
    <div>
      <Index.Box className="dashboard-content extra-work-main p-0 bg-none">
        <Index.Box className="card-main mob-p-0">
          <Index.Box className="mob-sub-header">
            <Index.Box className="title-header-flex">
              <Index.Link to="/admin/usermanage/userhistory">
                <img
                  src={PagesIndex.Svg.leftArrow}
                  className="left-arrow-ic"
                  alt="search grey img"
                />
              </Index.Link>
              <Index.Box className="job-title-header">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="job-title-label"
                >
                  {userJobDeatil?.title} <span>for</span> $
                  {PagesIndex.CostCalculateByTime(
                    PagesIndex.timeDifferenceCalculation(
                      userJobDeatil?.jobStartTime,
                      userJobDeatil?.jobEndTime
                    ),
                    userJobDeatil?.rate
                  ).toFixed(2)}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="mob-inner-scroll-area">
            <Index.Box className="assigned-officer-main box-sha">
              <Index.Box className="assigned-officer-header">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="main-title"
                >
                  Assigned Officer & Associated Agency/Department
                </Index.Typography>
              </Index.Box>

              <Index.Box className="assigned-officer-Detail admin-assigned-officer-Detail">
                <Index.Box className="assigned_officer_card">
                  {userJobDeatil?.officerId.map((item, index) => {
                    const officershortDetail = item?.officerId;
                    return (
                      <Index.Box className="card-main admin-card-main admin-card-main-box">
                        <Index.Box className="card-left admin-card-left">
                          <Index.Box className="officer-profile">
                            <img
                              src={PagesIndex.Png.usericon}
                              className="prof-img"
                              alt=""
                            />
                          </Index.Box>
                          <Index.Box className="officer-detail">
                            <Index.Box className="job-title-text m-b-0">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                {officershortDetail
                                  ? officershortDetail?.firstName
                                  : "" + " " + officershortDetail?.lastName}
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="job-title-text m-b-0">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {officershortDetail?.officerType}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Officer Assigned on :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {PagesIndex.dayjs(
                                  item?.jobAcceptedDate
                                ).format("MM-DD-YYYY")}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-label"
                              >
                                Officer Check-In :&nbsp;
                              </Index.Typography>
                              <Index.Typography
                                component="p"
                                variant="p"
                                className="job-title-text-label"
                              >
                                {/* {PagesIndex.dayjs(
                                  item.jobCheckInTime[0]
                                ).format("MM-DD-YYYY : HH:mm")} */}
                                 {item?.jobCheckInTime && PagesIndex.dayjs(item.jobCheckInTime).isValid() ? PagesIndex.dayjs(item.jobCheckInTime).format("MM-DD-YYYY - HH:mm") : " Not checkin"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="card-right admin-card-right">
                          <img
                            src={PagesIndex.Svg.rightArrow}
                            className="left-arrow-ic"
                            alt=""
                          />
                        </Index.Box>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
                <Index.Box className="associate_agency_card">
                  {[1].map((item, index) => (
                    <Index.Box className="card-main admin-card-main admin-card-main-box">
                      <Index.Box className="card-left admin-card-left">
                        {/* <Index.Box className='officer-profile'>
                                                <img src={PagesIndex.Png.usericon} className='prof-img' />
                                            </Index.Box> */}
                        <Index.Box className="officer-detail">
                          <Index.Box className="job-title-text m-b-0">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-label"
                            >
                              Agency Name
                            </Index.Typography>
                          </Index.Box>

                          <Index.Box className="job-title-text m-b-0">
                            <Index.Typography
                              component="p"
                              variant="p"
                              className="job-title-text-label"
                            >
                              Location
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="card-right admin-card-right">
                        <img
                          src={PagesIndex.Svg.rightArrow}
                          className="left-arrow-ic"
                          alt=""
                        />
                      </Index.Box>
                    </Index.Box>
                  ))}
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="assigned-officer-main box-sha">
              <Index.Box className="assigned-officer-header">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="main-title"
                >
                  General Information
                </Index.Typography>
              </Index.Box>
              <Index.Box className="officer-detail">
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Job Title :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.title}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Posted By :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.userId?.name}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Posted On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {PagesIndex.dayjs(userJobDeatil?.createdAt).format(
                      "MM-DD-YYYY"
                    )}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Total Payment :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Transaction Id :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Phone Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {formatPhoneNumber(userJobDeatil?.mobileNumber)}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Email Address :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.userId?.email}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Point Of Contact Name :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.pointOfContactName}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Point Of Contact Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {formatPhoneNumber(
                      userJobDeatil?.pointOfContactNumber || "-"
                    )}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Location :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.location}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Service :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.service}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Department :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.department}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Job Type :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.jobType?.jobType}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Zip Code :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.zipCode}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Date/Time :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {PagesIndex.showDateTime(userJobDeatil)}
                  </Index.Typography>
                </Index.Box>

                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Total Hours :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {PagesIndex.timeDifferenceCalculation(userJobDeatil)} hour
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Pay Rate :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.rate ? "$" : null}
                    {userJobDeatil?.rate}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    No of Officer :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.totalOfficer}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text job-start-align-pending">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label job-skill-details"
                  >
                    Description :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label  job-width-pending-flex"
                  >
                    {userJobDeatil?.description}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="assigned-officer-main box-sha">
              <Index.Box className="assigned-officer-header">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="main-title"
                >
                  Organizer (Client)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="officer-detail">
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Name :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.userId?.name}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Mobile Number :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.userId?.mobileNumber}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Email Address :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {userJobDeatil?.userId?.email}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="assigned-officer-main box-sha">
              <Index.Box className="assigned-officer-header">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="main-title"
                >
                  Job Log
                </Index.Typography>
              </Index.Box>
              <Index.Box className="officer-detail">
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Job Posted On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {PagesIndex.dayjs(userJobDeatil?.createdAt).format(
                      "MM-DD-YYYY"
                    )}
                  </Index.Typography>
                </Index.Box>
                {/* <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Officer Assigned on :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {PagesIndex.dayjs(
                      userJobDeatil?.officerId[0]?.jobAcceptedDate
                    ).format("MM-DD-YYYY")}
                  </Index.Typography>
                </Index.Box> */}
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Job Transferred To :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Job Transferred On :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    -
                  </Index.Typography>
                </Index.Box>
                {/* <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-label"
                  >
                    Officer Check-In :&nbsp;
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    {PagesIndex.dayjs(
                      userJobDeatil?.officerId[0]?.jobCheckInTime[0]
                    ).format("YYYY-MM-Do, h:mm:ss a")}
                  </Index.Typography>
                </Index.Box> */}
              </Index.Box>
            </Index.Box>
            <Index.Box className="assigned-officer-main box-sha">
              <Index.Box className="assigned-officer-header">
                <Index.Typography
                  component="p"
                  variant="p"
                  className="main-title"
                >
                  Rating & Review
                </Index.Typography>
              </Index.Box>
              {/* <Index.Box className="officer-detail">
                <Index.Box className="job-title-text">
                  <Index.Box className="rating-main">
                    <Index.Box
                      sx={{
                        "& > legend": { mt: 2 },
                      }}
                    >
                      <Index.Rating
                        name="simple-controlled"
                        value={ratingvalue}
                        onChange={(event, newValue) => {
                          setRatingValue(newValue);
                        }}
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="job-title-text">
                 
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label"
                  >
                    "Review from the client for the completed service/job will
                    be shown here"
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="job-title-text">
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="job-title-text-label text-right"
                  >
                    -00/00/0000 00:00:00 AM/PM
                  </Index.Typography>
                </Index.Box>
              </Index.Box> */}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}
