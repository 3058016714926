import React, { useState, useEffect } from "react";
import Index from "../../..";
import PagesIndex from "../../../../components/PagesIndex";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { AgencyworkPermitSchema, AgencyEditWorkPermitSchema } from "../../../../validation/FormikSchema";
import { useLocation } from "react-router-dom";
import Loader from "../../../../common/loader/Loader";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import dayjs from "dayjs";
// AdminSlice: AdminSliceReducer,

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
const officerType = [
  { title: "Active Police Officer" },
  { title: "Retired Police Officer" },
  { title: "Retired Military Officer" },
  { title: " Security Officer" },
];
const AddWorkPolicy = (props) => {
  const { state } = useLocation();
  let editData = state?.item;
  const dispatch = PagesIndex.useDispatch();
  const { open, handleClose, getExtraWorkPermitList, editUserData } = props;
  const [isLoading, setIsLoading] = useState(false);

  // validation regex
  const NUMERIC_REGEX_ONLY = /^[a-zA-Z ]+$/;
  const ONLY_NUMBER_NOT_SPACE_SPACIALCHAR = /\D/g;
  const initialValues = {
    dayWorkLimit: editUserData ? editUserData?.dayWorkLimit : "",
    weekWorkLimit: editUserData ? editUserData?.weekWorkLimit : "",
  };
  console.log(editUserData, "editUserData");

  // const handleTimeChange = (e, setFieldValue, fieldName) => {
  //   let value = e.target.value;
  //     // Limit to HH:MM format
  //     if (value.length > 2) {
  //       value = value.slice(0, 2);
  //     }
  //     if(fieldName == "dayWorkLimit")
  //       {
  //         setFieldValue('dayWorkLimit', value);
  //       }
  //       if(fieldName == "weekWorkLimit"){
  //         setFieldValue('weekWorkLimit', value);
  //       }

  // };
  const handleTimeChange = (e, setFieldValue, fieldName) => {
    let value = e.target.value;

    // Remove non-digit characters
    value = value.replace(/[^\d]/g, '');

    // // Limit to 3 characters
    // if (value.length > 3) {
    //     value = value.slice(0, 3);
    // }

    // Set the value using the provided field name
    setFieldValue(fieldName, value);
};

  // const handleAddWorkPermit = async (values) => {
  //   setIsLoading(true);
  //   console.log(values,"7878")
  //   const urlEncoded = new URLSearchParams();
  //   if (editUserData === undefined || editUserData) {
  //     urlEncoded.append("id", editUserData?._id);
  //   }
  //   urlEncoded.append("dayWorkLimit", values?.dayWorkLimit);
  //   urlEncoded.append("weekWorkLimit", values?.weekWorkLimit);
  //   try {
  //     const response = await DataService.post(
  //       Api.Agency.AGENCY_EXTRA_WORK_LIMIT,
  //       urlEncoded
  //     );
  //     if (response?.data?.status === 200) {
  //       PagesIndex.toast.success(response?.data?.message, {
  //         toastId: "customId",
  //       });
  //       handleClose();
  //       getExtraWorkPermitList();
  //       setTimeout(() => {
  //         setIsLoading(false);
  //       }, 2000);
  //     }
  //   } catch (error) {
  //     PagesIndex.toast.error(error?.response?.data?.message, {
  //       toastId: "customId",
  //     });
  //     setIsLoading(false);
  //   }
  // };

  const handleAddWorkPermit = async (values) => {
    setIsLoading(true);
  
    // Validation checks
    if (values.dayWorkLimit > 24) {
      PagesIndex.toast.error("Day work limit cannot exceed 24 hours", {
        toastId: "customId",
      });
      setIsLoading(false);
      return;
    }
  
    if (values.weekWorkLimit > 168) {
      PagesIndex.toast.error("Week work limit cannot exceed 168 hours", {
        toastId: "customId",
      });
      setIsLoading(false);
      return;
    }
  
    console.log(values, "7878");
  
    const urlEncoded = new URLSearchParams();
    if (editUserData === undefined || editUserData) {
      urlEncoded.append("id", editUserData?._id);
    }
    urlEncoded.append("dayWorkLimit", values?.dayWorkLimit);
    urlEncoded.append("weekWorkLimit", values?.weekWorkLimit);
  
    try {
      const response = await DataService.post(
        Api.Agency.AGENCY_EXTRA_WORK_LIMIT,
        urlEncoded
      );
      if (response?.data?.status === 200) {
        PagesIndex.toast.success(response?.data?.message, {
          toastId: "customId",
        });
        handleClose();
        getExtraWorkPermitList();
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, {
        toastId: "customId",
      });
      setIsLoading(false);
    }
  };
  

  return (
    <>
      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <PagesIndex.Formik
          // validationSchema={
          //   editUserData.length === 0 ? AgencyworkPermitSchema : AgencyEditWorkPermitSchema
          // }
          initialValues={initialValues}
          onSubmit={handleAddWorkPermit}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            touched,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            handleFocus,
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log("error", errors)}
              {/* {console.log("values>>", values)} */}
              <Index.Box
                sx={style}
                className="add-user-modal-inner-main modal-inner"
              >
                <Index.Box className="modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {editUserData ? "Edit Work Policy" : "Add Work Policy"}
                  </Index.Typography>
                  <img
                    src={PagesIndex.Svg.closeblack}
                    className="modal-close-icon"
                    onClick={handleClose}
                    alt=""
                  />
                </Index.Box>

                <Index.Box className="modal-body">
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                    Day Work 
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="HH"
                        name="dayWorkLimit"
                        value={values.dayWorkLimit}
                        // onChange={(e) => {
                        //     const newValue = e.target.value.replace(
                        //       ONLY_NUMBER_NOT_SPACE_SPACIALCHAR,
                        //       ""
                        //     );
                        //     setFieldValue("dayWorkLimit", newValue);
                        //   }}
                        // onChange={handleTimeChange}
                        onChange={(e) => {
                          handleTimeChange(e, setFieldValue, "dayWorkLimit");
                        }}
                        // onChange={handleChange}
                        inputProps={{maxLength: 2}}
                        onBlur={handleBlur}
                        error={errors.dayWorkLimit && touched.dayWorkLimit ? true : false}
                        helperText={
                          errors.dayWorkLimit && touched.dayWorkLimit ? errors.dayWorkLimit : null
                        }
                      />
                       {/* <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={[
                                        "MobileTimePicker",
                                        "MobileTimePicker",
                                        "MobileTimePicker",
                                      ]}
                                    >
                                      <TimePicker
                                        ampm={false}
                                        value={
                                          values.dayWorkLimit
                                            ? dayjs(
                                                `2023-12-25T${values.dayWorkLimit}`
                                              )
                                            : null
                                        }
                                        views={["hours", "minutes"]}
                                        name={`dayWorkLimit`}
                                        className="form-control work-policy"
                                        // defaultValue={dayjs(
                                        //   `2023-12-25T${row?.dayWorkLimit}`
                                        // )}
                                        onChange={(e) => {
                                          const formatedTime = moment(
                                            e?.$d
                                          ).format("HH:mm");
                                          setFieldValue(
                                            `dayWorkLimit`,
                                            formatedTime,
                                            true
                                          );
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider> */}
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box modal-input-box">
                    <Index.FormHelperText className="form-lable">
                      Week Work
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        placeholder="HH"
                        name="weekWorkLimit"
                        value={values.weekWorkLimit}
                        // onChange={(e) => {
                        //     const newValue = e.target.value.replace(
                        //       ONLY_NUMBER_NOT_SPACE_SPACIALCHAR,
                        //       ""
                        //     );
                        //     setFieldValue("weekWorkLimit", newValue);
                        //   }}
                        onChange={(e) => {
                          handleTimeChange(e, setFieldValue, "weekWorkLimit");
                        }}
                        inputProps={{maxLength: 3}}
                        onKeyDown={(event) => {
                          if (event.key == " ") {
                            event.preventDefault();
                          }
                        }}
                        onBlur={handleBlur}
                        error={errors.weekWorkLimit && touched.weekWorkLimit ? true : false}
                        helperText={
                          errors.weekWorkLimit && touched.weekWorkLimit ? errors.weekWorkLimit : null
                        }
                      />
                       {/* <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={[
                                        "MobileTimePicker",
                                        "MobileTimePicker",
                                        "MobileTimePicker",
                                      ]}
                                     
                                    >
                                      <TimePicker
                                        ampm={false}
                                        value={
                                          values.weekWorkLimit
                                            ? dayjs(
                                                `2023-12-25T${values.weekWorkLimit}`
                                              )
                                            : null
                                        }
                                        views={["hours", "minutes"]}
                                        name={`weekWorkLimit`}
                                        // className="form-control"
                                        className="form-control work-policy"
                                        // defaultValue={dayjs(
                                        //   `2023-12-25T${row?.weekWorkLimit}`
                                        // )}
                                        onChange={(e) => {
                                          const formatedTime = moment(
                                            e?.$d
                                          ).format("HH:mm");
                                          setFieldValue(
                                            `weekWorkLimit`,
                                            formatedTime,
                                            true
                                          );
                                        }}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider> */}
                    </Index.Box>
                  </Index.Box>

                </Index.Box>

                <Index.Box className="modal-footer">
                  <Index.Box className="modal-footer-btn-flex">
                    <Index.PrimaryButton
                      btnLabel={editUserData ? "Update" : "Add"}
                      className="btn-primary bg-approve"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? <Loader /> : "submit"}
                    </Index.PrimaryButton>

                    <Index.PrimaryButton
                      btnLabel="cancel"
                      className="btn-primary bg-suspend"
                      onClick={() => handleClose()}
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          )}
        </PagesIndex.Formik>
      </Index.Modal>
    </>
  );
};

export default AddWorkPolicy;
