import React from 'react'
import Index from '../../../../index';
import PagesIndex from '../../../../PageIndex';
import { SwipeableDrawer } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

function createData(jobTitle, status, client, agency, assignedOfficer, location, totalHours, payRate, totalAmount, overallRating) {
    return { jobTitle, status, client, agency, assignedOfficer, location, totalHours, payRate, totalAmount, overallRating };
}
  
  const rows = [
    createData(
      "Job Title ",
    //   <PagesIndex.PaidLable />,
    "status",
      "OCTOBER 25, 2022",
      "JEFF BREITENBERG",
      "JEFF BREITENBERG",
      "JEFF BREITENBERG",
      "JEFF BREITENBERG",
      "JEFF BREITENBERG",
      "JEFF BREITENBERG",
      "4953"
    ),
  ];

function Viewreport() {

    const [age, setAge] = React.useState('');

    const handleChangedropdown = (event) => {
        setAge(event.target.value);
    };


    // add user modal
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // delete modal
    const [openDelete, setOpenDelete] = React.useState(false);
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);



    // filter
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Index.Box className="filter-main"
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
        </Index.Box>
    );
    
  return (
    <>
    <div className='report'>
        <div className='filter-card card-wrape'>
            {/* head filter start */}
            <Index.Box className="dashboard-content user-list-content bg-none p-0">
                <Index.Box className="user-list-flex">
                    <Index.Typography className='admin-page-title user-list-page-title' component='h2' variant='h2'>
                        <Index.Link to="/admin/dashboard" className='filter-back-link'>
                            <img src={PagesIndex.Svg.leftArrow} className="down-icon" alt='download icon' />
                        </Index.Link> Job Report 
                    </Index.Typography>
                    <Index.Box className="userlist-btn-flex">
                        <Index.Box className="user-search-main">
                            <Index.Box className="user-search-box">
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder="Search"
                                    />
                                    <img
                                        src={PagesIndex.Svg.search}
                                        className="search-grey-img" alt='search grey img'
                                    ></img>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="filter-main">
                            {['right'].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <Index.Box className="export-btn-main border-btn-main">
                                        <Index.Button className='export-btn border-btn' onClick={toggleDrawer(anchor, true)}><img src={PagesIndex.Svg.filter} className="down-icon" alt='download icon' />Filter</Index.Button>
                                    </Index.Box>
                                    <SwipeableDrawer
                                        className='filter-main'
                                        anchor={anchor}
                                        open={state[anchor]}
                                        onClose={toggleDrawer(anchor, false)}
                                        onOpen={toggleDrawer(anchor, true)}
                                    >
                                        <Index.Box className="filter-header">
                                            <Index.Typography className='filter-title'>User Filter</Index.Typography>
                                            {/* <img src={PagesIndex.Png.close} className="filter-close-icon" onClick={toggleDrawer(anchor, false)} /> */}
                                            <img src={PagesIndex.Svg.closeicon} className="filter-close-icon" onClick={toggleDrawer(anchor, false)} />
                                        </Index.Box>
                                        <Index.Box className="filter-inner-main">
                                            <Index.Box className="input-box filter-input-box">
                                                <Index.FormHelperText className='form-lable'>Name</Index.FormHelperText>
                                                <Index.Box className="form-group">
                                                    <Index.TextField
                                                        fullWidth
                                                        id="fullWidth"
                                                        className="form-control"
                                                        placeholder=""
                                                    />
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="input-box filter-input-box">
                                                <Index.FormHelperText className='form-lable'>City</Index.FormHelperText>
                                                <Index.Box className="form-group">
                                                    <Index.TextField
                                                        fullWidth
                                                        id="fullWidth"
                                                        className="form-control"
                                                        placeholder=""
                                                    />
                                                </Index.Box>
                                            </Index.Box>
                                            <Index.Box className="input-box filter-input-box">
                                                <Index.FormHelperText className='form-lable'>Status</Index.FormHelperText>
                                                <Index.Box className='checkbox-main filter-checkbox-main input-box'>
                                                    <Index.FormControlLabel control={<Index.Checkbox defaultChecked />} label="Active" className='checkbox-lable' />
                                                    <Index.FormControlLabel control={<Index.Checkbox />} label="Deactivate" className='checkbox-lable' />
                                                    <Index.FormControlLabel control={<Index.Checkbox />} label="Pending" className='checkbox-lable' />
                                                </Index.Box>
                                            </Index.Box>
                                        </Index.Box>
                                        <Index.Box className="filter-footer">
                                            <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                                                <Index.Button className="border-btn filter-cancel-btn">Cancel</Index.Button>
                                                <Index.Button className="btn-primary filter-btn">Filter</Index.Button>
                                            </Index.Box>
                                        </Index.Box>
                                    </SwipeableDrawer>
                                </React.Fragment>
                            ))}
                        </Index.Box>
                        <Index.Box className="userlist-inner-btn-flex">

                            <Index.Box className="adduser-btn-main btn-main-primary">
                                <Index.Button className='adduser-btn btn-primary'><img src={PagesIndex.Svg.download} className="plus-icon" alt='plus icon' />Download</Index.Button>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
            {/* Add New User modal */}
            <Index.Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='modal'
            >
            <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
                    <Index.Box className="modal-header" >
                        <Index.Typography id="modal-modal-title" className='modal-title' variant="h6" component="h2">
                        Add New User
                        </Index.Typography>
                        <img src={PagesIndex.Svg.closeblack} className="modal-close-icon" onClick={handleClose} />
                    </Index.Box>
                    <Index.Box className="modal-body">
                    <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className='form-lable'>First Name</Index.FormHelperText>
                        <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                />
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className='form-lable'>Last Name</Index.FormHelperText>
                        <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                />
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className='form-lable'>Email Address</Index.FormHelperText>
                        <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                />
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className='form-lable'>Phone Number</Index.FormHelperText>
                        <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                    
                                />
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className='form-lable'>Password</Index.FormHelperText>
                        <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                    
                                />
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className='form-lable'>Confirm password</Index.FormHelperText>
                        <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                    
                                />
                        </Index.Box>
                    </Index.Box>
                    </Index.Box>
                    <Index.Box className="modal-footer">
                    <Index.Box className="modal-user-btn-flex">
                        <Index.Box className="discard-btn-main border-btn-main btn-modal-both">
                                <Index.Button className='discard-user-btn border-btn add-btn-modal'>Add</Index.Button>
                        </Index.Box>
                        <Index.Box className="save-btn-main border-btn-main">
                                <Index.Button className='save-user-btn border-btn cancel-btn'>Cancel</Index.Button>
                        </Index.Box>
                    </Index.Box>
                    </Index.Box>
            </Index.Box>
            </Index.Modal>
            {/* End Add New User */}
            {/* Edit User Details start */}
            <Index.Modal
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='modal-delete modal'
            >
            <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
                    <Index.Box className="modal-header" >
                        <Index.Typography id="modal-modal-title" className='modal-title' variant="h6" component="h2">
                        Edit User Details
                        </Index.Typography>
                        <img src={PagesIndex.Svg.closeblack} className="modal-close-icon" onClick={handleClose} />
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className='form-lable'>First Name</Index.FormHelperText>
                        <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                />
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className='form-lable'>Last Name</Index.FormHelperText>
                        <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                />
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className='form-lable'>Email Address</Index.FormHelperText>
                        <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                />
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className='form-lable'>Phone Number</Index.FormHelperText>
                        <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                    
                                />
                        </Index.Box>
                    </Index.Box>
                    <Index.Box className="input-box modal-input-box">
                        <Index.FormHelperText className='form-lable'>Password</Index.FormHelperText>
                        <Index.Box className="form-group">
                                <Index.TextField
                                    fullWidth
                                    id="fullWidth"
                                    className="form-control"
                                    placeholder=""
                                    
                                />
                        </Index.Box>
                    </Index.Box>
                    
                    <Index.Box className="modal-footer">
                        <Index.Box className="modal-user-btn-flex">
                            <Index.Box className="discard-btn-main border-btn-main btn-modal-both">
                                <Index.Button className='discard-user-btn border-btn add-btn-modal user-modal-btn'>Add</Index.Button>
                            </Index.Box>
                            <Index.Box className="save-btn-main border-btn-main">
                                <Index.Button className='save-user-btn border-btn cancel-btn bg-suspend user-modal-btn-color'>Cancel</Index.Button>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
            </Index.Box>
            </Index.Modal>
            {/* Edit User Details End */}
            {/* head filter end */}
        </div>

        {/* work table start */}
        <Index.Box className="admin-dashboard-list-row">
            <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
                display="grid"
                className="display-row"
                gridTemplateColumns="repeat(12, 1fr)"
                gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
                <Index.Box
                gridColumn={{
                    xs: "span 12",
                    sm: "span 12",
                    md: "span 12",
                    lg: "span 12",
                }}
                className="grid-column"
                >
                <Index.Box className="admin-dash-box box-shadow">
                    
                    <Index.Box className="page-table-main p-t-15">
                    <Index.TableContainer
                        component={Index.Paper}
                        className="table-container"
                    >
                        <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                        >
                        <Index.TableHead className="table-head">
                            <Index.TableRow className="table-row">
                            <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                            >
                                Job Title 
                            </Index.TableCell>
                            <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                            >
                                Status
                            </Index.TableCell>
                            <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                            >
                                User Name
                            </Index.TableCell>
                            <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                            >
                                Agency 
                            </Index.TableCell>
                            <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                            >
                                Assigned Officer
                            </Index.TableCell>
                            <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                            >
                                Location
                            </Index.TableCell>
                            <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                            >
                                Total Hours
                            </Index.TableCell>
                            <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                            >
                                Pay Rate
                            </Index.TableCell>
                            <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                            >
                                Total Amount
                            </Index.TableCell>
                            <Index.TableCell
                                component="th"
                                variant="th"
                                className="table-th"
                            >
                                Overall Rating
                            </Index.TableCell>
                            </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                            {rows.map((row) => (
                            <Index.TableRow
                                key={row.jobTitle}
                                sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                                }}
                            >
                                <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                                >
                                {row.jobTitle}
                                </Index.TableCell>
                                <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                >
                                {row.status}
                                </Index.TableCell>
                                <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                >
                                {row.client}
                                </Index.TableCell>
                                <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                >
                                {row.agency}
                                </Index.TableCell>
                                <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                >
                                {row.assignedOfficer}
                                </Index.TableCell>
                                <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                >
                                {row.location}
                                </Index.TableCell>
                                <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                >
                                {row.totalHours}
                                </Index.TableCell>
                                <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                >
                                {row.payRate}
                                </Index.TableCell>
                                <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                >
                                {row.totalAmount}
                                </Index.TableCell>
                                <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                                >
                                {row.overallRating}
                                </Index.TableCell>
                            </Index.TableRow>
                            ))}
                        </Index.TableBody>
                        </Index.Table>
                    </Index.TableContainer>
                    </Index.Box>
                </Index.Box>
                </Index.Box>
            </Index.Box>
            </Index.Box>
        </Index.Box>
        {/* work table end */}
    </div>
    </>
  )
}

export default Viewreport