import React from "react";

import { Outlet } from "react-router-dom";
import PagesIndex from "../../container/PageIndex";

const AdminPrivateLayout = ({ children }) => {
  const location = PagesIndex.useLocation();

  const token = localStorage.getItem("AdminToken");

  const isAdminLoggedIn = (token) => {
    if (!token) return false;
    PagesIndex.DataService.defaults.headers.common.auth = token;
    return true;
  };

  return isAdminLoggedIn(token) ? (
    <Outlet />
  ) : (
    <PagesIndex.Navigate
      to="/admin"
      state={{ from: location }}
      replace={true}
    />
  );
};

export default AdminPrivateLayout;
