import React, { useEffect } from "react";

import { Outlet, useNavigate } from "react-router-dom";
import PagesIndex from "../../container/PageIndex";
import DataService from "../../config/DataService";
import { logout } from "../../redux-toolkit/slice/admin-slice/AdminSlice";
import { useDispatch, useSelector } from "react-redux";
// import { logout } from "../../redux-toolkit";


const AgencyPrivateLayout = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    DataService.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 403) {
          // If 403 response is received, initiate logout
          return navigate("/restrict-page")
        } else if (error.response.status === 401) {
          console.log()
          localStorage.removeItem("AgencyToken")
          return navigate("/logged-in-another-device")
        }
        return Promise.reject(error)
      }
    );
  }, [])

  const location = PagesIndex.useLocation();

  const token = localStorage.getItem("AgencyToken");

  const isAgencyLoggedIn = (token) => {
    if (!token) return false;
    PagesIndex.DataService.defaults.headers.common.auth = token;
    return true;
  };

  return isAgencyLoggedIn(token) ? (
    <Outlet />
  ) : (
    <PagesIndex.Navigate
      to="/"
      state={{ from: location }}
      replace={true}
    />
  );
};

export default AgencyPrivateLayout;
