import PagesIndex from "../../container/PageIndex";

export function showDateTime(jobData) {
  if (!jobData || !jobData.jobSchedule) return null; // Check if jobData and jobSchedule exist

  const formattedTime = jobData.jobSchedule.map((items, index) => {
    const momentDate1 = PagesIndex.moment(items?.jobStartDate).format(
      "MM-DD-YYYY"
    );
    const momentDate2 = PagesIndex.moment(items?.jobEndDate).format(
      "MM-DD-YYYY"
    );
    const startTime = items.jobStartTime;
    const endTime = items.jobEndTime;
    // const momentTime1 = PagesIndex.moment(startTime, 'HH:mm');
    // const momentTime2 = PagesIndex.moment(endTime, 'HH:mm');
    return `${momentDate1} to ${momentDate2} - ${startTime} to ${endTime} \n`; // Return the formatted string inside map
  });

  return formattedTime; // Return the formatted time
}
