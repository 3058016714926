import PagesIndex from "../../container/PageIndex";

export function timeDifferenceCalculation(jobData) {
  let totalHours = PagesIndex.moment.duration(0);
  jobData?.jobSchedule?.map((items, index) => {
    
    let startTime = items.jobStartTime;
    let endTime = items.jobEndTime;
    let momentTime1 = PagesIndex.moment(startTime, "HH:mm");
    let momentTime2 = PagesIndex.moment(endTime, "HH:mm");
    let differenceInMinutes = momentTime2.diff(momentTime1, "minutes");
    const duration = PagesIndex.moment.duration(differenceInMinutes, "minutes");
    // Add the duration to totalHours
    totalHours = totalHours.add(duration);
  });
  const formattedTime = PagesIndex.moment
    .utc(totalHours.asMilliseconds())
    .format("HH:mm");

  return formattedTime;
  
}


// const removeColon = (s) => {
//   if (s.length === 4 || s.length === 5) {
//     return parseInt(s.replace(":", ""));
//   }
//   return parseInt(s);
// };
// export const timeDifferenceCalculation = (startTime, endTime) => {
//   let time1 = removeColon(startTime);
//   let time2 = removeColon(endTime);

//   // console.log("remove1", time1);
//   // console.log("remove2", time2);
//   let hourDiff = parseInt(time2 / 100 - time1 / 100);
//   let minDiff = parseInt((time2 % 100) + (60 - (time1 % 100)));

//   if (hourDiff == 0) {
//     minDiff -= 60;
//   } else if (minDiff >= 60) {
//     // hourDiff++
//     minDiff -= 60;
//   }

//   const res = `${hourDiff}:${minDiff.toString().padStart(2, "0")}`;
//   return res;
// };

// export function timeDifferenceCalculation(startTime, endTime) {
//   const [startHour, startMinute] = startTime.split(':').map(Number);
//   const [endHour, endMinute] = endTime.split(':').map(Number);

//   let hourDiff = endHour - startHour;
//   let minuteDiff = endMinute - startMinute;

//   if (minuteDiff < 0) {
//     minuteDiff += 60;
//     hourDiff--;
//   }

//   const result = `${hourDiff}:${minuteDiff.toString().padStart(2, '0')}`;
//   return result;
// }
