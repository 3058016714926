
import React from 'react'
import PagesIndex from '../../../PageIndex';
import Index from '../../../index';

export default function AdminJobManagementCompletedJobs() {

    const [ratingvalue, setRatingValue] = React.useState(2);
  return (
    <div>
     <Index.Box className="dashboard-content extra-work-main">
                    <Index.Box className='card-main'>
                        <Index.Box className="title-header-flex">
                            <img
                                src={PagesIndex.Svg.leftArrow}
                                className="left-arrow-ic" alt='search grey img'
                            ></img>
                            <Index.Box className='job-title-header'>
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Job Title <span>for</span> $1000
                                </Index.Typography>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="assigned-officer-main box-shadow">
                            <Index.Box className="assigned-officer-header">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="main-title"
                                >
                                   Assigned Officer & Associated Agency/Department
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="assigned-officer-Detail admin-assigned-officer-Detail">
                                <Index.Box className="card-main admin-card-main admin-card-main-box">
                                    <Index.Box className="card-left admin-card-left">
                                        <Index.Box className='officer-profile'>
                                            <img src={PagesIndex.Png.usericon} className='prof-img' />
                                        </Index.Box>
                                        <Index.Box className='officer-detail'>
                                            <Index.Box className="job-title-text">
                                                <Index.Typography
                                                    component="p"
                                                    variant="p"
                                                    className="job-title-label"
                                                >
                                                    Officer Name
                                                </Index.Typography>
                                            </Index.Box>
    
    
                                            <Index.Box className="job-title-text">
    
                                                <Index.Typography
                                                    component="p"
                                                    variant="p"
                                                    className="job-title-text-label"
                                                >
                                                    Active police Officer
                                                </Index.Typography>
                                            </Index.Box>
    
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="card-right admin-card-right">
                                        <img src={PagesIndex.Svg.rightArrow} className='left-arrow-ic' />
    
                                    </Index.Box>
                                </Index.Box>
                                <Index.Box className="card-main admin-card-main admin-card-main-box">
                                    <Index.Box className="card-left admin-card-left">
                                        {/* <Index.Box className='officer-profile'>
                                            <img src={PagesIndex.Png.usericon} className='prof-img' />
                                        </Index.Box> */}
                                        <Index.Box className='officer-detail'>
                                            <Index.Box className="job-title-text">
                                                <Index.Typography
                                                    component="p"
                                                    variant="p"
                                                    className="job-title-label"
                                                >
                                                   Agency Name 
                                                </Index.Typography>
                                            </Index.Box>
    
    
                                            <Index.Box className="job-title-text">
    
                                                <Index.Typography
                                                    component="p"
                                                    variant="p"
                                                    className="job-title-text-label"
                                                >
                                                    Location
                                                </Index.Typography>
                                            </Index.Box>
    
                                        </Index.Box>
                                    </Index.Box>
                                    <Index.Box className="card-right admin-card-right">
                                        <img src={PagesIndex.Svg.rightArrow} className='left-arrow-ic' />
    
                                    </Index.Box>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="assigned-officer-main box-sha">
                            <Index.Box className="assigned-officer-header">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="main-title"
                                >
                                    General Information
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className='officer-detail'>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Date :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        00/00/0000 to 00/00/0000
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Time :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        00:00:00 AM to 00:00:00 Pm
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Location :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        Location
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Service :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        Service
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Required Skill :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        skill
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Department :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        Department
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Total Hourse :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        10
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Pay Rate :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        $100/Hour
                                    </Index.Typography>
                                </Index.Box>
    
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="assigned-officer-main box-sha">
                            <Index.Box className="assigned-officer-header">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="main-title"
                                >
                                    Organizer (Client)
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className='officer-detail'>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Name :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        Username
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Mobile Number :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        9876543210
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Email Address :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        username@gmail.com
                                    </Index.Typography>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="assigned-officer-main box-sha">
                            <Index.Box className="assigned-officer-header">
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="main-title"
                                >
                                    Job Log
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className='officer-detail'>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Job posted on :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        00/00/0000
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Officer Assigned on :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        00/00/0000
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Job Transferred To :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        {/* username@gmail.com */}
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Job Transferred On :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        {/* username@gmail.com */}
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Officer Check-In :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        00/00/00 00:00:00 AM/PM
                                    </Index.Typography>
                                </Index.Box>
                                <Index.Box className="job-title-text">
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-label"
                                    >
                                        Officer Check-Out :&nbsp;
                                    </Index.Typography>
                                    <Index.Typography
                                        component="p"
                                        variant="p"
                                        className="job-title-text-label"
                                    >
                                        00/00/00 00:00:00 AM/PM
                                    </Index.Typography>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                        <Index.Box className="assigned-officer-main box-sha">
                        <Index.Box className="assigned-officer-header">
                            <Index.Typography
                                component="p"
                                variant="p"
                                className="main-title"
                            >
                                Ratin & Review
                            </Index.Typography>
                        </Index.Box>
                        <Index.Box className='officer-detail'>
                            
                            <Index.Box className="job-title-text">
                            <Index.Box className='rating-main'>
                                    <Index.Box
                                        sx={{
                                            '& > legend': { mt: 2 },
                                        }}
                                    >
                                        <Index.Rating
                                            name="simple-controlled"
                                            value={ratingvalue}
                                            onChange={(event, newValue) => {
                                                setRatingValue(newValue);
                                            }}
                                        />
                                    </Index.Box>
                                </Index.Box>
                                </Index.Box>
                            <Index.Box className="job-title-text">
                                
                                {/* <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-label"
                                >
                                    Name :&nbsp;
                                </Index.Typography> */}
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label"
                                >
                                    "Review from the client for the completed service/job will be shown here"
                                </Index.Typography>
                            </Index.Box>
                            <Index.Box className="job-title-text">
                                
                               
                                <Index.Typography
                                    component="p"
                                    variant="p"
                                    className="job-title-text-label text-right"
                                >
                                    -00/00/0000  00:00:00 AM/PM
                                </Index.Typography>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                    </Index.Box>
    
                </Index.Box>
    </div>
  )
}
