import React, { useEffect, useState } from "react";
import Index from "../../../index";
import PagesIndex from "../../../PageIndex";
import Loader from "../../../../common/loader/Loader";
import { Badge } from "@mui/material";

export default function Notification() {
  const [notification, setNotifications] = useState([]);
  // const [notificationIsread, setNotificationIsRead] = useState([]);
  const [loading, setLoading] = useState(false);


  const getNotification = async () => {
    try {
      setLoading(true);
      const res = await PagesIndex.DataService.get(
        PagesIndex.Api.Agency.GET_NOTIFICATION_HISTORY
      );
      setNotifications(res?.data?.data);
      let unreadCount = res?.data?.data.filter((data) => !data?.isRead)?.length
      localStorage.setItem("agencynotificationcount", unreadCount)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getNotification();
  }, []);



  const readNotificationPush = async (item) => {
    try {
      const urlEncoded = new URLSearchParams();
      urlEncoded.append("_id", item?._id);
      const res = await PagesIndex.DataService.post(
        PagesIndex.Api.Agency.UPDATE_NOTIFICATION_HISTORY, urlEncoded
      );
      if (res.status === 200) {
        getNotification()
        // setNotificationIsRead(res.data.data)
      } else {
        PagesIndex.toast.error(res?.message);
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message);
    }
  };

  const onRedirectNotification = (item) => {
    if (!item?.isRead) {
      readNotificationPush(item)
      // readUnreadPushNotification({
      //   id: item?._id,
      //   readUnread: item?.readUnread,
      // });
    }
  }

  return (
    <>
      {/* <Index.Box className="notification-page-wrape">
        <Index.Box className="notification-title-sec">
          {!loading ? (
            notification?.length ? (
              notification?.map((items, index) => (
                <Index.Box key={index} className="notification-main-box">
                  <Index.Box className="notification-box">
                    <Index.Box className="notification-img">
                      <img
                        src={PagesIndex.Png.avataricon}
                        alt="logo"
                        className="img-notification"
                      />
                    </Index.Box>

                    <Index.Box
                      onClick={() => onRedirectNotification(items)}
                      sx={{ cursor: "pointer" }}
                    >
                      <Index.Box sx={{ cursor: "pointer" }}>
                        <Index.Box>
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="notification-text"
                          >
                            {items?.title ? items?.title : "-"}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box>
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="notification-sub-text"
                          >
                            {items?.body ? items?.body : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box>
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="notification-time"
                      >
                        {PagesIndex.moment(items?.createdAt).format(
                          "MM-DD-YYYY hh:mm:ss A"
                        )}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="admin-header-right top-right-notification">
                    {items?.isRead === false && (
                      <Badge color={"secondary"} variant={"dot"}></Badge>
                    )}
                  </Index.Box>
                </Index.Box>
              ))
            ) : (
              <Index.Typography
                variant="p"
                component="p"
                className="notification-sub-text"
              >
                <PagesIndex.NoDataFound />
              </Index.Typography>
            )
          ) : (
            <Index.Box className="single-page-loader">
              <Loader />
            </Index.Box>
          )}
        </Index.Box>
      </Index.Box> */}
      <Index.Box className="notification-page-wrape">
        <>
          {!loading ? (
            notification?.length ? (
              notification?.map((items, index) => (
                <Index.Box key={index} className="notification-main-box common-card">
                  <Index.Box className="notification-left-main">
                    <Index.Box className="notification-box">
                      <Index.Box className="notification-img">
                        <img
                          src={PagesIndex.Png.avataricon}
                          alt="logo"
                          className="img-notification"
                        />
                      </Index.Box>
                      <Index.Box className="notification-text-box"
                        onClick={() => onRedirectNotification(items)}
                      >
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="notification-text"
                        >
                          {items?.title ? items?.title : "-"}
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="notification-sub-text"
                        >
                          {items?.body ? items?.body : "-"}
                        </Index.Typography>
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="notification-time"
                        >
                          {PagesIndex.moment(items?.createdAt).format(
                            "MM-DD-YYYY hh:mm:ss A"
                          )}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="notification-right-main">
                    {items?.isRead === false && (
                      <Badge color={"secondary"} variant={"dot"}></Badge>
                    )}
                  </Index.Box>
                </Index.Box>
              ))
            ) : (
              <PagesIndex.NoDataFound />
            )
          ) : (
            <Index.Box className="single-page-loader">
              <Loader />
            </Index.Box>
          )}
        </>
      </Index.Box>
    </>
  );
}