import React from "react";
import Index from "../../../..";
import PagesIndex from "../../../../PageIndex";
import DataService from "../../../../../config/DataService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AdminUserRestrict = (props) => {
  const {
    restrictUserhandleClose,
    restrictUserModalopen,
    restrictUserStatus,
    getUserListData,
  } = props;

  // console.log("restrictUserStatus",restrictUserStatus)

  const handleUserRestrictedConfirm = async () => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("isApprove", restrictUserStatus?.status ? true : false);
    urlEncoded.append("status", restrictUserStatus?.status ? "ACTIVE" : "RESTRICT");
    urlEncoded.append("id", restrictUserStatus?.userId);
    console.log("urlEncoded", [...urlEncoded]);
    try {
      const response = await DataService.post(
        PagesIndex.Api.Admin.RESTRICT_USER,
        urlEncoded
      );
      if (response?.data?.status == 200) {
        PagesIndex.toast.success(response?.data?.message, { toastId: "customID" });
        getUserListData();
        restrictUserhandleClose();
      }
    } catch (error) {
      PagesIndex.toast.error(error?.response?.data?.message, { toastId: "customID" });
    }
  };
  return (
    <>
      <Index.Modal
        open={restrictUserModalopen}
        onClose={restrictUserhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-delete modal"
      >

        <Index.Box
          sx={style}
          className="add-user-modal-inner-main modal-inner extra-delete"
        >
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >

            </Index.Typography>
            <img
              src={PagesIndex.Svg.closeblack}
              className="modal-close-icon"
              onClick={restrictUserhandleClose}
              alt=""
            />
          </Index.Box>

          <Index.Box className="modal-body">
            <Index.Box className="admin-modal-hgt-scroll">

              <Index.Box className="only-message-wrape">
                <img
                  src={PagesIndex.Svg.deletecross}
                  alt="Delete"
                  className="delete-icon"
                />
                <Index.Typography component="h6">Are you sure?</Index.Typography>
                <Index.Typography component="p">
                  Do you really want to restrict this user?
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

            <Index.Box className="modal-footer">
              <Index.Box className="modal-footer-btn-flex">
                <Index.PrimaryButton
                  btnLabel="Confirm"
                  className="btn-primary bg-approve appr-rej-btn"
                  onClick={() => handleUserRestrictedConfirm()}
                />

                <Index.PrimaryButton
                  btnLabel="Cancel"
                  className="btn-primary bg-suspend appr-rej-btn"
                  onClick={() => restrictUserhandleClose()}
                />
              </Index.Box>
            </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  );
};

export default AdminUserRestrict;
